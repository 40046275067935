var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode';
import axios from 'axios';
import { toast } from 'react-toastify';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 500px;\n  margin: 0 auto;\n  padding: 40px;\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n"], ["\n  max-width: 500px;\n  margin: 0 auto;\n  padding: 40px;\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n"])));
var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var QRContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  margin: 24px 0;\n"], ["\n  text-align: center;\n  margin: 24px 0;\n"])));
var Input = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n"], ["\n  width: 100%;\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n"])));
var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  padding: 12px;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border: none;\n  border-radius: 8px;\n  color: white;\n  font-family: 'Inter';\n  font-weight: 500;\n  cursor: pointer;\n\n  &:hover {\n    opacity: 0.9;\n  }\n"], ["\n  width: 100%;\n  padding: 12px;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border: none;\n  border-radius: 8px;\n  color: white;\n  font-family: 'Inter';\n  font-weight: 500;\n  cursor: pointer;\n\n  &:hover {\n    opacity: 0.9;\n  }\n"])));
var ErrorText = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #ff4d4f;\n  font-size: 14px;\n  margin: 8px 0;\n"], ["\n  color: #ff4d4f;\n  font-size: 14px;\n  margin: 8px 0;\n"])));
var LoadingSpinner = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  margin-left: 8px;\n  border: 2px solid #f3f3f3;\n  border-top: 2px solid #7094F4;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n  display: inline-block;\n\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n"], ["\n  width: 20px;\n  height: 20px;\n  margin-left: 8px;\n  border: 2px solid #f3f3f3;\n  border-top: 2px solid #7094F4;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n  display: inline-block;\n\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n"])));
var saveMFASecret = function (token, secret) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post('/api/auth/mfa_setup/verify/', {
                        token: token,
                        secret: secret
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data.success];
            case 2:
                error_1 = _a.sent();
                console.error('Failed to save MFA secret:', error_1);
                throw error_1;
            case 3: return [2 /*return*/];
        }
    });
}); };
var MFASetup = function (_a) {
    var onSetupComplete = _a.onSetupComplete;
    var _b = useState(''), secret = _b[0], setSecret = _b[1];
    var _c = useState(''), qrCode = _c[0], setQrCode = _c[1];
    var _d = useState(''), token = _d[0], setToken = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var _f = useState(''), error = _f[0], setError = _f[1];
    var _g = useState(''), userEmail = _g[0], setUserEmail = _g[1];
    useEffect(function () {
        var generateSecret = function () { return __awaiter(void 0, void 0, void 0, function () {
            var userResponse, email, response, secret_1, otpAuth, qrImage, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, axios.get('/api/auth/profile/')];
                    case 1:
                        userResponse = _a.sent();
                        email = userResponse.data.email;
                        setUserEmail(email);
                        return [4 /*yield*/, axios.post('/api/auth/mfa/setup/generate/')];
                    case 2:
                        response = _a.sent();
                        secret_1 = response.data.secret;
                        setSecret(secret_1);
                        otpAuth = "otpauth://totp/CognitioIntelligence:".concat(email, "?secret=").concat(secret_1, "&issuer=CognitioIntelligence");
                        return [4 /*yield*/, QRCode.toDataURL(otpAuth)];
                    case 3:
                        qrImage = _a.sent();
                        setQrCode(qrImage);
                        toast.success('2FA setup initialized');
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        setError('Failed to generate MFA secret');
                        toast.error('Failed to initialize 2FA setup');
                        console.error(error_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        generateSecret();
    }, []);
    var handleVerify = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    setError('');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.post('/api/auth/mfa/setup/verify/', {
                            token: token,
                            secret: secret
                        })];
                case 2:
                    response = _a.sent();
                    if (response.data.success) {
                        toast.success('2FA setup completed successfully');
                        onSetupComplete();
                    }
                    else {
                        setError('Invalid verification code');
                        toast.error('Invalid verification code');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_3 = _a.sent();
                    setError('Failed to verify code');
                    toast.error('Failed to verify code. Please try again.');
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Set Up Two-Factor Authentication"),
        React.createElement("p", null, "Scan the QR code with your authenticator app:"),
        React.createElement(QRContainer, null, qrCode && React.createElement("img", { src: qrCode, alt: "QR Code" })),
        React.createElement("p", null, "Enter the verification code from your authenticator app:"),
        React.createElement(Input, { type: "text", value: token, onChange: function (e) { return setToken(e.target.value); }, placeholder: "Enter 6-digit code", disabled: loading }),
        error && React.createElement(ErrorText, null, error),
        React.createElement(Button, { onClick: handleVerify, disabled: loading }, loading ? (React.createElement(React.Fragment, null,
            "Verifying",
            React.createElement(LoadingSpinner, null))) : ('Verify and Enable 2FA'))));
};
export default MFASetup;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
