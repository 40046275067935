var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
export var HomeIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "#000000" : _b;
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
        React.createElement("path", { d: "M7.135 18.773v-3.057c0-.78.637-1.414 1.423-1.414h2.875c.377 0 .74.15 1.006.414.267.265.417.625.417 1v3.057c-.002.325.126.637.356.867.23.23.544.36.87.36h1.962c1.088 0 2.132-.43 2.902-1.195.77-.766 1.204-1.802 1.204-2.883V7.867c0-.833-.347-1.627-.957-2.197L12.46.733c-1.223-1.133-3.115-1.133-4.338 0L1.39 5.67C.78 6.24.433 7.034.433 7.867v9.065c0 2.25 1.844 4.078 4.106 4.078h1.963c.68 0 1.231-.544 1.236-1.218l-.003-1.019Z", fill: color })));
};
export var DashboardIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "M8.5 3h-6v6h6V3ZM17.5 3h-6v6h6V3ZM17.5 11h-6v6h6v-6ZM8.5 11h-6v6h6v-6Z", fill: "currentColor" }))); };
export var AnalysisIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "M8.5 3.5A5.5 5.5 0 0 1 14 9c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5-1.5 1.5-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 7.5 15 5.5 5.5 0 0 1 2 9.5 5.5 5.5 0 0 1 7.5 4c.35 0 .69.03 1 .1Z", fill: "currentColor" }))); };
export var DatasetIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "M15 2h-4L9 0H5L3 2H1v2h14V2ZM2 17c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5H2v12Z", fill: "currentColor" }))); };
export var HistoryIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0Zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8Zm.5-13H9v6l5.2 3.2.8-1.3-4.5-2.7V5Z", fill: "currentColor" }))); };
export var StarIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "m10 15.27 6.18 3.73-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27Z", fill: "currentColor" }))); };
export var SettingsIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
    React.createElement("path", { d: "M15.95 10.78c.02-.25.05-.51.05-.78s-.03-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.03.25-.05.52-.05.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32ZM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3Z", fill: "currentColor" }))); };
export var QuestionIcon = function () { return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M12 17H12.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export var ExitIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M13.3334 14.1667L17.5 10L13.3334 5.83337", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M17.5 10H7.5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export var TableIcon = function (_a) {
    var style = _a.style, _b = _a.size, size = _b === void 0 ? 20 : _b, props = __rest(_a, ["style", "size"]);
    return (React.createElement("svg", __assign({ width: size, height: size, viewBox: "0 0 20 20", fill: "none", style: style }, props),
        React.createElement("path", { d: "M2 4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V4Z", stroke: "currentColor", strokeWidth: "1.5" }),
        React.createElement("path", { d: "M2 7H18", stroke: "currentColor", strokeWidth: "1.5" }),
        React.createElement("path", { d: "M7 7V18", stroke: "currentColor", strokeWidth: "1.5" })));
};
export var ColumnIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, props = __rest(_a, ["size"]);
    return (React.createElement("svg", __assign({ width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5ZM9 19H5V5H9V19ZM15 19H11V5H15V19ZM19 19H17V5H19V19Z", fill: "currentColor" })));
};
export var DatabaseIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 20 : _b, props = __rest(_a, ["size"]);
    return (React.createElement("svg", __assign({ width: size, height: size, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M10 1C5.5 1 2 2.6 2 4.5V15.5C2 17.4 5.5 19 10 19C14.5 19 18 17.4 18 15.5V4.5C18 2.6 14.5 1 10 1ZM16 15.5C16 16.2 13.5 17 10 17C6.5 17 4 16.2 4 15.5V12.9C5.5 13.7 7.7 14 10 14C12.3 14 14.5 13.7 16 12.9V15.5ZM16 10.5C16 11.2 13.5 12 10 12C6.5 12 4 11.2 4 10.5V7.9C5.5 8.7 7.7 9 10 9C12.3 9 14.5 8.7 16 7.9V10.5ZM10 7C6.5 7 4 6.2 4 5.5C4 4.8 6.5 4 10 4C13.5 4 16 4.8 16 5.5C16 6.2 13.5 7 10 7Z", fill: "currentColor" })));
};
export var ChevronRightIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 20 : _b, props = __rest(_a, ["size"]);
    return (React.createElement("svg", __assign({ width: size, height: size, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M7.5 15L12.5 10L7.5 5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export var ChevronDownIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 16 : _b, style = _a.style, props = __rest(_a, ["size", "style"]);
    return (React.createElement("svg", __assign({ width: size, height: size, viewBox: "0 0 24 24", fill: "none", style: style }, props),
        React.createElement("path", { d: "M7 10l5 5 5-5H7z", fill: "currentColor" })));
};
export var ChevronLeftIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 16 : _b, props = __rest(_a, ["size"]);
    return (React.createElement("svg", __assign({ width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M15 18l-6-6 6-6", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export var DownloadIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b;
    return (React.createElement("svg", { width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export var ExplorerIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? '#8F4ACF' : _b;
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7 12H17M12 7V17", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export var GlossaryIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c;
    return (React.createElement("svg", { width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.5 2H20V22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export var OrchestrationIcon = function () { return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M15 2H5C3.34315 2 2 3.34315 2 5V15C2 16.6569 3.34315 18 5 18H15C16.6569 18 18 16.6569 18 15V5C18 3.34315 16.6569 2 15 2Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M7 10H13M10 7V13", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }))); };
