var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DownloadIcon } from '../../assets/Icons';
import { toast } from 'react-toastify';
import { getCookie } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var ActivitySection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var Table = styled.table(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n"])));
var Th = styled.th(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"], ["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"])));
var Td = styled.td(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"], ["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"])));
var ExportButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var StatusBadge = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n"], ["\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n"])), function (props) {
    switch (props.status) {
        case 'success': return '#E6F4EA';
        case 'warning': return '#FFF3E0';
        case 'error': return '#FFEEF0';
    }
}, function (props) {
    switch (props.status) {
        case 'success': return '#137333';
        case 'warning': return '#B95000';
        case 'error': return '#D93025';
    }
});
var FilterSection = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n  margin-bottom: 24px;\n  align-items: center;\n"], ["\n  display: flex;\n  gap: 16px;\n  margin-bottom: 24px;\n  align-items: center;\n"])));
var SearchInput = styled.input(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  width: 300px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  width: 300px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var FilterSelect = styled.select(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  background: white;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  background: white;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var ActivityHistory = function () {
    var _a = useState([]), activityLogs = _a[0], setActivityLogs = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(''), searchTerm = _d[0], setSearchTerm = _d[1];
    var _e = useState('all'), statusFilter = _e[0], setStatusFilter = _e[1];
    var _f = useState('all'), departmentFilter = _f[0], setDepartmentFilter = _f[1];
    var _g = useState([]), departments = _g[0], setDepartments = _g[1];
    useEffect(function () {
        fetchActivityLogs();
        window.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        var uniqueDepartments = Array.from(new Set(activityLogs.map(function (log) { return log.department; })));
        setDepartments(uniqueDepartments);
    }, [activityLogs]);
    var filteredLogs = activityLogs.filter(function (log) {
        var matchesSearch = log.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
            log.action.toLowerCase().includes(searchTerm.toLowerCase()) ||
            log.department.toLowerCase().includes(searchTerm.toLowerCase());
        var matchesStatus = statusFilter === 'all' || log.status === statusFilter;
        var matchesDepartment = departmentFilter === 'all' || log.department === departmentFilter;
        return matchesSearch && matchesStatus && matchesDepartment;
    });
    var fetchActivityLogs = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    return [4 /*yield*/, fetch('/api/activity-logs/', {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || ''
                            },
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch activity logs');
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (data.status === 'success') {
                        setActivityLogs(data.logs);
                    }
                    else {
                        throw new Error(data.message || 'Failed to fetch activity logs');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    errorMessage = error_1 instanceof Error ? error_1.message : 'An unexpected error occurred';
                    setError(errorMessage);
                    toast.error('Failed to load activity logs');
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleExport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, blob, url, a, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/activity-logs/export/', {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || ''
                            },
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to export activity logs');
                    }
                    return [4 /*yield*/, response.blob()];
                case 2:
                    blob = _a.sent();
                    url = window.URL.createObjectURL(blob);
                    a = document.createElement('a');
                    a.href = url;
                    a.download = "activity-logs-".concat(new Date().toISOString().split('T')[0], ".csv");
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    toast.success('Activity logs exported successfully');
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error exporting logs:', error_2);
                    toast.error('Failed to export activity logs');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (loading) {
        return (React.createElement(Container, null,
            React.createElement(Title, null, "Activity History"),
            React.createElement(ActivitySection, null,
                React.createElement("div", null, "Loading activity logs..."))));
    }
    if (error) {
        return (React.createElement(Container, null,
            React.createElement(Title, null, "Activity History"),
            React.createElement(ActivitySection, null,
                React.createElement("div", null,
                    "Error: ",
                    error))));
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Activity History"),
        React.createElement(ActivitySection, null,
            React.createElement(ExportButton, { onClick: handleExport },
                React.createElement(DownloadIcon, { size: 16 }),
                "Export to CSV"),
            React.createElement(FilterSection, null,
                React.createElement(SearchInput, { placeholder: "Search by user, action, or department...", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); } }),
                React.createElement(FilterSelect, { value: statusFilter, onChange: function (e) { return setStatusFilter(e.target.value); } },
                    React.createElement("option", { value: "all" }, "All Status"),
                    React.createElement("option", { value: "success" }, "Success"),
                    React.createElement("option", { value: "warning" }, "Warning"),
                    React.createElement("option", { value: "error" }, "Error")),
                React.createElement(FilterSelect, { value: departmentFilter, onChange: function (e) { return setDepartmentFilter(e.target.value); } },
                    React.createElement("option", { value: "all" }, "All Departments"),
                    departments.map(function (dept) { return (React.createElement("option", { key: dept, value: dept }, dept)); }))),
            React.createElement(Table, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement(Th, null, "User"),
                        React.createElement(Th, null, "Department"),
                        React.createElement(Th, null, "Action"),
                        React.createElement(Th, null, "Details"),
                        React.createElement(Th, null, "Status"),
                        React.createElement(Th, null, "Timestamp"))),
                React.createElement("tbody", null, filteredLogs.map(function (log) { return (React.createElement("tr", { key: log.id },
                    React.createElement(Td, null, log.username),
                    React.createElement(Td, null, log.department),
                    React.createElement(Td, null, log.action),
                    React.createElement(Td, null,
                        log.details.target_type && (React.createElement("span", null,
                            log.details.target_type,
                            log.details.target_id && " #".concat(log.details.target_id))),
                        log.details.metadata && (React.createElement("div", { style: { fontSize: '12px', color: '#666' } }, Object.entries(log.details.metadata)
                            .map(function (_a) {
                            var key = _a[0], value = _a[1];
                            return "".concat(key, ": ").concat(value);
                        })
                            .join(', ')))),
                    React.createElement(Td, null,
                        React.createElement(StatusBadge, { status: log.status }, log.status.charAt(0).toUpperCase() + log.status.slice(1))),
                    React.createElement(Td, null, new Date(log.timestamp).toLocaleString()))); }))))));
};
export default ActivityHistory;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
