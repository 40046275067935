var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 90px;\n  width: 100%;\n  min-height: calc(100vh - 90px);\n  background: linear-gradient(135deg, #F8F5FF 0%, #ECEAFF 100%);\n"], ["\n  padding-top: 90px;\n  width: 100%;\n  min-height: calc(100vh - 90px);\n  background: linear-gradient(135deg, #F8F5FF 0%, #ECEAFF 100%);\n"])));
var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 60px 20px;\n  display: grid;\n  grid-template-columns: 1fr 1.5fr;\n  gap: 40px;\n\n  @media (max-width: 968px) {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 60px 20px;\n  display: grid;\n  grid-template-columns: 1fr 1.5fr;\n  gap: 40px;\n\n  @media (max-width: 968px) {\n    grid-template-columns: 1fr;\n  }\n"])));
var ContactInfo = styled(motion.div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: white;\n  padding: 40px;\n  border-radius: 20px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"], ["\n  background: white;\n  padding: 40px;\n  border-radius: 20px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"])));
var Title = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-weight: 700;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-weight: 700;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var InfoItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 24px;\n  \n  svg {\n    color: #8F4ACF;\n    font-size: 24px;\n    margin-right: 16px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 24px;\n  \n  svg {\n    color: #8F4ACF;\n    font-size: 24px;\n    margin-right: 16px;\n  }\n"])));
var InfoText = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-size: 16px;\n  color: #666666;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-size: 16px;\n  color: #666666;\n"])));
var Form = styled(motion.form)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: white;\n  border-radius: 20px;\n  padding: 40px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"], ["\n  background: white;\n  border-radius: 20px;\n  padding: 40px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"])));
var FormGroup = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 24px;\n"], ["\n  margin-bottom: 24px;\n"])));
var Label = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: block;\n  font-family: 'Inter', sans-serif;\n  font-weight: 500;\n  font-size: 14px;\n  margin-bottom: 8px;\n  color: #000000;\n"], ["\n  display: block;\n  font-family: 'Inter', sans-serif;\n  font-weight: 500;\n  font-size: 14px;\n  margin-bottom: 8px;\n  color: #000000;\n"])));
var Input = styled.input(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  height: 52px;\n  background: #F8F5FF;\n  border: 2px solid transparent;\n  border-radius: 12px;\n  padding: 0 16px;\n  font-family: 'Inter', sans-serif;\n  font-size: 14px;\n  color: #000000;\n  transition: all 0.2s;\n\n  &:focus {\n    border-color: #8F4ACF;\n    outline: none;\n  }\n"], ["\n  width: 100%;\n  height: 52px;\n  background: #F8F5FF;\n  border: 2px solid transparent;\n  border-radius: 12px;\n  padding: 0 16px;\n  font-family: 'Inter', sans-serif;\n  font-size: 14px;\n  color: #000000;\n  transition: all 0.2s;\n\n  &:focus {\n    border-color: #8F4ACF;\n    outline: none;\n  }\n"])));
var TextArea = styled.textarea(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 100%;\n  height: 150px;\n  background: #F8F5FF;\n  border: 2px solid transparent;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Inter', sans-serif;\n  font-size: 14px;\n  color: #000000;\n  resize: vertical;\n  transition: all 0.2s;\n\n  &:focus {\n    border-color: #8F4ACF;\n    outline: none;\n  }\n"], ["\n  width: 100%;\n  height: 150px;\n  background: #F8F5FF;\n  border: 2px solid transparent;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Inter', sans-serif;\n  font-size: 14px;\n  color: #000000;\n  resize: vertical;\n  transition: all 0.2s;\n\n  &:focus {\n    border-color: #8F4ACF;\n    outline: none;\n  }\n"])));
var Button = styled(motion.button)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  height: 52px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  color: #FFFFFF;\n  font-family: 'Inter', sans-serif;\n  font-weight: 500;\n  font-size: 16px;\n  border: none;\n  cursor: pointer;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #7B3AAF;\n  }\n"], ["\n  width: 100%;\n  height: 52px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  color: #FFFFFF;\n  font-family: 'Inter', sans-serif;\n  font-weight: 500;\n  font-size: 16px;\n  border: none;\n  cursor: pointer;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #7B3AAF;\n  }\n"])));
var ContactPage = function () {
    var handleSubmit = function (e) {
        e.preventDefault();
        // Add form submission logic here
    };
    return (React.createElement(PageContainer, null,
        React.createElement(ContentWrapper, null,
            React.createElement(ContactInfo, { initial: { opacity: 0, x: -20 }, animate: { opacity: 1, x: 0 }, transition: { duration: 0.5 } },
                React.createElement(Title, null, "Get in Touch"),
                React.createElement(InfoItem, null,
                    React.createElement(FaEnvelope, null),
                    React.createElement(InfoText, null, "contact@cognitio.com")),
                React.createElement(InfoItem, null,
                    React.createElement(FaPhone, null),
                    React.createElement(InfoText, null, "+1 (555) 123-4567")),
                React.createElement(InfoItem, null,
                    React.createElement(FaMapMarkerAlt, null),
                    React.createElement(InfoText, null, "123 AI Street, Tech Valley, CA 94025"))),
            React.createElement(Form, { onSubmit: handleSubmit, initial: { opacity: 0, x: 20 }, animate: { opacity: 1, x: 0 }, transition: { duration: 0.5 } },
                React.createElement(Title, null, "Send us a Message"),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Name"),
                    React.createElement(Input, { type: "text", placeholder: "Enter your name" })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Email"),
                    React.createElement(Input, { type: "email", placeholder: "Enter your email" })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Subject"),
                    React.createElement(Input, { type: "text", placeholder: "Enter subject" })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Message"),
                    React.createElement(TextArea, { placeholder: "Enter your message" })),
                React.createElement(Button, { type: "submit", whileHover: { scale: 1.02 }, whileTap: { scale: 0.98 } }, "Send Message")))));
};
export default ContactPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
