var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var PermissionsSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var Table = styled.table(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 24px;\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 24px;\n"])));
var Th = styled.th(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n  border-bottom: 1px solid #ECEAFF;\n"], ["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n  border-bottom: 1px solid #ECEAFF;\n"])));
var Td = styled.td(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 16px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #4A4A4A;\n  border-bottom: 1px solid #ECEAFF;\n"], ["\n  padding: 16px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #4A4A4A;\n  border-bottom: 1px solid #ECEAFF;\n"])));
var CheckboxGroup = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n"], ["\n  display: flex;\n  gap: 16px;\n"])));
var SaveButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-weight: 500;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AB3;\n  }\n  \n  &:disabled {\n    background: #CCCCCC;\n    cursor: not-allowed;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-weight: 500;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AB3;\n  }\n  \n  &:disabled {\n    background: #CCCCCC;\n    cursor: not-allowed;\n  }\n"])));
var UserPermissions = function () {
    var _a = useState([]), departments = _a[0], setDepartments = _a[1];
    var _b = useState([]), datasets = _b[0], setDatasets = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), saving = _d[0], setSaving = _d[1];
    var user = useAuth().user;
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var mockDepartments, mockDatasets;
            return __generator(this, function (_a) {
                try {
                    mockDepartments = [
                        { id: 1, name: 'Sales' },
                        { id: 2, name: 'Marketing' },
                        { id: 3, name: 'Engineering' }
                    ];
                    mockDatasets = [
                        {
                            id: 1,
                            name: 'Customer Data',
                            permissions: {
                                1: { canRead: true, canWrite: false },
                                2: { canRead: true, canWrite: true },
                                3: { canRead: true, canWrite: true }
                            }
                        },
                        {
                            id: 2,
                            name: 'Product Analytics',
                            permissions: {
                                1: { canRead: true, canWrite: false },
                                2: { canRead: true, canWrite: false },
                                3: { canRead: true, canWrite: true }
                            }
                        }
                    ];
                    setDepartments(mockDepartments);
                    setDatasets(mockDatasets);
                }
                catch (error) {
                    console.error('Error fetching permissions data:', error);
                }
                finally {
                    setLoading(false);
                }
                return [2 /*return*/];
            });
        }); };
        fetchData();
    }, []);
    var handlePermissionChange = function (datasetId, departmentId, type, checked) {
        setDatasets(function (prevDatasets) {
            return prevDatasets.map(function (dataset) {
                var _a, _b;
                if (dataset.id === datasetId) {
                    return __assign(__assign({}, dataset), { permissions: __assign(__assign({}, dataset.permissions), (_a = {}, _a[departmentId] = __assign(__assign({}, dataset.permissions[departmentId]), (_b = {}, _b[type === 'read' ? 'canRead' : 'canWrite'] = checked, _b)), _a)) });
                }
                return dataset;
            });
        });
    };
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSaving(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    // Replace with actual API call
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 1000); })];
                case 2:
                    // Replace with actual API call
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error saving permissions:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setSaving(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (loading) {
        return React.createElement(Container, null, "Loading...");
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Dataset Permissions"),
        React.createElement(PermissionsSection, null,
            React.createElement(Table, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement(Th, null, "Dataset"),
                        departments.map(function (dept) { return (React.createElement(Th, { key: dept.id }, dept.name)); }))),
                React.createElement("tbody", null, datasets.map(function (dataset) { return (React.createElement("tr", { key: dataset.id },
                    React.createElement(Td, null, dataset.name),
                    departments.map(function (dept) {
                        var _a, _b;
                        return (React.createElement(Td, { key: dept.id },
                            React.createElement(CheckboxGroup, null,
                                React.createElement("label", null,
                                    React.createElement("input", { type: "checkbox", checked: (_a = dataset.permissions[dept.id]) === null || _a === void 0 ? void 0 : _a.canRead, onChange: function (e) { return handlePermissionChange(dataset.id, dept.id, 'read', e.target.checked); } }),
                                    "Read"),
                                React.createElement("label", null,
                                    React.createElement("input", { type: "checkbox", checked: (_b = dataset.permissions[dept.id]) === null || _b === void 0 ? void 0 : _b.canWrite, onChange: function (e) { return handlePermissionChange(dataset.id, dept.id, 'write', e.target.checked); } }),
                                    "Write"))));
                    }))); }))),
            React.createElement(SaveButton, { onClick: handleSave, disabled: saving }, saving ? 'Saving...' : 'Save Permissions'))));
};
export default UserPermissions;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
