var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PlusIcon, XIcon, ChevronRight, Edit2 as EditIcon, Trash2 as DeleteIcon, List, Network } from 'lucide-react';
import { motion } from 'framer-motion';
import ReactFlow, { ConnectionLineType, MarkerType, Background, BackgroundVariant, Controls, Panel, applyNodeChanges, Handle, Position, } from 'reactflow';
import 'reactflow/dist/style.css';
// Update LAYOUT_CONFIG for better tree view spacing
var LAYOUT_CONFIG = {
    NODE_WIDTH: 280,
    NODE_HEIGHT: 100,
    HORIZONTAL_INDENT: 40, // New - indentation for child nodes
    VERTICAL_GAP: 20, // Reduced for tighter vertical spacing
    ROOT_START_X: 40, // Start from left side
    ROOT_START_Y: 40,
    MIN_CONTAINER_WIDTH: 1200,
    MIN_CONTAINER_HEIGHT: 800
};
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 24px;\n  background: #F8FAFC;\n  min-height: calc(100vh - 64px); // Adjust based on your header height\n"], ["\n  padding: 24px;\n  background: #F8FAFC;\n  min-height: calc(100vh - 64px); // Adjust based on your header height\n"])));
var PageHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 24px;\n  padding: 16px 24px;\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 24px;\n  padding: 16px 24px;\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);\n"])));
var HeaderTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  h1 {\n    font-size: 24px;\n    font-weight: 600;\n    color: #1E293B;\n    margin: 0;\n  }\n  \n  p {\n    font-size: 14px;\n    color: #64748B;\n    margin: 4px 0 0 0;\n  }\n"], ["\n  h1 {\n    font-size: 24px;\n    font-weight: 600;\n    color: #1E293B;\n    margin: 0;\n  }\n  \n  p {\n    font-size: 14px;\n    color: #64748B;\n    margin: 4px 0 0 0;\n  }\n"])));
var MetricsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);\n  padding: 16px;\n"], ["\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);\n  padding: 16px;\n"])));
var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 10px 16px;\n  border-radius: 8px;\n  font-weight: 500;\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  border: 1px solid transparent;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 10px 16px;\n  border-radius: 8px;\n  font-weight: 500;\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  border: 1px solid transparent;\n\n  ", "\n"])), function (props) { return props.variant === 'primary' ? "\n    background: #8F4ACF;\n    color: white;\n    &:hover {\n      background: #7B3AB2;\n    }\n  " : "\n    background: white;\n    color: #64748B;\n    border-color: #E2E8F0;\n    &:hover {\n      background: #F8FAFC;\n      border-color: #CBD5E1;\n    }\n  "; });
var AccordionItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 8px 0;\n  margin-left: ", "px;\n  border: 1px solid #E2E8F0;\n  border-radius: 8px;\n  overflow: hidden;\n  transition: all 0.2s ease;\n\n  &:hover {\n    border-color: #CBD5E1;\n  }\n"], ["\n  margin: 8px 0;\n  margin-left: ", "px;\n  border: 1px solid #E2E8F0;\n  border-radius: 8px;\n  overflow: hidden;\n  transition: all 0.2s ease;\n\n  &:hover {\n    border-color: #CBD5E1;\n  }\n"])), function (props) { return props.depth * 24; });
var AccordionHeader = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 12px 16px;\n  background: #F8FAFC;\n  cursor: pointer;\n  user-select: none;\n\n  &:hover {\n    background: #F1F5F9;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 12px 16px;\n  background: #F8FAFC;\n  cursor: pointer;\n  user-select: none;\n\n  &:hover {\n    background: #F1F5F9;\n  }\n"])));
var MetricInfo = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var MetricTitle = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-weight: 500;\n  color: #1E293B;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n"], ["\n  font-weight: 500;\n  color: #1E293B;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n"])));
var MetricMeta = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 12px;\n  color: #64748B;\n  margin-top: 4px;\n"], ["\n  font-size: 12px;\n  color: #64748B;\n  margin-top: 4px;\n"])));
var MetricDescription = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #475569;\n  padding: 12px 16px;\n  background: white;\n  border-top: 1px solid #E2E8F0;\n"], ["\n  font-size: 14px;\n  color: #475569;\n  padding: 12px 16px;\n  background: white;\n  border-top: 1px solid #E2E8F0;\n"])));
var ActionButtons = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n"], ["\n  display: flex;\n  gap: 8px;\n"])));
var IconButton = styled(Button)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 8px;\n  color: #64748B;\n  \n  &:hover {\n    color: #8F4ACF;\n    background: #F8FAFC;\n  }\n"], ["\n  padding: 8px;\n  color: #64748B;\n  \n  &:hover {\n    color: #8F4ACF;\n    background: #F8FAFC;\n  }\n"])));
var ChevronIcon = styled(ChevronRight)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  transition: transform 0.2s ease;\n  transform: rotate(", ");\n  min-width: 20px;\n"], ["\n  transition: transform 0.2s ease;\n  transform: rotate(", ");\n  min-width: 20px;\n"])), function (props) { return props.$isOpen ? '90deg' : '0deg'; });
var Badge = styled.span(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  padding: 2px 8px;\n  border-radius: 12px;\n  font-size: 12px;\n  font-weight: 500;\n  background: #F1F5F9;\n  color: #64748B;\n  margin-left: 8px;\n"], ["\n  padding: 2px 8px;\n  border-radius: 12px;\n  font-size: 12px;\n  font-weight: 500;\n  background: #F1F5F9;\n  color: #64748B;\n  margin-left: 8px;\n"])));
var Modal = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10000;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10000;\n"])));
var MetricForm = styled.form(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  background: white;\n  padding: 32px;\n  border-radius: 16px;\n  width: 500px;\n  max-height: 90vh;\n  overflow-y: auto;\n  position: relative;\n"], ["\n  background: white;\n  padding: 32px;\n  border-radius: 16px;\n  width: 500px;\n  max-height: 90vh;\n  overflow-y: auto;\n  position: relative;\n"])));
var CloseButton = styled.button(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  color: #666;\n  padding: 4px;\n  \n  &:hover {\n    color: #1a1a1a;\n  }\n"], ["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  color: #666;\n  padding: 4px;\n  \n  &:hover {\n    color: #1a1a1a;\n  }\n"])));
var FormGroup = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  margin-bottom: 16px;\n"], ["\n  margin-bottom: 16px;\n"])));
var Label = styled.label(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: 8px;\n  font-weight: 500;\n"], ["\n  display: block;\n  margin-bottom: 8px;\n  font-weight: 500;\n"])));
var Input = styled.input(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n"], ["\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n"])));
var Select = styled.select(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n"], ["\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n"])));
var TextArea = styled.textarea(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  min-height: 100px;\n"], ["\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  min-height: 100px;\n"])));
var FormError = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  color: #dc2626;\n  font-size: 0.875rem;\n  margin-top: 0.25rem;\n"], ["\n  color: #dc2626;\n  font-size: 0.875rem;\n  margin-top: 0.25rem;\n"])));
var ViewportControls = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 24px;\n  right: 24px;\n  display: flex;\n  gap: 8px;\n  background: white;\n  padding: 8px;\n  border-radius: 8px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n"], ["\n  position: fixed;\n  bottom: 24px;\n  right: 24px;\n  display: flex;\n  gap: 8px;\n  background: white;\n  padding: 8px;\n  border-radius: 8px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n"])));
var ControlButton = styled.button(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 36px;\n  height: 36px;\n  border: 1px solid #E2E8F0;\n  border-radius: 6px;\n  background: white;\n  cursor: pointer;\n  color: #666;\n  transition: all 0.2s;\n\n  &:hover {\n    background: #F7F7F7;\n    color: #8F4ACF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 36px;\n  height: 36px;\n  border: 1px solid #E2E8F0;\n  border-radius: 6px;\n  background: white;\n  cursor: pointer;\n  color: #666;\n  transition: all 0.2s;\n\n  &:hover {\n    background: #F7F7F7;\n    color: #8F4ACF;\n  }\n"])));
var NodesWrapper = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  position: relative;\n  padding: 20px;\n  max-width: 1200px;\n  margin: 0 auto;\n"], ["\n  position: relative;\n  padding: 20px;\n  max-width: 1200px;\n  margin: 0 auto;\n"])));
var NodeConnection = styled.path(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  stroke: #e2e8f0;\n  stroke-width: 2px;\n  fill: none;\n"], ["\n  stroke: #e2e8f0;\n  stroke-width: 2px;\n  fill: none;\n"])));
var POSITION_STORAGE_KEY = 'metric-positions';
var HeaderButtons = styled.div(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n"])));
var DraggableNode = styled(motion.div)(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  position: absolute;\n  cursor: grab;\n  transform-origin: center center;\n  \n  &:active {\n    cursor: grabbing;\n    z-index: 1000;\n  }\n"], ["\n  position: absolute;\n  cursor: grab;\n  transform-origin: center center;\n  \n  &:active {\n    cursor: grabbing;\n    z-index: 1000;\n  }\n"])));
var NodeDescription = styled.p(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  font-size: 12px;\n  color: #666;\n  margin: 4px 0 0 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"], ["\n  font-size: 12px;\n  color: #666;\n  margin: 4px 0 0 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"])));
var AccordionGrid = styled.div(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  display: grid;\n  gap: 12px;\n  padding: 20px;\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n"], ["\n  display: grid;\n  gap: 12px;\n  padding: 20px;\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n"])));
var ViewToggle = styled.div(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin-right: 16px;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin-right: 16px;\n"])));
var ToggleButton = styled.button(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 36px;\n  height: 36px;\n  border: 1px solid ", ";\n  border-radius: 6px;\n  background: ", ";\n  cursor: pointer;\n  color: ", ";\n  transition: all 0.2s;\n\n  &:hover {\n    background: #F9F5FF;\n    color: #8F4ACF;\n    border-color: #8F4ACF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 36px;\n  height: 36px;\n  border: 1px solid ", ";\n  border-radius: 6px;\n  background: ", ";\n  cursor: pointer;\n  color: ", ";\n  transition: all 0.2s;\n\n  &:hover {\n    background: #F9F5FF;\n    color: #8F4ACF;\n    border-color: #8F4ACF;\n  }\n"])), function (props) { return props.$active ? '#8F4ACF' : '#E2E8F0'; }, function (props) { return props.$active ? '#F9F5FF' : 'white'; }, function (props) { return props.$active ? '#8F4ACF' : '#64748B'; });
var TreeContainer = styled.div(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  width: 100%;\n  height: calc(100vh - 180px);\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);\n  margin-top: 24px;\n\n  .react-flow__edge {\n    z-index: 1;\n  }\n\n  .react-flow__edge-path {\n    stroke: #94A3B8;\n    stroke-width: 2;\n    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1));\n  }\n\n  .react-flow__edge.animated path {\n    stroke-dasharray: 5;\n    animation: dashdraw 0.5s linear infinite;\n  }\n\n  .react-flow__edge.selected .react-flow__edge-path {\n    stroke: #8F4ACF;\n    stroke-width: 3;\n  }\n\n  .react-flow__handle {\n    width: 8px;\n    height: 8px;\n    background: #94A3B8;\n    border: 2px solid white;\n    box-shadow: 0 1px 2px rgb(0 0 0 / 0.1);\n  }\n\n  @keyframes dashdraw {\n    from {\n      stroke-dashoffset: 10;\n    }\n  }\n"], ["\n  width: 100%;\n  height: calc(100vh - 180px);\n  background: white;\n  border-radius: 12px;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);\n  margin-top: 24px;\n\n  .react-flow__edge {\n    z-index: 1;\n  }\n\n  .react-flow__edge-path {\n    stroke: #94A3B8;\n    stroke-width: 2;\n    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1));\n  }\n\n  .react-flow__edge.animated path {\n    stroke-dasharray: 5;\n    animation: dashdraw 0.5s linear infinite;\n  }\n\n  .react-flow__edge.selected .react-flow__edge-path {\n    stroke: #8F4ACF;\n    stroke-width: 3;\n  }\n\n  .react-flow__handle {\n    width: 8px;\n    height: 8px;\n    background: #94A3B8;\n    border: 2px solid white;\n    box-shadow: 0 1px 2px rgb(0 0 0 / 0.1);\n  }\n\n  @keyframes dashdraw {\n    from {\n      stroke-dashoffset: 10;\n    }\n  }\n"])));
var POSITIONS_STORAGE_KEY = 'metric-node-positions';
var MetricsPage = function () {
    var _a = useState([]), metrics = _a[0], setMetrics = _a[1];
    var _b = useState(false), showForm = _b[0], setShowForm = _b[1];
    var _c = useState(null), selectedParent = _c[0], setSelectedParent = _c[1];
    var _d = useState([]), datasets = _d[0], setDatasets = _d[1];
    var _e = useState({ scale: 1 }), viewport = _e[0], setViewport = _e[1];
    var containerRef = useRef(null);
    var _f = useState(null), editingMetric = _f[0], setEditingMetric = _f[1];
    var _g = useState('list'), viewMode = _g[0], setViewMode = _g[1];
    var _h = useState([]), nodes = _h[0], setNodes = _h[1];
    var _j = useState([]), edges = _j[0], setEdges = _j[1];
    var fetchMetrics = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/metrics/root_nodes/')];
                case 1:
                    response = _a.sent();
                    setMetrics(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    toast.error('Failed to fetch metrics');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchDatasets = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/datasets/')];
                case 1:
                    response = _a.sent();
                    setDatasets(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    toast.error('Failed to fetch datasets');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var form, formData, response, error_3, errors;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    form = e.currentTarget;
                    formData = new FormData(form);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post('/api/metrics/', {
                            name: formData.get('name'),
                            description: formData.get('description'),
                            metric_type: formData.get('metric_type'),
                            custom_formula: formData.get('custom_formula') || null,
                            parent: selectedParent,
                            dataset: formData.get('dataset') ? Number(formData.get('dataset')) : null,
                            table_name: formData.get('table_name') || null,
                            column_name: formData.get('column_name') || null,
                            filter_conditions: {},
                            aggregation_period: formData.get('aggregation_period'),
                            is_active: true
                        })];
                case 2:
                    response = _b.sent();
                    toast.success('Metric created successfully');
                    setShowForm(false);
                    fetchMetrics();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _b.sent();
                    if ((_a = error_3.response) === null || _a === void 0 ? void 0 : _a.data) {
                        errors = error_3.response.data;
                        Object.entries(errors).forEach(function (_a) {
                            var field = _a[0], messages = _a[1];
                            toast.error("".concat(field, ": ").concat(messages));
                        });
                    }
                    else {
                        toast.error('Failed to create metric');
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleEdit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var form, formData, response, error_4, errors;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    form = e.currentTarget;
                    formData = new FormData(form);
                    if (!editingMetric)
                        return [2 /*return*/];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.put("/api/metrics/".concat(editingMetric.id, "/"), {
                            name: formData.get('name'),
                            description: formData.get('description'),
                            metric_type: formData.get('metric_type'),
                            custom_formula: formData.get('custom_formula') || null,
                            dataset: formData.get('dataset') ? Number(formData.get('dataset')) : null,
                            table_name: formData.get('table_name') || null,
                            column_name: formData.get('column_name') || null,
                            filter_conditions: editingMetric.filter_conditions,
                            aggregation_period: formData.get('aggregation_period'),
                            is_active: editingMetric.is_active
                        })];
                case 2:
                    response = _b.sent();
                    toast.success('Metric updated successfully');
                    setShowForm(false);
                    setEditingMetric(null);
                    fetchMetrics();
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _b.sent();
                    if ((_a = error_4.response) === null || _a === void 0 ? void 0 : _a.data) {
                        errors = error_4.response.data;
                        Object.entries(errors).forEach(function (_a) {
                            var field = _a[0], messages = _a[1];
                            toast.error("".concat(field, ": ").concat(messages));
                        });
                    }
                    else {
                        toast.error('Failed to update metric');
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleZoom = function (delta) {
        setViewport(function (prev) { return (__assign(__assign({}, prev), { scale: Math.min(Math.max(0.25, prev.scale + delta), 2) })); });
    };
    var handleDelete = function (nodeId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm('Are you sure you want to delete this metric and all its children?')) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.delete("/api/metrics/".concat(nodeId, "/"))];
                case 2:
                    _a.sent();
                    toast.success('Metric deleted successfully');
                    fetchMetrics(); // Refresh the metrics list
                    return [3 /*break*/, 4];
                case 3:
                    error_5 = _a.sent();
                    toast.error('Failed to delete metric');
                    console.error('Delete error:', error_5);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var loadSavedPositions = function () {
        var saved = localStorage.getItem(POSITIONS_STORAGE_KEY);
        return saved ? JSON.parse(saved) : {};
    };
    var savePositions = function (positions) {
        localStorage.setItem(POSITIONS_STORAGE_KEY, JSON.stringify(positions));
    };
    var getFlowElements = function (metrics) {
        var nodes = [];
        var edges = [];
        var savedPositions = loadSavedPositions();
        var processNode = function (node, defaultPosition, level) {
            if (level === void 0) { level = 0; }
            var savedPosition = savedPositions[node.id.toString()];
            var position = savedPosition || defaultPosition;
            nodes.push({
                id: node.id.toString(),
                type: 'metric',
                position: position,
                data: {
                    nodeId: node.id,
                    onEdit: function (metric) {
                        setEditingMetric(metric);
                        setShowForm(true);
                    },
                    onAddChild: function (parentId) {
                        setSelectedParent(parentId);
                        setShowForm(true);
                    },
                    onDelete: handleDelete,
                    metric: node,
                    label: (React.createElement(React.Fragment, null,
                        React.createElement("div", { style: { fontWeight: 500 } }, node.name),
                        React.createElement("div", { style: {
                                fontSize: '12px',
                                color: '#64748B',
                                marginTop: '4px',
                                display: 'flex',
                                gap: '8px'
                            } },
                            React.createElement("span", null, node.metric_type),
                            node.dataset && React.createElement("span", null,
                                "Dataset: ",
                                node.dataset)),
                        node.description && (React.createElement("div", { style: {
                                fontSize: '12px',
                                color: '#64748B',
                                marginTop: '4px',
                                fontStyle: 'italic'
                            } }, node.description))))
                },
                style: {
                    width: 250,
                }
            });
            if (node.children && node.children.length > 0) {
                var childSpacing_1 = 300;
                var totalHeight_1 = (node.children.length - 1) * childSpacing_1;
                var startY_1 = position.y - totalHeight_1 / 2;
                node.children.forEach(function (child, index) {
                    edges.push({
                        id: "e".concat(node.id, "-").concat(child.id),
                        source: node.id.toString(),
                        target: child.id.toString(),
                        type: 'smoothstep',
                        animated: true,
                        style: {
                            stroke: '#94A3B8',
                            strokeWidth: 2,
                            filter: 'drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))',
                        },
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 20,
                            height: 20,
                            color: '#94A3B8',
                        },
                        sourceHandle: 'right',
                        targetHandle: 'left',
                    });
                    var childX = position.x + 400;
                    var childY = startY_1 + index * childSpacing_1;
                    processNode(child, { x: childX, y: childY }, level + 1);
                });
            }
        };
        var rootSpacing = 400;
        var totalHeight = (metrics.length - 1) * rootSpacing;
        var startY = totalHeight / 2;
        metrics.forEach(function (metric, index) {
            processNode(metric, { x: 50, y: startY - index * rootSpacing });
        });
        return { nodes: nodes, edges: edges };
    };
    var onNodesChange = function (changes) {
        setNodes(function (nds) { return applyNodeChanges(changes, nds); });
    };
    var onNodeDragStop = function (event, node) {
        var savedPositions = loadSavedPositions();
        savedPositions[node.id] = node.position;
        savePositions(savedPositions);
    };
    useEffect(function () {
        fetchMetrics();
        fetchDatasets();
    }, []);
    useEffect(function () {
        var _a = getFlowElements(metrics), newNodes = _a.nodes, newEdges = _a.edges;
        setNodes(newNodes);
        setEdges(newEdges);
    }, [metrics]);
    return (React.createElement(PageContainer, null,
        React.createElement(PageHeader, null,
            React.createElement(HeaderTitle, null,
                React.createElement("h1", null, "Metrics"),
                React.createElement("p", null, "Manage your metrics and KPIs")),
            React.createElement(HeaderButtons, null,
                React.createElement(ViewToggle, null,
                    React.createElement(ToggleButton, { "$active": viewMode === 'list', onClick: function () { return setViewMode('list'); }, title: "List View" },
                        React.createElement(List, { size: 20 })),
                    React.createElement(ToggleButton, { "$active": viewMode === 'tree', onClick: function () { return setViewMode('tree'); }, title: "Tree View" },
                        React.createElement(Network, { size: 20 }))),
                viewMode === 'tree' && (React.createElement(Button, { onClick: function () {
                        localStorage.removeItem(POSITIONS_STORAGE_KEY);
                        var _a = getFlowElements(metrics), newNodes = _a.nodes, newEdges = _a.edges;
                        setNodes(newNodes);
                        setEdges(newEdges);
                        toast.success('Node positions reset');
                    } }, "Reset Layout")),
                React.createElement(Button, { variant: "primary", onClick: function () {
                        setSelectedParent(null);
                        setShowForm(true);
                    } },
                    React.createElement(PlusIcon, { size: 16 }),
                    "Add Root Metric"))),
        viewMode === 'list' ? (React.createElement(MetricsContainer, null, metrics.map(function (metric) { return (React.createElement(MetricTreeNode, { key: metric.id, node: metric, depth: 0, onAddChild: setSelectedParent, onEdit: setEditingMetric, onDelete: handleDelete })); }))) : (React.createElement(TreeContainer, null,
            React.createElement(ReactFlow, { nodes: nodes, edges: edges, onNodesChange: onNodesChange, onNodeDragStop: onNodeDragStop, nodeTypes: nodeTypes, connectionLineType: ConnectionLineType.SmoothStep, defaultEdgeOptions: {
                    type: 'smoothstep',
                    animated: true,
                    style: {
                        stroke: '#94A3B8',
                        strokeWidth: 2,
                        filter: 'drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))',
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        color: '#94A3B8',
                    },
                }, fitView: true, fitViewOptions: { padding: 0.2 }, minZoom: 0.1, maxZoom: 2, defaultViewport: { x: 0, y: 0, zoom: 0.8 }, nodesDraggable: true, nodesConnectable: false, nodesFocusable: false, panOnDrag: [1, 2], selectNodesOnDrag: false, zoomOnScroll: true, preventScrolling: true, elementsSelectable: true, snapToGrid: true, snapGrid: [15, 15] },
                React.createElement(Background, { color: "#E2E8F0", gap: 16, variant: BackgroundVariant.Dots }),
                React.createElement(Controls, null),
                React.createElement(Panel, { position: "top-left" },
                    React.createElement("div", { style: {
                            padding: '8px',
                            background: 'white',
                            borderRadius: '4px',
                            fontSize: '12px',
                            color: '#64748B',
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                        } }, "Drag nodes to reposition \u2022 Use mouse wheel to zoom \u2022 Drag canvas to pan"))))),
        (showForm || editingMetric) && (React.createElement(Modal, null,
            React.createElement(MetricForm, { onSubmit: editingMetric ? handleEdit : handleSubmit },
                React.createElement(CloseButton, { onClick: function () {
                        setShowForm(false);
                        setEditingMetric(null);
                        setSelectedParent(null);
                    } },
                    React.createElement(XIcon, { size: 20 })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Name"),
                    React.createElement(Input, { name: "name", required: true, defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.name) || '' })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Description"),
                    React.createElement(TextArea, { name: "description", defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.description) || '' })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Type"),
                    React.createElement(Select, { name: "metric_type", required: true, defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.metric_type) || '' },
                        React.createElement("option", { value: "count" }, "Count"),
                        React.createElement("option", { value: "sum" }, "Sum"),
                        React.createElement("option", { value: "average" }, "Average"),
                        React.createElement("option", { value: "min" }, "Minimum"),
                        React.createElement("option", { value: "max" }, "Maximum"),
                        React.createElement("option", { value: "custom" }, "Custom Formula"))),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Custom Formula"),
                    React.createElement(Input, { name: "custom_formula", defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.custom_formula) || '' })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Aggregation Period"),
                    React.createElement(Select, { name: "aggregation_period", required: true, defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.aggregation_period) || '' },
                        React.createElement("option", { value: "daily" }, "Daily"),
                        React.createElement("option", { value: "weekly" }, "Weekly"),
                        React.createElement("option", { value: "monthly" }, "Monthly"),
                        React.createElement("option", { value: "quarterly" }, "Quarterly"),
                        React.createElement("option", { value: "yearly" }, "Yearly"),
                        React.createElement("option", { value: "custom" }, "Custom"))),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Dataset"),
                    React.createElement(Select, { name: "dataset", defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.dataset) ? editingMetric.dataset.toString() : '' },
                        React.createElement("option", { value: "" }, "Select a dataset"),
                        datasets.map(function (dataset) { return (React.createElement("option", { key: dataset.id, value: dataset.id }, dataset.name)); }))),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Table Name"),
                    React.createElement(Input, { name: "table_name", defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.table_name) || '' })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Column Name"),
                    React.createElement(Input, { name: "column_name", defaultValue: (editingMetric === null || editingMetric === void 0 ? void 0 : editingMetric.column_name) || '' })),
                React.createElement(Button, { type: "submit" }, editingMetric ? 'Update Metric' : (selectedParent ? 'Create Child Metric' : 'Create Root Metric')))))));
};
var MetricTreeNode = function (_a) {
    var _b;
    var node = _a.node, depth = _a.depth, onAddChild = _a.onAddChild, onEdit = _a.onEdit, onDelete = _a.onDelete;
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var hasChildren = node.children_count > 0;
    return (React.createElement(AccordionItem, { depth: depth },
        React.createElement(AccordionHeader, { onClick: function () { return hasChildren && setIsOpen(!isOpen); } },
            React.createElement(ChevronIcon, { "$isOpen": isOpen, size: 20 }),
            React.createElement(MetricInfo, null,
                React.createElement(MetricTitle, null,
                    node.name,
                    React.createElement(Badge, null, node.metric_type),
                    node.dataset && React.createElement(Badge, null,
                        "Dataset: ",
                        node.dataset)),
                React.createElement(MetricMeta, null,
                    "Created by ",
                    node.created_by_name,
                    " \u2022 Last updated ",
                    new Date(node.updated_at).toLocaleDateString())),
            React.createElement(ActionButtons, null,
                React.createElement(IconButton, { onClick: function (e) {
                        e.stopPropagation();
                        onEdit(node);
                    } },
                    React.createElement(EditIcon, { size: 16 })),
                React.createElement(IconButton, { onClick: function (e) {
                        e.stopPropagation();
                        onAddChild(node.id);
                    } },
                    React.createElement(PlusIcon, { size: 16 })),
                React.createElement(DeleteButton, { onClick: function (e) {
                        e.stopPropagation();
                        onDelete(node.id);
                    } },
                    React.createElement(DeleteIcon, { size: 16 })))),
        isOpen && (React.createElement(React.Fragment, null,
            node.description && (React.createElement(MetricDescription, null, node.description)), (_b = node.children) === null || _b === void 0 ? void 0 :
            _b.map(function (child) { return (React.createElement(MetricTreeNode, { key: child.id, node: child, depth: depth + 1, onAddChild: onAddChild, onEdit: onEdit, onDelete: onDelete })); })))));
};
var DeleteButton = styled(IconButton)(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  color: #EF4444;\n  \n  &:hover {\n    color: #DC2626;\n    background: #FEE2E2;\n  }\n"], ["\n  color: #EF4444;\n  \n  &:hover {\n    color: #DC2626;\n    background: #FEE2E2;\n  }\n"])));
var nodeTypes = {
    metric: function (_a) {
        var data = _a.data, dragging = _a.dragging;
        return (React.createElement("div", { style: {
                padding: '12px',
                background: 'white',
                border: '1px solid #E2E8F0',
                borderRadius: '8px',
                minWidth: '200px',
                cursor: dragging ? 'grabbing' : 'grab',
                opacity: dragging ? 0.8 : 1,
                transition: 'opacity 0.2s, transform 0.2s',
                transform: "scale(".concat(dragging ? 1.02 : 1, ")"),
            } },
            React.createElement(Handle, { type: "target", position: Position.Left, style: { background: '#94A3B8' } }),
            data.label,
            React.createElement("div", { style: {
                    display: 'flex',
                    gap: '8px',
                    marginTop: '8px',
                    justifyContent: 'flex-end'
                } },
                React.createElement(IconButton, { onClick: function (e) {
                        e.stopPropagation();
                        data.onEdit(data.metric);
                    } },
                    React.createElement(EditIcon, { size: 16 })),
                React.createElement(IconButton, { onClick: function (e) {
                        e.stopPropagation();
                        data.onAddChild(data.nodeId);
                    } },
                    React.createElement(PlusIcon, { size: 16 })),
                React.createElement(DeleteButton, { onClick: function (e) {
                        e.stopPropagation();
                        data.onDelete(data.nodeId);
                    } },
                    React.createElement(DeleteIcon, { size: 16 }))),
            React.createElement(Handle, { type: "source", position: Position.Right, style: { background: '#94A3B8' } })));
    },
};
export default MetricsPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36;
