var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Sidebar from '../Layout/Sidebar';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Analysis from '../Analysis/Analysis';
import QuestionsDashboard from '../Dashboard/QuestionsDashboard';
import ManageQuestions from '../Questions/ManageQuestions';
import AnalysisResult from '../Analysis/AnalysisResult';
import Questions from '../Questions/Questions';
import QuestionHistory from '../Questions/QuestionHistory';
import DataGlossary from '../Dataset/DataGlossary';
import Settings from '../Settings/Settings';
import UsageLimitations from '../Settings/UsageLimitations';
import UserProfile from '../Settings/UserProfile';
import AccountSecurity from '../Settings/AccountSecurity';
import UserPermissions from '../Settings/UserPermissions';
import ServiceAccount from '../Settings/ServiceAccount';
import ManageRoles from '../Settings/ManageRoles';
import ActivityHistory from '../Settings/ActivityHistory';
import ManageUsers from '../Settings/ManageUsers';
import ManageDepartments from '../Settings/ManageDepartments';
import ManageQuotas from '../Settings/ManageQuotas';
import ManageCompanies from '../Settings/ManageCompanies';
import AssignUsers from '../Settings/AssignUsers';
import SQLEditor from '../Dataset/SQLEditor';
import FavoritesDashboard from '../Dashboard/FavoritesDashboard';
import DataExplorer from '../DataExplorer/DataExplorer';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  min-height: 100vh;\n  background: #FFFFFF;\n  display: flex;\n"], ["\n  position: relative;\n  width: 100%;\n  min-height: 100vh;\n  background: #FFFFFF;\n  display: flex;\n"])));
var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n"], ["\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n"])));
var MainContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: ", ";\n  padding: 34px 60px;\n  width: ", ";\n  min-height: 140vh;\n  padding-bottom: 100px;\n  background: #FFFFFF;\n  position: relative;\n  overflow-x: hidden;\n  transition: margin-left 0.3s ease, width 0.3s ease;\n\n  & > ", " {\n    padding-left: 20px;\n  }\n"], ["\n  margin-left: ", ";\n  padding: 34px 60px;\n  width: ", ";\n  min-height: 140vh;\n  padding-bottom: 100px;\n  background: #FFFFFF;\n  position: relative;\n  overflow-x: hidden;\n  transition: margin-left 0.3s ease, width 0.3s ease;\n\n  & > ", " {\n    padding-left: 20px;\n  }\n"])), function (props) { return props.sidebarOpen ? '284px' : '24px'; }, function (props) { return props.sidebarOpen ? 'calc(100% - 284px)' : 'calc(100% - 24px)'; }, ContentWrapper);
var WelcomeSection = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: center;\n  margin-bottom: 20px;\n"], ["\n  text-align: center;\n  margin-bottom: 20px;\n"])));
var Title = styled.h1(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 14px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 14px;\n"])));
var Subtitle = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 110%;\n  color: #A1A1A1;\n"], ["\n  font-family: 'Inter';\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 110%;\n  color: #A1A1A1;\n"])));
var GridContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 32px;\n  max-width: 1200px;\n  margin: 0 auto;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 32px;\n  max-width: 1200px;\n  margin: 0 auto;\n"])));
var Card = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n  min-height: 268px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  background: ", ";\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n  min-height: 268px;\n  display: flex;\n  flex-direction: column;\n"])), function (props) { return props.bgColor; });
var CardTitle = styled.h2(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 28px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 28px;\n"])));
var CardText = styled.p(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n  margin-bottom: auto;\n"], ["\n  font-family: 'Inter';\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n  margin-bottom: auto;\n"])));
var Button = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 154px;\n  height: 50px;\n  background: #000000;\n  border-radius: 16px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n"], ["\n  width: 154px;\n  height: 50px;\n  background: #000000;\n  border-radius: 16px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n"])));
var MainApp = function (_a) {
    var setIsAuthenticated = _a.setIsAuthenticated;
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useState(true), sidebarOpen = _b[0], setSidebarOpen = _b[1];
    var _c = useState(1), userRole = _c[0], setUserRole = _c[1];
    useEffect(function () {
        var checkAuth = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch('/api/check-auth/')];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        if (data.authenticated && data.user) {
                            setUserRole(data.user.role);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error checking authentication:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        checkAuth();
    }, []);
    var getHeaderTitle = function () {
        switch (location.pathname) {
            case '/app':
                return 'Welcome To AI Data Analyst';
            case '/app/dashboard':
                return 'Dashboard';
            case '/app/analysis':
                return 'Data Analysis';
            case '/app/datasets':
                return 'Datasets';
            case '/app/history':
                return 'Analysis History';
            case '/app/favorites':
                return 'Favorites';
            case '/app/settings':
                return 'Settings';
            case '/app/data-explorer':
                return 'Data Explorer';
            default:
                if (location.pathname.startsWith('/app/settings/')) {
                    return 'Settings';
                }
                return 'AI Data Analyst';
        }
    };
    var getHeaderSubtitle = function () {
        switch (location.pathname) {
            case '/app':
                return 'Unlock the power of your data with AI-driven insights';
            case '/app/dashboard':
                return 'View your analytics and insights at a glance';
            case '/app/analysis':
                return 'Start analyzing your data using our advanced AI algorithms';
            case '/app/datasets':
                return 'Explore and manage your connected datasets';
            case '/app/history':
                return 'Review your past analyses and insights';
            case '/app/favorites':
                return 'Access your favorite analyses and queries';
            case '/app/settings':
                return 'Manage your account and preferences';
            case '/app/data-explorer':
                return 'Explore and analyze your data in detail';
            default:
                return '';
        }
    };
    var handleAnalysisClick = function () {
        navigate('/app/analysis');
    };
    var handleDatasetAnalyzerClick = function () {
        navigate('/dataset-analyzer');
    };
    return (React.createElement(Container, null,
        React.createElement(Sidebar, { isOpen: sidebarOpen, setIsOpen: setSidebarOpen }),
        React.createElement(MainContent, { sidebarOpen: sidebarOpen },
            React.createElement(Header, { title: getHeaderTitle(), setIsAuthenticated: setIsAuthenticated, sidebarOpen: sidebarOpen }),
            React.createElement(ContentWrapper, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "/", element: React.createElement(React.Fragment, null,
                            React.createElement(WelcomeSection, null,
                                React.createElement(Title, null, getHeaderTitle()),
                                React.createElement(Subtitle, null, getHeaderSubtitle())),
                            React.createElement(GridContainer, null,
                                React.createElement(Card, { bgColor: "#F8F5FF" },
                                    React.createElement(CardTitle, null, "Dashboard"),
                                    React.createElement(CardText, null, "View key metrics and insights about your data analysis activities at a glance."),
                                    React.createElement(Button, { onClick: function () { return navigate('/app/dashboard'); } }, "View Dashboard")),
                                React.createElement(Card, { bgColor: "#EEF3FF" },
                                    React.createElement(CardTitle, null, "Data Analysis"),
                                    React.createElement(CardText, null, "Start analyzing your data using our advanced AI algorithms. Get insights and visualizations in minutes."),
                                    React.createElement(Button, { onClick: handleAnalysisClick }, "Begin Analysis")),
                                React.createElement(Card, { bgColor: "#F8F5FF" },
                                    React.createElement(CardTitle, null, "Dataset Explorer"),
                                    React.createElement(CardText, null, "Explore and understand your datasets with our powerful explorer tool."),
                                    React.createElement(Button, { onClick: function () { return navigate('/app/data-explorer'); } }, "Explore Data")),
                                React.createElement(Card, { bgColor: "#EEF3FF" },
                                    React.createElement(CardTitle, null, "Analysis History"),
                                    React.createElement(CardText, null, "Review your past queries and insights to track your data journey."),
                                    React.createElement(Button, { onClick: function () { return navigate('/app/history'); } }, "View History")))) }),
                    React.createElement(Route, { path: "/dashboard", element: React.createElement(Dashboard, null) }),
                    React.createElement(Route, { path: "/dashboard/view-questions/:folderId", element: React.createElement(QuestionsDashboard, null) }),
                    React.createElement(Route, { path: "/dashboard/favorites", element: React.createElement(FavoritesDashboard, null) }),
                    React.createElement(Route, { path: "/analysis", element: React.createElement(Analysis, null) }),
                    React.createElement(Route, { path: "/dashboard/manage-questions/:folderId", element: React.createElement(ManageQuestions, null) }),
                    React.createElement(Route, { path: "/analysis/result/:questionId", element: React.createElement(AnalysisResult, null) }),
                    React.createElement(Route, { path: "/:datasetName/questions", element: React.createElement(Questions, null) }),
                    React.createElement(Route, { path: "/history", element: React.createElement(QuestionHistory, null) }),
                    userRole === 1 && (React.createElement(React.Fragment, null,
                        React.createElement(Route, { path: "/data-glossary", element: React.createElement(DataGlossary, null) }),
                        React.createElement(Route, { path: "/data-glossary/:datasetName/sql-editor", element: React.createElement(SQLEditor, null) }))),
                    React.createElement(Route, { path: "/settings", element: React.createElement(Settings, null) }),
                    React.createElement(Route, { path: "/settings/usage", element: React.createElement(UsageLimitations, null) }),
                    React.createElement(Route, { path: "/settings/profile", element: React.createElement(UserProfile, null) }),
                    React.createElement(Route, { path: "/settings/security", element: React.createElement(AccountSecurity, null) }),
                    React.createElement(Route, { path: "/settings/permissions", element: React.createElement(UserPermissions, null) }),
                    React.createElement(Route, { path: "/settings/service-account", element: React.createElement(ServiceAccount, null) }),
                    React.createElement(Route, { path: "/settings/roles", element: React.createElement(ManageRoles, null) }),
                    React.createElement(Route, { path: "/settings/activity", element: React.createElement(ActivityHistory, null) }),
                    React.createElement(Route, { path: "/settings/users", element: React.createElement(ManageUsers, null) }),
                    React.createElement(Route, { path: "/settings/departments", element: React.createElement(ManageDepartments, null) }),
                    React.createElement(Route, { path: "/settings/quotas", element: React.createElement(ManageQuotas, null) }),
                    React.createElement(Route, { path: "/settings/companies", element: React.createElement(ManageCompanies, null) }),
                    React.createElement(Route, { path: "/settings/companies/:companyId/users", element: React.createElement(AssignUsers, null) }),
                    React.createElement(Route, { path: "/data-explorer", element: React.createElement(DataExplorer, null) }))),
            React.createElement(Footer, { sidebarOpen: sidebarOpen }))));
};
export default MainApp;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
