var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../common/Modal';
import { ModalContent } from '../common/Modal';
import axios from 'axios';
import Form from '../common/Form';
import { getCookie } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var UsersSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var CreateButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var Table = styled.table(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n"])));
var Th = styled.th(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"], ["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"])));
var Td = styled.td(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"], ["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"])));
var ActionButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.variant === 'edit' ? '#F8F5FF' : '#FFF1F0'; }, function (props) { return props.variant === 'edit' ? '#8F4ACF' : '#FF4D4F'; }, function (props) { return props.variant === 'edit' ? '#ECEAFF' : '#FFA39E'; }, function (props) { return props.variant === 'edit' ? '#F0E7FF' : '#FFF1F0'; });
var StatusBadge = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n"], ["\n  background: ", ";\n  color: ", ";\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n"])), function (props) { return props.status === 'active' ? '#E6F4EA' : '#FFF1F0'; }, function (props) { return props.status === 'active' ? '#137333' : '#D93025'; });
var FormGroup = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 16px;\n"])));
var Label = styled.label(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  color: #333;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  color: #333;\n"])));
var Input = styled.input(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var ButtonGroup = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  padding-top: 24px;\n  border-top: 2px solid #F8F5FF;\n  margin-top: 24px;\n  background: white;\n  position: relative;\n  z-index: 9999;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  padding-top: 24px;\n  border-top: 2px solid #F8F5FF;\n  margin-top: 24px;\n  background: white;\n  position: relative;\n  z-index: 9999;\n"])));
var CancelButton = styled.button(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background: #F8F5FF;\n  color: #8F4ACF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #F0E7FF;\n  }\n"], ["\n  background: #F8F5FF;\n  color: #8F4ACF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #F0E7FF;\n  }\n"])));
var SaveButton = styled.button(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var ErrorMessage = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: #FF4D4F;\n  font-size: 12px;\n  margin-top: 4px;\n"], ["\n  color: #FF4D4F;\n  font-size: 12px;\n  margin-top: 4px;\n"])));
var Select = styled.select(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  font-size: 14px;\n  background-color: white;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  font-size: 14px;\n  background-color: white;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var ModalOverlay = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 9998;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 9998;\n"])));
var StyledModalContent = styled(ModalContent)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  max-width: 800px;\n  padding: 32px;\n  border-radius: 16px;\n  background: white;\n  position: relative;\n  overflow: visible;\n  z-index: 9999;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 80px;\n    background: white;\n    border-radius: 0 0 16px 16px;\n    z-index: 9998;\n  }\n"], ["\n  max-width: 800px;\n  padding: 32px;\n  border-radius: 16px;\n  background: white;\n  position: relative;\n  overflow: visible;\n  z-index: 9999;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 80px;\n    background: white;\n    border-radius: 0 0 16px 16px;\n    z-index: 9998;\n  }\n"])));
var StyledForm = styled(Form)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
var ManageUsers = function () {
    var _a = useState([]), users = _a[0], setUsers = _a[1];
    var _b = useState(false), isAddModalOpen = _b[0], setIsAddModalOpen = _b[1];
    var _c = useState({
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        department: '',
        role: ''
    }), formData = _c[0], setFormData = _c[1];
    var _d = useState({}), formErrors = _d[0], setFormErrors = _d[1];
    var _e = useState(false), isEditModalOpen = _e[0], setIsEditModalOpen = _e[1];
    var _f = useState(null), editingUser = _f[0], setEditingUser = _f[1];
    var _g = useState([]), departments = _g[0], setDepartments = _g[1];
    var _h = useState([]), roles = _h[0], setRoles = _h[1];
    var _j = useState({
        username: '',
        email: '',
        status: 'active',
        department: '',
        role: ''
    }), editFormData = _j[0], setEditFormData = _j[1];
    var fetchUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    console.log('Fetching users...');
                    return [4 /*yield*/, axios.get('/api/users/', {
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    console.log('Response:', response);
                    if (response.status === 200 && Array.isArray(response.data)) {
                        console.log('Setting users:', response.data);
                        setUsers(response.data);
                    }
                    else {
                        console.error('Invalid response format:', response.data);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error fetching users:', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _k = useState(true), loading = _k[0], setLoading = _k[1];
    useEffect(function () {
        var loadUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        return [4 /*yield*/, fetchUsers()];
                    case 1:
                        _a.sent();
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        loadUsers();
    }, []);
    var handleAddUser = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var userData, response, error_2;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    setFormErrors({});
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 5, , 6]);
                    // Validate required fields
                    if (!formData.department || !formData.role) {
                        setFormErrors({
                            department: ['Department is required'],
                            role: ['Role is required']
                        });
                        return [2 /*return*/];
                    }
                    userData = {
                        username: formData.username,
                        email: formData.email,
                        password1: formData.password,
                        password2: formData.repeatPassword,
                        department_id: parseInt(formData.department),
                        role_id: parseInt(formData.role)
                    };
                    console.log('Sending user data:', userData);
                    return [4 /*yield*/, axios.post('/api/users/add/', userData, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _d.sent();
                    if (!response.data.success) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetchUsers()];
                case 3:
                    _d.sent();
                    setFormData({
                        username: '',
                        email: '',
                        password: '',
                        repeatPassword: '',
                        department: '',
                        role: ''
                    });
                    setIsAddModalOpen(false);
                    _d.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_2 = _d.sent();
                    console.error('Error adding user:', ((_a = error_2.response) === null || _a === void 0 ? void 0 : _a.data) || error_2);
                    if ((_c = (_b = error_2.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errors) {
                        setFormErrors(error_2.response.data.errors);
                    }
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteUser = function (userId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm('Are you sure you want to delete this user?'))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, axios.delete("/api/users/".concat(userId, "/"), {
                            withCredentials: true
                        })];
                case 2:
                    response = _a.sent();
                    if (!(response.status === 200)) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetchUsers()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_3 = _a.sent();
                    console.error('Error deleting user:', error_3);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleEditUser = function (userId) { return __awaiter(void 0, void 0, void 0, function () {
        var user_1, matchingRole, error_4;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    user_1 = users.find(function (u) { return u.id === userId; });
                    if (!user_1) {
                        console.error('User not found:', userId);
                        return [2 /*return*/];
                    }
                    // Fetch fresh role and department data before opening modal
                    return [4 /*yield*/, Promise.all([
                            fetchRoles(),
                            fetchDepartments()
                        ])];
                case 1:
                    // Fetch fresh role and department data before opening modal
                    _d.sent();
                    matchingRole = roles.find(function (r) { return r.display_name === user_1.role; });
                    console.log('Edit user data:', {
                        user: user_1,
                        userRole: user_1.role,
                        matchingRole: matchingRole,
                        availableRoles: roles
                    });
                    setEditingUser(user_1);
                    setEditFormData({
                        username: user_1.username,
                        email: user_1.email,
                        status: user_1.status,
                        department: ((_b = (_a = user_1.department) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString()) || '',
                        role: ((_c = matchingRole === null || matchingRole === void 0 ? void 0 : matchingRole.id) === null || _c === void 0 ? void 0 : _c.toString()) || ''
                    });
                    setIsEditModalOpen(true);
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _d.sent();
                    console.error('Error in handleEditUser:', error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleEditUserSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var updateData, response, error_5;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    if (!editingUser)
                        return [2 /*return*/];
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 5, , 6]);
                    updateData = {
                        email: editFormData.email,
                        status: editFormData.status,
                    };
                    // Only include department_id if it has a value
                    if (editFormData.department) {
                        updateData.department_id = Number(editFormData.department);
                    }
                    // Only include role_id if it has a value
                    if (editFormData.role) {
                        updateData.role_id = Number(editFormData.role);
                    }
                    console.log('Submitting user update:', updateData);
                    return [4 /*yield*/, axios.patch("/api/users/".concat(editingUser.id, "/update/"), updateData, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _d.sent();
                    if (!response.data.success) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetchUsers()];
                case 3:
                    _d.sent(); // Refresh the users list
                    setIsEditModalOpen(false);
                    setEditingUser(null);
                    setFormErrors({});
                    _d.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_5 = _d.sent();
                    console.error('Error updating user:', ((_a = error_5.response) === null || _a === void 0 ? void 0 : _a.data) || error_5);
                    if ((_c = (_b = error_5.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errors) {
                        setFormErrors(error_5.response.data.errors);
                    }
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var fetchDepartments = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/departments/', {
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    setDepartments(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    error_6 = _a.sent();
                    console.error('Error fetching departments:', error_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchRoles = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/roles/', {
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    setRoles(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    error_7 = _a.sent();
                    console.error('Error fetching roles:', error_7);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var loadInitialData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        return [4 /*yield*/, Promise.all([
                                fetchUsers(),
                                fetchDepartments(),
                                fetchRoles()
                            ])];
                    case 1:
                        _a.sent();
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        loadInitialData();
    }, []);
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Manage Users"),
        React.createElement(UsersSection, null,
            React.createElement(CreateButton, { onClick: function () { return setIsAddModalOpen(true); } }, "Add New User"),
            React.createElement(Modal, { isOpen: isAddModalOpen },
                React.createElement(ModalOverlay, null),
                React.createElement(StyledModalContent, null,
                    React.createElement("h2", null, "Add New User"),
                    React.createElement(StyledForm, { onSubmit: handleAddUser },
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Username"),
                            React.createElement(Input, { type: "text", value: formData.username, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { username: e.target.value })); }, required: true }),
                            formErrors.username && (React.createElement(ErrorMessage, null, formErrors.username[0]))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Email"),
                            React.createElement(Input, { type: "email", value: formData.email, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { email: e.target.value })); }, required: true }),
                            formErrors.email && (React.createElement(ErrorMessage, null, formErrors.email[0]))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Password"),
                            React.createElement(Input, { type: "password", value: formData.password, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { password: e.target.value })); }, required: true }),
                            formErrors.password1 && (React.createElement(ErrorMessage, null, formErrors.password1[0]))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Repeat Password"),
                            React.createElement(Input, { type: "password", value: formData.repeatPassword, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { repeatPassword: e.target.value })); }, required: true }),
                            formErrors.password2 && (React.createElement(ErrorMessage, null, formErrors.password2[0]))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Department"),
                            React.createElement(Select, { value: formData.department, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { department: e.target.value })); }, required: true },
                                React.createElement("option", { value: "" }, "Select Department"),
                                departments.map(function (dept) { return (React.createElement("option", { key: dept.id, value: dept.id }, dept.name)); })),
                            formErrors.department && (React.createElement(ErrorMessage, null, formErrors.department[0]))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Role"),
                            React.createElement(Select, { value: formData.role, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { role: e.target.value })); }, required: true },
                                React.createElement("option", { value: "" }, "Select Role"),
                                roles.map(function (role) { return (React.createElement("option", { key: role.id, value: role.id.toString() }, role.display_name)); })),
                            formErrors.role && (React.createElement(ErrorMessage, null, formErrors.role[0]))),
                        React.createElement(ButtonGroup, null,
                            React.createElement(CancelButton, { type: "button", onClick: function () { return setIsAddModalOpen(false); } }, "Cancel"),
                            React.createElement(SaveButton, { type: "submit", onClick: handleAddUser }, "Add User"))))),
            React.createElement(Modal, { isOpen: isEditModalOpen },
                React.createElement(ModalOverlay, null),
                React.createElement(StyledModalContent, null,
                    React.createElement("h2", null, "Edit User"),
                    React.createElement(StyledForm, { onSubmit: handleEditUserSubmit },
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Username"),
                            React.createElement(Input, { type: "text", value: editFormData.username, disabled: true })),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Email"),
                            React.createElement(Input, { type: "email", value: editFormData.email, onChange: function (e) { return setEditFormData(__assign(__assign({}, editFormData), { email: e.target.value })); }, required: true }),
                            formErrors.email && (React.createElement(ErrorMessage, null, formErrors.email[0]))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Status"),
                            React.createElement(Select, { value: editFormData.status, onChange: function (e) { return setEditFormData(__assign(__assign({}, editFormData), { status: e.target.value })); } },
                                React.createElement("option", { value: "active" }, "Active"),
                                React.createElement("option", { value: "inactive" }, "Inactive"))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Department"),
                            React.createElement(Select, { value: editFormData.department, onChange: function (e) { return setEditFormData(__assign(__assign({}, editFormData), { department: e.target.value })); } },
                                React.createElement("option", { value: "" }, "Select Department"),
                                departments.map(function (dept) { return (React.createElement("option", { key: dept.id, value: dept.id }, dept.name)); }))),
                        React.createElement(FormGroup, null,
                            React.createElement(Label, null, "Role"),
                            React.createElement(Select, { value: editFormData.role, onChange: function (e) { return setEditFormData(__assign(__assign({}, editFormData), { role: e.target.value })); } },
                                React.createElement("option", { value: "" }, "Select Role"),
                                roles.map(function (role) { return (React.createElement("option", { key: role.id, value: role.id.toString() }, role.display_name)); })),
                            formErrors.role && (React.createElement(ErrorMessage, null, formErrors.role[0]))),
                        React.createElement(ButtonGroup, null,
                            React.createElement(CancelButton, { type: "button", onClick: function () {
                                    setIsEditModalOpen(false);
                                    setEditingUser(null);
                                    setFormErrors({});
                                } }, "Cancel"),
                            React.createElement(SaveButton, { type: "submit", onClick: handleEditUserSubmit }, "Save Changes"))))),
            loading ? (React.createElement("div", null, "Loading users...")) : users.length === 0 ? (React.createElement("div", null, "No users found")) : (React.createElement(Table, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement(Th, null, "Username"),
                        React.createElement(Th, null, "Email"),
                        React.createElement(Th, null, "Department"),
                        React.createElement(Th, null, "Role"),
                        React.createElement(Th, null, "Status"),
                        React.createElement(Th, null, "Actions"))),
                React.createElement("tbody", null, users.map(function (user) { return (React.createElement("tr", { key: user.id },
                    React.createElement(Td, null, String(user.username)),
                    React.createElement(Td, null, String(user.email)),
                    React.createElement(Td, null, user.department ? String(user.department.name) : '-'),
                    React.createElement(Td, null, user.role || '-'),
                    React.createElement(Td, null,
                        React.createElement(StatusBadge, { status: user.status }, user.status.charAt(0).toUpperCase() + user.status.slice(1))),
                    React.createElement(Td, null,
                        React.createElement(ActionButton, { variant: "edit", onClick: function () { return handleEditUser(user.id); } }, "Edit"),
                        React.createElement(ActionButton, { variant: "delete", onClick: function () { return handleDeleteUser(user.id); } }, "Delete")))); })))))));
};
export default ManageUsers;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
