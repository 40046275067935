var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
var HomeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 90px;\n  width: 100%;\n  background: #FFFFFF;\n"], ["\n  padding-top: 90px;\n  width: 100%;\n  background: #FFFFFF;\n"])));
/* Hero Section */
var HeroSection = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 100px 20px;\n  text-align: center;\n  background: linear-gradient(135deg, #F8F5FF 0%, #ECEAFF 100%);\n"], ["\n  padding: 100px 20px;\n  text-align: center;\n  background: linear-gradient(135deg, #F8F5FF 0%, #ECEAFF 100%);\n"])));
var HeroTitle = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-weight: 800;\n  font-size: 52px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-weight: 800;\n  font-size: 52px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var HeroSubtitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-size: 22px;\n  line-height: 1.6;\n  color: #666666;\n  max-width: 700px;\n  margin: 0 auto 40px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-size: 22px;\n  line-height: 1.6;\n  color: #666666;\n  max-width: 700px;\n  margin: 0 auto 40px;\n"])));
var HeroButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 16px 32px;\n  background: #8F4ACF;\n  border: none;\n  border-radius: 12px;\n  color: white;\n  font-family: 'Inter', sans-serif;\n  font-weight: 600;\n  font-size: 18px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  margin-top: 20px;\n\n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-2px);\n  }\n"], ["\n  padding: 16px 32px;\n  background: #8F4ACF;\n  border: none;\n  border-radius: 12px;\n  color: white;\n  font-family: 'Inter', sans-serif;\n  font-weight: 600;\n  font-size: 18px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  margin-top: 20px;\n\n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-2px);\n  }\n"])));
/* Features Section */
var FeaturesSection = styled.section(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 80px 20px;\n  background: #FFFFFF;\n"], ["\n  padding: 80px 20px;\n  background: #FFFFFF;\n"])));
var SectionTitle = styled.h2(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-weight: 700;\n  font-size: 36px;\n  color: #000000;\n  text-align: center;\n  margin-bottom: 60px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-weight: 700;\n  font-size: 36px;\n  color: #000000;\n  text-align: center;\n  margin-bottom: 60px;\n"])));
var FeatureGrid = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));\n  gap: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));\n  gap: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"])));
var FeatureCard = styled(motion.div)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n  text-align: center;\n  transition: all 0.3s ease;\n  box-shadow: 0 4px 6px rgba(0,0,0,0.05);\n\n  &:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 10px 20px rgba(0,0,0,0.1);\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n  text-align: center;\n  transition: all 0.3s ease;\n  box-shadow: 0 4px 6px rgba(0,0,0,0.05);\n\n  &:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 10px 20px rgba(0,0,0,0.1);\n  }\n"])));
var FeatureIcon = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 48px;\n  color: #8F4ACF;\n  margin-bottom: 20px;\n"], ["\n  font-size: 48px;\n  color: #8F4ACF;\n  margin-bottom: 20px;\n"])));
var FeatureTitle = styled.h3(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 16px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 16px;\n"])));
var FeatureText = styled.p(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n"])));
/* Values Section */
var ValuesSection = styled.section(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 80px 20px;\n  background: #F8F5FF;\n"], ["\n  padding: 80px 20px;\n  background: #F8F5FF;\n"])));
var ValueGrid = styled(FeatureGrid)(templateObject_14 || (templateObject_14 = __makeTemplateObject([""], [""])));
var ValueCard = styled(FeatureCard)(templateObject_15 || (templateObject_15 = __makeTemplateObject([""], [""])));
/* Story Section */
var StorySection = styled.section(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  padding: 80px 20px;\n  background: #FFFFFF;\n"], ["\n  padding: 80px 20px;\n  background: #FFFFFF;\n"])));
var StoryContent = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  max-width: 1000px;\n  margin: 0 auto;\n  text-align: center;\n"], ["\n  max-width: 1000px;\n  margin: 0 auto;\n  text-align: center;\n"])));
var StoryText = styled(FeatureText)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  margin-bottom: 40px;\n"], ["\n  margin-bottom: 40px;\n"])));
/* Use Cases Section */
var UseCasesSection = styled.section(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  padding: 80px 20px;\n  background: #FFFFFF;\n"], ["\n  padding: 80px 20px;\n  background: #FFFFFF;\n"])));
var UseCaseGrid = styled(FeatureGrid)(templateObject_20 || (templateObject_20 = __makeTemplateObject([""], [""])));
var UseCaseCard = styled(FeatureCard)(templateObject_21 || (templateObject_21 = __makeTemplateObject([""], [""])));
/* References Section */
var ReferencesSection = styled.section(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  padding: 80px 20px;\n  background: #F8F5FF;\n"], ["\n  padding: 80px 20px;\n  background: #F8F5FF;\n"])));
/* Contact Section */
var ContactSection = styled.section(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  padding: 80px 20px;\n  background: #FFFFFF;\n  text-align: center;\n"], ["\n  padding: 80px 20px;\n  background: #FFFFFF;\n  text-align: center;\n"])));
var ContactButton = styled(HeroButton)(templateObject_24 || (templateObject_24 = __makeTemplateObject([""], [""])));
/* HomePage Component */
var HomePage = function () {
    var navigate = useNavigate();
    // Features data
    var features = [
        {
            icon: '🔍',
            title: 'Data Analysis',
            text: 'Leverage advanced AI algorithms to gain deep insights from your data.',
        },
        {
            icon: '📈',
            title: 'Marketing Optimization',
            text: 'Optimize your marketing strategies with AI-powered recommendations.',
        },
    ];
    // Values data
    var values = [
        {
            icon: '🚀',
            title: 'Innovation',
            text: 'We strive to push the boundaries of technology and creativity in all that we do.',
        },
        {
            icon: '🤝',
            title: 'Integrity',
            text: "Honesty and transparency are at the heart of our company's core principles.",
        },
        {
            icon: '🌐',
            title: 'Collaboration',
            text: 'We believe that great things are achieved through teamwork and partnership.',
        },
    ];
    // Story data
    var stories = [
        {
            icon: '📅',
            title: 'Our Beginning',
            text: 'Founded in 2023 by a team of data scientists and marketers passionate about AI.',
        },
        {
            icon: '📈',
            title: 'Our Growth',
            text: 'Rapidly expanding our services and client base, driven by innovative AI solutions.',
        },
    ];
    // Use cases data
    var useCases = [
        {
            icon: '🛒',
            title: 'E-commerce',
            text: 'Optimize product recommendations and pricing strategies to boost sales.',
        },
        {
            icon: '👨‍⚕️',
            title: 'Healthcare',
            text: 'Improve patient outcomes through predictive analytics and personalized treatment plans.',
        },
    ];
    // References data
    var references = [
        {
            icon: '🏢',
            title: 'Tech Innovators Inc.',
            text: '"The AI platform has revolutionized our data analysis process, saving us countless hours."',
        },
        {
            icon: '🏥',
            title: 'Global Health Systems',
            text: '"We\'ve seen a 30% improvement in patient outcomes since implementing this AI solution."',
        },
    ];
    return (React.createElement(HomeContainer, null,
        React.createElement(HeroSection, null,
            React.createElement(HeroTitle, null, "Welcome to AI-Powered Platform"),
            React.createElement(HeroSubtitle, null, "Discover the power of AI-driven data analysis and marketing optimization."),
            React.createElement(HeroButton, { onClick: function () { return navigate('/app'); } }, "Get Started")),
        React.createElement(FeaturesSection, null,
            React.createElement(SectionTitle, null, "Our Services"),
            React.createElement(FeatureGrid, null, features.map(function (feature, index) { return (React.createElement(FeatureCard, { key: index, whileHover: { scale: 1.05 } },
                React.createElement(FeatureIcon, null, feature.icon),
                React.createElement(FeatureTitle, null, feature.title),
                React.createElement(FeatureText, null, feature.text))); }))),
        React.createElement(ValuesSection, null,
            React.createElement(SectionTitle, null, "Our Mission"),
            React.createElement(FeatureText, null, "We exist to shorten your data analysis processes and reach the most accurate results with the most precise analysis. Our biggest goal is to offer the most optimal solutions by combining marketing channels with the power of artificial intelligence."),
            React.createElement(ValueGrid, null, values.map(function (value, index) { return (React.createElement(ValueCard, { key: index, whileHover: { scale: 1.05 } },
                React.createElement(FeatureIcon, null, value.icon),
                React.createElement(FeatureTitle, null, value.title),
                React.createElement(FeatureText, null, value.text))); }))),
        React.createElement(StorySection, null,
            React.createElement(SectionTitle, null, "Our Story"),
            React.createElement(StoryContent, null,
                React.createElement(StoryText, null, "Our journey began with a vision to revolutionize data analysis and marketing through AI."),
                React.createElement(FeatureGrid, null, stories.map(function (story, index) { return (React.createElement(FeatureCard, { key: index, whileHover: { scale: 1.05 } },
                    React.createElement(FeatureIcon, null, story.icon),
                    React.createElement(FeatureTitle, null, story.title),
                    React.createElement(FeatureText, null, story.text))); })))),
        React.createElement(UseCasesSection, null,
            React.createElement(SectionTitle, null, "Use Cases"),
            React.createElement(UseCaseGrid, null, useCases.map(function (useCase, index) { return (React.createElement(UseCaseCard, { key: index, whileHover: { scale: 1.05 } },
                React.createElement(FeatureIcon, null, useCase.icon),
                React.createElement(FeatureTitle, null, useCase.title),
                React.createElement(FeatureText, null, useCase.text))); }))),
        React.createElement(ReferencesSection, null,
            React.createElement(SectionTitle, null, "References"),
            React.createElement(FeatureGrid, null, references.map(function (reference, index) { return (React.createElement(FeatureCard, { key: index, whileHover: { scale: 1.05 } },
                React.createElement(FeatureIcon, null, reference.icon),
                React.createElement(FeatureTitle, null, reference.title),
                React.createElement(FeatureText, null, reference.text))); }))),
        React.createElement(ContactSection, null,
            React.createElement(SectionTitle, null, "Get in Touch"),
            React.createElement(HeroSubtitle, null, "Contact our team to learn more about our AI-powered solutions and how we can help your business."),
            React.createElement(ContactButton, { onClick: function () { return navigate('/contact'); } }, "Contact Us"))));
};
export default HomePage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;
