var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  min-width: 160px;\n  height: 40px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  min-width: 160px;\n  height: 40px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n"])));
var GlossaryButton = styled(StyledButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 12px;\n  background: #F8F5FF;\n  color: #8F4ACF;\n  border: 1px solid #ECEAFF;\n\n  &:hover {\n    background: #F0E7FF;\n    transform: translateY(-1px);\n  }\n"], ["\n  margin-left: 12px;\n  background: #F8F5FF;\n  color: #8F4ACF;\n  border: 1px solid #ECEAFF;\n\n  &:hover {\n    background: #F0E7FF;\n    transform: translateY(-1px);\n  }\n"])));
var DataExplorer = function () {
    var navigate = useNavigate();
    var _a = useState([]), datasets = _a[0], setDatasets = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), roleId = _d[0], setRoleId = _d[1];
    useEffect(function () {
        fetchDatasets();
        fetchUserRole();
    }, []);
    var fetchUserRole = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, roleId_1, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/check-auth/')];
                case 1:
                    response = _b.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch user role');
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    console.log('Fetched user data:', data);
                    roleId_1 = ((_a = data.role) === null || _a === void 0 ? void 0 : _a.id) || null;
                    setRoleId(roleId_1);
                    console.log('Set roleId to:', roleId_1);
                    toast.success('User role verified', { id: 'check-role' });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _b.sent();
                    console.error('Error fetching user role:', err_1);
                    toast.error('Failed to verify user role', { id: 'check-role' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchDatasets = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    return [4 /*yield*/, fetch('/api/datasets/')];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch datasets');
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setDatasets(data);
                    toast.success('Datasets loaded successfully', { id: 'fetch-datasets' });
                    return [3 /*break*/, 5];
                case 3:
                    err_2 = _a.sent();
                    setError(err_2 instanceof Error ? err_2.message : 'An error occurred');
                    toast.error('Failed to load datasets', { id: 'fetch-datasets' });
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var columnDetailsStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px'
    };
    var handleAskQuestions = function (datasetName) {
        navigate("/app/".concat(datasetName, "/questions"));
    };
    var handleGlossaryNavigation = function () {
        navigate('/app/data-glossary');
    };
    if (loading) {
        return (React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" },
            React.createElement(CircularProgress, null)));
    }
    if (error) {
        return (React.createElement(Box, { p: 3 },
            React.createElement(Typography, { color: "error" }, error)));
    }
    console.log('User Role:', roleId);
    return (React.createElement(Box, { p: 3 }, datasets === null || datasets === void 0 ? void 0 : datasets.map(function (dataset) {
        var _a;
        return (React.createElement(Accordion, { key: dataset.id, sx: {
                mb: 2,
                '&:before': { display: 'none' },
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'
            } },
            React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), sx: {
                    backgroundColor: '#f8f9fa',
                    '&.Mui-expanded': {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    }
                } },
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', pr: 2 } },
                    React.createElement(Box, null,
                        React.createElement(Typography, { variant: "h5", gutterBottom: true, sx: { mb: 1 } }, dataset.name),
                        React.createElement(Typography, { variant: "body2", color: "textSecondary" },
                            "Last updated: ",
                            new Date(dataset.last_updated).toLocaleDateString())),
                    React.createElement(Box, { sx: { display: 'flex', gap: 2 } },
                        React.createElement(StyledButton, { onClick: function (e) {
                                e.stopPropagation();
                                handleAskQuestions(dataset.name);
                            } },
                            React.createElement(QuestionAnswerIcon, { style: { marginRight: '8px' } }),
                            "Ask Questions"),
                        (React.createElement(GlossaryButton, { onClick: function (e) {
                                console.log('Glossary button clicked, roleId:', roleId);
                                e.stopPropagation();
                                handleGlossaryNavigation();
                            } }, "Data Glossary"))))),
            React.createElement(AccordionDetails, { sx: { p: 3 } },
                React.createElement(Typography, { variant: "body1", paragraph: true }, dataset.description),
                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { mt: 2, mb: 2 } }, "Tables"),
                Object.entries(((_a = dataset.data) === null || _a === void 0 ? void 0 : _a.tables) || {}).map(function (_a) {
                    var _b;
                    var tableName = _a[0], table = _a[1];
                    return (React.createElement(Accordion, { key: tableName, sx: {
                            mb: 1,
                            '&:before': { display: 'none' },
                            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)'
                        } },
                        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                            React.createElement(Typography, { variant: "subtitle1" }, tableName)),
                        React.createElement(AccordionDetails, null,
                            React.createElement(Typography, { variant: "body2", paragraph: true }, table.description),
                            React.createElement(Typography, { variant: "subtitle2", gutterBottom: true }, "Columns:"), (_b = table.columns) === null || _b === void 0 ? void 0 :
                            _b.map(function (column) { return (React.createElement(Accordion, { key: column.name, sx: {
                                    mb: 1,
                                    boxShadow: 'none',
                                    '&:before': { display: 'none' }
                                } },
                                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                        borderRadius: '4px',
                                        '&.Mui-expanded': {
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }
                                    } },
                                    React.createElement(Typography, { variant: "body2", sx: { fontWeight: 'bold' } },
                                        column.name,
                                        " (",
                                        column.type,
                                        ")")),
                                React.createElement(AccordionDetails, { sx: columnDetailsStyle },
                                    React.createElement(Typography, { variant: "body2", paragraph: true }, column.description),
                                    column.distinct_values && column.distinct_values.length > 0 && (React.createElement(Typography, { variant: "body2" },
                                        React.createElement("strong", null, "Distinct values:"),
                                        " ",
                                        column.distinct_values.join(', ')))))); }))));
                }))));
    })));
};
export default DataExplorer;
var templateObject_1, templateObject_2;
