var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"], ["\n  padding: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 40px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 40px;\n"])));
var TitleRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n"])));
var Title = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 32px;\n  font-weight: 700;\n  color: #2d3748;\n  margin: 0;\n"], ["\n  font-size: 32px;\n  font-weight: 700;\n  color: #2d3748;\n  margin: 0;\n"])));
var ScoreCard = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: linear-gradient(135deg, #8f4acf, #6b2e9e);\n  padding: 20px 30px;\n  border-radius: 16px;\n  color: white;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  box-shadow: 0 4px 15px rgba(143, 74, 207, 0.2);\n  min-width: 200px;\n"], ["\n  background: linear-gradient(135deg, #8f4acf, #6b2e9e);\n  padding: 20px 30px;\n  border-radius: 16px;\n  color: white;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  box-shadow: 0 4px 15px rgba(143, 74, 207, 0.2);\n  min-width: 200px;\n"])));
var ScoreValue = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 28px;\n  font-weight: 700;\n"], ["\n  font-size: 28px;\n  font-weight: 700;\n"])));
var ScoreLabel = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  opacity: 0.9;\n"], ["\n  font-size: 16px;\n  opacity: 0.9;\n"])));
var StatsContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 16px;\n  padding: 20px;\n  background: #f7fafc;\n  border-radius: 12px;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n  justify-content: space-around;\n"], ["\n  margin-top: 16px;\n  padding: 20px;\n  background: #f7fafc;\n  border-radius: 12px;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n  justify-content: space-around;\n"])));
var StatsCard = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: white;\n  padding: 16px 24px;\n  border-radius: 12px;\n  box-shadow: 0 2px 8px rgba(0,0,0,0.05);\n  flex: 1 1 200px;\n  text-align: center;\n"], ["\n  background: white;\n  padding: 16px 24px;\n  border-radius: 12px;\n  box-shadow: 0 2px 8px rgba(0,0,0,0.05);\n  flex: 1 1 200px;\n  text-align: center;\n"])));
var StatValue = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 600;\n  color: #4a5568;\n"], ["\n  font-size: 24px;\n  font-weight: 600;\n  color: #4a5568;\n"])));
var StatLabel = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #718096;\n"], ["\n  font-size: 14px;\n  color: #718096;\n"])));
var SectionTitle = styled.h2(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 700;\n  color: #2d3748;\n  margin: 32px 0 16px;\n"], ["\n  font-size: 24px;\n  font-weight: 700;\n  color: #2d3748;\n  margin: 32px 0 16px;\n"])));
var BadgesGrid = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));\n  gap: 24px;\n  margin-bottom: 40px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));\n  gap: 24px;\n  margin-bottom: 40px;\n"])));
var BadgeCard = styled(motion.div)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background: white;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);\n  border: 1px solid ", ";\n  opacity: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-height: 260px;\n  \n  &:hover {\n    transform: translateY(-2px);\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n  }\n"], ["\n  background: white;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);\n  border: 1px solid ", ";\n  opacity: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-height: 260px;\n  \n  &:hover {\n    transform: translateY(-2px);\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n  }\n"])), function (props) { return props.$earned ? '#8F4ACF' : '#edf2f7'; }, function (props) { return props.$earned ? 1 : 0.85; });
var BadgeIcon = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 64px;\n  height: 64px;\n  margin-bottom: 16px;\n  opacity: ", ";\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"], ["\n  width: 64px;\n  height: 64px;\n  margin-bottom: 16px;\n  opacity: ", ";\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"])), function (props) { return props.$earned ? 1 : 0.6; });
var BadgeName = styled.h3(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  color: #2d3748;\n  margin-bottom: 8px;\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  color: #2d3748;\n  margin-bottom: 8px;\n"])));
var BadgeDescription = styled.p(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #718096;\n  margin-bottom: 16px;\n  flex-grow: 1;\n"], ["\n  font-size: 14px;\n  color: #718096;\n  margin-bottom: 16px;\n  flex-grow: 1;\n"])));
var BadgeRequirement = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #8f4acf;\n  font-weight: 500;\n"], ["\n  font-size: 14px;\n  color: #8f4acf;\n  font-weight: 500;\n"])));
// --- New styled components for Goals section ---
var GoalsSection = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  margin-top: 40px;\n"], ["\n  margin-top: 40px;\n"])));
var GoalCard = styled(motion.div)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  background: #e9f5ff;\n  border: 1px solid #a6d8ff;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 2px 8px rgba(0,0,0,0.1);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  max-width: 400px;\n  margin: 0 auto;\n"], ["\n  background: #e9f5ff;\n  border: 1px solid #a6d8ff;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 2px 8px rgba(0,0,0,0.1);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  max-width: 400px;\n  margin: 0 auto;\n"])));
var GoalName = styled.h3(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: 600;\n  margin-bottom: 8px;\n  color: #1a202c;\n"], ["\n  font-size: 20px;\n  font-weight: 600;\n  margin-bottom: 8px;\n  color: #1a202c;\n"])));
var GoalDescription = styled.p(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  font-size: 16px;\n  color: #4a5568;\n  margin-bottom: 16px;\n"], ["\n  font-size: 16px;\n  color: #4a5568;\n  margin-bottom: 16px;\n"])));
var PointsNeeded = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 700;\n  color: #3182ce;\n"], ["\n  font-size: 18px;\n  font-weight: 700;\n  color: #3182ce;\n"])));
// --- End of new styled components ---
// New styled components
var ProgressGrid = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));\n  gap: 24px;\n  margin: 32px 0;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));\n  gap: 24px;\n  margin: 32px 0;\n"])));
var ProgressCard = styled(motion.div)(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  background: white;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);\n  border-left: 4px solid ", ";\n  position: relative;\n  overflow: hidden;\n\n  &::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: ", ";\n    opacity: 0.1;\n  }\n"], ["\n  background: white;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);\n  border-left: 4px solid ", ";\n  position: relative;\n  overflow: hidden;\n\n  &::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: ", ";\n    opacity: 0.1;\n  }\n"])), function (props) { return props.$color; }, function (props) { return props.$color; });
var ProgressHeader = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 16px;\n"])));
var ProgressIcon = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  width: 40px;\n  height: 40px;\n  border-radius: 10px;\n  background: ", ";\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n"], ["\n  width: 40px;\n  height: 40px;\n  border-radius: 10px;\n  background: ", ";\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n"])), function (props) { return "".concat(props.$color, "15"); }, function (props) { return props.$color; });
var ProgressInfo = styled.div(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var ProgressTitle = styled.h3(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 600;\n  color: #2d3748;\n  margin: 0;\n"], ["\n  font-size: 16px;\n  font-weight: 600;\n  color: #2d3748;\n  margin: 0;\n"])));
var ProgressValue = styled.div(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 700;\n  color: #1a202c;\n  margin: 8px 0;\n"], ["\n  font-size: 24px;\n  font-weight: 700;\n  color: #1a202c;\n  margin: 8px 0;\n"])));
var ProgressBar = styled.div(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  height: 6px;\n  background: #edf2f7;\n  border-radius: 3px;\n  position: relative;\n  overflow: hidden;\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: ", "%;\n    background: ", ";\n    border-radius: 3px;\n    transition: width 0.3s ease;\n  }\n"], ["\n  height: 6px;\n  background: #edf2f7;\n  border-radius: 3px;\n  position: relative;\n  overflow: hidden;\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: ", "%;\n    background: ", ";\n    border-radius: 3px;\n    transition: width 0.3s ease;\n  }\n"])), function (props) { return Math.min(props.$progress, 100); }, function (props) { return props.$color; });
var EmptyState = styled(motion.div)(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  text-align: center;\n  padding: 48px 24px;\n  background: #f7fafc;\n  border-radius: 16px;\n  margin: 32px 0;\n"], ["\n  text-align: center;\n  padding: 48px 24px;\n  background: #f7fafc;\n  border-radius: 16px;\n  margin: 32px 0;\n"])));
var EmptyStateTitle = styled.h3(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: 600;\n  color: #2d3748;\n  margin-bottom: 12px;\n"], ["\n  font-size: 20px;\n  font-weight: 600;\n  color: #2d3748;\n  margin-bottom: 12px;\n"])));
var EmptyStateText = styled.p(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  color: #718096;\n  max-width: 500px;\n  margin: 0 auto;\n"], ["\n  color: #718096;\n  max-width: 500px;\n  margin: 0 auto;\n"])));
var BadgesPage = function () {
    var _a = useState(null), badgesData = _a[0], setBadgesData = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        fetchBadges();
    }, []);
    var fetchBadges = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    return [4 /*yield*/, fetch('/api/badges/', {
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch badges');
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setBadgesData(data);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error fetching badges:', error_1);
                    toast.error('Failed to load badges');
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (loading) {
        return React.createElement("div", null, "Loading...");
    }
    if (!badgesData) {
        return React.createElement("div", null, "No badges data available");
    }
    var earnedBadgeIds = new Set(badgesData.earned_badges.map(function (ub) { return ub.badge.id; }));
    // --- Compute the upcoming goal (badges not earned and with required_score above current total) ---
    var upcomingGoals = badgesData.available_badges
        .filter(function (badge) { return !earnedBadgeIds.has(badge.id) && badge.required_score > badgesData.total_score; })
        .sort(function (a, b) { return (a.required_score - badgesData.total_score) - (b.required_score - badgesData.total_score); });
    var nextGoal = upcomingGoals.length > 0 ? upcomingGoals[0] : null;
    var pointsNeeded = nextGoal ? nextGoal.required_score - badgesData.total_score : 0;
    // --- End computing goals ---
    var progressCategories = [
        {
            name: 'Total Wait Score',
            value: badgesData.total_score,
            icon: '🏆',
            description: 'Your overall progress score',
            target: 1000,
            color: '#8f4acf'
        },
        {
            name: 'Questions Asked',
            value: badgesData.stats.asked_questions_count,
            icon: '❓',
            description: 'Keep asking great questions!',
            target: 500,
            color: '#3182ce'
        },
        {
            name: 'Favorites',
            value: badgesData.stats.favorite_count,
            icon: '⭐',
            description: 'Save more questions you love',
            target: 25,
            color: '#ecc94b'
        },
        {
            name: 'Folders Created',
            value: badgesData.stats.folder_count,
            icon: '📁',
            description: 'Organize your questions',
            target: 10,
            color: '#48bb78'
        },
        {
            name: 'Questions in Folders',
            value: badgesData.stats.questions_in_folders,
            icon: '📑',
            description: 'Build your knowledge base',
            target: 50,
            color: '#805ad5'
        }
    ];
    return (React.createElement(PageContainer, null,
        React.createElement(Header, null,
            React.createElement(TitleRow, null,
                React.createElement(Title, null, "Your Progress"))),
        React.createElement(ProgressGrid, null, progressCategories.map(function (category) {
            var progress = (category.value / category.target) * 100;
            return (React.createElement(ProgressCard, { key: category.name, "$color": category.color, initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.3 } },
                React.createElement(ProgressHeader, null,
                    React.createElement(ProgressIcon, { "$color": category.color }, category.icon),
                    React.createElement(ProgressInfo, null,
                        React.createElement(ProgressTitle, null, category.name),
                        React.createElement(ProgressValue, null, category.value))),
                React.createElement(ProgressBar, { "$progress": progress, "$color": category.color })));
        })),
        badgesData.available_badges.length === 0 ? (React.createElement(EmptyState, { initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 } },
            React.createElement(EmptyStateTitle, null, "Badges Coming Soon!"),
            React.createElement(EmptyStateText, null, "We're working on an exciting badge system to recognize your achievements. Keep engaging with the platform to earn badges when they're released!"))) : (React.createElement(SectionTitle, null, "Available Badges")),
        badgesData.available_badges.length > 0 ? (React.createElement(BadgesGrid, null, badgesData.available_badges.map(function (badge) {
            var isEarned = earnedBadgeIds.has(badge.id);
            return (React.createElement(BadgeCard, { key: badge.id, "$earned": isEarned, initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.3 } },
                React.createElement(BadgeIcon, { "$earned": isEarned },
                    React.createElement("img", { src: badge.icon, alt: badge.name })),
                React.createElement(BadgeName, null, badge.name),
                React.createElement(BadgeDescription, null, badge.description),
                React.createElement(BadgeRequirement, null, isEarned ? 'Earned!' : "Requires ".concat(badge.required_score, " wait score"))));
        }))) : (React.createElement("div", null, "No available badges at this time."))));
};
export default BadgesPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34;
