var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import LogoImage from '../../assets/LOGO-light-vertical.png';
var glow = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n  50% { box-shadow: 0 0 20px rgba(143, 74, 207, 0.6); }\n  100% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n"], ["\n  0% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n  50% { box-shadow: 0 0 20px rgba(143, 74, 207, 0.6); }\n  100% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n"])));
var floatAnimation = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0%, 100% { transform: translateY(0) rotate(0deg); }\n  25% { transform: translateY(-15px) rotate(5deg); }\n  75% { transform: translateY(15px) rotate(-5deg); }\n"], ["\n  0%, 100% { transform: translateY(0) rotate(0deg); }\n  25% { transform: translateY(-15px) rotate(5deg); }\n  75% { transform: translateY(15px) rotate(-5deg); }\n"])));
var pulseGlow = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0%, 100% { filter: drop-shadow(0 0 8px rgba(143, 74, 207, 0.3)); }\n  50% { filter: drop-shadow(0 0 20px rgba(143, 74, 207, 0.6)); }\n"], ["\n  0%, 100% { filter: drop-shadow(0 0 8px rgba(143, 74, 207, 0.3)); }\n  50% { filter: drop-shadow(0 0 20px rgba(143, 74, 207, 0.6)); }\n"])));
var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: grid;\n  grid-template-rows: 1fr auto;\n  background: rgba(255, 255, 255, 0.97);\n  border-radius: 20px;\n  overflow: hidden;\n  backdrop-filter: blur(10px);\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: grid;\n  grid-template-rows: 1fr auto;\n  background: rgba(255, 255, 255, 0.97);\n  border-radius: 20px;\n  overflow: hidden;\n  backdrop-filter: blur(10px);\n"])));
var CanvasContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n  height: 100%;\n"])));
var Canvas = styled.canvas(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n"])));
var Footer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n"], ["\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n"])));
var InfoOverlay = styled(motion.div)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  background: rgba(255, 255, 255, 0.9);\n  padding: 16px 24px;\n  border-radius: 16px;\n  box-shadow: 0 4px 20px rgba(143, 74, 207, 0.15);\n  backdrop-filter: blur(8px);\n  z-index: 2;\n  font-family: 'Inter';\n  animation: ", " 3s ease-in-out infinite;\n"], ["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  background: rgba(255, 255, 255, 0.9);\n  padding: 16px 24px;\n  border-radius: 16px;\n  box-shadow: 0 4px 20px rgba(143, 74, 207, 0.15);\n  backdrop-filter: blur(8px);\n  z-index: 2;\n  font-family: 'Inter';\n  animation: ", " 3s ease-in-out infinite;\n"])), pulseGlow);
var Score = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 600;\n  color: #8F4ACF;\n"], ["\n  font-size: 16px;\n  font-weight: 600;\n  color: #8F4ACF;\n"])));
var Fact = styled(motion.div)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #6B2E9E;\n  line-height: 1.5;\n  font-weight: 500;\n  text-align: center;\n  padding: 16px 24px;\n  background: #F8F5FF;\n  border-radius: 12px;\n  max-width: 80%;\n  animation: ", " 10s ease-in-out infinite;\n"], ["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #6B2E9E;\n  line-height: 1.5;\n  font-weight: 500;\n  text-align: center;\n  padding: 16px 24px;\n  background: #F8F5FF;\n  border-radius: 12px;\n  max-width: 80%;\n  animation: ", " 10s ease-in-out infinite;\n"])), glow);
var LoadingProgress = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 80%;\n  height: 4px;\n  background: #E2E8F0;\n  border-radius: 2px;\n  overflow: hidden;\n  z-index: 2;\n"], ["\n  position: absolute;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 80%;\n  height: 4px;\n  background: #E2E8F0;\n  border-radius: 2px;\n  overflow: hidden;\n  z-index: 2;\n"])));
var ProgressBar = styled(motion.div)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  height: 100%;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border-radius: 2px;\n"], ["\n  height: 100%;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border-radius: 2px;\n"])));
var InteractiveLoading = function (_a) {
    var onComplete = _a.onComplete, _b = _a.progress, progress = _b === void 0 ? 0 : _b, questionId = _a.questionId, _c = _a.isAnalysisConfirmed, isAnalysisConfirmed = _c === void 0 ? false : _c;
    var canvasRef = useRef(null);
    var _d = useState([]), particles = _d[0], setParticles = _d[1];
    var _e = useState(function () {
        var savedScore = localStorage.getItem('waitingScore');
        return savedScore ? parseInt(savedScore) : 0;
    }), score = _e[0], setScore = _e[1];
    var _f = useState(0), currentFact = _f[0], setCurrentFact = _f[1];
    var requestRef = useRef();
    var previousTimeRef = useRef();
    var facts = [
        "Click the bubbles while you wait!",
        "Each bubble gives you points...",
        "The more bubbles you pop, the higher your score!",
        "Analysis in progress...",
        "Almost there...",
    ];
    var createParticle = function (x, y) {
        var _a, _b;
        // If no specific position is provided, create random position around the edges
        if (x === undefined || y === undefined) {
            var side = Math.floor(Math.random() * 4); // 0: top, 1: right, 2: bottom, 3: left
            var canvasWidth = ((_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.width) || window.innerWidth;
            var canvasHeight = ((_b = canvasRef.current) === null || _b === void 0 ? void 0 : _b.height) || window.innerHeight;
            switch (side) {
                case 0: // top
                    x = Math.random() * canvasWidth;
                    y = -50;
                    break;
                case 1: // right
                    x = canvasWidth + 50;
                    y = Math.random() * canvasHeight;
                    break;
                case 2: // bottom
                    x = Math.random() * canvasWidth;
                    y = canvasHeight + 50;
                    break;
                case 3: // left
                    x = -50;
                    y = Math.random() * canvasHeight;
                    break;
            }
        }
        var size = Math.random() * 30 + 40;
        return {
            id: Math.random(),
            x: x,
            y: y,
            vx: (Math.random() - 0.5) * 1.5,
            vy: (Math.random() - 0.5) * 1.5,
            size: size,
            color: "hsla(".concat(Math.random() * 60 + 260, ", 70%, 60%, 0.4)"),
            score: Math.floor(Math.random() * 5) + 1,
            rotation: Math.random() * 360,
            rotationSpeed: (Math.random() - 0.5) * 2
        };
    };
    var createPopEffect = function (x, y, color, score) {
        var popParticles = Array.from({ length: 12 }, function (_, i) { return (__assign(__assign({}, createParticle(x, y)), { size: 4, color: color, isPopping: true, vx: Math.cos((i / 12) * Math.PI * 2) * 5, vy: Math.sin((i / 12) * Math.PI * 2) * 5 })); });
        setParticles(function (prev) { return __spreadArray(__spreadArray([], prev, true), popParticles, true); });
        // Pass the score parameter instead of accessing particle
        showScoreAnimation(x, y, score);
    };
    var showScoreAnimation = function (x, y, score) {
        var _a;
        var rect = (_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!rect)
            return;
        var scoreElement = document.createElement('div');
        scoreElement.innerText = "+".concat(score);
        scoreElement.style.position = 'absolute';
        scoreElement.style.left = "".concat(x + rect.left, "px");
        scoreElement.style.top = "".concat(y + rect.top, "px");
        scoreElement.style.color = '#8F4ACF';
        scoreElement.style.fontWeight = 'bold';
        scoreElement.style.fontSize = '20px';
        scoreElement.style.pointerEvents = 'none';
        scoreElement.style.zIndex = '1000';
        scoreElement.style.animation = 'scoreFloat 1s ease-out forwards';
        document.body.appendChild(scoreElement);
        setTimeout(function () {
            document.body.removeChild(scoreElement);
        }, 1000);
    };
    var GlobalStyle = createGlobalStyle(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    @keyframes scoreFloat {\n      0% {\n        transform: translateY(0) scale(1);\n        opacity: 1;\n      }\n      100% {\n        transform: translateY(-50px) scale(1.5);\n        opacity: 0;\n      }\n    }\n  "], ["\n    @keyframes scoreFloat {\n      0% {\n        transform: translateY(0) scale(1);\n        opacity: 1;\n      }\n      100% {\n        transform: translateY(-50px) scale(1.5);\n        opacity: 0;\n      }\n    }\n  "])));
    useEffect(function () {
        if (canvasRef.current) {
            canvasRef.current.width = canvasRef.current.offsetWidth;
            canvasRef.current.height = canvasRef.current.offsetHeight;
            // Create initial particles with random positions around all edges
            var initialParticles = Array.from({ length: 12 }, function () {
                var _a, _b;
                var canvasWidth = ((_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.width) || window.innerWidth;
                var canvasHeight = ((_b = canvasRef.current) === null || _b === void 0 ? void 0 : _b.height) || window.innerHeight;
                // Randomly choose which edge to spawn from
                var edge = Math.floor(Math.random() * 4);
                var x, y;
                switch (edge) {
                    case 0: // top
                        x = Math.random() * canvasWidth;
                        y = -50;
                        break;
                    case 1: // right
                        x = canvasWidth + 50;
                        y = Math.random() * canvasHeight;
                        break;
                    case 2: // bottom
                        x = Math.random() * canvasWidth;
                        y = canvasHeight + 50;
                        break;
                    case 3: // left
                    default:
                        x = -50;
                        y = Math.random() * canvasHeight;
                        break;
                }
                return createParticle(x, y);
            });
            setParticles(initialParticles);
        }
        // Slow down fact transition to 5 seconds
        var factInterval = setInterval(function () {
            setCurrentFact(function (prev) { return (prev + 1) % facts.length; });
        }, 10000); // Changed from 3000 to 5000
        return function () { return clearInterval(factInterval); };
    }, []);
    var animate = function (time) {
        if (previousTimeRef.current !== undefined) {
            updateParticles();
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    };
    var drawParticle = function (ctx, particle) {
        var logo = new Image();
        logo.src = LogoImage;
        ctx.save();
        // Add floating animation effect
        var floatOffset = Math.sin(Date.now() * 0.002 + particle.id) * 5;
        var adjustedY = particle.y + floatOffset;
        // Rotate with smooth oscillation
        var rotationOffset = Math.sin(Date.now() * 0.001 + particle.id) * 15;
        ctx.translate(particle.x, adjustedY);
        ctx.rotate(((particle.rotation + rotationOffset) * Math.PI) / 180);
        ctx.translate(-particle.x, -adjustedY);
        // Draw logo with proper sizing
        var aspectRatio = logo.width / logo.height;
        var drawWidth = particle.size * 1.2;
        var drawHeight = drawWidth / aspectRatio;
        ctx.globalAlpha = 0.95;
        ctx.drawImage(logo, particle.x - drawWidth / 2, adjustedY - drawHeight / 2, drawWidth, drawHeight);
        ctx.restore();
    };
    var updateParticles = function () {
        var canvas = canvasRef.current;
        if (!canvas)
            return;
        var ctx = canvas.getContext('2d');
        if (!ctx)
            return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        setParticles(function (prevParticles) {
            return prevParticles
                .filter(function (particle) {
                if (particle.isPopping) {
                    return particle.size > 0.2;
                }
                return true;
            })
                .map(function (particle) {
                var x = particle.x + particle.vx;
                var y = particle.y + particle.vy;
                // Bounce off walls with dampening
                if (x < 0 || x > canvas.width) {
                    particle.vx *= -0.8;
                    x = x < 0 ? 0 : canvas.width;
                }
                if (y < 0 || y > canvas.height) {
                    particle.vy *= -0.8;
                    y = y < 0 ? 0 : canvas.height;
                }
                var size = particle.isPopping
                    ? particle.size * 0.9
                    : particle.size;
                // Draw the particle with logo
                if (!particle.isPopping) {
                    drawParticle(ctx, __assign(__assign({}, particle), { x: x, y: y, size: size }));
                }
                return __assign(__assign({}, particle), { x: x, y: y, size: size, rotation: particle.rotation + particle.rotationSpeed });
            });
        });
    };
    useEffect(function () {
        if (canvasRef.current) {
            canvasRef.current.width = canvasRef.current.offsetWidth;
            canvasRef.current.height = canvasRef.current.offsetHeight;
        }
        requestRef.current = requestAnimationFrame(animate);
        return function () {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);
    var logScore = function (scoreToLog) {
        localStorage.setItem('waitingScore', scoreToLog.toString());
    };
    var handleCanvasClick = function (event) {
        var _a;
        var rect = (_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!rect)
            return;
        var x = event.clientX - rect.left;
        var y = event.clientY - rect.top;
        particles.forEach(function (particle) {
            if (particle.isPopping)
                return;
            var dx = x - particle.x;
            var dy = y - particle.y;
            var distance = Math.sqrt(dx * dx + dy * dy);
            if (distance < particle.size) {
                var particleScore_1 = particle.score || 1;
                setScore(function (prev) {
                    var newScore = prev + particleScore_1;
                    logScore(newScore);
                    return newScore;
                });
                createPopEffect(particle.x, particle.y, particle.color, particleScore_1);
                setParticles(function (prev) { return prev.filter(function (p) { return p.id !== particle.id; }); });
                setTimeout(function () {
                    setParticles(function (prev) {
                        var _a, _b;
                        return __spreadArray(__spreadArray([], prev, true), [createParticle(Math.random() * (((_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.width) || window.innerWidth), Math.random() * (((_b = canvasRef.current) === null || _b === void 0 ? void 0 : _b.height) || window.innerHeight))], false);
                    });
                }, 1000);
            }
        });
    };
    useEffect(function () {
        return function () {
            setScore(0);
        };
    }, []);
    return (React.createElement(Container, null,
        React.createElement(CanvasContainer, null,
            React.createElement(Canvas, { ref: canvasRef, onClick: handleCanvasClick }),
            React.createElement(InfoOverlay, { initial: { opacity: 0, y: -20 }, animate: { opacity: 1, y: 0 } },
                React.createElement(Score, null,
                    "Score: ",
                    score))),
        React.createElement(Footer, null,
            React.createElement(AnimatePresence, { mode: "wait" },
                React.createElement(Fact, { key: currentFact, initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: -20 } }, facts[currentFact])))));
};
export default InteractiveLoading;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
