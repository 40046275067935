var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
export var shimmer = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { background-position: -1000px 0; }\n  100% { background-position: 1000px 0; }\n"], ["\n  0% { background-position: -1000px 0; }\n  100% { background-position: 1000px 0; }\n"])));
export var float = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0%, 100% { transform: translateY(0); }\n  50% { transform: translateY(-20px); }\n"], ["\n  0%, 100% { transform: translateY(0); }\n  50% { transform: translateY(-20px); }\n"])));
export var pulse = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0% { transform: scale(1); }\n  50% { transform: scale(1.05); }\n  100% { transform: scale(1); }\n"], ["\n  0% { transform: scale(1); }\n  50% { transform: scale(1.05); }\n  100% { transform: scale(1); }\n"])));
export var AnimatedBackground = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: linear-gradient(\n    45deg,\n    #F8F5FF 0%,\n    #ECEAFF 25%,\n    #E7DBFF 50%,\n    #ECEAFF 75%,\n    #F8F5FF 100%\n  );\n  background-size: 200% 200%;\n  animation: ", " 15s linear infinite;\n"], ["\n  background: linear-gradient(\n    45deg,\n    #F8F5FF 0%,\n    #ECEAFF 25%,\n    #E7DBFF 50%,\n    #ECEAFF 75%,\n    #F8F5FF 100%\n  );\n  background-size: 200% 200%;\n  animation: ", " 15s linear infinite;\n"])), shimmer);
export var FloatingElement = styled(motion.div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  animation: ", " 6s ease-in-out infinite;\n"], ["\n  animation: ", " 6s ease-in-out infinite;\n"])), float);
export var PulsingElement = styled(motion.div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  animation: ", " 4s ease-in-out infinite;\n"], ["\n  animation: ", " 4s ease-in-out infinite;\n"])), pulse);
export var GlassCard = styled(motion.div)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: rgba(255, 255, 255, 0.7);\n  backdrop-filter: blur(10px);\n  border-radius: 20px;\n  border: 1px solid rgba(255, 255, 255, 0.3);\n  padding: 30px;\n  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);\n"], ["\n  background: rgba(255, 255, 255, 0.7);\n  backdrop-filter: blur(10px);\n  border-radius: 20px;\n  border: 1px solid rgba(255, 255, 255, 0.3);\n  padding: 30px;\n  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);\n"])));
export var pageTransition = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
};
export var staggerContainer = {
    initial: { opacity: 0 },
    animate: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1
        }
    }
};
export var fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5
        }
    }
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
