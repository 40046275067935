var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var ProfileSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var AvatarSection = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 24px;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 24px;\n  margin-bottom: 32px;\n"])));
var Avatar = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  background: ", ";\n  background-size: cover;\n  background-position: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #8F4ACF;\n  font-size: 36px;\n"], ["\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  background: ", ";\n  background-size: cover;\n  background-position: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #8F4ACF;\n  font-size: 36px;\n"])), function (props) { return props.url ? "url(".concat(props.url, ")") : '#F8F5FF'; });
var AvatarUpload = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var UploadButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  color: #8F4ACF;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:hover {\n    background: #ECEAFF;\n  }\n"], ["\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  color: #8F4ACF;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:hover {\n    background: #ECEAFF;\n  }\n"])));
var Form = styled.form(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n  \n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n  \n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"])));
var FormGroup = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var Label = styled.label(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n"])));
var Input = styled.input(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var SaveButton = styled.button(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-top: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-top: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var ErrorMessage = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: #dc3545;\n  padding: 10px;\n  margin-bottom: 20px;\n  border-radius: 4px;\n  background-color: #f8d7da;\n"], ["\n  color: #dc3545;\n  padding: 10px;\n  margin-bottom: 20px;\n  border-radius: 4px;\n  background-color: #f8d7da;\n"])));
var SuccessMessage = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: #28a745;\n  padding: 10px;\n  margin-bottom: 20px;\n  border-radius: 4px;\n  background-color: #d4edda;\n"], ["\n  color: #28a745;\n  padding: 10px;\n  margin-bottom: 20px;\n  border-radius: 4px;\n  background-color: #d4edda;\n"])));
var UserProfile = function () {
    var user = useAuth().user;
    var navigate = useNavigate();
    var _a = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        department: '',
    }), formData = _a[0], setFormData = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    var _d = useState(''), successMessage = _d[0], setSuccessMessage = _d[1];
    useEffect(function () {
        fetchUserProfile();
    }, []);
    var fetchUserProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/user/profile/')];
                case 1:
                    response = _a.sent();
                    setFormData(response.data);
                    setIsLoading(false);
                    toast.success('Profile data loaded successfully', { id: 'fetch-profile' });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error fetching profile:', error_1);
                    setError('Failed to load profile data');
                    toast.error('Failed to load profile data', { id: 'fetch-profile' });
                    setIsLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setFormData(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setError('');
                    setSuccessMessage('');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.patch('/api/user/profile/', {
                            firstName: formData.firstName,
                            lastName: formData.lastName,
                            phone: formData.phone,
                        })];
                case 2:
                    response = _a.sent();
                    if (response.data.success) {
                        setSuccessMessage('Profile updated successfully');
                        toast.success('Profile updated successfully', { id: 'update-profile' });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error updating profile:', error_2);
                    setError('Failed to update profile');
                    toast.error('Failed to update profile', { id: 'update-profile' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (isLoading) {
        return React.createElement("div", null, "Loading...");
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null, "User Profile"),
        error && React.createElement(ErrorMessage, null, error),
        successMessage && React.createElement(SuccessMessage, null, successMessage),
        React.createElement(ProfileSection, null,
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "First Name"),
                    React.createElement(Input, { type: "text", name: "firstName", value: formData.firstName, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Last Name"),
                    React.createElement(Input, { type: "text", name: "lastName", value: formData.lastName, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Email"),
                    React.createElement(Input, { type: "email", name: "email", value: formData.email, disabled: true, style: { backgroundColor: '#f5f5f5' } })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Phone"),
                    React.createElement(Input, { type: "tel", name: "phone", value: formData.phone, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Company"),
                    React.createElement(Input, { type: "text", name: "company", value: formData.company, disabled: true, style: { backgroundColor: '#f5f5f5' } })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Department"),
                    React.createElement(Input, { type: "text", name: "department", value: formData.department, disabled: true, style: { backgroundColor: '#f5f5f5' } })),
                React.createElement(SaveButton, { type: "submit" }, "Save Changes")))));
};
export default UserProfile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
