var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactFlow, { Background, Controls, Handle, Position, } from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import dagre from 'dagre';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100vh;\n  background: #fafafa;\n"], ["\n  width: 100%;\n  height: 100vh;\n  background: #fafafa;\n"])));
var FlowContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: calc(100vh - 80px);\n  background: #fafafa;\n  overflow: hidden;\n\n  .react-flow__node {\n    width: auto;\n    height: auto;\n  }\n\n  .react-flow__edge {\n    pointer-events: none;\n  }\n\n  .react-flow__edge-path {\n    stroke-width: 2;\n  }\n\n  .react-flow__handle {\n    opacity: 0;\n  }\n"], ["\n  width: 100%;\n  height: calc(100vh - 80px);\n  background: #fafafa;\n  overflow: hidden;\n\n  .react-flow__node {\n    width: auto;\n    height: auto;\n  }\n\n  .react-flow__edge {\n    pointer-events: none;\n  }\n\n  .react-flow__edge-path {\n    stroke-width: 2;\n  }\n\n  .react-flow__handle {\n    opacity: 0;\n  }\n"])));
var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 80px;\n  padding: 0 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: white;\n  border-bottom: 1px solid #e2e8f0;\n"], ["\n  height: 80px;\n  padding: 0 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: white;\n  border-bottom: 1px solid #e2e8f0;\n"])));
var BackButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 16px;\n  border: 1px solid #e2e8f0;\n  border-radius: 8px;\n  background: white;\n  color: #4a5568;\n  cursor: pointer;\n  font-size: 14px;\n  transition: all 0.2s;\n\n  &:hover {\n    background: #f7fafc;\n    transform: translateY(-1px);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 16px;\n  border: 1px solid #e2e8f0;\n  border-radius: 8px;\n  background: white;\n  color: #4a5568;\n  cursor: pointer;\n  font-size: 14px;\n  transition: all 0.2s;\n\n  &:hover {\n    background: #f7fafc;\n    transform: translateY(-1px);\n  }\n"])));
var QuestionNode = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 16px;\n  border-radius: 12px;\n  background: white;\n  border: 2px solid ", ";\n  width: 350px;\n  font-family: 'Inter', sans-serif;\n  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);\n  transition: all 0.2s;\n  position: relative;\n  cursor: ", ";\n\n  &:hover {\n    transform: ", ";\n    box-shadow: ", ";\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: -20px;\n    left: 50%;\n    width: 2px;\n    height: 20px;\n    background: ", ";\n  }\n"], ["\n  padding: 16px;\n  border-radius: 12px;\n  background: white;\n  border: 2px solid ", ";\n  width: 350px;\n  font-family: 'Inter', sans-serif;\n  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);\n  transition: all 0.2s;\n  position: relative;\n  cursor: ", ";\n\n  &:hover {\n    transform: ", ";\n    box-shadow: ", ";\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: -20px;\n    left: 50%;\n    width: 2px;\n    height: 20px;\n    background: ", ";\n  }\n"])), function (props) { return props.isAsked ? '#8F4ACF' : '#e2e8f0'; }, function (props) { return props.isAsked ? 'pointer' : 'default'; }, function (props) { return props.isAsked ? 'translateY(-2px)' : 'none'; }, function (props) { return props.isAsked ? '0 8px 12px -2px rgba(0, 0, 0, 0.15)' : '0 4px 6px -1px rgba(0, 0, 0, 0.1)'; }, function (props) { return (props.isAsked ? '#8F4ACF' : '#e2e8f0'); });
var QuestionText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #2d3748;\n  margin-bottom: 12px;\n  line-height: 1.4;\n"], ["\n  font-size: 14px;\n  color: #2d3748;\n  margin-bottom: 12px;\n  line-height: 1.4;\n"])));
var NodeFooter = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 8px;\n  font-size: 12px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 8px;\n  font-size: 12px;\n"])));
var Status = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 4px 8px;\n  border-radius: 12px;\n  background: ", ";\n  color: ", ";\n"], ["\n  padding: 4px 8px;\n  border-radius: 12px;\n  background: ", ";\n  color: ", ";\n"])), function (props) { return (props.isAsked ? '#F0FDF4' : '#FEF2F2'); }, function (props) { return (props.isAsked ? '#166534' : '#991B1B'); });
var AskNowButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  gap: 4px;\n  padding: 4px 12px;\n  background: #F0FDF4;\n  border: 1px solid #166534;\n  border-radius: 12px;\n  color: #166534;\n  font-size: 12px;\n  cursor: pointer !important;\n  transition: all 0.2s;\n  z-index: 50;\n  position: relative;\n  pointer-events: all !important;\n\n  &:hover {\n    background: #DCFCE7;\n    transform: translateY(-1px);\n  }\n\n  svg {\n    width: 12px;\n    height: 12px;\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  gap: 4px;\n  padding: 4px 12px;\n  background: #F0FDF4;\n  border: 1px solid #166534;\n  border-radius: 12px;\n  color: #166534;\n  font-size: 12px;\n  cursor: pointer !important;\n  transition: all 0.2s;\n  z-index: 50;\n  position: relative;\n  pointer-events: all !important;\n\n  &:hover {\n    background: #DCFCE7;\n    transform: translateY(-1px);\n  }\n\n  svg {\n    width: 12px;\n    height: 12px;\n  }\n"])));
var CustomNode = function (_a) {
    var data = _a.data;
    var navigate = useNavigate();
    var handleAskNow = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        navigate('/app/analysis', { state: { question: data.question } });
    }, [data.question, navigate]);
    var handleNodeClick = useCallback(function (e) {
        e.preventDefault();
        if (data.isAsked && data.id) {
            navigate("/app/analysis/result/".concat(data.id));
        }
    }, [data.isAsked, data.id, navigate]);
    return (React.createElement("div", { onClick: handleNodeClick },
        React.createElement(QuestionNode, { isAsked: data.isAsked, style: {
                pointerEvents: 'all',
                cursor: data.isAsked ? 'pointer' : 'default'
            } },
            React.createElement(Handle, { type: "target", position: Position.Top, style: { opacity: 0 } }),
            React.createElement(QuestionText, null, data.question),
            React.createElement(NodeFooter, null,
                React.createElement(Status, { isAsked: data.isAsked }, data.isAsked ? 'Asked' : 'Not Asked'),
                !data.isAsked && (React.createElement(AskNowButton, { type: "button", onClick: handleAskNow, style: {
                        pointerEvents: 'all',
                        position: 'relative',
                        zIndex: 50
                    } },
                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor" },
                        React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" })),
                    "Ask Now"))),
            React.createElement(Handle, { type: "source", position: Position.Bottom, style: { opacity: 0 } }))));
};
var nodeTypes = {
    questionNode: CustomNode,
};
var getLayoutedElements = function (nodes, edges, direction) {
    if (direction === void 0) { direction = 'TB'; }
    var dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(function () { return ({}); });
    // Improved graph configuration for better tree layout
    dagreGraph.setGraph({
        rankdir: direction,
        align: 'DL', // Changed to DL for better downward alignment
        nodesep: 200, // Increased horizontal spacing
        ranksep: 180, // Increased vertical spacing
        edgesep: 100, // Increased edge separation
        marginx: 50, // Increased margins
        marginy: 50,
        acyclicer: 'greedy', // Added to handle any potential cycles
        ranker: 'network-simplex', // Better for tree structures
    });
    var NODE_WIDTH = 350;
    var NODE_HEIGHT = 150;
    // Add nodes with consistent dimensions
    nodes.forEach(function (node) {
        dagreGraph.setNode(node.id, {
            width: NODE_WIDTH,
            height: NODE_HEIGHT,
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 20,
            paddingBottom: 20,
        });
    });
    // Add edges with consistent weight
    edges.forEach(function (edge) {
        dagreGraph.setEdge(edge.source, edge.target, {
            weight: 2, // Increased weight for more stable layout
            minlen: 1, // Minimum edge length
            labelpos: 'c',
            labeloffset: 10,
        });
    });
    dagre.layout(dagreGraph);
    // Position nodes with offset compensation
    var layoutedNodes = nodes.map(function (node) {
        var nodeWithPosition = dagreGraph.node(node.id);
        return __assign(__assign({}, node), { position: {
                x: nodeWithPosition.x - NODE_WIDTH / 2,
                y: nodeWithPosition.y - NODE_HEIGHT / 2,
            } });
    });
    return { nodes: layoutedNodes, edges: edges };
};
var QuestionLineage = function () {
    var questionId = useParams().questionId;
    var navigate = useNavigate();
    var _a = useState([]), lineage = _a[0], setLineage = _a[1];
    var _b = useState(null), rootQuestion = _b[0], setRootQuestion = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    // Add early return for undefined questionId
    if (!questionId)
        return null;
    // This function processes your lineage tree into flat nodes and edges.
    var processLineageToFlow = useCallback(function (nodes, parentId) {
        var flowNodes = [];
        var flowEdges = [];
        nodes.forEach(function (node) {
            var nodeId = "".concat(node.id || 'temp', "-").concat(node.created_at);
            // Create a node without a set position (it will be computed by dagre)
            flowNodes.push({
                id: nodeId,
                type: 'questionNode',
                position: { x: 0, y: 0 },
                data: __assign({}, node),
            });
            // If there is a parent, add an edge from the parent node to this node.
            if (parentId) {
                flowEdges.push({
                    id: "e-".concat(parentId, "-").concat(nodeId),
                    source: parentId,
                    target: nodeId,
                    type: 'smoothstep',
                    animated: true,
                    style: { stroke: '#8F4ACF', strokeWidth: 2 },
                });
            }
            // Process children recursively.
            if (node.children && node.children.length > 0) {
                var _a = processLineageToFlow(node.children, nodeId), childNodes = _a[0], childEdges = _a[1];
                flowNodes = __spreadArray(__spreadArray([], flowNodes, true), childNodes, true);
                flowEdges = __spreadArray(__spreadArray([], flowEdges, true), childEdges, true);
            }
        });
        return [flowNodes, flowEdges];
    }, []);
    // Fetch lineage data from your API.
    useEffect(function () {
        var fetchLineage = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, 4, 5]);
                        return [4 /*yield*/, fetch("/api/questions/lineage/".concat(questionId, "/"))];
                    case 1:
                        response = _a.sent();
                        if (!response.ok)
                            throw new Error('Failed to fetch lineage');
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        if (data.status === 'success') {
                            // Set the root question from the API response
                            setRootQuestion({
                                id: parseInt(questionId),
                                question: data.question.question,
                                created_at: data.question.created_at,
                                isAsked: true, // Root question is always asked
                                children: data.lineage // Add lineage as children of root
                            });
                            setLineage(data.lineage);
                        }
                        else {
                            throw new Error(data.message || 'Failed to load lineage data');
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error fetching lineage:', error_1);
                        toast.error('Failed to load question lineage');
                        return [3 /*break*/, 5];
                    case 4:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        if (questionId) {
            fetchLineage();
        }
    }, [questionId]);
    // First, flatten the tree structure into raw nodes and edges.
    var _d = useMemo(function () {
        if (!rootQuestion)
            return [[], []];
        // Process from root question instead of lineage
        return processLineageToFlow([rootQuestion]);
    }, [rootQuestion, processLineageToFlow]), rawNodes = _d[0], rawEdges = _d[1];
    // Then, use dagre to compute proper positions.
    var _e = useMemo(function () {
        return getLayoutedElements(rawNodes, rawEdges, 'TB');
    }, [rawNodes, rawEdges]), nodes = _e.nodes, edges = _e.edges;
    if (loading) {
        return React.createElement(Container, null, "Loading...");
    }
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(BackButton, { onClick: function () { return navigate("/app/analysis/result/".concat(questionId)); } }, "\u2190 Back to Analysis")),
        React.createElement(FlowContainer, null,
            React.createElement(ReactFlow, { nodes: nodes, edges: edges, nodeTypes: nodeTypes, fitView: true, fitViewOptions: {
                    padding: 0.8, // Increased padding
                    includeHiddenNodes: true,
                    minZoom: 0.4, // Adjusted min zoom
                    maxZoom: 1.2, // Adjusted max zoom
                }, minZoom: 0.2, maxZoom: 1.5, defaultViewport: { x: 0, y: 0, zoom: 0.5 }, nodesDraggable: false, nodesConnectable: false, elementsSelectable: false, zoomOnScroll: true, panOnScroll: true },
                React.createElement(Background, { gap: 12, size: 1, color: "#f0f0f0" }),
                React.createElement(Controls, { showInteractive: false, position: "bottom-right" })))));
};
export default QuestionLineage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
