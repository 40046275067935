var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import LogoImage from '../../assets/Logo.png';
import { HomeIcon, DashboardIcon, AnalysisIcon, HistoryIcon, SettingsIcon, ExplorerIcon, GlossaryIcon } from '../../assets/Icons';
var SidebarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  width: ", ";\n  height: 100vh;\n  left: 0;\n  top: 0;\n  background: #FFFFFF;\n  border-right: 1px solid #ECEAFF;\n  border-radius: 20px 0 0 0;\n  transition: width 0.3s ease;\n  overflow: hidden;\n  z-index: 1000;\n  white-space: nowrap;\n  display: flex;\n  flex-direction: column;\n"], ["\n  position: fixed;\n  width: ", ";\n  height: 100vh;\n  left: 0;\n  top: 0;\n  background: #FFFFFF;\n  border-right: 1px solid #ECEAFF;\n  border-radius: 20px 0 0 0;\n  transition: width 0.3s ease;\n  overflow: hidden;\n  z-index: 1000;\n  white-space: nowrap;\n  display: flex;\n  flex-direction: column;\n"])), function (props) { return props.isOpen ? '284px' : '0px'; });
var Logo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 165.76px;\n  height: 46px;\n  margin: 16px 0 0 59px;\n  opacity: ", ";\n  transition: opacity 0.3s ease;\n  \n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"], ["\n  position: relative;\n  width: 165.76px;\n  height: 46px;\n  margin: 16px 0 0 59px;\n  opacity: ", ";\n  transition: opacity 0.3s ease;\n  \n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"])), function (props) { return props.isOpen ? 1 : 0; });
var NavItemText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 15px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 15px;\n  color: #000000;\n"])));
var NavMenu = styled.nav(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  width: 218px;\n  margin: 24px 0 0 33px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  ", " {\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n  }\n"], ["\n  position: relative;\n  width: 218px;\n  margin: 24px 0 0 33px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  ", " {\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n  }\n"])), NavItemText, function (props) { return props.isOpen ? 1 : 0; });
var NavItem = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 48px;\n  padding: 8px 12px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 16px;\n  cursor: pointer;\n  text-decoration: none;\n"], ["\n  width: 100%;\n  height: 48px;\n  padding: 8px 12px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 16px;\n  cursor: pointer;\n  text-decoration: none;\n"])), function (props) { return props.isActive ? '#F8F5FF' : 'transparent'; }, function (props) { return props.isActive ? '0px 1px 0px #ECEAFF' : 'none'; });
var IconWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n  background: ", ";\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 32px;\n  height: 32px;\n  background: ", ";\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), function (props) { return props.bgColor || '#F8F5FF'; });
var ToggleButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: fixed;\n  left: ", ";\n  top: 50%;\n  transform: translateY(-50%);\n  width: 44px;\n  height: 44px;\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 50%;\n  cursor: pointer;\n  z-index: 1001;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0 2px 8px rgba(143, 74, 207, 0.08);\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    background: #F8F5FF;\n    transform: translateY(-50%) scale(1.05);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.12);\n  }\n\n  &:active {\n    transform: translateY(-50%) scale(0.95);\n  }\n\n  &::before {\n    content: '", "';\n    font-size: 18px;\n    color: #8F4ACF;\n    font-family: 'Inter';\n    font-weight: 500;\n    transition: transform 0.2s ease;\n  }\n\n  &:hover::before {\n    transform: scale(1.1);\n  }\n"], ["\n  position: fixed;\n  left: ", ";\n  top: 50%;\n  transform: translateY(-50%);\n  width: 44px;\n  height: 44px;\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 50%;\n  cursor: pointer;\n  z-index: 1001;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0 2px 8px rgba(143, 74, 207, 0.08);\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    background: #F8F5FF;\n    transform: translateY(-50%) scale(1.05);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.12);\n  }\n\n  &:active {\n    transform: translateY(-50%) scale(0.95);\n  }\n\n  &::before {\n    content: '", "';\n    font-size: 18px;\n    color: #8F4ACF;\n    font-family: 'Inter';\n    font-weight: 500;\n    transition: transform 0.2s ease;\n  }\n\n  &:hover::before {\n    transform: scale(1.1);\n  }\n"])), function (props) { return props.isOpen ? '272px' : '0px'; }, function (props) { return (props.isOpen ? '←' : '→'); });
var Sidebar = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen;
    var location = useLocation();
    var toggleSidebar = function () {
        setIsOpen(!isOpen);
    };
    return (React.createElement(SidebarContainer, { isOpen: isOpen },
        React.createElement(ToggleButton, { onClick: toggleSidebar, isOpen: isOpen }, isOpen ? '' : ''),
        React.createElement(Logo, { isOpen: isOpen },
            React.createElement("img", { src: LogoImage, alt: "Cognitio Intelligence Logo" })),
        React.createElement(NavMenu, { isOpen: isOpen },
            React.createElement(NavItem, { to: "/app", isActive: location.pathname === '/app' },
                React.createElement(IconWrapper, { bgColor: "#FFFFFF" },
                    React.createElement(HomeIcon, { color: "#8F4ACF" })),
                React.createElement(NavItemText, null, "Home")),
            React.createElement(NavItem, { to: "/app/dashboard", isActive: location.pathname === '/app/dashboard' },
                React.createElement(IconWrapper, null,
                    React.createElement(DashboardIcon, null)),
                React.createElement(NavItemText, null, "Dashboard")),
            React.createElement(NavItem, { to: "/app/analysis", isActive: location.pathname === '/app/analysis' },
                React.createElement(IconWrapper, null,
                    React.createElement(AnalysisIcon, null)),
                React.createElement(NavItemText, null, "Analysis")),
            React.createElement(NavItem, { to: "/app/data-explorer", isActive: location.pathname === '/app/data-explorer' },
                React.createElement(IconWrapper, null,
                    React.createElement(ExplorerIcon, null)),
                React.createElement(NavItemText, null, "Data Explorer")),
            React.createElement(NavItem, { to: "/app/data-glossary", isActive: location.pathname === '/app/data-glossary' },
                React.createElement(IconWrapper, null,
                    React.createElement(GlossaryIcon, null)),
                React.createElement(NavItemText, null, "Data Glossary")),
            React.createElement(NavItem, { to: "/app/history", isActive: location.pathname === '/app/history' },
                React.createElement(IconWrapper, null,
                    React.createElement(HistoryIcon, null)),
                React.createElement(NavItemText, null, "Question History")),
            React.createElement(NavItem, { to: "/app/settings", isActive: location.pathname === '/app/settings' },
                React.createElement(IconWrapper, null,
                    React.createElement(SettingsIcon, null)),
                React.createElement(NavItemText, null, "Settings")))));
};
export default Sidebar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
