var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import MFASetup from '../Auth/MFASetup';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var SecuritySection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var MFAStatus = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 24px;\n  background: #F8F5FF;\n  border-radius: 12px;\n  margin-bottom: 24px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 24px;\n  background: #F8F5FF;\n  border-radius: 12px;\n  margin-bottom: 24px;\n"])));
var StatusText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  h3 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 18px;\n    color: #000000;\n    margin-bottom: 8px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    max-width: 600px;\n    line-height: 1.5;\n  }\n"], ["\n  h3 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 18px;\n    color: #000000;\n    margin-bottom: 8px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    max-width: 600px;\n    line-height: 1.5;\n  }\n"])));
var MFAButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 12px 24px;\n  background: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 8px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  \n  &:hover {\n    opacity: 0.9;\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n"], ["\n  padding: 12px 24px;\n  background: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 8px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  \n  &:hover {\n    opacity: 0.9;\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n"])), function (props) {
    if (props.variant === 'disable')
        return '#FF4D4F';
    return props.enabled ? '#f5f5f5' : 'linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%)';
}, function (props) { return props.variant === 'disable' ? 'white' : props.enabled ? '#666' : 'white'; });
var ButtonGroup = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n"], ["\n  display: flex;\n  gap: 12px;\n"])));
var ConfirmationModal = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  .content {\n    background: white;\n    padding: 24px;\n    border-radius: 16px;\n    max-width: 400px;\n    width: 100%;\n  }\n\n  h3 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 18px;\n    color: #000000;\n    margin-bottom: 16px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    margin-bottom: 24px;\n    line-height: 1.5;\n  }\n\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n  }\n"], ["\n  .content {\n    background: white;\n    padding: 24px;\n    border-radius: 16px;\n    max-width: 400px;\n    width: 100%;\n  }\n\n  h3 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 18px;\n    color: #000000;\n    margin-bottom: 16px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    margin-bottom: 24px;\n    line-height: 1.5;\n  }\n\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n  }\n"])));
var Modal = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 100000;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 100000;\n"])));
var SecurityInfo = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 32px;\n  padding: 24px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n"], ["\n  margin-top: 32px;\n  padding: 24px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n"])));
var InfoTitle = styled.h4(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n  margin-bottom: 16px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n  margin-bottom: 16px;\n"])));
var InfoList = styled.ul(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n\n  li {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    margin-bottom: 12px;\n    display: flex;\n    align-items: flex-start;\n    gap: 8px;\n\n    &:before {\n      content: '\u2022';\n      color: #8F4ACF;\n      font-weight: bold;\n    }\n  }\n"], ["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n\n  li {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    margin-bottom: 12px;\n    display: flex;\n    align-items: flex-start;\n    gap: 8px;\n\n    &:before {\n      content: '\u2022';\n      color: #8F4ACF;\n      font-weight: bold;\n    }\n  }\n"])));
var TwoFactorAuth = function () {
    var user = useAuth().user;
    var _a = useState(false), mfaEnabled = _a[0], setMfaEnabled = _a[1];
    var _b = useState(false), showMFASetup = _b[0], setShowMFASetup = _b[1];
    var _c = useState(false), showConfirmDisable = _c[0], setShowConfirmDisable = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), disabling = _e[0], setDisabling = _e[1];
    useEffect(function () {
        checkMFAStatus();
    }, []);
    var checkMFAStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/auth/mfa/status/')];
                case 1:
                    response = _a.sent();
                    setMfaEnabled(response.data.mfaEnabled);
                    setLoading(false);
                    toast.success('MFA status checked successfully', { id: 'check-mfa-status' });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Failed to check MFA status:', error_1);
                    setLoading(false);
                    toast.error('Failed to check MFA status', { id: 'check-mfa-status' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleMFASetupComplete = function () {
        setShowMFASetup(false);
        setMfaEnabled(true);
        toast.success('Two-factor authentication enabled successfully', { id: 'mfa-setup' });
    };
    var handleDisable2FA = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2, errorMessage;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setDisabling(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.post('/api/auth/mfa/disable/')];
                case 2:
                    response = _c.sent();
                    if (response.data.success) {
                        setMfaEnabled(false);
                        setShowConfirmDisable(false);
                        toast.success('Two-factor authentication disabled successfully', { id: 'disable-2fa' });
                    }
                    else {
                        throw new Error('Failed to disable 2FA');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _c.sent();
                    console.error('Failed to disable 2FA:', error_2);
                    if (axios.isAxiosError(error_2)) {
                        errorMessage = ((_b = (_a = error_2.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Failed to disable 2FA. Please try again.';
                        toast.error(errorMessage, { id: 'disable-2fa' });
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setDisabling(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (loading) {
        return (React.createElement(Container, null,
            React.createElement(Title, null, "Two-Factor Authentication"),
            React.createElement(SecuritySection, null, "Loading...")));
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Two-Factor Authentication"),
        React.createElement(SecuritySection, null,
            React.createElement(MFAStatus, null,
                React.createElement(StatusText, null,
                    React.createElement("h3", null, "Two-Factor Authentication (2FA)"),
                    React.createElement("p", null, mfaEnabled
                        ? 'Your account is protected with two-factor authentication. Each time you sign in, you\'ll need to enter a code from your authenticator app.'
                        : 'Add an extra layer of security to your account by enabling two-factor authentication. Once enabled, you\'ll need to enter a code from your authenticator app when signing in.')),
                mfaEnabled ? (React.createElement(ButtonGroup, null,
                    React.createElement(MFAButton, { enabled: true }, "Enabled"),
                    React.createElement(MFAButton, { variant: "disable", onClick: function () { return setShowConfirmDisable(true); } }, "Disable 2FA"))) : (React.createElement(MFAButton, { onClick: function () { return setShowMFASetup(true); } }, "Enable 2FA"))),
            React.createElement(SecurityInfo, null,
                React.createElement(InfoTitle, null, "Why use Two-Factor Authentication?"),
                React.createElement(InfoList, null,
                    React.createElement("li", null, "Adds an extra layer of security to your account"),
                    React.createElement("li", null, "Protects against unauthorized access even if your password is compromised"),
                    React.createElement("li", null, "Meets security compliance requirements for sensitive data access"),
                    React.createElement("li", null, "Provides peace of mind when accessing your account from new devices")))),
        showMFASetup && (React.createElement(Modal, { onClick: function () { return setShowMFASetup(false); } },
            React.createElement("div", { onClick: function (e) { return e.stopPropagation(); } },
                React.createElement(MFASetup, { onSetupComplete: handleMFASetupComplete })))),
        showConfirmDisable && (React.createElement(ConfirmationModal, { onClick: function () { return !disabling && setShowConfirmDisable(false); } },
            React.createElement("div", { className: "content", onClick: function (e) { return e.stopPropagation(); } },
                React.createElement("h3", null, "Disable Two-Factor Authentication?"),
                React.createElement("p", null, "This will remove the additional security layer from your account. You will no longer need to enter a verification code when signing in."),
                React.createElement("div", { className: "actions" },
                    React.createElement(MFAButton, { onClick: function () { return !disabling && setShowConfirmDisable(false); }, enabled: true }, "Cancel"),
                    React.createElement(MFAButton, { variant: "disable", onClick: handleDisable2FA, disabled: disabling }, disabling ? 'Disabling...' : 'Disable 2FA')))))));
};
export default TwoFactorAuth;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
