var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"], ["\n  padding: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var Section = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 32px;\n"], ["\n  margin-bottom: 32px;\n"])));
var SectionTitle = styled.h2(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 24px;\n  margin-bottom: 16px;\n"], ["\n  font-size: 24px;\n  margin-bottom: 16px;\n"])));
var SecurityPolicy = function () {
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Security Policy"),
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Reporting Security Issues"),
            React.createElement("p", null, "We take the security of our systems seriously. If you believe you've found a security vulnerability in any of our services, please report it to us as described below.")),
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "How to Report"),
            React.createElement("p", null, "Please send reports to security@cognitioi.com. For secure communication, use our PGP key available at /.well-known/pgp-key.txt.")),
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "What to Include"),
            React.createElement("ul", null,
                React.createElement("li", null, "Description of the vulnerability"),
                React.createElement("li", null, "Steps to reproduce"),
                React.createElement("li", null, "Potential impact"),
                React.createElement("li", null, "Any suggested remediation"))),
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Our Commitment"),
            React.createElement("p", null, "We commit to acknowledging your report within 24 hours and will strive to keep you informed about our progress in addressing the issue.")),
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Safe Harbor"),
            React.createElement("p", null, "We consider security research conducted under this policy to constitute \"authorized\" conduct and will not initiate legal action against researchers for security research conducted in accordance with this policy."))));
};
export default SecurityPolicy;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
