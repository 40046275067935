var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 90px;\n  width: 100%;\n  background: #FFFFFF;\n"], ["\n  padding-top: 90px;\n  width: 100%;\n  background: #FFFFFF;\n"])));
var Section = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 80px 20px;\n  max-width: 1200px;\n  margin: 0 auto;\n"], ["\n  padding: 80px 20px;\n  max-width: 1200px;\n  margin: 0 auto;\n"])));
var HeroSection = styled(Section)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  background: linear-gradient(135deg, #F8F5FF 0%, #ECEAFF 100%);\n  border-radius: 0 0 30px 30px;\n  margin-bottom: 40px;\n"], ["\n  text-align: center;\n  background: linear-gradient(135deg, #F8F5FF 0%, #ECEAFF 100%);\n  border-radius: 0 0 30px 30px;\n  margin-bottom: 40px;\n"])));
var Title = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-weight: 800;\n  font-size: 48px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-weight: 800;\n  font-size: 48px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var Subtitle = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-size: 20px;\n  line-height: 1.6;\n  color: #666666;\n  max-width: 800px;\n  margin: 0 auto 40px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-size: 20px;\n  line-height: 1.6;\n  color: #666666;\n  max-width: 800px;\n  margin: 0 auto 40px;\n"])));
var Grid = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 30px;\n  margin-top: 40px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 30px;\n  margin-top: 40px;\n"])));
var Card = styled(motion.div)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: white;\n  padding: 30px;\n  border-radius: 20px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"], ["\n  background: white;\n  padding: 30px;\n  border-radius: 20px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"])));
var CardTitle = styled.h3(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 16px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 16px;\n"])));
var CardText = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n"])));
var AboutPage = function () {
    return (React.createElement(PageContainer, null,
        React.createElement(HeroSection, null,
            React.createElement(Title, null, "About Cognitio Intelligence"),
            React.createElement(Subtitle, null, "Transforming data into actionable insights through artificial intelligence")),
        React.createElement(Section, null,
            React.createElement(Grid, null,
                React.createElement(Card, { whileHover: { y: -5 }, initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.5 } },
                    React.createElement(CardTitle, null, "Our Mission"),
                    React.createElement(CardText, null, "To democratize data intelligence by making advanced AI technologies accessible and intuitive for organizations of all sizes.")),
                React.createElement(Card, { whileHover: { y: -5 }, initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.5, delay: 0.2 } },
                    React.createElement(CardTitle, null, "Our Vision"),
                    React.createElement(CardText, null, "To become the leading platform for AI-powered data analysis, enabling businesses to make better, data-driven decisions.")),
                React.createElement(Card, { whileHover: { y: -5 }, initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.5, delay: 0.4 } },
                    React.createElement(CardTitle, null, "Our Approach"),
                    React.createElement(CardText, null, "Combining cutting-edge AI with user-friendly interfaces to analyze complex datasets and uncover meaningful patterns."))))));
};
export default AboutPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
