export var getCsrfToken = function () {
    var name = 'csrftoken';
    var cookieValue = '';
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};
export var setCookie = function (name, value, days) {
    if (days === void 0) { days = 7; }
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "expires=".concat(date.toUTCString());
    document.cookie = "".concat(name, "=").concat(value, ";").concat(expires, ";path=/");
};
export var getCookie = function (name) {
    var nameEQ = "".concat(name, "=");
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return undefined;
};
export var deleteCookie = function (name) {
    document.cookie = "".concat(name, "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/");
};
