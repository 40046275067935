var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { getCookie } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var UsageSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var UsageStats = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 24px;\n  margin-bottom: 32px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 24px;\n  margin-bottom: 32px;\n"])));
var StatBox = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 24px;\n"], ["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 24px;\n"])));
var StatLabel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"])));
var StatValue = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"])));
var ProgressBarContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 16px 0;\n  overflow: hidden;\n"], ["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 16px 0;\n  overflow: hidden;\n"])));
var ProgressBar = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: ", "%;\n  height: 100%;\n  background: ", ";\n  border-radius: 4px;\n  transition: width 0.3s ease;\n"], ["\n  width: ", "%;\n  height: 100%;\n  background: ", ";\n  border-radius: 4px;\n  transition: width 0.3s ease;\n"])), function (props) { return props.width; }, function (props) { return props.width > 90 ? '#EB564F' : '#8F4ACF'; });
var WarningMessage = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: #FFF5F5;\n  border: 1px solid #FED7D7;\n  border-radius: 8px;\n  padding: 16px;\n  color: #E53E3E;\n  font-family: 'Inter';\n  font-size: 14px;\n  margin-top: 16px;\n  display: ", ";\n"], ["\n  background: #FFF5F5;\n  border: 1px solid #FED7D7;\n  border-radius: 8px;\n  padding: 16px;\n  color: #E53E3E;\n  font-family: 'Inter';\n  font-size: 14px;\n  margin-top: 16px;\n  display: ", ";\n"])), function (props) { return props.hidden ? 'none' : 'block'; });
var UsageHistory = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 32px;\n"], ["\n  margin-top: 32px;\n"])));
var HistoryTitle = styled.h2(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  color: #000000;\n  margin-bottom: 16px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  color: #000000;\n  margin-bottom: 16px;\n"])));
var HistoryTable = styled.table(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n  \n  th, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ECEAFF;\n    font-family: 'Inter';\n  }\n  \n  th {\n    font-weight: 600;\n    color: #6B6B6B;\n    font-size: 14px;\n  }\n  \n  td {\n    font-size: 14px;\n    color: #000000;\n  }\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n  \n  th, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ECEAFF;\n    font-family: 'Inter';\n  }\n  \n  th {\n    font-weight: 600;\n    color: #6B6B6B;\n    font-size: 14px;\n  }\n  \n  td {\n    font-size: 14px;\n    color: #000000;\n  }\n"])));
var UsageLimitations = function () {
    var user = useAuth().user;
    var _a = useState(null), creditData = _a[0], setCreditData = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    useEffect(function () {
        var fetchCreditUsage = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, err_1, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, axios.get('/api/credits/usage/', {
                                headers: {
                                    'X-CSRFToken': getCookie('csrftoken') || '',
                                }
                            })];
                    case 1:
                        response = _a.sent();
                        if (response.data.success) {
                            setCreditData(response.data.credit_data);
                            toast.success('Credit usage data loaded successfully', { id: 'fetch-credits' });
                        }
                        else {
                            throw new Error(response.data.error || 'Failed to fetch credit usage data');
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _a.sent();
                        errorMessage = err_1 instanceof Error ? err_1.message : 'An error occurred';
                        setError(errorMessage);
                        toast.error("Failed to load credit usage data: ".concat(errorMessage), { id: 'fetch-credits' });
                        return [3 /*break*/, 4];
                    case 3:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchCreditUsage();
    }, []);
    var renderHistoryTable = function () {
        if (!(creditData === null || creditData === void 0 ? void 0 : creditData.daily_counts.length)) {
            return React.createElement("p", null, "No credit usage history available.");
        }
        return (React.createElement(HistoryTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Date"),
                    React.createElement("th", null, "Activity Type"),
                    React.createElement("th", null, "Credits Used"))),
            React.createElement("tbody", null, creditData.daily_counts.flatMap(function (day, dayIndex) {
                var entries = Object.entries(day)
                    .filter(function (_a) {
                    var key = _a[0];
                    return key !== 'date' && key !== 'total';
                })
                    .map(function (_a, typeIndex) {
                    var type = _a[0], count = _a[1];
                    return (React.createElement("tr", { key: "".concat(dayIndex, "-").concat(typeIndex) },
                        React.createElement("td", null, new Date(day.date).toLocaleDateString()),
                        React.createElement("td", null, type.replace(/_/g, ' ').replace(/\b\w/g, function (l) { return l.toUpperCase(); })),
                        React.createElement("td", null, count)));
                });
                return entries.length ? entries : [
                    React.createElement("tr", { key: "".concat(dayIndex, "-empty") },
                        React.createElement("td", null, new Date(day.date).toLocaleDateString()),
                        React.createElement("td", null, "No activity"),
                        React.createElement("td", null, "0"))
                ];
            }))));
    };
    var renderTransactionsTable = function () {
        if (!(creditData === null || creditData === void 0 ? void 0 : creditData.recent_transactions.length)) {
            return React.createElement("p", null, "No recent credit transactions available.");
        }
        return (React.createElement(HistoryTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Date"),
                    React.createElement("th", null, "Transaction Type"),
                    React.createElement("th", null, "Credits"),
                    React.createElement("th", null, "Description"))),
            React.createElement("tbody", null, creditData.recent_transactions.map(function (transaction, index) { return (React.createElement("tr", { key: index },
                React.createElement("td", null, new Date(transaction.date).toLocaleDateString()),
                React.createElement("td", null, transaction.type.replace(/_/g, ' ').replace(/\b\w/g, function (l) { return l.toUpperCase(); })),
                React.createElement("td", null, transaction.credits),
                React.createElement("td", null, transaction.description || '-'))); }))));
    };
    if (isLoading) {
        return React.createElement("div", null, "Loading credit usage data...");
    }
    if (error) {
        return React.createElement("div", null,
            "Error loading credit usage data: ",
            error);
    }
    if (!creditData) {
        return React.createElement("div", null, "No credit data available.");
    }
    var userCreditPercentage = creditData.department_credits > 0
        ? (creditData.current_credits / creditData.department_credits) * 100
        : 0;
    var departmentCreditPercentage = creditData.company_credits > 0
        ? (creditData.department_credits / creditData.company_credits) * 100
        : 0;
    var usagePercentage = creditData.current_credits > 0
        ? (creditData.total_usage / creditData.current_credits) * 100
        : 0;
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Credit Usage & Limitations"),
        React.createElement(UsageSection, null,
            React.createElement(UsageStats, null,
                React.createElement(StatBox, null,
                    React.createElement(StatLabel, null, "Your Available Credits"),
                    React.createElement(StatValue, null,
                        creditData.current_credits.toLocaleString(),
                        " / ",
                        creditData.department_credits.toLocaleString()),
                    React.createElement(ProgressBarContainer, null,
                        React.createElement(ProgressBar, { width: userCreditPercentage }))),
                React.createElement(StatBox, null,
                    React.createElement(StatLabel, null, "Department Credits"),
                    React.createElement(StatValue, null,
                        creditData.department_credits.toLocaleString(),
                        " / ",
                        creditData.company_credits.toLocaleString()),
                    React.createElement(ProgressBarContainer, null,
                        React.createElement(ProgressBar, { width: departmentCreditPercentage }))),
                React.createElement(StatBox, null,
                    React.createElement(StatLabel, null, "Recent Credit Usage (30 days)"),
                    React.createElement(StatValue, null,
                        creditData.total_usage.toLocaleString(),
                        " credits"),
                    React.createElement(ProgressBarContainer, null,
                        React.createElement(ProgressBar, { width: usagePercentage > 100 ? 100 : usagePercentage })))),
            React.createElement(WarningMessage, { hidden: creditData.current_credits > 100 }, "You are running low on credits. Please contact your administrator to request more credits."),
            React.createElement(UsageHistory, null,
                React.createElement(HistoryTitle, null, "Recent Credit Usage History"),
                renderHistoryTable()),
            React.createElement(UsageHistory, null,
                React.createElement(HistoryTitle, null, "Recent Credit Transactions"),
                renderTransactionsTable()))));
};
export default UsageLimitations;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
