var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ExitIcon, ChevronLeftIcon } from '../../assets/Icons';
var HeaderContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 80px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 80px;\n"])));
var TopSection = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var TitleGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"])));
var NavText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 15px;\n  line-height: 140%;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-size: 15px;\n  line-height: 140%;\n  color: #000000;\n"])));
var SignOutButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  \n  svg {\n    color: #000000;\n  }\n\n  &:hover {\n    opacity: 0.8;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  \n  svg {\n    color: #000000;\n  }\n\n  &:hover {\n    opacity: 0.8;\n  }\n"])));
var BackButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #8F4ACF;\n  padding: 8px 12px;\n  border-radius: 8px;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n\n  svg {\n    color: #8F4ACF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #8F4ACF;\n  padding: 8px 12px;\n  border-radius: 8px;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n\n  svg {\n    color: #8F4ACF;\n  }\n"])));
var ButtonGroup = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"])));
var ClickableText = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  cursor: pointer;\n  &:hover {\n    color: #8F4ACF;\n  }\n"], ["\n  cursor: pointer;\n  &:hover {\n    color: #8F4ACF;\n  }\n"])));
var CreditDisplay = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 8px 15px;\n  background: #F8F5FF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  \n  span {\n    font-weight: 600;\n    margin-left: 5px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 8px 15px;\n  background: #F8F5FF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  \n  span {\n    font-weight: 600;\n    margin-left: 5px;\n  }\n"])));
var Header = function (_a) {
    var _b;
    var title = _a.title, setIsAuthenticated = _a.setIsAuthenticated, sidebarOpen = _a.sidebarOpen;
    var navigate = useNavigate();
    var _c = useState(null), credits = _c[0], setCredits = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    useEffect(function () {
        // Fetch user credits when component mounts
        var fetchUserCredits = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setLoading(true);
                        return [4 /*yield*/, axios.get('/api/analysis/user-credits/')];
                    case 1:
                        response = _a.sent();
                        if (response.status === 200) {
                            setCredits(response.data.current_balance);
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching user credits:', error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchUserCredits();
        // Set up interval to refresh credits every minute
        var intervalId = setInterval(fetchUserCredits, 60000);
        // Clean up interval on component unmount
        return function () { return clearInterval(intervalId); };
    }, []);
    var handleBack = function () {
        navigate(-1);
    };
    var handleSignOut = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, axios.get('/api/csrf/')];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, axios.post('/api/signout/', {}, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _a.sent();
                    if (response.status === 200) {
                        localStorage.removeItem('isAuthenticated');
                        sessionStorage.removeItem('isAuthenticated');
                        setIsAuthenticated(false);
                        navigate('/signin', { replace: true });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error signing out:', error_2);
                    localStorage.removeItem('isAuthenticated');
                    sessionStorage.removeItem('isAuthenticated');
                    setIsAuthenticated(false);
                    navigate('/signin', { replace: true });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(HeaderContainer, null,
        React.createElement(TopSection, null,
            React.createElement(TitleGroup, null,
                React.createElement(BackButton, { onClick: handleBack },
                    React.createElement(ChevronLeftIcon, { size: 16 })),
                React.createElement(NavText, null, ((_b = window.location.pathname.split('/app/')[1]) === null || _b === void 0 ? void 0 : _b.split('/').map(function (segment, index, segments) {
                    var formattedSegment = segment
                        .split('-')
                        .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
                        .join(' ');
                    var path = '/app/' + segments.slice(0, index + 1).join('/');
                    return (React.createElement(React.Fragment, { key: segment },
                        React.createElement(ClickableText, { onClick: function () { return navigate(path); } }, formattedSegment),
                        index < segments.length - 1 && ' > '));
                })) || 'Home')),
            React.createElement(ButtonGroup, null,
                !loading && credits !== null && (React.createElement(CreditDisplay, null,
                    "Credits: ",
                    React.createElement("span", null, credits))),
                React.createElement(SignOutButton, { onClick: handleSignOut },
                    React.createElement(ExitIcon, null),
                    "Sign Out")))));
};
export default Header;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
