var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LogoImage from '../../assets/Logo.png';
console.log('Logo Image Path:', LogoImage);
var Nav = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  height: 90px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 60px;\n  background: #FFFFFF;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  box-sizing: border-box;\n"], ["\n  position: fixed;\n  width: 100%;\n  height: 90px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 60px;\n  background: #FFFFFF;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  box-sizing: border-box;\n"])));
var Logo = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  min-width: 165.76px;\n  height: 46px;\n\n  img {\n    height: 100%;\n    width: auto;\n    object-fit: contain;\n    display: block;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  min-width: 165.76px;\n  height: 46px;\n\n  img {\n    height: 100%;\n    width: auto;\n    object-fit: contain;\n    display: block;\n  }\n"])));
var MenuItems = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: 50px;\n  font-family: 'Inter';\n  font-size: 15px;\n"], ["\n  display: flex;\n  gap: 50px;\n  font-family: 'Inter';\n  font-size: 15px;\n"])));
var AuthButtons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n"], ["\n  display: flex;\n  gap: 8px;\n"])));
var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  min-width: 89px;\n  height: 40px;\n  border-radius: 10px;\n  font-family: 'Inter';\n  font-size: 15px;\n  border: none;\n  cursor: pointer;\n  padding: 0 15px;\n  \n  ", "\n"], ["\n  min-width: 89px;\n  height: 40px;\n  border-radius: 10px;\n  font-family: 'Inter';\n  font-size: 15px;\n  border: none;\n  cursor: pointer;\n  padding: 0 15px;\n  \n  ", "\n"])), function (props) { return props.variant === 'contained' ? "\n    background: #000000;\n    color: #FFFFFF;\n  " : "\n    background: #FAFAFA;\n    color: #000000;\n  "; });
var Navbar = function () {
    return (React.createElement(Nav, null,
        React.createElement(Logo, { to: "/" },
            React.createElement("img", { src: LogoImage, alt: "Cognitio Intelligence Logo" })),
        React.createElement(MenuItems, null,
            React.createElement(Link, { to: "/" }, "Home"),
            React.createElement(Link, { to: "/about" }, "About"),
            React.createElement(Link, { to: "/support" }, "Support"),
            React.createElement(Link, { to: "/contact" }, "Contact Us")),
        React.createElement(AuthButtons, null,
            React.createElement(Link, { to: "/signin" },
                React.createElement(Button, { variant: "contained" }, "Sign In")))));
};
export default Navbar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
