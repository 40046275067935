var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TableIcon, DatabaseIcon, ColumnIcon, ChevronRightIcon } from '../../assets/Icons';
import { toast } from 'react-toastify';
import { useDatasets } from '../../hooks/useDatasets';
import { getCookie } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  max-width: 1400px;\n  margin: 0 auto;\n"], ["\n  padding: 20px;\n  max-width: 1400px;\n  margin: 0 auto;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 40px;\n  padding-bottom: 20px;\n  border-bottom: 1px solid #ECEAFF;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 40px;\n  padding-bottom: 20px;\n  border-bottom: 1px solid #ECEAFF;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 110%;\n  color: #000000;\n  margin: 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 110%;\n  color: #000000;\n  margin: 0;\n"])));
var DatasetGrid = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 24px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 24px;\n"])));
var DatasetCard = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 20px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  display: flex;\n  align-items: center;\n  gap: 24px;\n  position: relative;\n  overflow: hidden;\n\n  &:hover {\n    transform: translateX(8px);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.12);\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 4px;\n    background: #8F4ACF;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 20px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  display: flex;\n  align-items: center;\n  gap: 24px;\n  position: relative;\n  overflow: hidden;\n\n  &:hover {\n    transform: translateX(8px);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.12);\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 4px;\n    background: #8F4ACF;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n  }\n"])), function (props) { return props.selected ? '1' : '0'; });
var DatasetIcon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    color: #8F4ACF;\n    width: 24px;\n    height: 24px;\n  }\n"], ["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    color: #8F4ACF;\n    width: 24px;\n    height: 24px;\n  }\n"])));
var DatasetInfo = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var DatasetMeta = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-top: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-top: 8px;\n"])));
var Description = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #666;\n  font-size: 14px;\n  margin: 0;\n  font-style: italic;\n"], ["\n  color: #666;\n  font-size: 14px;\n  margin: 0;\n  font-style: italic;\n"])));
var DatasetName = styled.h3(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #2D3748;\n  margin: 0 0 12px 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #2D3748;\n  margin: 0 0 12px 0;\n"])));
var DatasetStats = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n  margin-top: 8px;\n"], ["\n  display: flex;\n  gap: 12px;\n  margin-top: 8px;\n"])));
var StatItem = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  font-size: 13px;\n  color: #666;\n  \n  svg {\n    color: #8F4ACF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  font-size: 13px;\n  color: #666;\n  \n  svg {\n    color: #8F4ACF;\n  }\n"])));
var TableList = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-left: 64px;\n  margin-top: 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  margin-left: 64px;\n  margin-top: 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var TableItem = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 12px 16px;\n  background: #F8F5FF;\n  border-radius: 8px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  gap: 12px;\n\n  &:hover {\n    background: #F0E7FF;\n    transform: translateX(4px);\n  }\n\n  svg {\n    color: #8F4ACF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 12px 16px;\n  background: #F8F5FF;\n  border-radius: 8px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  gap: 12px;\n\n  &:hover {\n    background: #F0E7FF;\n    transform: translateX(4px);\n  }\n\n  svg {\n    color: #8F4ACF;\n  }\n"])));
var TableDetails = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  margin-top: 24px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  margin-top: 24px;\n"])));
var TableTitle = styled.h2(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  color: #2D3748;\n  margin: 0 0 16px 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  color: #2D3748;\n  margin: 0 0 16px 0;\n"])));
var ColumnGrid = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));\n  gap: 20px;\n  margin-top: 24px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));\n  gap: 20px;\n  margin-top: 24px;\n"])));
var ColumnCard = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 16px;\n  margin: 8px 0;\n  margin-left: 40px;\n  transition: all 0.2s ease;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n\n  &:hover {\n    transform: translateX(4px);\n    box-shadow: 0 4px 8px rgba(143, 74, 207, 0.06);\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 16px;\n  margin: 8px 0;\n  margin-left: 40px;\n  transition: all 0.2s ease;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n\n  &:hover {\n    transform: translateX(4px);\n    box-shadow: 0 4px 8px rgba(143, 74, 207, 0.06);\n  }\n"])));
var ColumnHeader = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 12px;\n\n  svg {\n    color: #8F4ACF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 12px;\n\n  svg {\n    color: #8F4ACF;\n  }\n"])));
var ColumnName = styled.h4(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  color: #2D3748;\n  margin: 0 0 8px 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  color: #2D3748;\n  margin: 0 0 8px 0;\n"])));
var ColumnType = styled.span(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  background: #F0E7FF;\n  padding: 4px 8px;\n  border-radius: 4px;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  background: #F0E7FF;\n  padding: 4px 8px;\n  border-radius: 4px;\n"])));
var ColumnStats = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  margin-top: 12px;\n  padding-top: 12px;\n  border-top: 1px solid #ECEAFF;\n  font-size: 13px;\n  color: #666;\n"], ["\n  margin-top: 12px;\n  padding-top: 12px;\n  border-top: 1px solid #ECEAFF;\n  font-size: 13px;\n  color: #666;\n"])));
var AccordionItem = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  margin-bottom: 16px;\n  background: #FFFFFF;\n  transition: all 0.3s ease;\n  \n  &:hover {\n    box-shadow: 0 4px 20px rgba(143, 74, 207, 0.08);\n  }\n"], ["\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  margin-bottom: 16px;\n  background: #FFFFFF;\n  transition: all 0.3s ease;\n  \n  &:hover {\n    box-shadow: 0 4px 20px rgba(143, 74, 207, 0.08);\n  }\n"])));
var AccordionHeader = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  background: #FFFFFF;\n  padding: 20px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  border-radius: ", ";\n  position: relative;\n  overflow: hidden;\n\n  &::before {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 4px;\n    background: #8F4ACF;\n    opacity: ", ";\n    transition: opacity 0.3s ease;\n  }\n\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: #FFFFFF;\n  padding: 20px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  border-radius: ", ";\n  position: relative;\n  overflow: hidden;\n\n  &::before {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 4px;\n    background: #8F4ACF;\n    opacity: ", ";\n    transition: opacity 0.3s ease;\n  }\n\n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.isOpen ? '16px 16px 0 0' : '16px'; }, function (props) { return props.isOpen ? '1' : '0'; }, function (props) { return props.isOpen ? '#FFFFFF' : '#F8F5FF'; });
var AccordionContent = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  background: #FFFFFF;\n  max-height: ", ";\n  opacity: ", ";\n  overflow: hidden;\n  transition: all 0.3s ease-in-out;\n  border-top: ", ";\n  padding: ", " 20px;\n"], ["\n  background: #FFFFFF;\n  max-height: ", ";\n  opacity: ", ";\n  overflow: hidden;\n  transition: all 0.3s ease-in-out;\n  border-top: ", ";\n  padding: ", " 20px;\n"])), function (props) { return props.isOpen ? '2000px' : '0'; }, function (props) { return props.isOpen ? '1' : '0'; }, function (props) { return props.isOpen ? '1px solid #ECEAFF' : 'none'; }, function (props) { return props.isOpen ? '20px' : '0'; });
var AccordionIcon = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transform: rotate(", ");\n  transition: transform 0.3s ease;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transform: rotate(", ");\n  transition: transform 0.3s ease;\n"])), function (props) { return props.isOpen ? '90deg' : '0deg'; });
var TableAccordion = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin: 8px 0;\n  margin-left: 40px;\n  transition: all 0.3s ease;\n\n  &:hover {\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.06);\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin: 8px 0;\n  margin-left: 40px;\n  transition: all 0.3s ease;\n\n  &:hover {\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.06);\n  }\n"])));
var ColumnAccordion = styled(AccordionItem)(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  margin-left: 48px;\n  background: #F0E7FF;\n"], ["\n  margin-left: 48px;\n  background: #F0E7FF;\n"])));
var ColumnInfo = styled.div(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  flex: 1;\n  margin-right: 12px;\n"], ["\n  flex: 1;\n  margin-right: 12px;\n"])));
var GlossaryBar = styled.div(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  padding: 16px;\n  margin-bottom: 24px;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  padding: 16px;\n  margin-bottom: 24px;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n"])));
var GlossaryInput = styled.input(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  flex: 1;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #2D3748;\n  outline: none;\n\n  &:focus {\n    border-color: #8F4ACF;\n    box-shadow: 0 0 0 2px rgba(143, 74, 207, 0.1);\n  }\n"], ["\n  flex: 1;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #2D3748;\n  outline: none;\n\n  &:focus {\n    border-color: #8F4ACF;\n    box-shadow: 0 0 0 2px rgba(143, 74, 207, 0.1);\n  }\n"])));
var GlossaryButton = styled.button(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  padding: 4px;\n  cursor: pointer;\n  color: #8F4ACF;\n  display: flex;\n  align-items: center;\n  opacity: 0.6;\n  transition: opacity 0.2s;\n\n  &:hover {\n    opacity: 1;\n  }\n"], ["\n  background: none;\n  border: none;\n  padding: 4px;\n  cursor: pointer;\n  color: #8F4ACF;\n  display: flex;\n  align-items: center;\n  opacity: 0.6;\n  transition: opacity 0.2s;\n\n  &:hover {\n    opacity: 1;\n  }\n"])));
var GlossaryOverlay = styled.div(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  backdrop-filter: blur(4px);\n  z-index: 9998;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  backdrop-filter: blur(4px);\n  z-index: 9998;\n"])));
var GlossaryPopup = styled.div(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: white;\n  padding: 32px 40px;\n  border-radius: 20px;\n  box-shadow: 0 8px 32px rgba(143, 74, 207, 0.15);\n  width: 560px;\n  max-width: 90vw;\n  z-index: 9999;\n\n  h3 {\n    color: #8F4ACF;\n    margin: 0 0 24px 0;\n    font-size: 24px;\n    font-weight: 600;\n    text-align: center;\n  }\n\n  p {\n    color: #4A5568;\n    margin: 12px 0;\n    font-size: 15px;\n    line-height: 1.5;\n  }\n"], ["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: white;\n  padding: 32px 40px;\n  border-radius: 20px;\n  box-shadow: 0 8px 32px rgba(143, 74, 207, 0.15);\n  width: 560px;\n  max-width: 90vw;\n  z-index: 9999;\n\n  h3 {\n    color: #8F4ACF;\n    margin: 0 0 24px 0;\n    font-size: 24px;\n    font-weight: 600;\n    text-align: center;\n  }\n\n  p {\n    color: #4A5568;\n    margin: 12px 0;\n    font-size: 15px;\n    line-height: 1.5;\n  }\n"])));
var GlossaryTextArea = styled.textarea(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 140px;\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin: 24px 0;\n  font-family: inherit;\n  font-size: 15px;\n  resize: vertical;\n  outline: none;\n  transition: border-color 0.2s ease;\n\n  &:focus {\n    border-color: #8F4ACF;\n    box-shadow: 0 0 0 2px rgba(143, 74, 207, 0.1);\n  }\n\n  &::placeholder {\n    color: #A0AEC0;\n  }\n"], ["\n  width: 100%;\n  min-height: 140px;\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin: 24px 0;\n  font-family: inherit;\n  font-size: 15px;\n  resize: vertical;\n  outline: none;\n  transition: border-color 0.2s ease;\n\n  &:focus {\n    border-color: #8F4ACF;\n    box-shadow: 0 0 0 2px rgba(143, 74, 207, 0.1);\n  }\n\n  &::placeholder {\n    color: #A0AEC0;\n  }\n"])));
var ButtonGroup = styled.div(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n  margin-top: 32px;\n"], ["\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n  margin-top: 32px;\n"])));
var Button = styled.button(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  padding: 12px 32px;\n  border-radius: 12px;\n  font-size: 15px;\n  font-weight: 500;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  min-width: 120px;\n\n  ", "\n"], ["\n  padding: 12px 32px;\n  border-radius: 12px;\n  font-size: 15px;\n  font-weight: 500;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  min-width: 120px;\n\n  ", "\n"])), function (props) { return props.primary ? "\n    background: #8F4ACF;\n    color: white;\n    border: none;\n\n    &:hover {\n      background: #7B3AAF;\n      transform: translateY(-1px);\n    }\n\n    &:active {\n      transform: translateY(0);\n    }\n  " : "\n    background: white;\n    color: #4A5568;\n    border: 1px solid #ECEAFF;\n\n    &:hover {\n      background: #F7F7FF;\n      transform: translateY(-1px);\n    }\n\n    &:active {\n      transform: translateY(0);\n    }\n  "; });
var InfoLabel = styled.span(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n  font-weight: 600;\n  color: #2D3748;\n  margin-right: 8px;\n"], ["\n  font-weight: 600;\n  color: #2D3748;\n  margin-right: 8px;\n"])));
var DistinctValuesButton = styled.button(templateObject_39 || (templateObject_39 = __makeTemplateObject(["\n  padding: 8px 16px;\n  background: #F7F7FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  color: #8F4ACF;\n  font-size: 14px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin: 16px 0;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #ECEAFF;\n  }\n"], ["\n  padding: 8px 16px;\n  background: #F7F7FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  color: #8F4ACF;\n  font-size: 14px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin: 16px 0;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #ECEAFF;\n  }\n"])));
var DistinctValuesList = styled.div(templateObject_40 || (templateObject_40 = __makeTemplateObject(["\n  margin-top: 16px;\n  background: #F7F7FF;\n  border-radius: 8px;\n  max-height: 200px;\n  overflow-y: auto;\n  \n  &::-webkit-scrollbar {\n    width: 8px;\n  }\n  \n  &::-webkit-scrollbar-track {\n    background: #F7F7FF;\n    border-radius: 8px;\n  }\n  \n  &::-webkit-scrollbar-thumb {\n    background: #ECEAFF;\n    border-radius: 8px;\n    \n    &:hover {\n      background: #8F4ACF;\n    }\n  }\n"], ["\n  margin-top: 16px;\n  background: #F7F7FF;\n  border-radius: 8px;\n  max-height: 200px;\n  overflow-y: auto;\n  \n  &::-webkit-scrollbar {\n    width: 8px;\n  }\n  \n  &::-webkit-scrollbar-track {\n    background: #F7F7FF;\n    border-radius: 8px;\n  }\n  \n  &::-webkit-scrollbar-thumb {\n    background: #ECEAFF;\n    border-radius: 8px;\n    \n    &:hover {\n      background: #8F4ACF;\n    }\n  }\n"])));
var DistinctValueItem = styled.div(templateObject_41 || (templateObject_41 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-size: 14px;\n  \n  span {\n    color: #4A5568;\n    font-family: 'Roboto Mono', monospace;\n  }\n  \n  &:last-child {\n    border-bottom: none;\n  }\n  \n  &:hover {\n    background: #ECEAFF;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-size: 14px;\n  \n  span {\n    color: #4A5568;\n    font-family: 'Roboto Mono', monospace;\n  }\n  \n  &:last-child {\n    border-bottom: none;\n  }\n  \n  &:hover {\n    background: #ECEAFF;\n  }\n"])));
var DeleteButton = styled.button(templateObject_42 || (templateObject_42 = __makeTemplateObject(["\n  padding: 4px 12px;\n  background: #FFF1F0;\n  border: 1px solid #FFD1D1;\n  border-radius: 4px;\n  color: #FF4D4F;\n  font-size: 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #FFD1D1;\n  }\n"], ["\n  padding: 4px 12px;\n  background: #FFF1F0;\n  border: 1px solid #FFD1D1;\n  border-radius: 4px;\n  color: #FF4D4F;\n  font-size: 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #FFD1D1;\n  }\n"])));
var DistinctValuesActions = styled.div(templateObject_43 || (templateObject_43 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin-bottom: 12px;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin-bottom: 12px;\n"])));
var ActionButton = styled.button(templateObject_44 || (templateObject_44 = __makeTemplateObject(["\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  border: none;\n  cursor: pointer;\n  background: ", ";\n  color: ", ";\n  \n  &:hover {\n    opacity: 0.8;\n  }\n"], ["\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  border: none;\n  cursor: pointer;\n  background: ", ";\n  color: ", ";\n  \n  &:hover {\n    opacity: 0.8;\n  }\n"])), function (props) {
    switch (props.variant) {
        case 'primary': return '#8F4ACF';
        case 'danger': return '#FF4D4D';
        default: return '#ECEAFF';
    }
}, function (props) { return props.variant === 'primary' ? 'white' : 'inherit'; });
var DatasetHeader = styled.div(templateObject_45 || (templateObject_45 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  cursor: pointer;\n  padding: 8px;\n  border-radius: 8px;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  cursor: pointer;\n  padding: 8px;\n  border-radius: 8px;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"])));
var DatasetContent = styled.div(templateObject_46 || (templateObject_46 = __makeTemplateObject(["\n  margin-top: 16px;\n  margin-left: 24px;\n"], ["\n  margin-top: 16px;\n  margin-left: 24px;\n"])));
var TableSection = styled.div(templateObject_47 || (templateObject_47 = __makeTemplateObject(["\n  margin-bottom: 16px;\n"], ["\n  margin-bottom: 16px;\n"])));
var TableHeader = styled.div(templateObject_48 || (templateObject_48 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  cursor: pointer;\n  padding: 8px;\n  border-radius: 8px;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  cursor: pointer;\n  padding: 8px;\n  border-radius: 8px;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"])));
var TableName = styled.span(templateObject_49 || (templateObject_49 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  color: #2D3748;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  color: #2D3748;\n"])));
var ColumnList = styled.div(templateObject_50 || (templateObject_50 = __makeTemplateObject(["\n  margin-top: 8px;\n  margin-left: 24px;\n"], ["\n  margin-top: 8px;\n  margin-left: 24px;\n"])));
var ColumnItem = styled.div(templateObject_51 || (templateObject_51 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 8px;\n  border-radius: 8px;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 8px;\n  border-radius: 8px;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"])));
var ColumnDescription = styled.div(templateObject_52 || (templateObject_52 = __makeTemplateObject(["\n  font-size: 13px;\n  color: #666;\n  margin-top: 4px;\n  padding-left: 32px;\n  font-style: italic;\n"], ["\n  font-size: 13px;\n  color: #666;\n  margin-top: 4px;\n  padding-left: 32px;\n  font-style: italic;\n"])));
var LoadingOverlay = styled.div(templateObject_53 || (templateObject_53 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 200px;\n  font-size: 1.2em;\n  color: #666;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 200px;\n  font-size: 1.2em;\n  color: #666;\n"])));
var ErrorMessage = styled.div(templateObject_54 || (templateObject_54 = __makeTemplateObject(["\n  color: #dc3545;\n  padding: 20px;\n  text-align: center;\n  background: #fff;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n"], ["\n  color: #dc3545;\n  padding: 20px;\n  text-align: center;\n  background: #fff;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n"])));
var RetryButton = styled.button(templateObject_55 || (templateObject_55 = __makeTemplateObject(["\n  margin-left: 10px;\n  padding: 5px 10px;\n  background: #dc3545;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #c82333;\n  }\n"], ["\n  margin-left: 10px;\n  padding: 5px 10px;\n  background: #dc3545;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #c82333;\n  }\n"])));
var EmptyState = styled.div(templateObject_56 || (templateObject_56 = __makeTemplateObject(["\n  text-align: center;\n  padding: 40px;\n  background: #fff;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n"], ["\n  text-align: center;\n  padding: 40px;\n  background: #fff;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n"])));
var RefreshButton = styled.button(templateObject_57 || (templateObject_57 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var ActionButtons = styled.div(templateObject_58 || (templateObject_58 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin-left: auto;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin-left: auto;\n"])));
// Add new styled component for the SQL Editor button
var SQLEditorButton = styled(ActionButton)(templateObject_59 || (templateObject_59 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  padding: 8px 16px;\n  margin-left: 12px;\n  \n  &:hover {\n    background: #7B3AAF;\n    opacity: 1;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  padding: 8px 16px;\n  margin-left: 12px;\n  \n  &:hover {\n    background: #7B3AAF;\n    opacity: 1;\n  }\n"])));
// Add new styled component for the AI Description button
var AIDescriptionButton = styled(ActionButton)(templateObject_60 || (templateObject_60 = __makeTemplateObject(["\n  background: #4A90E2;\n  color: white;\n  padding: 8px 16px;\n  margin-left: 12px;\n  \n  &:hover {\n    background: #357ABD;\n    opacity: 1;\n  }\n\n  &:disabled {\n    background: #B8B8B8;\n    cursor: not-allowed;\n  }\n"], ["\n  background: #4A90E2;\n  color: white;\n  padding: 8px 16px;\n  margin-left: 12px;\n  \n  &:hover {\n    background: #357ABD;\n    opacity: 1;\n  }\n\n  &:disabled {\n    background: #B8B8B8;\n    cursor: not-allowed;\n  }\n"])));
// Add this new styled component near the other styled components
var TruncatedDescription = styled(Description)(templateObject_61 || (templateObject_61 = __makeTemplateObject(["\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  position: relative;\n  cursor: pointer;\n  \n  &:hover {\n    color: #8F4ACF;\n  }\n"], ["\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  position: relative;\n  cursor: pointer;\n  \n  &:hover {\n    color: #8F4ACF;\n  }\n"])));
var ShowMoreButton = styled.button(templateObject_62 || (templateObject_62 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  color: #8F4ACF;\n  font-size: 12px;\n  padding: 4px 8px;\n  cursor: pointer;\n  margin-top: 4px;\n  \n  &:hover {\n    text-decoration: underline;\n  }\n"], ["\n  background: none;\n  border: none;\n  color: #8F4ACF;\n  font-size: 12px;\n  padding: 4px 8px;\n  cursor: pointer;\n  margin-top: 4px;\n  \n  &:hover {\n    text-decoration: underline;\n  }\n"])));
// Add this helper function at the component level
var truncateText = function (text, maxLength) {
    if (maxLength === void 0) { maxLength = 100; }
    if (!text || text.length <= maxLength)
        return text;
    return text.slice(0, maxLength) + '...';
};
var DataGlossary = function () {
    var _a = useDatasets(), datasets = _a.datasets, setDatasets = _a.setDatasets, loading = _a.loading, error = _a.error, refreshDatasets = _a.refreshDatasets;
    var _b = useState([]), openDatasets = _b[0], setOpenDatasets = _b[1];
    var _c = useState([]), openTables = _c[0], setOpenTables = _c[1];
    var _d = useState(false), isGlossaryOpen = _d[0], setIsGlossaryOpen = _d[1];
    var _e = useState(''), glossaryText = _e[0], setGlossaryText = _e[1];
    var _f = useState(null), currentGlossaryItem = _f[0], setCurrentGlossaryItem = _f[1];
    var _g = useState(false), isUpdating = _g[0], setIsUpdating = _g[1];
    var toggleDataset = function (datasetId) {
        setOpenDatasets(function (prev) {
            return prev.includes(datasetId)
                ? prev.filter(function (id) { return id !== datasetId; })
                : __spreadArray(__spreadArray([], prev, true), [datasetId], false);
        });
    };
    var toggleTable = function (tableKey) {
        setOpenTables(function (prev) {
            return prev.includes(tableKey)
                ? prev.filter(function (key) { return key !== tableKey; })
                : __spreadArray(__spreadArray([], prev, true), [tableKey], false);
        });
    };
    var handleGlossaryClick = function (type, name, parentName) {
        var _a;
        setCurrentGlossaryItem({ type: type, name: name, parentName: parentName });
        // Set initial description based on type
        if (type === 'dataset') {
            var dataset = datasets.find(function (d) { return d.name === name; });
            setGlossaryText((dataset === null || dataset === void 0 ? void 0 : dataset.description) || '');
        }
        else if (type === 'table') {
            var dataset = datasets.find(function (d) { return d.data.tables[name]; });
            setGlossaryText(((_a = dataset === null || dataset === void 0 ? void 0 : dataset.data.tables[name]) === null || _a === void 0 ? void 0 : _a.description) || '');
        }
        else {
            var dataset = datasets.find(function (d) {
                return Object.values(d.data.tables).some(function (t) {
                    return t.columns.some(function (c) { return c.name === name; });
                });
            });
            var column = Object.values((dataset === null || dataset === void 0 ? void 0 : dataset.data.tables) || {})
                .flatMap(function (t) { return t.columns; })
                .find(function (c) { return c.name === name; });
            setGlossaryText((column === null || column === void 0 ? void 0 : column.description) || '');
        }
        setIsGlossaryOpen(true);
    };
    var handleSaveDescription = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dataset, headers, response, error_2, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!currentGlossaryItem)
                        return [2 /*return*/];
                    setIsUpdating(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    dataset = datasets.find(function (d) {
                        if (currentGlossaryItem.type === 'dataset') {
                            return d.name === currentGlossaryItem.name;
                        }
                        return Object.keys(d.data.tables).some(function (tableName) {
                            return tableName === currentGlossaryItem.name ||
                                d.data.tables[tableName].columns.some(function (col) { return col.name === currentGlossaryItem.name; });
                        });
                    });
                    if (!dataset) {
                        throw new Error('Parent dataset not found');
                    }
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/datasets/".concat(dataset.name, "/metadata/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                update_type: currentGlossaryItem.type,
                                item_name: currentGlossaryItem.type === 'column'
                                    ? "".concat(currentGlossaryItem.parentName, ".").concat(currentGlossaryItem.name)
                                    : currentGlossaryItem.name,
                                description: glossaryText
                            })
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    error_2 = _a.sent();
                    throw new Error(error_2.message || 'Failed to update metadata');
                case 4:
                    toast.success('Description updated successfully');
                    setIsGlossaryOpen(false);
                    refreshDatasets();
                    return [3 /*break*/, 7];
                case 5:
                    error_1 = _a.sent();
                    toast.error(error_1 instanceof Error ? error_1.message : 'Failed to update description');
                    console.error('Error updating description:', error_1);
                    return [3 /*break*/, 7];
                case 6:
                    setIsUpdating(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleFetchDistinctValues = function (dataset, table, column) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/datasets/".concat(dataset, "/tables/").concat(table, "/columns/").concat(column, "/distinct-values/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok)
                        throw new Error('Failed to fetch distinct values');
                    toast.success('Distinct values updated');
                    refreshDatasets();
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    toast.error('Failed to fetch distinct values');
                    console.error('Error fetching distinct values:', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteDistinctValues = function (dataset, table, column) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/datasets/".concat(dataset, "/tables/").concat(table, "/columns/").concat(column, "/distinct-values/delete/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok)
                        throw new Error('Failed to delete distinct values');
                    toast.success('Distinct values deleted');
                    refreshDatasets();
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    toast.error('Failed to delete distinct values');
                    console.error('Error deleting distinct values:', error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleUpdateDescription = function (dataset, table, column, description) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/datasets/".concat(dataset, "/tables/").concat(table, "/columns/").concat(column, "/update-description/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({ description: description })
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok)
                        throw new Error('Failed to update description');
                    toast.success('Description updated');
                    refreshDatasets();
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _a.sent();
                    toast.error('Failed to update description');
                    console.error('Error updating description:', error_5);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleGenerateAIDescription = function (dataset, table, column) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, data, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/datasets/".concat(dataset, "/tables/").concat(table, "/columns/").concat(column, "/generate-description/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok)
                        throw new Error('Failed to generate AI description');
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    toast.success('AI description generated');
                    return [2 /*return*/, data.description];
                case 3:
                    error_6 = _a.sent();
                    toast.error('Failed to generate AI description');
                    console.error('Error generating AI description:', error_6);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleOpenSQLEditor = function (datasetName) {
        window.location.href = "/app/data-glossary/".concat(datasetName, "/sql-editor");
    };
    var handleGenerateAIDescriptions = function (datasetName) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, error_8, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/datasets/".concat(datasetName, "/generate-descriptions/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    error_8 = _a.sent();
                    throw new Error(error_8.error || 'Failed to generate AI descriptions');
                case 3:
                    toast.success('AI descriptions generated successfully');
                    refreshDatasets();
                    return [3 /*break*/, 5];
                case 4:
                    error_7 = _a.sent();
                    toast.error(error_7 instanceof Error ? error_7.message : 'Failed to generate AI descriptions');
                    console.error('Error generating AI descriptions:', error_7);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleRefreshDatasets = function () { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, error_10, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    toast.info('Refreshing datasets...');
                    return [4 /*yield*/, fetch('/api/datasets/refresh/', {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    error_10 = _a.sent();
                    throw new Error(error_10.error || 'Failed to refresh datasets');
                case 3: return [4 /*yield*/, refreshDatasets()];
                case 4:
                    _a.sent();
                    toast.success('Datasets refreshed successfully');
                    return [3 /*break*/, 6];
                case 5:
                    error_9 = _a.sent();
                    toast.error(error_9 instanceof Error ? error_9.message : 'Failed to refresh datasets');
                    console.error('Error refreshing datasets:', error_9);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        console.log('Component mounted');
        console.log('Initial state:', { datasets: datasets, loading: loading, error: error });
    }, []);
    useEffect(function () {
        console.log('State updated:', { datasets: datasets, loading: loading, error: error });
    }, [datasets, loading, error]);
    try {
        if (loading) {
            console.log('Rendering loading state');
            return (React.createElement(Container, null,
                React.createElement(Header, null,
                    React.createElement(Title, null, "Data Glossary")),
                React.createElement(LoadingOverlay, null, "Loading datasets...")));
        }
        if (error) {
            console.log('Rendering error state:', error);
            return (React.createElement(Container, null,
                React.createElement(Header, null,
                    React.createElement(Title, null, "Data Glossary")),
                React.createElement(ErrorMessage, null,
                    "Error loading datasets: ",
                    error,
                    React.createElement(RetryButton, { onClick: refreshDatasets }, "Retry"))));
        }
        if (!datasets || datasets.length === 0) {
            console.log('Rendering empty state');
            return (React.createElement(Container, null,
                React.createElement(Header, null,
                    React.createElement(Title, null, "Data Glossary"),
                    React.createElement(RefreshButton, { onClick: handleRefreshDatasets }, "Refresh Datasets")),
                React.createElement(EmptyState, null,
                    React.createElement("p", null, "No datasets available. Click refresh to load datasets."))));
        }
        console.log('Rendering main component with datasets:', datasets);
        return (React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(Title, null, "Data Glossary"),
                React.createElement(RefreshButton, { onClick: handleRefreshDatasets }, "Refresh Datasets")),
            React.createElement(DatasetGrid, null, datasets.map(function (dataset) { return (React.createElement(AccordionItem, { key: dataset.id },
                React.createElement(AccordionHeader, { isOpen: openDatasets.includes(dataset.id), onClick: function () { return toggleDataset(dataset.id); } },
                    React.createElement(AccordionIcon, { isOpen: openDatasets.includes(dataset.id) },
                        React.createElement(ChevronRightIcon, { size: 20 })),
                    React.createElement(DatasetIcon, null,
                        React.createElement(DatabaseIcon, { size: 24 })),
                    React.createElement(DatasetInfo, null,
                        React.createElement(DatasetName, null, dataset.name),
                        React.createElement(DatasetMeta, null,
                            React.createElement("span", null,
                                "Last updated: ",
                                new Date(dataset.last_updated).toLocaleDateString()),
                            dataset.description && (React.createElement(React.Fragment, null,
                                React.createElement(TruncatedDescription, null, truncateText(dataset.description)),
                                dataset.description.length > 100 && (React.createElement(ShowMoreButton, { onClick: function (e) {
                                        e.stopPropagation();
                                        handleGlossaryClick('dataset', dataset.name);
                                    } }, "Show More")))))),
                    React.createElement(ActionButtons, null,
                        React.createElement(ActionButton, { onClick: function (e) {
                                e.stopPropagation();
                                handleGlossaryClick('dataset', dataset.name);
                            } }, "Edit Description"),
                        React.createElement(SQLEditorButton, { onClick: function (e) {
                                e.stopPropagation();
                                handleOpenSQLEditor(dataset.name);
                            } }, "SQL Editor"),
                        React.createElement(AIDescriptionButton, { onClick: function (e) {
                                e.stopPropagation();
                                handleGenerateAIDescriptions(dataset.name);
                            } }, "Generate AI Descriptions"))),
                React.createElement(AccordionContent, { isOpen: openDatasets.includes(dataset.id) }, Object.entries(dataset.data.tables).map(function (_a) {
                    var tableName = _a[0], table = _a[1];
                    return (React.createElement(TableAccordion, { key: tableName },
                        React.createElement(TableHeader, { onClick: function () { return toggleTable("".concat(dataset.id, "-").concat(tableName)); } },
                            React.createElement(TableIcon, { size: 20 }),
                            React.createElement("div", null,
                                React.createElement(TableName, null, tableName),
                                table.description && (React.createElement(React.Fragment, null,
                                    React.createElement(TruncatedDescription, null, truncateText(table.description)),
                                    table.description.length > 100 && (React.createElement(ShowMoreButton, { onClick: function (e) {
                                            e.stopPropagation();
                                            handleGlossaryClick('table', tableName, dataset.name);
                                        } }, "Show More"))))),
                            table.owner && React.createElement("span", null,
                                "Owner: ",
                                table.owner),
                            React.createElement(ActionButtons, null,
                                React.createElement(ActionButton, { onClick: function () { return handleGlossaryClick('table', tableName, dataset.name); } }, "Edit Description"))),
                        React.createElement(AccordionContent, { isOpen: openTables.includes("".concat(dataset.id, "-").concat(tableName)) }, table.columns.map(function (column, index) { return (React.createElement(ColumnCard, { key: index },
                            React.createElement(ColumnIcon, { size: 20 }),
                            React.createElement(ColumnInfo, null,
                                React.createElement(ColumnName, null, column.name),
                                React.createElement(ColumnType, null, column.type)),
                            React.createElement(ActionButtons, null,
                                React.createElement(ActionButton, { onClick: function () { return handleGlossaryClick('column', column.name, tableName); } }, "Edit Description"),
                                React.createElement(ActionButton, { variant: "primary", onClick: function () { return handleFetchDistinctValues(dataset.name, tableName, column.name); } }, "Fetch Values"),
                                column.distinct_values && column.distinct_values.length > 0 && (React.createElement(ActionButton, { variant: "danger", onClick: function () { return handleDeleteDistinctValues(dataset.name, tableName, column.name); } }, "Clear Values"))),
                            column.description && (React.createElement(React.Fragment, null,
                                React.createElement(TruncatedDescription, null, truncateText(column.description)),
                                column.description.length > 100 && (React.createElement(ShowMoreButton, { onClick: function (e) {
                                        e.stopPropagation();
                                        handleGlossaryClick('column', column.name, tableName);
                                    } }, "Show More")))),
                            column.distinct_values && column.distinct_values.length > 0 && (React.createElement(ColumnDescription, null,
                                "Distinct values (sample): ",
                                column.distinct_values.slice(0, 10).join(', '),
                                column.distinct_values.length > 10 && ' ...')))); }))));
                })))); })),
            isGlossaryOpen && (React.createElement(React.Fragment, null,
                React.createElement(GlossaryOverlay, { onClick: function () { return setIsGlossaryOpen(false); } }),
                React.createElement(GlossaryPopup, { onClick: function (e) { return e.stopPropagation(); } },
                    React.createElement("h3", null, (currentGlossaryItem === null || currentGlossaryItem === void 0 ? void 0 : currentGlossaryItem.type)
                        ? "".concat(currentGlossaryItem.type.charAt(0).toUpperCase()).concat(currentGlossaryItem.type.slice(1), " Description")
                        : 'Description'),
                    (currentGlossaryItem === null || currentGlossaryItem === void 0 ? void 0 : currentGlossaryItem.parentName) && (React.createElement("p", null,
                        React.createElement(InfoLabel, null, "Parent:"),
                        currentGlossaryItem.parentName)),
                    React.createElement("p", null,
                        React.createElement(InfoLabel, null, "Name:"), currentGlossaryItem === null || currentGlossaryItem === void 0 ? void 0 :
                        currentGlossaryItem.name),
                    React.createElement(GlossaryTextArea, { value: glossaryText, onChange: function (e) { return setGlossaryText(e.target.value); }, placeholder: "Enter description..." }),
                    React.createElement(ButtonGroup, null,
                        React.createElement(Button, { onClick: function () { return setIsGlossaryOpen(false); } }, "Cancel"),
                        React.createElement(Button, { primary: true, onClick: handleSaveDescription }, "Save")))))));
    }
    catch (err) {
        console.error('Error in DataGlossary render:', err);
        return (React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(Title, null, "Data Glossary")),
            React.createElement(ErrorMessage, null,
                "An unexpected error occurred. Please try refreshing the page.",
                React.createElement(RetryButton, { onClick: function () { return window.location.reload(); } }, "Refresh Page"))));
    }
};
export default DataGlossary;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38, templateObject_39, templateObject_40, templateObject_41, templateObject_42, templateObject_43, templateObject_44, templateObject_45, templateObject_46, templateObject_47, templateObject_48, templateObject_49, templateObject_50, templateObject_51, templateObject_52, templateObject_53, templateObject_54, templateObject_55, templateObject_56, templateObject_57, templateObject_58, templateObject_59, templateObject_60, templateObject_61, templateObject_62;
