var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import LogoImage from '../../assets/Logo.png';
import { FiX, FiMenu } from 'react-icons/fi';
var Nav = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  height: 90px;\n  left: 0;\n  top: 0;\n  border-bottom: 1px solid #ECEAFF;\n  background: white;\n  z-index: 1000;\n  display: flex;\n  justify-content: center;\n"], ["\n  position: fixed;\n  width: 100%;\n  height: 90px;\n  left: 0;\n  top: 0;\n  border-bottom: 1px solid #ECEAFF;\n  background: white;\n  z-index: 1000;\n  display: flex;\n  justify-content: center;\n"])));
var NavContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  max-width: 1440px;\n  height: 100%;\n  padding: 0 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  position: relative;\n  width: 100%;\n  max-width: 1440px;\n  height: 100%;\n  padding: 0 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
var Logo = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 165.76px;\n  height: 46px;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"], ["\n  width: 165.76px;\n  height: 46px;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"])));
var NavItems = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  gap: 30px;\n\n  @media (max-width: 768px) {\n    display: none;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  gap: 30px;\n\n  @media (max-width: 768px) {\n    display: none;\n  }\n"])));
var NavLink = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 400;\n  font-size: 15px;\n  color: #000000;\n  text-decoration: none;\n  &:hover {\n    color: #8F4ACF;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 400;\n  font-size: 15px;\n  color: #000000;\n  text-decoration: none;\n  &:hover {\n    color: #8F4ACF;\n  }\n"])));
var AuthButtons = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n\n  @media (max-width: 768px) {\n    display: none;\n  }\n"], ["\n  display: flex;\n  gap: 8px;\n\n  @media (max-width: 768px) {\n    display: none;\n  }\n"])));
var MobileMenuButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: none;\n  background: none;\n  border: none;\n  cursor: pointer;\n\n  @media (max-width: 768px) {\n    display: block;\n  }\n"], ["\n  display: none;\n  background: none;\n  border: none;\n  cursor: pointer;\n\n  @media (max-width: 768px) {\n    display: block;\n  }\n"])));
var MobileMenu = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: none;\n  position: fixed;\n  top: 90px;\n  left: 0;\n  right: 0;\n  background: white;\n  padding: 20px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n\n  @media (max-width: 768px) {\n    display: ", ";\n  }\n"], ["\n  display: none;\n  position: fixed;\n  top: 90px;\n  left: 0;\n  right: 0;\n  background: white;\n  padding: 20px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n\n  @media (max-width: 768px) {\n    display: ", ";\n  }\n"])), function (props) { return (props.isOpen ? 'block' : 'none'); });
var MobileNavLink = styled(Link)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: block;\n  padding: 12px 16px;\n  color: #000000;\n  text-decoration: none;\n  font-weight: 500;\n  &:hover {\n    background: #F8F5FF;\n    color: #8F4ACF;\n  }\n"], ["\n  display: block;\n  padding: 12px 16px;\n  color: #000000;\n  text-decoration: none;\n  font-weight: 500;\n  &:hover {\n    background: #F8F5FF;\n    color: #8F4ACF;\n  }\n"])));
var SignUpButton = styled(Link)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: relative;\n  width: 89px;\n  height: 40px;\n  background: #000000;\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-decoration: none;\n\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 140%;\n  text-align: center;\n  color: #FFFFFF;\n"], ["\n  position: relative;\n  width: 89px;\n  height: 40px;\n  background: #000000;\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-decoration: none;\n\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 140%;\n  text-align: center;\n  color: #FFFFFF;\n"])));
var SignInButton = styled(Link)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: relative;\n  width: 89px;\n  height: 40px;\n  background: #FAFAFA;\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-decoration: none;\n\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 140%;\n  text-align: center;\n  color: #000000;\n"], ["\n  position: relative;\n  width: 89px;\n  height: 40px;\n  background: #FAFAFA;\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-decoration: none;\n\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 140%;\n  text-align: center;\n  color: #000000;\n"])));
export default function Navbar() {
    var _a = React.useState(false), isMobileMenuOpen = _a[0], setIsMobileMenuOpen = _a[1];
    return (React.createElement(Nav, null,
        React.createElement(NavContainer, null,
            React.createElement(Logo, { to: "/" },
                React.createElement("img", { src: LogoImage, alt: "Cognitio Intelligence" })),
            React.createElement(NavItems, null,
                React.createElement(NavLink, { to: "/features" }, "Features"),
                React.createElement(NavLink, { to: "/documentation" }, "Documentation"),
                React.createElement(NavLink, { to: "/about" }, "About"),
                React.createElement(NavLink, { to: "/support" }, "Support"),
                React.createElement(NavLink, { to: "/pricing" }, "Pricing"),
                React.createElement(NavLink, { to: "/contact" }, "Contact Us")),
            React.createElement(AuthButtons, null,
                React.createElement(SignUpButton, { to: "/pricing" }, "Sign Up"),
                React.createElement(SignInButton, { to: "/signin" }, "Sign In")),
            React.createElement(MobileMenuButton, { onClick: function () { return setIsMobileMenuOpen(!isMobileMenuOpen); } }, isMobileMenuOpen ? React.createElement(FiX, { size: 24 }) : React.createElement(FiMenu, { size: 24 }))),
        React.createElement(MobileMenu, { isOpen: isMobileMenuOpen },
            React.createElement(MobileNavLink, { to: "/features", onClick: function () { return setIsMobileMenuOpen(false); } }, "Features"),
            React.createElement(MobileNavLink, { to: "/documentation", onClick: function () { return setIsMobileMenuOpen(false); } }, "Documentation"),
            React.createElement(MobileNavLink, { to: "/about", onClick: function () { return setIsMobileMenuOpen(false); } }, "About"),
            React.createElement(MobileNavLink, { to: "/support", onClick: function () { return setIsMobileMenuOpen(false); } }, "Support"),
            React.createElement(MobileNavLink, { to: "/pricing", onClick: function () { return setIsMobileMenuOpen(false); } }, "Pricing"),
            React.createElement(MobileNavLink, { to: "/contact", onClick: function () { return setIsMobileMenuOpen(false); } }, "Contact Us"),
            React.createElement(MobileNavLink, { to: "/signin", onClick: function () { return setIsMobileMenuOpen(false); } }, "Sign In"),
            React.createElement(MobileNavLink, { to: "/signup", onClick: function () { return setIsMobileMenuOpen(false); } }, "Sign Up"))));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
