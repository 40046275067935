var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var SecuritySection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var Form = styled.form(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 500px;\n"], ["\n  max-width: 500px;\n"])));
var FormGroup = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 24px;\n"], ["\n  margin-bottom: 24px;\n"])));
var Label = styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: block;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"], ["\n  display: block;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"])));
var Input = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  height: 48px;\n  background: #FFFFFF;\n  border: 1px solid #E5E5E5;\n  border-radius: 8px;\n  padding: 0 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  width: 100%;\n  height: 48px;\n  background: #FFFFFF;\n  border: 1px solid #E5E5E5;\n  border-radius: 8px;\n  padding: 0 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var SaveButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: #8F4ACF;\n  border-radius: 8px;\n  padding: 12px 24px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  width: 100%;\n  margin-top: 16px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n  \n  &:disabled {\n    background: #E5E5E5;\n    cursor: not-allowed;\n  }\n"], ["\n  background: #8F4ACF;\n  border-radius: 8px;\n  padding: 12px 24px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  width: 100%;\n  margin-top: 16px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n  \n  &:disabled {\n    background: #E5E5E5;\n    cursor: not-allowed;\n  }\n"])));
var ErrorMessage = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #FF4D4D;\n  font-size: 14px;\n  margin-top: 4px;\n"], ["\n  color: #FF4D4D;\n  font-size: 14px;\n  margin-top: 4px;\n"])));
var SuccessMessage = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: #00C48C;\n  font-size: 14px;\n  margin-top: 4px;\n"], ["\n  color: #00C48C;\n  font-size: 14px;\n  margin-top: 4px;\n"])));
var HelperText = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 12px;\n  color: #6B6B6B;\n  margin-top: 4px;\n"], ["\n  font-size: 12px;\n  color: #6B6B6B;\n  margin-top: 4px;\n"])));
var AccountSecurity = function () {
    var _a = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }), formData = _a[0], setFormData = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useState(''), success = _c[0], setSuccess = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var navigate = useNavigate();
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setFormData(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = value, _a)));
        });
        setError('');
        setSuccess('');
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    e.preventDefault();
                    setError('');
                    setSuccess('');
                    setLoading(true);
                    if (formData.newPassword !== formData.confirmPassword) {
                        setError('New passwords do not match');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    if (formData.newPassword.length < 8) {
                        setError('Password must be at least 8 characters long');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, axios.get('/api/csrf/')];
                case 2:
                    _c.sent();
                    return [4 /*yield*/, axios.post('/api/change-password/', {
                            current_password: formData.currentPassword,
                            new_password: formData.newPassword
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            withCredentials: true
                        })];
                case 3:
                    response = _c.sent();
                    if (response.status === 200) {
                        setSuccess('Password changed successfully');
                        setFormData({
                            currentPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        });
                    }
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _c.sent();
                    setError(((_b = (_a = error_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'Failed to change password');
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Account Security"),
        React.createElement(SecuritySection, null,
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Current Password"),
                    React.createElement(Input, { type: "password", name: "currentPassword", value: formData.currentPassword, onChange: handleInputChange, required: true })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "New Password"),
                    React.createElement(Input, { type: "password", name: "newPassword", value: formData.newPassword, onChange: handleInputChange, required: true }),
                    React.createElement(HelperText, null, "Password must be at least 8 characters long and include a mix of letters, numbers, and symbols")),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Confirm New Password"),
                    React.createElement(Input, { type: "password", name: "confirmPassword", value: formData.confirmPassword, onChange: handleInputChange, required: true })),
                error && React.createElement(ErrorMessage, null, error),
                success && React.createElement(SuccessMessage, null, success),
                React.createElement(SaveButton, { type: "submit", disabled: loading }, loading ? 'Changing Password...' : 'Change Password')))));
};
export default AccountSecurity;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
