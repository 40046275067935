var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie } from '../../utils/cookies';
import { Container, Title, UsersSection, Table, Th, Td, ActionButton } from './styles';
// Reuse the styled components from ManageUsers.tsx
// Add this new styled component
var BackButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: transparent;\n  color: #8F4ACF;\n  border: 1px solid #8F4ACF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n"], ["\n  background: transparent;\n  color: #8F4ACF;\n  border: 1px solid #8F4ACF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n"])));
// Add these styled components
var ButtonGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  margin-top: 16px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  margin-top: 16px;\n"])));
var SaveButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var AssignUsers = function () {
    var companyId = useParams().companyId;
    var navigate = useNavigate();
    var _a = useState([]), users = _a[0], setUsers = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(''), companyName = _c[0], setCompanyName = _c[1];
    var _d = useState([]), selectedUsers = _d[0], setSelectedUsers = _d[1];
    var _e = useState([]), availableUsers = _e[0], setAvailableUsers = _e[1];
    var fetchCompanyUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, usersResponse, companyResponse, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Promise.all([
                            axios.get("/api/companies/".concat(companyId, "/users/"), {
                                withCredentials: true
                            }),
                            axios.get("/api/companies/".concat(companyId, "/"), {
                                withCredentials: true
                            })
                        ])];
                case 1:
                    _a = _b.sent(), usersResponse = _a[0], companyResponse = _a[1];
                    setUsers(usersResponse.data);
                    setCompanyName(companyResponse.data.name);
                    toast.success('Company users loaded successfully', { id: 'fetch-company-users' });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    console.error('Error fetching company users:', error_1);
                    toast.error('Failed to load company users', { id: 'fetch-company-users' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchAvailableUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2, errorMessage;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/users/available/', {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            }
                        })];
                case 1:
                    response = _c.sent();
                    if (Array.isArray(response.data)) {
                        setAvailableUsers(response.data);
                        toast.success('Available users loaded successfully', { id: 'fetch-available-users' });
                    }
                    else {
                        console.error('Invalid response format:', response.data);
                        toast.error('Invalid response format from server', { id: 'fetch-available-users' });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _c.sent();
                    console.error('Error fetching available users:', error_2);
                    errorMessage = ((_b = (_a = error_2.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Failed to load available users';
                    toast.error(errorMessage, { id: 'fetch-available-users' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setLoading(true);
                        return [4 /*yield*/, Promise.all([
                                fetchCompanyUsers(),
                                fetchAvailableUsers()
                            ])];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        error_3 = _a.sent();
                        console.error('Error fetching data:', error_3);
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [companyId]);
    var handleAssignUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4, errorMessage;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, axios.post("/api/users/assign-to-company/", {
                            company_id: companyId,
                            user_ids: selectedUsers
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _c.sent();
                    if (!response.data.success) return [3 /*break*/, 3];
                    return [4 /*yield*/, Promise.all([
                            fetchCompanyUsers(),
                            fetchAvailableUsers()
                        ])];
                case 2:
                    _c.sent();
                    setSelectedUsers([]);
                    toast.success('Users assigned successfully', { id: 'assign-users' });
                    _c.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_4 = _c.sent();
                    console.error('Error assigning users:', error_4);
                    errorMessage = ((_b = (_a = error_4.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Failed to assign users to company';
                    toast.error(errorMessage, { id: 'assign-users' });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleUnassignUsers = function (userId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, axios.post("/api/companies/".concat(companyId, "/unassign-users/"), { user_ids: [userId] }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.data.success) return [3 /*break*/, 3];
                    return [4 /*yield*/, Promise.all([
                            fetchCompanyUsers(),
                            fetchAvailableUsers()
                        ])];
                case 2:
                    _a.sent();
                    toast.success('User unassigned successfully', { id: "unassign-user-".concat(userId) });
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_5 = _a.sent();
                    console.error('Error unassigning user:', error_5);
                    toast.error('Failed to unassign user from company', { id: "unassign-user-".concat(userId) });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Container, null,
        React.createElement(Title, null,
            "Manage Users - ",
            companyName),
        React.createElement(UsersSection, null,
            React.createElement("div", { style: { marginBottom: '24px' } },
                React.createElement("h3", null, "Available Users"),
                React.createElement(Table, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement(Th, { style: { width: '40px' } },
                                React.createElement("input", { type: "checkbox", onChange: function (e) {
                                        if (e.target.checked) {
                                            setSelectedUsers(availableUsers.map(function (user) { return Number(user.id); }));
                                        }
                                        else {
                                            setSelectedUsers([]);
                                        }
                                    } })),
                            React.createElement(Th, null, "Username"),
                            React.createElement(Th, null, "Email"),
                            React.createElement(Th, null, "Department"),
                            React.createElement(Th, null, "Role"))),
                    React.createElement("tbody", null, availableUsers.map(function (user) { return (React.createElement("tr", { key: user.id },
                        React.createElement(Td, null,
                            React.createElement("input", { type: "checkbox", checked: selectedUsers.includes(Number(user.id)), onChange: function (e) {
                                    if (e.target.checked) {
                                        setSelectedUsers(__spreadArray(__spreadArray([], selectedUsers, true), [Number(user.id)], false));
                                    }
                                    else {
                                        setSelectedUsers(selectedUsers.filter(function (id) { return id !== Number(user.id); }));
                                    }
                                } })),
                        React.createElement(Td, null, user.username),
                        React.createElement(Td, null, user.email),
                        React.createElement(Td, null, user.department || '-'),
                        React.createElement(Td, null, user.role || '-'))); }))),
                selectedUsers.length > 0 && (React.createElement(ButtonGroup, null,
                    React.createElement(SaveButton, { onClick: handleAssignUsers },
                        "Assign Selected Users (",
                        selectedUsers.length,
                        ")")))),
            React.createElement("div", null,
                React.createElement("h3", null, "Assigned Users"),
                React.createElement(Table, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement(Th, null, "Username"),
                            React.createElement(Th, null, "Email"),
                            React.createElement(Th, null, "Department"),
                            React.createElement(Th, null, "Role"),
                            React.createElement(Th, null, "Actions"))),
                    React.createElement("tbody", null, users.map(function (user) { return (React.createElement("tr", { key: user.id },
                        React.createElement(Td, null, user.username),
                        React.createElement(Td, null, user.email),
                        React.createElement(Td, null, user.department || '-'),
                        React.createElement(Td, null, user.role || '-'),
                        React.createElement(Td, null,
                            React.createElement(ActionButton, { variant: "delete", onClick: function () { return handleUnassignUsers(Number(user.id)); } }, "Remove")))); })))))));
};
export default AssignUsers;
var templateObject_1, templateObject_2, templateObject_3;
