var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AnimatedBackground, GlassCard, FloatingElement, pageTransition, staggerContainer, fadeInUp } from './shared/AnimatedComponents';
import { FaBook, FaQuestionCircle, FaHeadset, FaSearch } from 'react-icons/fa';
var PageContainer = styled(AnimatedBackground)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: calc(100vh - 90px);\n  padding: 90px 60px 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  min-height: calc(100vh - 90px);\n  padding: 90px 60px 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var SearchSection = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 800px;\n  margin-bottom: 60px;\n  text-align: center;\n"], ["\n  width: 100%;\n  max-width: 800px;\n  margin-bottom: 60px;\n  text-align: center;\n"])));
var SearchBar = styled(motion.div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  background: rgba(255, 255, 255, 0.9);\n  border-radius: 50px;\n  padding: 15px 30px;\n  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);\n  margin: 20px 0;\n\n  input {\n    flex: 1;\n    border: none;\n    background: none;\n    padding: 10px;\n    font-size: 16px;\n    color: #333;\n    outline: none;\n\n    &::placeholder {\n      color: #999;\n    }\n  }\n\n  svg {\n    color: #8F4ACF;\n    font-size: 20px;\n    margin-right: 10px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  background: rgba(255, 255, 255, 0.9);\n  border-radius: 50px;\n  padding: 15px 30px;\n  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);\n  margin: 20px 0;\n\n  input {\n    flex: 1;\n    border: none;\n    background: none;\n    padding: 10px;\n    font-size: 16px;\n    color: #333;\n    outline: none;\n\n    &::placeholder {\n      color: #999;\n    }\n  }\n\n  svg {\n    color: #8F4ACF;\n    font-size: 20px;\n    margin-right: 10px;\n  }\n"])));
var ContentGrid = styled(motion.div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 30px;\n  width: 100%;\n  max-width: 1200px;\n  margin-bottom: 40px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 30px;\n  width: 100%;\n  max-width: 1200px;\n  margin-bottom: 40px;\n"])));
var SupportCard = styled(GlassCard)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  cursor: pointer;\n  transition: all 0.3s ease;\n\n  &:hover {\n    transform: translateY(-5px);\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  cursor: pointer;\n  transition: all 0.3s ease;\n\n  &:hover {\n    transform: translateY(-5px);\n  }\n"])));
var IconWrapper = styled(FloatingElement)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 60px;\n  height: 60px;\n  background: linear-gradient(135deg, #8F4ACF 0%, #6B2E9E 100%);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n  color: white;\n  font-size: 24px;\n"], ["\n  width: 60px;\n  height: 60px;\n  background: linear-gradient(135deg, #8F4ACF 0%, #6B2E9E 100%);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n  color: white;\n  font-size: 24px;\n"])));
var Title = styled.h1(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 20px;\n  color: #000000;\n  text-align: center;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 20px;\n  color: #000000;\n  text-align: center;\n"])));
var CardTitle = styled.h3(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  margin: 15px 0;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  margin: 15px 0;\n  color: #000000;\n"])));
var Text = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n  margin-bottom: 20px;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n  margin-bottom: 20px;\n"])));
var FAQSection = styled(motion.section)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 800px;\n  margin-top: 40px;\n"], ["\n  width: 100%;\n  max-width: 800px;\n  margin-top: 40px;\n"])));
var FAQItem = styled(motion.div)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: rgba(255, 255, 255, 0.8);\n  border-radius: 12px;\n  padding: 20px;\n  margin-bottom: 15px;\n  cursor: pointer;\n\n  h4 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 18px;\n    color: #000000;\n    margin: 0;\n  }\n\n  p {\n    margin-top: 15px;\n    display: none;\n  }\n\n  &.active p {\n    display: block;\n  }\n"], ["\n  background: rgba(255, 255, 255, 0.8);\n  border-radius: 12px;\n  padding: 20px;\n  margin-bottom: 15px;\n  cursor: pointer;\n\n  h4 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 18px;\n    color: #000000;\n    margin: 0;\n  }\n\n  p {\n    margin-top: 15px;\n    display: none;\n  }\n\n  &.active p {\n    display: block;\n  }\n"])));
var SupportPage = function () {
    var _a = useState(''), searchQuery = _a[0], setSearchQuery = _a[1];
    var _b = useState(null), activeFAQ = _b[0], setActiveFAQ = _b[1];
    var supportCards = [
        {
            icon: React.createElement(FaBook, null),
            title: "Documentation",
            text: "Access our comprehensive documentation to learn about all features and capabilities of Cognitio Intelligence."
        },
        {
            icon: React.createElement(FaQuestionCircle, null),
            title: "FAQs",
            text: "Find answers to commonly asked questions about our platform, pricing, features, and technical requirements."
        },
        {
            icon: React.createElement(FaHeadset, null),
            title: "Technical Support",
            text: "Need technical assistance? Our support team is available 24/7 to help you resolve any issues."
        }
    ];
    var faqs = [
        {
            question: "How do I get started with the platform?",
            answer: "Getting started is easy! Simply sign up for an account, connect your data sources, and begin analyzing your data using our intuitive interface."
        },
        {
            question: "What data formats do you support?",
            answer: "We support a wide range of data formats including CSV, JSON, SQL databases, and various API integrations."
        },
        {
            question: "How secure is my data?",
            answer: "We implement industry-leading security measures including end-to-end encryption, secure data centers, and regular security audits."
        }
    ];
    return (React.createElement(PageContainer, { as: motion.div, variants: pageTransition, initial: "initial", animate: "animate", exit: "exit" },
        React.createElement(SearchSection, null,
            React.createElement(Title, null, "Support Center"),
            React.createElement(SearchBar, { initial: { scale: 0.9, opacity: 0 }, animate: { scale: 1, opacity: 1 }, transition: { delay: 0.2 } },
                React.createElement(FaSearch, null),
                React.createElement("input", { type: "text", placeholder: "Search for help...", value: searchQuery, onChange: function (e) { return setSearchQuery(e.target.value); } }))),
        React.createElement(ContentGrid, { variants: staggerContainer, initial: "initial", animate: "animate" }, supportCards.map(function (card, index) { return (React.createElement(SupportCard, { key: index, variants: fadeInUp, whileHover: { scale: 1.03 }, whileTap: { scale: 0.98 } },
            React.createElement(IconWrapper, null, card.icon),
            React.createElement(CardTitle, null, card.title),
            React.createElement(Text, null, card.text))); })),
        React.createElement(FAQSection, { variants: fadeInUp, initial: "initial", animate: "animate" },
            React.createElement(Title, null, "Frequently Asked Questions"),
            faqs.map(function (faq, index) { return (React.createElement(FAQItem, { key: index, className: activeFAQ === index ? 'active' : '', onClick: function () { return setActiveFAQ(activeFAQ === index ? null : index); }, initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, transition: { delay: index * 0.1 } },
                React.createElement("h4", null, faq.question),
                React.createElement(motion.p, { initial: { height: 0, opacity: 0 }, animate: {
                        height: activeFAQ === index ? 'auto' : 0,
                        opacity: activeFAQ === index ? 1 : 0
                    }, transition: { duration: 0.3 } }, faq.answer))); }))));
};
export default SupportPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
