var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { getCookie } from '../../utils/cookies';
import { toast } from 'react-hot-toast';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  height: calc(100vh - 150px);\n  position: relative;\n"], ["\n  padding: 20px;\n  height: calc(100vh - 150px);\n  position: relative;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 22px;\n  line-height: 110%;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 22px;\n  line-height: 110%;\n  color: #000000;\n"])));
var AddQuestionButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 16px 24px;\n  height: 50px;\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  color: #8F4ACF;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n\n  &:hover {\n    background: #F0E7FF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 16px 24px;\n  height: 50px;\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  color: #8F4ACF;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n\n  &:hover {\n    background: #F0E7FF;\n  }\n"])));
var QuestionsTable = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n"], ["\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n"])));
var TableHeader = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 200px 100px;\n  gap: 24px;\n  padding: 10px 0;\n  margin-bottom: 20px;\n  border-bottom: 1px solid #E8E8E8;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 200px 100px;\n  gap: 24px;\n  padding: 10px 0;\n  margin-bottom: 20px;\n  border-bottom: 1px solid #E8E8E8;\n"])));
var TableHeaderCell = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 110%;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 110%;\n  color: #000000;\n"])));
var QuestionRow = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 200px 100px;\n  gap: 24px;\n  padding: 20px 0;\n  border-bottom: 1px solid #E8E8E8;\n  align-items: center;\n\n  &:last-child {\n    border-bottom: none;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 200px 100px;\n  gap: 24px;\n  padding: 20px 0;\n  border-bottom: 1px solid #E8E8E8;\n  align-items: center;\n\n  &:last-child {\n    border-bottom: none;\n  }\n"])));
var QuestionText = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n  cursor: pointer;\n  transition: color 0.2s;\n\n  &:hover {\n    color: #8F4ACF;\n    text-decoration: underline;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n  cursor: pointer;\n  transition: color 0.2s;\n\n  &:hover {\n    color: #8F4ACF;\n    text-decoration: underline;\n  }\n"])));
var DateText = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n"])));
var DeleteButton = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 69px;\n  height: 50px;\n  background: #F3F3F3;\n  border-radius: 16px;\n  border: none;\n  color: #6B6B6B;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    background: #E8E8E8;\n  }\n"], ["\n  width: 69px;\n  height: 50px;\n  background: #F3F3F3;\n  border-radius: 16px;\n  border: none;\n  color: #6B6B6B;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    background: #E8E8E8;\n  }\n"])));
var Modal = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 800px;\n  max-height: 80vh;\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n  z-index: 10000;\n  overflow-y: auto;\n"], ["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 800px;\n  max-height: 80vh;\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n  z-index: 10000;\n  overflow-y: auto;\n"])));
var ModalOverlay = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 9999;\n  pointer-events: all;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 9999;\n  pointer-events: all;\n"])));
var ModalHeader = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 24px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 24px;\n"])));
var ModalTitle = styled.h2(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"])));
var CloseButton = styled.button(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  color: #6B6B6B;\n"], ["\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  color: #6B6B6B;\n"])));
var QuestionOption = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin-bottom: 16px;\n  cursor: pointer;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"], ["\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin-bottom: 16px;\n  cursor: pointer;\n  transition: background-color 0.2s;\n\n  &:hover {\n    background-color: #F8F5FF;\n  }\n"])));
var QuestionOptionContent = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 16px;\n"])));
var QuestionOptionText = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  color: #000000;\n  flex: 1;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  color: #000000;\n  flex: 1;\n"])));
var QuestionOptionDate = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  white-space: nowrap;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  white-space: nowrap;\n"])));
var SearchInput = styled.input(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  width: 100%;\n  padding: 12px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin-bottom: 24px;\n  font-family: 'Inter';\n  font-size: 16px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  width: 100%;\n  padding: 12px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin-bottom: 24px;\n  font-family: 'Inter';\n  font-size: 16px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var formatDate = function (date) {
    return date.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
    });
};
var ManageQuestions = function () {
    var folderId = useParams().folderId;
    var _a = useState([]), folderQuestions = _a[0], setFolderQuestions = _a[1];
    var _b = useState([]), availableQuestions = _b[0], setAvailableQuestions = _b[1];
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = useState(''), searchQuery = _d[0], setSearchQuery = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var navigate = useNavigate();
    useEffect(function () {
        fetchQuestions();
    }, [folderId]);
    var fetchQuestions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var folderResponse, folderData, availableResponse, availableData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, 6, 7]);
                    return [4 /*yield*/, fetch("/api/folders/".concat(folderId, "/questions/"))];
                case 1:
                    folderResponse = _a.sent();
                    if (!folderResponse.ok)
                        throw new Error('Failed to fetch folder questions');
                    return [4 /*yield*/, folderResponse.json()];
                case 2:
                    folderData = _a.sent();
                    setFolderQuestions(folderData.questions);
                    return [4 /*yield*/, fetch('/api/questions/unassigned/')];
                case 3:
                    availableResponse = _a.sent();
                    if (!availableResponse.ok)
                        throw new Error('Failed to fetch available questions');
                    return [4 /*yield*/, availableResponse.json()];
                case 4:
                    availableData = _a.sent();
                    setAvailableQuestions(availableData.questions);
                    return [3 /*break*/, 7];
                case 5:
                    error_1 = _a.sent();
                    console.error('Error fetching questions:', error_1);
                    toast.error('Failed to load questions');
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleMoveToFolder = function (questionId) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch('/api/move-to-folder/', {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                question_id: questionId,
                                folder_id: folderId
                            })
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    throw new Error(data.error || 'Failed to move question');
                case 3:
                    toast.success('Question moved successfully');
                    return [4 /*yield*/, fetchQuestions()];
                case 4:
                    _a.sent(); // Refresh questions
                    setIsModalOpen(false);
                    return [3 /*break*/, 6];
                case 5:
                    error_2 = _a.sent();
                    console.error('Error moving question:', error_2);
                    toast.error(error_2 instanceof Error ? error_2.message : 'Failed to move question');
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleRemoveFromFolder = function (questionId) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, data, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch('/api/move-to-folder/', {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                question_id: questionId,
                                folder_id: null
                            })
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    throw new Error(data.error || 'Failed to remove question');
                case 3:
                    toast.success('Question removed from folder');
                    return [4 /*yield*/, fetchQuestions()];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    error_3 = _a.sent();
                    console.error('Error removing question:', error_3);
                    toast.error(error_3 instanceof Error ? error_3.message : 'Failed to remove question');
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleQuestionClick = function (questionId) {
        navigate("/app/analysis/result/".concat(questionId));
    };
    var filteredQuestions = availableQuestions
        .filter(function (question) {
        return question.question.toLowerCase().includes(searchQuery.toLowerCase());
    })
        .sort(function (a, b) { return new Date(b.created_at).getTime() - new Date(a.created_at).getTime(); });
    var handleDeleteQuestion = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, data, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/questions/".concat(id, "/"), {
                            method: 'DELETE',
                            headers: headers,
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    throw new Error(data.error || 'Failed to delete question');
                case 3:
                    toast.success('Question deleted successfully');
                    return [4 /*yield*/, fetchQuestions()];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    error_4 = _a.sent();
                    console.error('Error deleting question:', error_4);
                    toast.error(error_4 instanceof Error ? error_4.message : 'Failed to delete question');
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    if (loading)
        return React.createElement("div", null, "Loading...");
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Title, null, "Manage Questions"),
            React.createElement(AddQuestionButton, { onClick: function () { return setIsModalOpen(true); } }, "+ Add Question")),
        React.createElement(QuestionsTable, null,
            React.createElement(TableHeader, null,
                React.createElement(TableHeaderCell, null, "Question"),
                React.createElement(TableHeaderCell, null, "Date Added"),
                React.createElement(TableHeaderCell, null, "Actions")),
            folderQuestions.map(function (question) { return (React.createElement(QuestionRow, { key: question.id },
                React.createElement(QuestionText, { onClick: function () { return handleQuestionClick(question.id); } }, question.question),
                React.createElement(DateText, null, formatDate(new Date(question.created_at))),
                React.createElement(DeleteButton, { onClick: function () { return handleRemoveFromFolder(question.id); } }, "Remove"))); })),
        isModalOpen && (React.createElement(React.Fragment, null,
            React.createElement(ModalOverlay, { onClick: function () { return setIsModalOpen(false); } }),
            React.createElement(Modal, null,
                React.createElement(ModalHeader, null,
                    React.createElement(ModalTitle, null, "Add Question to Folder"),
                    React.createElement(CloseButton, { onClick: function () { return setIsModalOpen(false); } }, "\u00D7")),
                React.createElement(SearchInput, { type: "text", placeholder: "Search questions...", value: searchQuery, onChange: function (e) { return setSearchQuery(e.target.value); } }),
                filteredQuestions.map(function (question) { return (React.createElement(QuestionOption, { key: question.id, onClick: function () { return handleMoveToFolder(question.id); } },
                    React.createElement(QuestionOptionContent, null,
                        React.createElement(QuestionOptionText, null, question.question),
                        React.createElement(QuestionOptionDate, null, formatDate(new Date(question.created_at)))))); }))))));
};
export default ManageQuestions;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
