var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var ModalOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10000;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10000;\n"])));
export var ModalContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: white;\n  padding: 24px;\n  border-radius: 8px;\n  min-width: 400px;\n  position: relative;\n  z-index: 10001;\n"], ["\n  background: white;\n  padding: 24px;\n  border-radius: 8px;\n  min-width: 400px;\n  position: relative;\n  z-index: 10001;\n"])));
var Modal = function (_a) {
    var isOpen = _a.isOpen, children = _a.children;
    if (!isOpen)
        return null;
    return (React.createElement(ModalOverlay, null, children));
};
export default Modal;
var templateObject_1, templateObject_2;
