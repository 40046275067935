var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, AreaChart, Area } from 'recharts';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getCookie } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 20px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 24px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 24px;\n  color: #000000;\n"])));
var ButtonGroup = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n"], ["\n  display: flex;\n  gap: 16px;\n"])));
var ExportButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 12px 20px;\n  height: 44px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 12px;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    transform: translateY(-1px);\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 12px 20px;\n  height: 44px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 12px;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    transform: translateY(-1px);\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  }\n"])), function (_a) {
    var variant = _a.variant;
    return variant === 'sheets' ? '#EDFBF2' : '#FEF8E0';
}, function (_a) {
    var variant = _a.variant;
    return variant === 'sheets' ? '#7AD28C' : '#F6C763';
}, function (_a) {
    var variant = _a.variant;
    return variant === 'sheets' ? '#7AD28C' : '#F6C763';
});
var GridContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 24px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 24px;\n"])));
var ChartCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);\n  transition: transform 0.2s ease-in-out;\n\n  &:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);\n  transition: transform 0.2s ease-in-out;\n\n  &:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);\n  }\n"])));
var ChartTitle = styled.h3(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var ChartContent = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  height: 400px;\n  background: #FFFFFF;\n  border-radius: 8px;\n  margin-bottom: 16px;\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  height: 400px;\n  background: #FFFFFF;\n  border-radius: 8px;\n  margin-bottom: 16px;\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var DetailButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  padding: 8px;\n  background: #F3F0FF;\n  border: none;\n  border-radius: 8px;\n  color: #7B61FF;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n"], ["\n  width: 100%;\n  padding: 8px;\n  background: #F3F0FF;\n  border: none;\n  border-radius: 8px;\n  color: #7B61FF;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n"])));
var CustomTooltip = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: rgba(255, 255, 255, 0.95);\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 12px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n\n  .label {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 14px;\n    color: #000;\n    margin-bottom: 4px;\n  }\n\n  .value {\n    font-family: 'Inter';\n    font-size: 13px;\n    color: #666;\n  }\n"], ["\n  background: rgba(255, 255, 255, 0.95);\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 12px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n\n  .label {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 14px;\n    color: #000;\n    margin-bottom: 4px;\n  }\n\n  .value {\n    font-family: 'Inter';\n    font-size: 13px;\n    color: #666;\n  }\n"])));
var COLORS = {
    primary: ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'],
    secondary: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
    accent: ['#8884d8', '#4CAF50', '#ff7300']
};
var CustomChartTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, label = _a.label;
    if (active && payload && payload.length) {
        return (React.createElement(CustomTooltip, null,
            React.createElement("p", { className: "label" }, label),
            React.createElement("p", { className: "value" }, "Value: ".concat(payload[0].value))));
    }
    return null;
};
var ChartType;
(function (ChartType) {
    ChartType["BAR"] = "BAR";
    ChartType["LINE"] = "LINE";
    ChartType["PIE"] = "PIE";
    ChartType["AREA"] = "AREA";
})(ChartType || (ChartType = {}));
var calculateYValue = function (data, xAxis, yConfig) {
    // Group data by x-axis values with explicit typing
    var groupedData = data.reduce(function (acc, row) {
        var xValue = String(row[xAxis]);
        if (!acc[xValue]) {
            acc[xValue] = [];
        }
        var yValue = Number(row[yConfig.column]);
        if (!isNaN(yValue)) {
            acc[xValue].push(yValue);
        }
        return acc;
    }, {});
    // Apply calculation for each group
    return Object.entries(groupedData).map(function (_a) {
        var label = _a[0], values = _a[1];
        var value = 0;
        switch (yConfig.calculation.toLowerCase()) {
            case 'sum':
                value = values.reduce(function (sum, v) { return sum + v; }, 0);
                break;
            case 'average':
                value = values.reduce(function (sum, v) { return sum + v; }, 0) / values.length;
                break;
            case 'count':
                value = values.length;
                break;
            case 'min':
                value = Math.min.apply(Math, values);
                break;
            case 'max':
                value = Math.max.apply(Math, values);
                break;
            default:
                value = values[0] || 0;
        }
        return { label: label, value: value };
    });
};
var LoadingState = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: 40px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 24px;\n"], ["\n  padding: 40px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 24px;\n"])));
var EmptyState = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  text-align: center;\n  padding: 60px 40px;\n  color: #666;\n  font-size: 16px;\n  background: #f8f9fa;\n  border-radius: 12px;\n  margin: 20px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n\n  svg {\n    width: 64px;\n    height: 64px;\n    color: #999;\n  }\n"], ["\n  text-align: center;\n  padding: 60px 40px;\n  color: #666;\n  font-size: 16px;\n  background: #f8f9fa;\n  border-radius: 12px;\n  margin: 20px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n\n  svg {\n    width: 64px;\n    height: 64px;\n    color: #999;\n  }\n"])));
var ErrorState = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  text-align: center;\n  padding: 40px;\n  color: #dc3545;\n  background: #fff3f3;\n  border-radius: 12px;\n  margin: 20px 0;\n"], ["\n  text-align: center;\n  padding: 40px;\n  color: #dc3545;\n  background: #fff3f3;\n  border-radius: 12px;\n  margin: 20px 0;\n"])));
var FavoritesDashboard = function () {
    var folderId = useParams().folderId;
    var _a = useState([]), questions = _a[0], setQuestions = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var navigate = useNavigate();
    useEffect(function () {
        fetchFavoriteQuestions();
    }, []);
    var fetchFavoriteQuestions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, errorData, data, questionsWithData, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, 7, 8]);
                    setError(null);
                    setLoading(true);
                    return [4 /*yield*/, fetch('/api/questions/favorites/', {
                            method: 'GET',
                            credentials: 'include',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || ''
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json().catch(function () { return ({}); })];
                case 2:
                    errorData = _a.sent();
                    throw new Error(errorData.error || 'Failed to fetch favorite questions');
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    data = _a.sent();
                    if (!data.questions) {
                        throw new Error('Invalid response format');
                    }
                    return [4 /*yield*/, Promise.all(data.questions.map(function (question) { return __awaiter(void 0, void 0, void 0, function () {
                            var _a, configResponse, analysisResponse, config, _b, analysisResult, _c, error_2;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        _d.trys.push([0, 8, , 9]);
                                        return [4 /*yield*/, Promise.all([
                                                fetch("/api/visualization-config/".concat(question.id, "/"), {
                                                    credentials: 'include',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'X-CSRFToken': getCookie('csrftoken') || ''
                                                    }
                                                }),
                                                fetch("/api/analysis-results/".concat(question.id, "/"), {
                                                    credentials: 'include',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'X-CSRFToken': getCookie('csrftoken') || ''
                                                    }
                                                })
                                            ])];
                                    case 1:
                                        _a = _d.sent(), configResponse = _a[0], analysisResponse = _a[1];
                                        if (!configResponse.ok) return [3 /*break*/, 3];
                                        return [4 /*yield*/, configResponse.json()];
                                    case 2:
                                        _b = _d.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        _b = null;
                                        _d.label = 4;
                                    case 4:
                                        config = _b;
                                        if (!analysisResponse.ok) return [3 /*break*/, 6];
                                        return [4 /*yield*/, analysisResponse.json()];
                                    case 5:
                                        _c = _d.sent();
                                        return [3 /*break*/, 7];
                                    case 6:
                                        _c = null;
                                        _d.label = 7;
                                    case 7:
                                        analysisResult = _c;
                                        return [2 /*return*/, {
                                                id: question.id,
                                                question: question.question,
                                                created_at: question.created_at,
                                                is_favorite: question.is_favorite,
                                                config: config,
                                                analysisResult: analysisResult
                                            }];
                                    case 8:
                                        error_2 = _d.sent();
                                        console.error("Error fetching details for question ".concat(question.id, ":"), error_2);
                                        return [2 /*return*/, {
                                                id: question.id,
                                                question: question.question,
                                                created_at: question.created_at,
                                                is_favorite: question.is_favorite,
                                                config: null,
                                                analysisResult: null
                                            }];
                                    case 9: return [2 /*return*/];
                                }
                            });
                        }); }))];
                case 5:
                    questionsWithData = _a.sent();
                    setQuestions(questionsWithData);
                    return [3 /*break*/, 8];
                case 6:
                    error_1 = _a.sent();
                    errorMessage = error_1 instanceof Error ? error_1.message : 'An unexpected error occurred';
                    console.error('Error fetching favorite questions:', error_1);
                    setError(errorMessage);
                    toast.error('Failed to load favorite questions');
                    return [3 /*break*/, 8];
                case 7:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var renderChart = function (questionData) {
        var config = questionData.config, analysisResult = questionData.analysisResult;
        if (!config || !(analysisResult === null || analysisResult === void 0 ? void 0 : analysisResult.full_data))
            return null;
        // Calculate chart data based on configuration
        var chartData = calculateYValue(analysisResult.full_data, config.x_axis, {
            column: config.y_axis_column,
            calculation: config.y_axis_calculation
        });
        // Sort data by value in descending order
        var sortedData = __spreadArray([], chartData, true).sort(function (a, b) { return b.value - a.value; });
        var commonProps = {
            data: sortedData,
            margin: { top: 20, right: 30, left: 20, bottom: 70 }
        };
        // Get Y-axis label based on calculation
        var getYAxisLabel = function () {
            var calc = config.y_axis_calculation.toLowerCase();
            var column = config.y_axis_column;
            return calc === 'none' ? column : "".concat(calc, " of ").concat(column);
        };
        switch (config.chart_type.toUpperCase()) {
            case 'LINE':
                return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                    React.createElement(LineChart, __assign({}, commonProps),
                        React.createElement(CartesianGrid, { strokeDasharray: "3 3", stroke: "#f0f0f0" }),
                        React.createElement(XAxis, { dataKey: "label", angle: -45, textAnchor: "end", height: 70, interval: 0, tick: { fontSize: 12 } }),
                        React.createElement(YAxis, { tick: { fontSize: 12 }, tickFormatter: function (value) { return value.toLocaleString(); }, label: { value: getYAxisLabel(), angle: -90, position: 'insideLeft' } }),
                        React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null) }),
                        React.createElement(Line, { type: "monotone", dataKey: "value", stroke: "#8884d8", strokeWidth: 2, dot: { fill: '#8884d8' } }))));
            case 'PIE':
                return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                    React.createElement(PieChart, null,
                        React.createElement(Pie, { data: sortedData, dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, label: function (_a) {
                                var name = _a.name, percent = _a.percent;
                                return "".concat(name, " (").concat((percent * 100).toFixed(0), "%)");
                            } }, sortedData.map(function (entry, index) { return (React.createElement(Cell, { key: "cell-".concat(index), fill: COLORS.primary[index % COLORS.primary.length] })); })),
                        React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null) }),
                        React.createElement(Legend, null))));
            case 'AREA':
                return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                    React.createElement(AreaChart, __assign({}, commonProps),
                        React.createElement("defs", null,
                            React.createElement("linearGradient", { id: "colorValue", x1: "0", y1: "0", x2: "0", y2: "1" },
                                React.createElement("stop", { offset: "5%", stopColor: "#8884d8", stopOpacity: 0.8 }),
                                React.createElement("stop", { offset: "95%", stopColor: "#8884d8", stopOpacity: 0.1 }))),
                        React.createElement(CartesianGrid, { strokeDasharray: "3 3", stroke: "#f0f0f0" }),
                        React.createElement(XAxis, { dataKey: "label", angle: -45, textAnchor: "end", height: 70, interval: 0, tick: { fontSize: 12 } }),
                        React.createElement(YAxis, { tick: { fontSize: 12 }, tickFormatter: function (value) { return value.toLocaleString(); }, label: { value: getYAxisLabel(), angle: -90, position: 'insideLeft' } }),
                        React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null) }),
                        React.createElement(Area, { type: "monotone", dataKey: "value", stroke: "#8884d8", fill: "url(#colorValue)" }))));
            case 'BAR':
            default:
                return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                    React.createElement(BarChart, __assign({}, commonProps),
                        React.createElement(CartesianGrid, { strokeDasharray: "3 3", stroke: "#f0f0f0" }),
                        React.createElement(XAxis, { dataKey: "label", angle: -45, textAnchor: "end", height: 70, interval: 0, tick: { fontSize: 12 } }),
                        React.createElement(YAxis, { tick: { fontSize: 12 }, tickFormatter: function (value) { return value.toLocaleString(); }, label: { value: getYAxisLabel(), angle: -90, position: 'insideLeft' } }),
                        React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null) }),
                        React.createElement(Bar, { dataKey: "value", fill: "#8884d8", radius: [4, 4, 0, 0] }, sortedData.map(function (entry, index) { return (React.createElement(Cell, { key: "cell-".concat(index), fill: COLORS.primary[index % COLORS.primary.length] })); })))));
        }
    };
    if (loading) {
        return (React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(Title, null,
                    React.createElement(Skeleton, { width: 300 })),
                React.createElement(ButtonGroup, null,
                    React.createElement(Skeleton, { width: 140, height: 44 }),
                    React.createElement(Skeleton, { width: 140, height: 44 }))),
            React.createElement(LoadingState, null, [1, 2, 3, 4].map(function (i) { return (React.createElement(ChartCard, { key: i },
                React.createElement(Skeleton, { height: 24, width: "80%", style: { marginBottom: '24px' } }),
                React.createElement(Skeleton, { height: 400, style: { marginBottom: '16px' } }),
                React.createElement(Skeleton, { height: 36 }))); }))));
    }
    if (error) {
        return (React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(Title, null, folderId === 'favorites' ? 'Favorite Questions' : 'Questions Analytics Dashboard')),
            React.createElement(ErrorState, null,
                React.createElement("h3", null, "Error Loading Dashboard"),
                React.createElement("p", null, error),
                React.createElement("button", { onClick: function () { return fetchFavoriteQuestions(); } }, "Try Again"))));
    }
    if (questions.length === 0) {
        return (React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(Title, null, "Favorite Questions")),
            React.createElement(EmptyState, null,
                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor" },
                    React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" })),
                React.createElement("p", null, "No favorite questions yet. Mark questions as favorites to see them here."),
                React.createElement("button", { onClick: function () { return navigate('/app/analysis'); } }, "Go to Analysis"))));
    }
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Title, null, "Favorite Questions"),
            React.createElement(ButtonGroup, null,
                React.createElement(ExportButton, { variant: "sheets" },
                    React.createElement("span", null),
                    " Export to Sheets"),
                React.createElement(ExportButton, { variant: "slides" },
                    React.createElement("span", null),
                    " Export to Slides"))),
        React.createElement(GridContainer, null, questions.map(function (questionData) { return (React.createElement(ChartCard, { key: questionData.id },
            React.createElement(ChartTitle, null, questionData.question),
            React.createElement(ChartContent, null, renderChart(questionData) || React.createElement("div", null, "No chart data available")),
            React.createElement(DetailButton, { onClick: function () { return navigate("/app/analysis/result/".concat(questionData.id)); } }, "View Detailed Analysis"))); }))));
};
export default FavoritesDashboard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
