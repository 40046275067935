var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie } from '../../utils/cookies';
import Modal from '../common/Modal';
import { ModalContent } from '../common/Modal';
import Form from '../common/Form';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var CompaniesSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var CreateButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  margin-bottom: 24px;\n  cursor: pointer;\n  font-family: 'Inter';\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  margin-bottom: 24px;\n  cursor: pointer;\n  font-family: 'Inter';\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var Table = styled.table(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n"])));
var Th = styled.th(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"], ["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"])));
var Td = styled.td(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"], ["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"])));
var CreditBar = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 8px;\n  background: ", ";\n  border-radius: 4px;\n  width: ", "%;\n  margin-top: 8px;\n  transition: width 0.3s ease;\n"], ["\n  height: 8px;\n  background: ", ";\n  border-radius: 4px;\n  width: ", "%;\n  margin-top: 8px;\n  transition: width 0.3s ease;\n"])), function (props) { return props.percentage > 90 ? '#EB564F' : '#8F4ACF'; }, function (props) { return Math.min(props.percentage, 100); });
var StatusBadge = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n"], ["\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n"])), function (props) { return props.status === 'active' ? '#E6F4EA' : '#FFF3E0'; }, function (props) { return props.status === 'active' ? '#137333' : '#B95000'; });
var ActionButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.variant === 'edit' ? '#F8F5FF' : '#E6F4EA'; }, function (props) { return props.variant === 'edit' ? '#8F4ACF' : '#137333'; }, function (props) { return props.variant === 'edit' ? '#ECEAFF' : '#CEEAD6'; }, function (props) { return props.variant === 'edit' ? '#F0E7FF' : '#D8F0DE'; });
var TextArea = styled.textarea(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  font-size: 14px;\n  min-height: 100px;\n  resize: vertical;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  font-size: 14px;\n  min-height: 100px;\n  resize: vertical;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var FormGroup = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-bottom: 16px;\n"], ["\n  margin-bottom: 16px;\n"])));
var Label = styled.label(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: 8px;\n  font-weight: 500;\n  \n  &::after {\n    content: ", ";\n    color: #dc3545;\n  }\n"], ["\n  display: block;\n  margin-bottom: 8px;\n  font-weight: 500;\n  \n  &::after {\n    content: ", ";\n    color: #dc3545;\n  }\n"])), function (props) { return props.required ? '" *"' : '""'; });
var Input = styled.input(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 100%;\n  padding: 8px 12px;\n  border: 1px solid ", ";\n  border-radius: 4px;\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n"], ["\n  width: 100%;\n  padding: 8px 12px;\n  border: 1px solid ", ";\n  border-radius: 4px;\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n"])), function (props) { return props.required && props['aria-invalid'] ? '#dc3545' : '#ECEAFF'; }, function (props) { return props.required && props['aria-invalid'] ? '#dc3545' : '#8F4ACF'; });
var ButtonGroup = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  padding-top: 24px;\n  border-top: 2px solid #F8F5FF;\n  margin-top: 24px;\n  background: white;\n  position: relative;\n  z-index: 1002;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  gap: 12px;\n  padding-top: 24px;\n  border-top: 2px solid #F8F5FF;\n  margin-top: 24px;\n  background: white;\n  position: relative;\n  z-index: 1002;\n"])));
var CancelButton = styled.button(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  background: white;\n  color: #666;\n  cursor: pointer;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n"], ["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  background: white;\n  color: #666;\n  cursor: pointer;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n"])));
var SaveButton = styled.button(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: 8px 16px;\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  padding: 8px 16px;\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var ToggleSwitch = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 16px;\n\n  input[type=\"checkbox\"] {\n    width: 40px;\n    height: 20px;\n    appearance: none;\n    background: #ddd;\n    border-radius: 20px;\n    position: relative;\n    cursor: pointer;\n    transition: background 0.3s;\n\n    &:checked {\n      background: #8F4ACF;\n    }\n\n    &:before {\n      content: '';\n      position: absolute;\n      width: 16px;\n      height: 16px;\n      background: white;\n      border-radius: 50%;\n      top: 2px;\n      left: 2px;\n      transition: left 0.3s;\n    }\n\n    &:checked:before {\n      left: 22px;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 16px;\n\n  input[type=\"checkbox\"] {\n    width: 40px;\n    height: 20px;\n    appearance: none;\n    background: #ddd;\n    border-radius: 20px;\n    position: relative;\n    cursor: pointer;\n    transition: background 0.3s;\n\n    &:checked {\n      background: #8F4ACF;\n    }\n\n    &:before {\n      content: '';\n      position: absolute;\n      width: 16px;\n      height: 16px;\n      background: white;\n      border-radius: 50%;\n      top: 2px;\n      left: 2px;\n      transition: left 0.3s;\n    }\n\n    &:checked:before {\n      left: 22px;\n    }\n  }\n"])));
var StyledModalContent = styled(ModalContent)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  max-width: 800px;\n  padding: 32px;\n  border-radius: 16px;\n  background: white;\n  position: relative;\n  overflow: visible;\n  z-index: 1001;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 80px;\n    background: white;\n    border-radius: 0 0 16px 16px;\n    z-index: 0;\n  }\n"], ["\n  max-width: 800px;\n  padding: 32px;\n  border-radius: 16px;\n  background: white;\n  position: relative;\n  overflow: visible;\n  z-index: 1001;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 80px;\n    background: white;\n    border-radius: 0 0 16px 16px;\n    z-index: 0;\n  }\n"])));
var ModalTitle = styled.h2(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin: 0 0 32px 0;\n  padding-bottom: 16px;\n  border-bottom: 2px solid #F8F5FF;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin: 0 0 32px 0;\n  padding-bottom: 16px;\n  border-bottom: 2px solid #F8F5FF;\n"])));
var ModalGrid = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n  margin-bottom: 24px;\n\n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n  margin-bottom: 24px;\n\n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"])));
var DeleteButton = styled.button(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  padding: 8px 16px;\n  background: #dc3545;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #c82333;\n  }\n"], ["\n  padding: 8px 16px;\n  background: #dc3545;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #c82333;\n  }\n"])));
var ModalWrapper = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  position: relative;\n  z-index: 1001;\n"], ["\n  position: relative;\n  z-index: 1001;\n"])));
var ErrorMessage = styled.span(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  color: #dc3545;\n  font-size: 12px;\n  margin-top: 4px;\n  display: block;\n"], ["\n  color: #dc3545;\n  font-size: 12px;\n  margin-top: 4px;\n  display: block;\n"])));
var ManageCompanies = function () {
    var _a = useState([]), companies = _a[0], setCompanies = _a[1];
    var _b = useState(false), isAddModalOpen = _b[0], setIsAddModalOpen = _b[1];
    var _c = useState(false), isEditModalOpen = _c[0], setIsEditModalOpen = _c[1];
    var _d = useState(null), selectedCompany = _d[0], setSelectedCompany = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState({
        name: '',
        credits: 20000,
        description: '',
        email: '',
        phone: '',
        website: '',
        is_active: true,
        address: ''
    }), formData = _f[0], setFormData = _f[1];
    var _g = useState({}), formErrors = _g[0], setFormErrors = _g[1];
    var navigate = useNavigate();
    var fetchCompanies = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, mappedCompanies, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.get('/api/companies/', {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            }
                        })];
                case 2:
                    response = _a.sent();
                    if (!Array.isArray(response.data)) {
                        console.error('Expected array of companies but got:', response.data);
                        setCompanies([]);
                        toast.error('Invalid response format from server', { id: 'fetch-companies' });
                        return [2 /*return*/];
                    }
                    mappedCompanies = response.data.map(function (company) { return ({
                        id: company.id,
                        name: company.name,
                        totalCredits: company.company_credits,
                        usedCredits: company.used_credits,
                        userCount: company.user_count,
                        departmentCount: company.department_count,
                        status: company.is_active ? 'active' : 'inactive',
                        description: company.description || '',
                        email: company.email || '',
                        phone: company.phone || '',
                        website: company.website || '',
                        is_active: company.is_active
                    }); });
                    setCompanies(mappedCompanies);
                    toast.success('Companies loaded successfully', { id: 'fetch-companies' });
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error fetching companies:', error_1);
                    setCompanies([]);
                    toast.error('Failed to load companies', { id: 'fetch-companies' });
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchCompanies();
    }, []);
    var validateForm = function () {
        var errors = {};
        if (!formData.name.trim()) {
            errors.name = 'Company name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        }
        else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Please enter a valid email address';
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required';
        }
        if (!formData.address.trim()) {
            errors.address = 'Address is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    var handleCreateCompany = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, newCompany, error_2, errorMessage;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        toast.error('Please fill in all required fields', { id: 'validation-error' });
                        return [2 /*return*/];
                    }
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post('/api/companies/', {
                            name: formData.name,
                            company_credits: formData.credits,
                            description: formData.description || '',
                            email: formData.email,
                            phone: formData.phone,
                            website: formData.website || '',
                            is_active: formData.is_active,
                            address: formData.address
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _d.sent();
                    if (response.data.success) {
                        newCompany = {
                            id: response.data.company.id,
                            name: response.data.company.name,
                            totalCredits: response.data.company.company_credits,
                            usedCredits: 0,
                            userCount: 0,
                            departmentCount: 0,
                            status: response.data.company.is_active ? 'active' : 'inactive',
                            description: response.data.company.description,
                            email: response.data.company.email,
                            phone: response.data.company.phone,
                            website: response.data.company.website,
                            is_active: response.data.company.is_active
                        };
                        setCompanies(__spreadArray(__spreadArray([], companies, true), [newCompany], false));
                        setIsAddModalOpen(false);
                        setFormData({
                            name: '',
                            credits: 20000,
                            description: '',
                            email: '',
                            phone: '',
                            website: '',
                            is_active: true,
                            address: ''
                        });
                        toast.success('Company created successfully', { id: 'create-company' });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _d.sent();
                    console.error('Error creating company:', error_2);
                    errorMessage = ((_b = (_a = error_2.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) ||
                        Object.values(((_c = error_2.response) === null || _c === void 0 ? void 0 : _c.data) || {}).flat().join(', ') ||
                        error_2.message;
                    toast.error("Failed to create company: ".concat(errorMessage), { id: 'create-company' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleEditCompany = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3, errorMessage;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    if (!selectedCompany)
                        return [2 /*return*/];
                    if (!validateForm()) {
                        toast.error('Please fill in all required fields', { id: 'validation-error' });
                        return [2 /*return*/];
                    }
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.put("/api/companies/".concat(selectedCompany.id, "/"), {
                            name: formData.name,
                            company_credits: formData.credits,
                            description: formData.description,
                            email: formData.email,
                            phone: formData.phone,
                            website: formData.website,
                            is_active: formData.is_active,
                            address: formData.address
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _d.sent();
                    if (response.data.success) {
                        setCompanies(companies.map(function (company) {
                            return company.id === selectedCompany.id
                                ? __assign(__assign({}, company), { name: formData.name, totalCredits: formData.credits, description: formData.description, email: formData.email, phone: formData.phone, website: formData.website, is_active: formData.is_active, status: formData.is_active ? 'active' : 'inactive', address: formData.address }) : company;
                        }));
                        setIsEditModalOpen(false);
                        setSelectedCompany(null);
                        toast.success('Company updated successfully', { id: "edit-company-".concat(selectedCompany.id) });
                    }
                    else {
                        throw new Error(response.data.error || 'Failed to update company');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _d.sent();
                    console.error('Error updating company:', error_3);
                    errorMessage = ((_b = (_a = error_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) ||
                        Object.values(((_c = error_3.response) === null || _c === void 0 ? void 0 : _c.data) || {}).flat().join(', ') ||
                        error_3.message;
                    toast.error("Failed to update company: ".concat(errorMessage), { id: "edit-company-".concat(selectedCompany.id) });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAssignUsers = function (companyId) {
        navigate("/app/settings/companies/".concat(companyId, "/assign-users"));
    };
    var handleDeleteCompany = function (companyId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.delete("/api/companies/".concat(companyId, "/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken')
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.data.success) {
                        setCompanies(companies.filter(function (company) { return company.id !== companyId; }));
                        setIsEditModalOpen(false);
                        setSelectedCompany(null);
                        toast.success('Company deleted successfully', { id: "delete-company-".concat(companyId) });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    console.error('Error deleting company:', error_4);
                    toast.error('Failed to delete company', { id: "delete-company-".concat(companyId) });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleClearQuestionFiles = function () { return __awaiter(void 0, void 0, void 0, function () {
        var csrfToken, response, errorData, error_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    csrfToken = (_a = document.cookie
                        .split('; ')
                        .find(function (row) { return row.startsWith('csrftoken='); })) === null || _a === void 0 ? void 0 : _a.split('=')[1];
                    return [4 /*yield*/, fetch('/api/files/clear-question-files/', {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': csrfToken || '',
                            },
                        })];
                case 1:
                    response = _b.sent();
                    if (!response.ok) return [3 /*break*/, 2];
                    toast.success('Question files cleared successfully');
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, response.json()];
                case 3:
                    errorData = _b.sent();
                    toast.error(errorData.message || 'Failed to clear question files');
                    _b.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_5 = _b.sent();
                    console.error('Error clearing question files:', error_5);
                    toast.error('An error occurred while clearing question files');
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var modalForm = (React.createElement(Form, { onSubmit: isAddModalOpen ? handleCreateCompany : handleEditCompany },
        React.createElement(ModalGrid, null,
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Company Name *"),
                React.createElement(Input, { type: "text", value: formData.name, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { name: e.target.value })); }, required: true }),
                formErrors.name && React.createElement(ErrorMessage, null, formErrors.name)),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Credits *"),
                React.createElement(Input, { type: "number", value: formData.credits, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { credits: parseInt(e.target.value) })); }, required: true })),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Email *"),
                React.createElement(Input, { type: "email", value: formData.email, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { email: e.target.value })); }, required: true }),
                formErrors.email && React.createElement(ErrorMessage, null, formErrors.email)),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Phone *"),
                React.createElement(Input, { type: "tel", value: formData.phone, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { phone: e.target.value })); }, required: true }),
                formErrors.phone && React.createElement(ErrorMessage, null, formErrors.phone))),
        React.createElement(FormGroup, null,
            React.createElement(Label, null, "Address *"),
            React.createElement(Input, { type: "text", value: formData.address, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { address: e.target.value })); }, required: true }),
            formErrors.address && React.createElement(ErrorMessage, null, formErrors.address)),
        React.createElement(FormGroup, null,
            React.createElement(Label, null, "Website"),
            React.createElement(Input, { type: "url", value: formData.website, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { website: e.target.value })); } })),
        React.createElement(ToggleSwitch, null,
            React.createElement(Label, null, "Company Status"),
            React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: '8px' } },
                React.createElement("input", { type: "checkbox", checked: formData.is_active, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { is_active: e.target.checked })); } }),
                React.createElement("span", { style: {
                        color: formData.is_active ? '#137333' : '#B95000',
                        fontWeight: 500
                    } }, formData.is_active ? 'Active' : 'Inactive'))),
        React.createElement(ButtonGroup, null,
            isEditModalOpen && (React.createElement(DeleteButton, { type: "button", onClick: function () { return selectedCompany && handleDeleteCompany(selectedCompany.id); } }, "Delete Company")),
            React.createElement(CancelButton, { type: "button", onClick: function () {
                    isAddModalOpen ? setIsAddModalOpen(false) : setIsEditModalOpen(false);
                    setSelectedCompany(null);
                } }, "Cancel"),
            React.createElement(SaveButton, { type: "submit", onClick: isAddModalOpen ? handleCreateCompany : handleEditCompany }, isAddModalOpen ? 'Create Company' : 'Save Changes'))));
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Manage Companies"),
        React.createElement(CompaniesSection, null,
            React.createElement("div", { style: { display: 'flex', marginBottom: '20px' } },
                React.createElement(CreateButton, { onClick: handleClearQuestionFiles, style: { marginRight: '10px' } }, "Clear Question Files"),
                React.createElement(CreateButton, { onClick: function () { return setIsAddModalOpen(true); } }, "Create New Company")),
            loading ? (React.createElement("div", null, "Loading companies...")) : companies.length === 0 ? (React.createElement("div", null, "No companies found")) : (React.createElement(Table, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement(Th, null, "Company Name"),
                        React.createElement(Th, null, "Credit Usage"),
                        React.createElement(Th, null, "Users"),
                        React.createElement(Th, null, "Departments"),
                        React.createElement(Th, null, "Status"),
                        React.createElement(Th, null, "Actions"))),
                React.createElement("tbody", null, companies.map(function (company) { return (React.createElement("tr", { key: company.id },
                    React.createElement(Td, null, company.name),
                    React.createElement(Td, null,
                        (company.usedCredits || 0).toLocaleString(),
                        " / ",
                        (company.totalCredits || 0).toLocaleString(),
                        React.createElement(CreditBar, { percentage: company.totalCredits ? ((company.usedCredits || 0) / company.totalCredits * 100) : 0 })),
                    React.createElement(Td, null, company.userCount || 0),
                    React.createElement(Td, null, company.departmentCount || 0),
                    React.createElement(Td, null,
                        React.createElement(StatusBadge, { status: company.status }, company.status.charAt(0).toUpperCase() + company.status.slice(1))),
                    React.createElement(Td, null,
                        React.createElement(ActionButton, { variant: "edit", onClick: function () {
                                setSelectedCompany(company);
                                setFormData({
                                    name: company.name,
                                    credits: company.totalCredits || 0,
                                    description: company.description || '',
                                    email: company.email || '',
                                    phone: company.phone || '',
                                    website: company.website || '',
                                    is_active: company.is_active,
                                    address: ''
                                });
                                setIsEditModalOpen(true);
                            } }, "Edit"),
                        React.createElement(ActionButton, { variant: "info", onClick: function () { return handleAssignUsers(company.id); } }, "Assign Users")))); })))),
            React.createElement(ModalWrapper, null,
                React.createElement(Modal, { isOpen: isAddModalOpen },
                    React.createElement(StyledModalContent, null,
                        React.createElement(ModalTitle, null, "Create New Company"),
                        modalForm))),
            React.createElement(ModalWrapper, null,
                React.createElement(Modal, { isOpen: isEditModalOpen },
                    React.createElement(StyledModalContent, null,
                        React.createElement(ModalTitle, null, "Edit Company"),
                        modalForm))))));
};
export default ManageCompanies;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;
