var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import StorageIcon from '@mui/icons-material/Storage';
import InteractiveLoading from '../Loading/InteractiveLoading';
var getCookie = function (name) {
    return Cookies.get(name) || '';
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding: 24px 0;\n"], ["\n  width: 100%;\n  padding: 24px 0;\n"])));
var QuestionSection = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 40px;\n  margin-bottom: 40px;\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);\n  position: relative;\n  min-height: 400px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 40px;\n  margin-bottom: 40px;\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);\n  position: relative;\n  min-height: 400px;\n"])));
var Label = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  margin-bottom: 16px;\n  color: #000000;\n"], ["\n  display: block;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  margin-bottom: 16px;\n  color: #000000;\n"])));
var TextArea = styled.textarea(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 180px;\n  background: #FAFAFA;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  resize: vertical;\n  transition: all 0.2s ease;\n  position: relative;\n  z-index: 1;\n  line-height: 24px;\n\n  &::placeholder {\n    color: #A6A6A6;\n  }\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n    background: #FFFFFF;\n  }\n"], ["\n  width: 100%;\n  height: 180px;\n  background: #FAFAFA;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  resize: vertical;\n  transition: all 0.2s ease;\n  position: relative;\n  z-index: 1;\n  line-height: 24px;\n\n  &::placeholder {\n    color: #A6A6A6;\n  }\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n    background: #FFFFFF;\n  }\n"])));
var ModelSelect = styled.select(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 52px;\n  background: #FAFAFA;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  margin-bottom: 32px;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n    background: #FFFFFF;\n  }\n"], ["\n  width: 100%;\n  height: 52px;\n  background: #FAFAFA;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  margin-bottom: 32px;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n    background: #FFFFFF;\n  }\n"])));
var ButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
var AnalyzeButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 180px;\n  height: 52px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  transition: background-color 0.2s ease;\n\n  &:hover {\n    background: #7B3AB2;\n  }\n\n  &:disabled {\n    background: #E0E0E0;\n    cursor: not-allowed;\n  }\n"], ["\n  width: 180px;\n  height: 52px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  transition: background-color 0.2s ease;\n\n  &:hover {\n    background: #7B3AB2;\n  }\n\n  &:disabled {\n    background: #E0E0E0;\n    cursor: not-allowed;\n  }\n"])));
var ResponseSection = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 40px;\n  margin-top: 20px;\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 40px;\n  margin-top: 20px;\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);\n"])));
var ResponseText = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 15px;\n  line-height: 1.6;\n  color: #000000;\n  white-space: pre-wrap;\n  background: #FAFAFA;\n  padding: 20px;\n  border-radius: 12px;\n"], ["\n  font-family: 'Inter';\n  font-size: 15px;\n  line-height: 1.6;\n  color: #000000;\n  white-space: pre-wrap;\n  background: #FAFAFA;\n  padding: 20px;\n  border-radius: 12px;\n"])));
var pulse = keyframes(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  0% { transform: scale(1); opacity: 0.5; }\n  50% { transform: scale(1.05); opacity: 1; }\n  100% { transform: scale(1); opacity: 0.5; }\n"], ["\n  0% { transform: scale(1); opacity: 0.5; }\n  50% { transform: scale(1.05); opacity: 1; }\n  100% { transform: scale(1); opacity: 0.5; }\n"])));
var rotate = keyframes(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n"], ["\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n"])));
var wave = keyframes(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  0%, 100% { transform: translateY(0); }\n  50% { transform: translateY(-10px); }\n"], ["\n  0%, 100% { transform: translateY(0); }\n  50% { transform: translateY(-10px); }\n"])));
var shimmer = keyframes(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  0% { background-position: -1000px 0; }\n  100% { background-position: 1000px 0; }\n"], ["\n  0% { background-position: -1000px 0; }\n  100% { background-position: 1000px 0; }\n"])));
var float = keyframes(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  0% { transform: translateY(0px) rotate(0deg); }\n  50% { transform: translateY(-20px) rotate(5deg); }\n  100% { transform: translateY(0px) rotate(0deg); }\n"], ["\n  0% { transform: translateY(0px) rotate(0deg); }\n  50% { transform: translateY(-20px) rotate(5deg); }\n  100% { transform: translateY(0px) rotate(0deg); }\n"])));
var glow = keyframes(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  0% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n  50% { box-shadow: 0 0 20px rgba(143, 74, 207, 0.6); }\n  100% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n"], ["\n  0% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n  50% { box-shadow: 0 0 20px rgba(143, 74, 207, 0.6); }\n  100% { box-shadow: 0 0 5px rgba(143, 74, 207, 0.2); }\n"])));
var LoadingContainer = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: rgba(255, 255, 255, 0.95);\n  border-radius: 20px;\n  backdrop-filter: blur(5px);\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: rgba(255, 255, 255, 0.95);\n  border-radius: 20px;\n  backdrop-filter: blur(5px);\n"])));
var LoadingIcon = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 200px;\n  height: 200px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  animation: ", " 3s ease-in-out infinite;\n  position: relative;\n  margin-bottom: 30px;\n  filter: drop-shadow(0 0 20px rgba(143, 74, 207, 0.3));\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    animation: ", " 2s ease-in-out infinite;\n  }\n"], ["\n  width: 200px;\n  height: 200px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  animation: ", " 3s ease-in-out infinite;\n  position: relative;\n  margin-bottom: 30px;\n  filter: drop-shadow(0 0 20px rgba(143, 74, 207, 0.3));\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    animation: ", " 2s ease-in-out infinite;\n  }\n"])), float, pulse);
var LoadingText = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 20px;\n  font-weight: 500;\n  color: #8F4ACF;\n  margin-bottom: 20px;\n  animation: ", " 2s ease-in-out infinite;\n  text-align: center;\n"], ["\n  font-family: 'Inter';\n  font-size: 20px;\n  font-weight: 500;\n  color: #8F4ACF;\n  margin-bottom: 20px;\n  animation: ", " 2s ease-in-out infinite;\n  text-align: center;\n"])), pulse);
var LoadingProgress = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: 200px;\n  height: 4px;\n  background: #F0E7FF;\n  border-radius: 2px;\n  overflow: hidden;\n  position: relative;\n\n  &::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 30%;\n    background: #8F4ACF;\n    border-radius: 2px;\n    animation: progress 1.5s ease-in-out infinite;\n  }\n\n  @keyframes progress {\n    0% { left: -30%; }\n    100% { left: 100%; }\n  }\n"], ["\n  width: 200px;\n  height: 4px;\n  background: #F0E7FF;\n  border-radius: 2px;\n  overflow: hidden;\n  position: relative;\n\n  &::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 30%;\n    background: #8F4ACF;\n    border-radius: 2px;\n    animation: progress 1.5s ease-in-out infinite;\n  }\n\n  @keyframes progress {\n    0% { left: -30%; }\n    100% { left: 100%; }\n  }\n"])));
var FactContainer = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin-top: 30px;\n  padding: 16px 24px;\n  background: #F8F5FF;\n  border-radius: 12px;\n  max-width: 80%;\n  text-align: center;\n  animation: ", " 3s ease-in-out infinite;\n  transition: transform 0.3s ease;\n\n  &:hover {\n    transform: translateY(-2px);\n  }\n"], ["\n  margin-top: 30px;\n  padding: 16px 24px;\n  background: #F8F5FF;\n  border-radius: 12px;\n  max-width: 80%;\n  text-align: center;\n  animation: ", " 3s ease-in-out infinite;\n  transition: transform 0.3s ease;\n\n  &:hover {\n    transform: translateY(-2px);\n  }\n"])), glow);
var FactText = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #6B2E9E;\n  line-height: 1.5;\n  font-weight: 500;\n"], ["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #6B2E9E;\n  line-height: 1.5;\n  font-weight: 500;\n"])));
var QuestionContainer = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 32px;\n"], ["\n  position: relative;\n  margin-bottom: 32px;\n"])));
var LoadingVector = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  position: absolute;\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background: ", ";\n  animation: ", " 1.2s ease-in-out infinite;\n  animation-delay: ", "s;\n  transform-origin: center center;\n  \n  &:hover {\n    animation: ", " 0.6s ease-in-out infinite;\n    transform: scale(1.2);\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n    background: inherit;\n    opacity: 0.3;\n    filter: blur(2px);\n    animation: ", " 1.2s ease-in-out infinite;\n    animation-delay: ", "s;\n  }\n"], ["\n  position: absolute;\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background: ", ";\n  animation: ", " 1.2s ease-in-out infinite;\n  animation-delay: ", "s;\n  transform-origin: center center;\n  \n  &:hover {\n    animation: ", " 0.6s ease-in-out infinite;\n    transform: scale(1.2);\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n    background: inherit;\n    opacity: 0.3;\n    filter: blur(2px);\n    animation: ", " 1.2s ease-in-out infinite;\n    animation-delay: ", "s;\n  }\n"])), function (props) { return props.color; }, wave, function (props) { return props.index * 0.1; }, pulse, pulse, function (props) { return props.index * 0.1; });
var EstimatedQuerySection = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n"], ["\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 32px;\n"])));
var QueryTitle = styled.h2(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var CostInfo = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 20px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 20px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var QueryBox = styled.textarea(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 200px;\n  background: #FAFAFA;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Monaco', monospace;\n  font-size: 14px;\n  line-height: 1.5;\n  color: #4A5568;\n  resize: vertical;\n  margin-top: 12px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n    background: #FFFFFF;\n  }\n"], ["\n  width: 100%;\n  min-height: 200px;\n  background: #FAFAFA;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Monaco', monospace;\n  font-size: 14px;\n  line-height: 1.5;\n  color: #4A5568;\n  resize: vertical;\n  margin-top: 12px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n    background: #FFFFFF;\n  }\n"])));
var CopyButton = styled.button(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  position: absolute;\n  top: -8px;\n  right: 0;\n  width: 81px;\n  height: 38px;\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  color: #8F4ACF;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n"], ["\n  position: absolute;\n  top: -8px;\n  right: 0;\n  width: 81px;\n  height: 38px;\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  color: #8F4ACF;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n"])));
var FeedbackButtons = styled.div(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n  margin: 24px 0;\n"], ["\n  display: flex;\n  gap: 12px;\n  margin: 24px 0;\n"])));
var FeedbackButton = styled.button(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  padding: 8px 24px;\n  border-radius: 16px;\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  cursor: ", ";\n  opacity: ", ";\n  transition: all 0.2s ease;\n\n  ", "\n\n  &:hover {\n    ", "\n  }\n"], ["\n  padding: 8px 24px;\n  border-radius: 16px;\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  cursor: ", ";\n  opacity: ", ";\n  transition: all 0.2s ease;\n\n  ", "\n\n  &:hover {\n    ", "\n  }\n"])), function (props) { return props.disabled ? 'not-allowed' : 'pointer'; }, function (props) { return props.disabled ? 0.6 : 1; }, function (props) {
    switch (props.variant) {
        case 'correct':
            return "\n          background: #EDFBF2;\n          border: 1px solid #7AD28C;\n          color: #7AD28C;\n        ";
        case 'adjust':
            return "\n          background: #FEF8E0;\n          border: 1px solid #F6C763;\n          color: #F6C763;\n        ";
        case 'false':
            return "\n          background: #FFE8E7;\n          border: 1px solid #EB564F;\n          color: #EB564F;\n        ";
    }
}, function (props) { return !props.disabled && "\n      transform: translateY(-1px);\n      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    "; });
var ConfirmationDialog = styled.div(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #E8E8E8;\n  border-radius: 12px;\n  padding: 24px;\n  text-align: center;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #E8E8E8;\n  border-radius: 12px;\n  padding: 24px;\n  text-align: center;\n"])));
var DialogButtons = styled.div(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n  justify-content: center;\n  margin-top: 16px;\n"], ["\n  display: flex;\n  gap: 12px;\n  justify-content: center;\n  margin-top: 16px;\n"])));
var ProceedButton = styled.button(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  padding: 12px 24px;\n  background: #000000;\n  border-radius: 8px;\n  color: #FFFFFF;\n  font-family: 'Inter';\n  font-size: 14px;\n  border: none;\n  cursor: pointer;\n\n  &:hover {\n    background: #1A1A1A;\n  }\n"], ["\n  padding: 12px 24px;\n  background: #000000;\n  border-radius: 8px;\n  color: #FFFFFF;\n  font-family: 'Inter';\n  font-size: 14px;\n  border: none;\n  cursor: pointer;\n\n  &:hover {\n    background: #1A1A1A;\n  }\n"])));
var CancelButton = styled.button(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  padding: 12px 24px;\n  background: #FFFFFF;\n  border: 1px solid #E2E8F0;\n  border-radius: 8px;\n  color: #4A5568;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n\n  &:hover {\n    background: #F7FAFC;\n  }\n"], ["\n  padding: 12px 24px;\n  background: #FFFFFF;\n  border: 1px solid #E2E8F0;\n  border-radius: 8px;\n  color: #4A5568;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n\n  &:hover {\n    background: #F7FAFC;\n  }\n"])));
var QueryQuestion = styled.div(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n  margin: 24px 0 16px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n  margin: 24px 0 16px;\n"])));
var QueryContainer = styled.div(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 24px;\n  padding-top: 8px;\n"], ["\n  position: relative;\n  margin-bottom: 24px;\n  padding-top: 8px;\n"])));
var EditQueryText = styled.div(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 16px;\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 16px;\n"])));
var MiniExplorerContainer = styled.div(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n  margin-top: 16px;\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  padding: 20px;\n  max-height: 300px;\n  overflow-y: auto;\n"], ["\n  margin-top: 16px;\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  padding: 20px;\n  max-height: 300px;\n  overflow-y: auto;\n"])));
var MiniExplorerHeader = styled.div(templateObject_39 || (templateObject_39 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"])));
var MiniExplorerTitle = styled.h3(templateObject_40 || (templateObject_40 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n  margin: 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n  margin: 0;\n"])));
var TableAccordion = styled(Accordion)(templateObject_41 || (templateObject_41 = __makeTemplateObject(["\n  margin-bottom: 8px !important;\n  box-shadow: none !important;\n  border: 1px solid #F0F0F0 !important;\n  border-radius: 8px !important;\n  \n  &:before {\n    display: none !important;\n  }\n"], ["\n  margin-bottom: 8px !important;\n  box-shadow: none !important;\n  border: 1px solid #F0F0F0 !important;\n  border-radius: 8px !important;\n  \n  &:before {\n    display: none !important;\n  }\n"])));
var TableName = styled.span(templateObject_42 || (templateObject_42 = __makeTemplateObject(["\n  font-weight: 500;\n  margin-right: 8px;\n"], ["\n  font-weight: 500;\n  margin-right: 8px;\n"])));
var ColumnChip = styled.div(templateObject_43 || (templateObject_43 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 4px 12px;\n  margin: 4px;\n  font-size: 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F0E7FF;\n    transform: translateY(-1px);\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 4px 12px;\n  margin: 4px;\n  font-size: 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F0E7FF;\n    transform: translateY(-1px);\n  }\n"])));
var SearchInput = styled.input(templateObject_44 || (templateObject_44 = __makeTemplateObject(["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-size: 14px;\n  width: 200px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-size: 14px;\n  width: 200px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var SplitLayout = styled.div(templateObject_45 || (templateObject_45 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: ", ";\n  gap: 24px;\n  max-width: ", ";\n  margin: 0 auto;\n  padding: 0 24px;\n  transition: all 0.3s ease;\n"], ["\n  display: grid;\n  grid-template-columns: ", ";\n  gap: 24px;\n  max-width: ", ";\n  margin: 0 auto;\n  padding: 0 24px;\n  transition: all 0.3s ease;\n"])), function (props) { return props.isExplorerOpen ? '1fr 380px' : '1fr'; }, function (props) { return props.isExplorerOpen ? '1600px' : '1200px'; });
var DataExplorerPanel = styled.div(templateObject_46 || (templateObject_46 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  height: calc(100vh - 100px);\n  position: sticky;\n  top: 20px;\n  overflow-y: auto;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  height: calc(100vh - 100px);\n  position: sticky;\n  top: 20px;\n  overflow-y: auto;\n"])));
var DataPreviewModal = styled.div(templateObject_47 || (templateObject_47 = __makeTemplateObject(["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: #FFFFFF;\n  border-radius: 20px;\n  padding: 32px;\n  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);\n  max-width: 800px;\n  width: 90%;\n  max-height: 80vh;\n  overflow-y: auto;\n  z-index: 10000;\n  animation: modalFadeIn 0.3s ease;\n\n  @keyframes modalFadeIn {\n    from {\n      opacity: 0;\n      transform: translate(-50%, -48%);\n    }\n    to {\n      opacity: 1;\n      transform: translate(-50%, -50%);\n    }\n  }\n"], ["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: #FFFFFF;\n  border-radius: 20px;\n  padding: 32px;\n  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);\n  max-width: 800px;\n  width: 90%;\n  max-height: 80vh;\n  overflow-y: auto;\n  z-index: 10000;\n  animation: modalFadeIn 0.3s ease;\n\n  @keyframes modalFadeIn {\n    from {\n      opacity: 0;\n      transform: translate(-50%, -48%);\n    }\n    to {\n      opacity: 1;\n      transform: translate(-50%, -50%);\n    }\n  }\n"])));
var ModalOverlay = styled.div(templateObject_48 || (templateObject_48 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.75);\n  z-index: 9999;\n  backdrop-filter: blur(4px);\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.75);\n  z-index: 9999;\n  backdrop-filter: blur(4px);\n"])));
var PreviewTable = styled.table(templateObject_49 || (templateObject_49 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n\n  th, td {\n    padding: 12px;\n    border-bottom: 1px solid #ECEAFF;\n    text-align: left;\n  }\n\n  th {\n    background: #F8F5FF;\n    font-weight: 600;\n  }\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n\n  th, td {\n    padding: 12px;\n    border-bottom: 1px solid #ECEAFF;\n    text-align: left;\n  }\n\n  th {\n    background: #F8F5FF;\n    font-weight: 600;\n  }\n"])));
var TableHeader = styled.div(templateObject_50 || (templateObject_50 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"])));
var ModalCloseButton = styled.button(templateObject_51 || (templateObject_51 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  color: #666;\n  padding: 4px;\n  \n  &:hover {\n    color: #000;\n  }\n"], ["\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  color: #666;\n  padding: 4px;\n  \n  &:hover {\n    color: #000;\n  }\n"])));
var ExplorerCloseButton = styled.button(templateObject_52 || (templateObject_52 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  color: #666;\n  cursor: pointer;\n  padding: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    color: #000;\n  }\n\n  svg {\n    font-size: 20px;\n  }\n"], ["\n  background: none;\n  border: none;\n  color: #666;\n  cursor: pointer;\n  padding: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    color: #000;\n  }\n\n  svg {\n    font-size: 20px;\n  }\n"])));
var DataExplorerToggle = styled.button(templateObject_53 || (templateObject_53 = __makeTemplateObject(["\n  position: fixed;\n  right: 24px;\n  bottom: 120px;\n  width: 40px;\n  height: 40px;\n  border-radius: 20px;\n  background: #8F4ACF;\n  border: none;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  box-shadow: 0 2px 8px rgba(143, 74, 207, 0.25);\n  z-index: 101;\n\n  &:hover {\n    background: #7B3AAF;\n    transform: scale(1.05);\n  }\n\n  svg {\n    font-size: 20px;\n  }\n"], ["\n  position: fixed;\n  right: 24px;\n  bottom: 120px;\n  width: 40px;\n  height: 40px;\n  border-radius: 20px;\n  background: #8F4ACF;\n  border: none;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  box-shadow: 0 2px 8px rgba(143, 74, 207, 0.25);\n  z-index: 101;\n\n  &:hover {\n    background: #7B3AAF;\n    transform: scale(1.05);\n  }\n\n  svg {\n    font-size: 20px;\n  }\n"])));
var DataExplorerPanelWrapper = styled.div(templateObject_54 || (templateObject_54 = __makeTemplateObject(["\n  position: fixed;\n  right: ", ";\n  top: 100px;\n  width: 380px;\n  height: calc(100vh - 160px);\n  background: #FFFFFF;\n  border-left: 1px solid #ECEAFF;\n  border-radius: 16px 0 0 16px;\n  transition: right 0.3s ease;\n  z-index: 100;\n  box-shadow: ", ";\n  overflow-y: auto;\n"], ["\n  position: fixed;\n  right: ", ";\n  top: 100px;\n  width: 380px;\n  height: calc(100vh - 160px);\n  background: #FFFFFF;\n  border-left: 1px solid #ECEAFF;\n  border-radius: 16px 0 0 16px;\n  transition: right 0.3s ease;\n  z-index: 100;\n  box-shadow: ", ";\n  overflow-y: auto;\n"])), function (props) { return props.isOpen ? '0' : '-380px'; }, function (props) { return props.isOpen ? '-4px 0 20px rgba(0, 0, 0, 0.1)' : 'none'; });
var DataExplorerHeader = styled.div(templateObject_55 || (templateObject_55 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  border-bottom: 1px solid #ECEAFF;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  border-bottom: 1px solid #ECEAFF;\n"])));
var DataExplorerTitle = styled.h3(templateObject_56 || (templateObject_56 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin: 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin: 0;\n"])));
var CloseButton = styled.button(templateObject_57 || (templateObject_57 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  color: #666;\n  padding: 4px;\n  \n  &:hover {\n    color: #000;\n  }\n"], ["\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  color: #666;\n  padding: 4px;\n  \n  &:hover {\n    color: #000;\n  }\n"])));
var StyledButton = styled.button(templateObject_58 || (templateObject_58 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 8px 16px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 8px 16px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n"])));
var AskQuestionsButton = styled(StyledButton)(templateObject_59 || (templateObject_59 = __makeTemplateObject(["\n  margin-top: 8px;\n  width: 100%;\n  background: #8F4ACF;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  margin-top: 8px;\n  width: 100%;\n  background: #8F4ACF;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var DatasetHeader = styled.div(templateObject_60 || (templateObject_60 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  width: 100%;\n"])));
var DatasetTitle = styled(Typography)(templateObject_61 || (templateObject_61 = __makeTemplateObject(["\n  font-weight: 600;\n  flex: 1;\n"], ["\n  font-weight: 600;\n  flex: 1;\n"])));
var IconButton = styled.button(templateObject_62 || (templateObject_62 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  padding: 6px;\n  cursor: pointer;\n  color: #8F4ACF;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.1);\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n\n  svg {\n    font-size: 20px;\n  }\n"], ["\n  background: none;\n  border: none;\n  padding: 6px;\n  cursor: pointer;\n  color: #8F4ACF;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.1);\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n\n  svg {\n    font-size: 20px;\n  }\n"])));
var AutocompleteContainer = styled.div(templateObject_63 || (templateObject_63 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  margin-bottom: 32px;\n"], ["\n  position: relative;\n  width: 100%;\n  margin-bottom: 32px;\n"])));
var SuggestionsWrapper = styled.div(templateObject_64 || (templateObject_64 = __makeTemplateObject(["\n  position: absolute;\n  left: 16px;\n  right: 16px;\n  top: ", "px;\n  z-index: 2;\n  pointer-events: none;\n  max-width: 320px;\n"], ["\n  position: absolute;\n  left: 16px;\n  right: 16px;\n  top: ", "px;\n  z-index: 2;\n  pointer-events: none;\n  max-width: 320px;\n"])), function (props) { return props.top; });
var SuggestionsContainer = styled.div(templateObject_65 || (templateObject_65 = __makeTemplateObject(["\n  position: relative;\n  background: rgba(255, 255, 255, 0.92);\n  backdrop-filter: blur(8px);\n  border-radius: 6px;\n  box-shadow: 0 2px 12px rgba(143, 74, 207, 0.08);\n  max-height: 180px;\n  overflow-y: auto;\n  pointer-events: auto;\n  border: 1px solid rgba(143, 74, 207, 0.15);\n  margin-top: 4px;\n  \n  &::-webkit-scrollbar {\n    width: 4px;\n    height: 4px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: rgba(143, 74, 207, 0.3);\n    border-radius: 2px;\n    \n    &:hover {\n      background: rgba(143, 74, 207, 0.5);\n    }\n  }\n"], ["\n  position: relative;\n  background: rgba(255, 255, 255, 0.92);\n  backdrop-filter: blur(8px);\n  border-radius: 6px;\n  box-shadow: 0 2px 12px rgba(143, 74, 207, 0.08);\n  max-height: 180px;\n  overflow-y: auto;\n  pointer-events: auto;\n  border: 1px solid rgba(143, 74, 207, 0.15);\n  margin-top: 4px;\n  \n  &::-webkit-scrollbar {\n    width: 4px;\n    height: 4px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: rgba(143, 74, 207, 0.3);\n    border-radius: 2px;\n    \n    &:hover {\n      background: rgba(143, 74, 207, 0.5);\n    }\n  }\n"])));
var SuggestionItem = styled.div(templateObject_66 || (templateObject_66 = __makeTemplateObject(["\n  padding: 8px 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n  background: ", ";\n  border-left: 2px solid ", ";\n\n  &:not(:last-child) {\n    border-bottom: 1px solid rgba(143, 74, 207, 0.08);\n  }\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.05);\n    border-left-color: rgba(143, 74, 207, 0.5);\n  }\n\n  &:active {\n    background: rgba(143, 74, 207, 0.1);\n  }\n\n  &:first-child {\n    padding-top: 12px;\n  }\n\n  &:last-child {\n    padding-bottom: 12px;\n  }\n"], ["\n  padding: 8px 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n  background: ", ";\n  border-left: 2px solid ", ";\n\n  &:not(:last-child) {\n    border-bottom: 1px solid rgba(143, 74, 207, 0.08);\n  }\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.05);\n    border-left-color: rgba(143, 74, 207, 0.5);\n  }\n\n  &:active {\n    background: rgba(143, 74, 207, 0.1);\n  }\n\n  &:first-child {\n    padding-top: 12px;\n  }\n\n  &:last-child {\n    padding-bottom: 12px;\n  }\n"])), function (props) { return props.isSelected ? 'rgba(143, 74, 207, 0.08)' : 'transparent'; }, function (props) { return props.isSelected ? '#8F4ACF' : 'transparent'; });
var SuggestionTitle = styled.div(templateObject_67 || (templateObject_67 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 13px;\n  color: #2D3748;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  &::before {\n    content: '';\n    display: inline-block;\n    width: 4px;\n    height: 4px;\n    background: rgba(143, 74, 207, 0.6);\n    border-radius: 50%;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 13px;\n  color: #2D3748;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  &::before {\n    content: '';\n    display: inline-block;\n    width: 4px;\n    height: 4px;\n    background: rgba(143, 74, 207, 0.6);\n    border-radius: 50%;\n  }\n"])));
var SuggestionMeta = styled.div(templateObject_68 || (templateObject_68 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 11px;\n  color: #718096;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  span {\n    display: inline-flex;\n    align-items: center;\n    padding: 1px 6px;\n    background: rgba(143, 74, 207, 0.08);\n    border-radius: 3px;\n    color: #8F4ACF;\n    font-size: 10px;\n    font-weight: 500;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 11px;\n  color: #718096;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  span {\n    display: inline-flex;\n    align-items: center;\n    padding: 1px 6px;\n    background: rgba(143, 74, 207, 0.08);\n    border-radius: 3px;\n    color: #8F4ACF;\n    font-size: 10px;\n    font-weight: 500;\n  }\n"])));
var AutocompleteTextArea = function (_a) {
    var value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder, datasets = _a.datasets;
    var _b = useState([]), suggestions = _b[0], setSuggestions = _b[1];
    var _c = useState(-1), selectedIndex = _c[0], setSelectedIndex = _c[1];
    var _d = useState(0), cursorPosition = _d[0], setCursorPosition = _d[1];
    var _e = useState(0), suggestionPosition = _e[0], setSuggestionPosition = _e[1];
    var textAreaRef = useRef(null);
    var _f = useState(false), isFocused = _f[0], setIsFocused = _f[1];
    var allOptions = useMemo(function () {
        var options = [];
        datasets.forEach(function (dataset) {
            Object.entries(dataset.data.tables).forEach(function (_a) {
                var tableName = _a[0], table = _a[1];
                table.columns.forEach(function (column) {
                    options.push({
                        label: "".concat(tableName, ".").concat(column.name),
                        value: "".concat(tableName, ".").concat(column.name),
                        type: column.type,
                        description: column.description
                    });
                });
            });
        });
        return options;
    }, [datasets]);
    var getCurrentWord = function (text, position) {
        var beforeCursor = text.slice(0, position);
        var words = beforeCursor.split(/\s/);
        return words[words.length - 1];
    };
    var calculateSuggestionPosition = function () {
        if (!textAreaRef.current)
            return;
        var textarea = textAreaRef.current;
        var text = textarea.value;
        var cursorPosition = textarea.selectionStart || 0;
        var textBeforeCursor = text.substring(0, cursorPosition);
        var lines = textBeforeCursor.split('\n');
        var currentLine = lines[lines.length - 1];
        var lineHeight = 24;
        var paddingTop = 16;
        var currentLineNumber = lines.length;
        var charWidth = 8;
        var horizontalPosition = (currentLine.length * charWidth) + 16;
        var scrollTop = textarea.scrollTop;
        var verticalPosition = (currentLineNumber * lineHeight) - scrollTop + paddingTop;
        setSuggestionPosition(verticalPosition);
    };
    var handleInputChange = function (e) {
        var newValue = e.target.value;
        var newPosition = e.target.selectionStart || 0;
        onChange(newValue);
        setCursorPosition(newPosition);
        calculateSuggestionPosition();
        var currentWord = getCurrentWord(newValue, newPosition);
        if (currentWord.length > 0) {
            var filtered = allOptions.filter(function (option) {
                return option.value.toLowerCase().includes(currentWord.toLowerCase());
            });
            setSuggestions(filtered.slice(0, 5));
            setSelectedIndex(-1);
        }
        else {
            setSuggestions([]);
        }
    };
    var handleKeyDown = function (e) {
        if (suggestions.length === 0)
            return;
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedIndex(function (prev) { return (prev + 1) % suggestions.length; });
        }
        else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedIndex(function (prev) { return (prev - 1 + suggestions.length) % suggestions.length; });
        }
        else if (e.key === 'Enter' && selectedIndex !== -1) {
            e.preventDefault();
            handleSuggestionClick(suggestions[selectedIndex]);
        }
        else if (e.key === 'Escape') {
            setSuggestions([]);
        }
    };
    var handleSuggestionClick = function (suggestion) {
        if (textAreaRef.current) {
            var beforeCursor = value.slice(0, cursorPosition);
            var afterCursor = value.slice(cursorPosition);
            var words = beforeCursor.split(/\s/);
            words[words.length - 1] = suggestion.value;
            var newValue = __spreadArray(__spreadArray([], words, true), [afterCursor], false).join(' ');
            onChange(newValue);
            setSuggestions([]);
            textAreaRef.current.focus();
        }
    };
    useEffect(function () {
        var textarea = textAreaRef.current;
        if (!textarea)
            return;
        var handleScroll = function () { return calculateSuggestionPosition(); };
        textarea.addEventListener('scroll', handleScroll);
        return function () {
            textarea.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (React.createElement(AutocompleteContainer, null,
        React.createElement(TextArea, { ref: textAreaRef, value: value, onChange: handleInputChange, onKeyDown: handleKeyDown, onFocus: function (e) {
                setIsFocused(true);
                calculateSuggestionPosition();
            }, onBlur: function () {
                setTimeout(function () { return setIsFocused(false); }, 200);
            }, onClick: calculateSuggestionPosition, onSelect: calculateSuggestionPosition, placeholder: placeholder }),
        suggestions.length > 0 && isFocused && (React.createElement(SuggestionsWrapper, { top: suggestionPosition },
            React.createElement(SuggestionsContainer, null, suggestions.map(function (suggestion, index) { return (React.createElement(SuggestionItem, { key: suggestion.value, isSelected: index === selectedIndex, onClick: function () { return handleSuggestionClick(suggestion); } },
                React.createElement(SuggestionTitle, null, suggestion.label),
                React.createElement(SuggestionMeta, null,
                    React.createElement("span", null, suggestion.type),
                    suggestion.description))); }))))));
};
var QuestionGeneratorSection = styled.div(templateObject_69 || (templateObject_69 = __makeTemplateObject(["\n  margin-top: 32px;\n  padding: 24px;\n  background: linear-gradient(169deg, rgba(248, 245, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);\n  border: 1px solid rgba(143, 74, 207, 0.08);\n  border-radius: 16px;\n  backdrop-filter: blur(8px);\n  box-shadow: 0 4px 24px rgba(143, 74, 207, 0.03);\n"], ["\n  margin-top: 32px;\n  padding: 24px;\n  background: linear-gradient(169deg, rgba(248, 245, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);\n  border: 1px solid rgba(143, 74, 207, 0.08);\n  border-radius: 16px;\n  backdrop-filter: blur(8px);\n  box-shadow: 0 4px 24px rgba(143, 74, 207, 0.03);\n"])));
var QuestionGeneratorHeader = styled.div(templateObject_70 || (templateObject_70 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  position: relative;\n\n  &::after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    right: 0;\n    height: 1px;\n    background: linear-gradient(90deg, \n      rgba(143, 74, 207, 0.1) 0%, \n      rgba(143, 74, 207, 0.05) 50%,\n      rgba(143, 74, 207, 0) 100%\n    );\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  position: relative;\n\n  &::after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    right: 0;\n    height: 1px;\n    background: linear-gradient(90deg, \n      rgba(143, 74, 207, 0.1) 0%, \n      rgba(143, 74, 207, 0.05) 50%,\n      rgba(143, 74, 207, 0) 100%\n    );\n  }\n"])));
var QuestionGeneratorTitle = styled.h3(templateObject_71 || (templateObject_71 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #00000;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #00000;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n\n"])));
var DatasetSelector = styled.div(templateObject_72 || (templateObject_72 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin: 16px 0;\n  flex-wrap: wrap;\n  padding: 4px;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin: 16px 0;\n  flex-wrap: wrap;\n  padding: 4px;\n"])));
var DatasetChip = styled.button(templateObject_73 || (templateObject_73 = __makeTemplateObject(["\n  padding: 8px 16px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 20px;\n  color: ", ";\n  font-size: 13px;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  position: relative;\n  overflow: hidden;\n\n  &:hover {\n    background: ", ";\n    transform: translateY(-1px);\n    box-shadow: 0 2px 8px rgba(143, 74, 207, 0.08);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n\n  .dataset-count {\n    background: ", ";\n    padding: 2px 8px;\n    border-radius: 12px;\n    font-size: 11px;\n    font-weight: 500;\n  }\n"], ["\n  padding: 8px 16px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 20px;\n  color: ", ";\n  font-size: 13px;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  position: relative;\n  overflow: hidden;\n\n  &:hover {\n    background: ", ";\n    transform: translateY(-1px);\n    box-shadow: 0 2px 8px rgba(143, 74, 207, 0.08);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n\n  .dataset-count {\n    background: ", ";\n    padding: 2px 8px;\n    border-radius: 12px;\n    font-size: 11px;\n    font-weight: 500;\n  }\n"])), function (props) { return props.isSelected ?
    'linear-gradient(135deg, rgba(143, 74, 207, 0.1) 0%, rgba(143, 74, 207, 0.05) 100%)' :
    'transparent'; }, function (props) { return props.isSelected ? '#8F4ACF' : 'rgba(143, 74, 207, 0.15)'; }, function (props) { return props.isSelected ? '#8F4ACF' : '#4A5568'; }, function (props) { return props.isSelected ?
    'linear-gradient(135deg, rgba(143, 74, 207, 0.15) 0%, rgba(143, 74, 207, 0.08) 100%)' :
    'rgba(143, 74, 207, 0.05)'; }, function (props) { return props.isSelected ?
    'rgba(143, 74, 207, 0.15)' :
    'rgba(143, 74, 207, 0.08)'; });
var QuickQuestionsContainer = styled.div(templateObject_74 || (templateObject_74 = __makeTemplateObject(["\n  background: transparent;\n  overflow: hidden;\n  max-height: 0;\n  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);\n  opacity: 0;\n  \n  &.open {\n    max-height: 400px;\n    opacity: 1;\n  }\n"], ["\n  background: transparent;\n  overflow: hidden;\n  max-height: 0;\n  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);\n  opacity: 0;\n  \n  &.open {\n    max-height: 400px;\n    opacity: 1;\n  }\n"])));
var QuestionsList = styled.div(templateObject_75 || (templateObject_75 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 12px;\n  padding: 8px 4px;\n  max-height: 320px;\n  overflow-y: auto;\n\n  &::-webkit-scrollbar {\n    width: 4px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: rgba(143, 74, 207, 0.1);\n    border-radius: 4px;\n    \n    &:hover {\n      background: rgba(143, 74, 207, 0.2);\n    }\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 12px;\n  padding: 8px 4px;\n  max-height: 320px;\n  overflow-y: auto;\n\n  &::-webkit-scrollbar {\n    width: 4px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: rgba(143, 74, 207, 0.1);\n    border-radius: 4px;\n    \n    &:hover {\n      background: rgba(143, 74, 207, 0.2);\n    }\n  }\n"])));
var QuestionItem = styled.div(templateObject_76 || (templateObject_76 = __makeTemplateObject(["\n  padding: 12px 16px;\n  background: rgba(255, 255, 255, 0.7);\n  border: 1px solid rgba(143, 74, 207, 0.08);\n  border-radius: 12px;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  font-size: 13px;\n  color: #4A5568;\n  position: relative;\n  overflow: hidden;\n  backdrop-filter: blur(8px);\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 2px;\n    background: linear-gradient(90deg, #8F4ACF 0%, rgba(143, 74, 207, 0) 100%);\n    opacity: 0;\n    transition: opacity 0.2s ease;\n  }\n\n  &:hover {\n    background: rgba(255, 255, 255, 0.9);\n    transform: translateY(-2px);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.08);\n    border-color: rgba(143, 74, 207, 0.15);\n\n    &::before {\n      opacity: 1;\n    }\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n"], ["\n  padding: 12px 16px;\n  background: rgba(255, 255, 255, 0.7);\n  border: 1px solid rgba(143, 74, 207, 0.08);\n  border-radius: 12px;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  font-size: 13px;\n  color: #4A5568;\n  position: relative;\n  overflow: hidden;\n  backdrop-filter: blur(8px);\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 2px;\n    background: linear-gradient(90deg, #8F4ACF 0%, rgba(143, 74, 207, 0) 100%);\n    opacity: 0;\n    transition: opacity 0.2s ease;\n  }\n\n  &:hover {\n    background: rgba(255, 255, 255, 0.9);\n    transform: translateY(-2px);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.08);\n    border-color: rgba(143, 74, 207, 0.15);\n\n    &::before {\n      opacity: 1;\n    }\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n"])));
var ShowQuestionsButton = styled.button(templateObject_77 || (templateObject_77 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 16px;\n  background: transparent;\n  border: 1px solid rgba(143, 74, 207, 0.15);\n  border-radius: 12px;\n  color: #8F4ACF;\n  font-size: 13px;\n  font-weight: 500;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.05);\n    border-color: #8F4ACF;\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n\n  svg {\n    font-size: 18px;\n    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  }\n\n  &.open svg {\n    transform: rotate(180deg);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 16px;\n  background: transparent;\n  border: 1px solid rgba(143, 74, 207, 0.15);\n  border-radius: 12px;\n  color: #8F4ACF;\n  font-size: 13px;\n  font-weight: 500;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.05);\n    border-color: #8F4ACF;\n    transform: translateY(-1px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n\n  svg {\n    font-size: 18px;\n    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  }\n\n  &.open svg {\n    transform: rotate(180deg);\n  }\n"])));
var Analysis = function () {
    var _a;
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useState(((_a = location.state) === null || _a === void 0 ? void 0 : _a.question) || ''), question = _b[0], setQuestion = _b[1];
    var _c = useState('openai'), selectedModel = _c[0], setSelectedModel = _c[1];
    var _d = useState(''), response = _d[0], setResponse = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState(0), currentFact = _f[0], setCurrentFact = _f[1];
    var _g = useState(false), showEstimatedQuery = _g[0], setShowEstimatedQuery = _g[1];
    var _h = useState(null), queryFeedback = _h[0], setQueryFeedback = _h[1];
    var _j = useState(''), estimatedQuery = _j[0], setEstimatedQuery = _j[1];
    var _k = useState(null), estimatedCost = _k[0], setEstimatedCost = _k[1];
    var _l = useState(null), questionId = _l[0], setQuestionId = _l[1];
    var _m = useState(''), editedQuery = _m[0], setEditedQuery = _m[1];
    var _o = useState([]), datasets = _o[0], setDatasets = _o[1];
    var textAreaRef = useRef(null);
    var _p = useState(null), selectedColumn = _p[0], setSelectedColumn = _p[1];
    var _q = useState(false), showPreview = _q[0], setShowPreview = _q[1];
    var _r = useState(false), isExplorerOpen = _r[0], setIsExplorerOpen = _r[1];
    var _s = useState(false), showQuestions = _s[0], setShowQuestions = _s[1];
    var _t = useState([]), quickQuestions = _t[0], setQuickQuestions = _t[1];
    var _u = useState(null), selectedDataset = _u[0], setSelectedDataset = _u[1];
    var vectorPositions = [
        { left: '14.43%', right: '79.83%', top: '75.16%', bottom: '19.1%', color: '#F7F0FD' },
        { left: '3.04%', right: '90%', top: '55.32%', bottom: '37.72%', color: '#F8F0FE' },
        { left: '2.52%', right: '89.48%', top: '32.59%', bottom: '59.41%', color: '#F5EAFF' },
        { left: '13.1%', right: '77.86%', top: '12.84%', bottom: '78.12%', color: '#F1E1FF' },
        { left: '31.64%', right: '57.93%', top: '1.04%', bottom: '88.53%', color: '#EEDBFF' },
        { left: '53.15%', right: '35.03%', top: '0.35%', bottom: '87.83%', color: '#E7CEFF' },
        { left: '71.77%', right: '15.18%', top: '10.84%', bottom: '76.11%', color: '#DEBAFF' },
        { left: '82.17%', right: '3.38%', top: '28.99%', bottom: '56.57%', color: '#D3A5FE' },
        { left: '81.31%', right: '2.52%', top: '50.71%', bottom: '33.12%', color: '#C18AF5' },
        { left: '69.51%', right: '12.93%', top: '69.25%', bottom: '13.19%', color: '#B277E9' },
        { left: '49.41%', right: '31.29%', top: '79.48%', bottom: '1.21%', color: '#A264DC' },
        { left: '26.33%', right: '52.62%', top: '78.61%', bottom: '0.34%', color: '#8F4ACF' }
    ];
    var facts = [
        "Did you know? GPT-4 can process up to 25,000 words at once!",
        "AI can now generate images, music, and even code!",
        "The first AI chatbot ELIZA was created in 1966.",
        "AI can detect diseases earlier than human doctors.",
        "Neural networks were inspired by the human brain.",
        "AI can now learn from just a few examples like humans!",
        "Claude can analyze complex data in milliseconds.",
        "AI helps reduce energy consumption in data centers.",
    ];
    useEffect(function () {
        if (isLoading) {
            var interval_1 = setInterval(function () {
                setCurrentFact(function (prev) { return (prev + 1) % facts.length; });
            }, 3000);
            return function () { return clearInterval(interval_1); };
        }
    }, [isLoading]);
    useEffect(function () {
        fetchDatasets();
    }, []);
    var fetchDatasets = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response_1, data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/datasets/')];
                case 1:
                    response_1 = _a.sent();
                    if (!response_1.ok) {
                        throw new Error('Failed to fetch datasets');
                    }
                    return [4 /*yield*/, response_1.json()];
                case 2:
                    data = _a.sent();
                    setDatasets(data);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    console.error('Error fetching datasets:', err_1);
                    toast.error('Failed to load available data');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchColumnPreview = function (datasetId, tableName, columnName) { return __awaiter(void 0, void 0, void 0, function () {
        var response_2, data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/preview/".concat(datasetId, "/").concat(tableName, "/").concat(columnName, "/"))];
                case 1:
                    response_2 = _a.sent();
                    if (!response_2.ok)
                        throw new Error('Failed to fetch preview');
                    return [4 /*yield*/, response_2.json()];
                case 2:
                    data = _a.sent();
                    return [2 /*return*/, data];
                case 3:
                    err_2 = _a.sent();
                    console.error('Error fetching preview:', err_2);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleColumnClick = function (tableName, column, event, datasetId) { return __awaiter(void 0, void 0, void 0, function () {
        var preview, columnRef_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!event.shiftKey) return [3 /*break*/, 2];
                    event.preventDefault();
                    return [4 /*yield*/, fetchColumnPreview(datasetId, tableName, column.name)];
                case 1:
                    preview = _a.sent();
                    setSelectedColumn({ tableName: tableName, column: column, preview: preview });
                    setShowPreview(true);
                    return [3 /*break*/, 3];
                case 2:
                    columnRef_1 = "".concat(tableName, ".").concat(column.name);
                    setQuestion(function (prev) { return prev ? "".concat(prev, " ").concat(columnRef_1) : columnRef_1; });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleProceedAnalysis = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response_3, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!questionId) {
                        toast.error('Missing question ID');
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, fetch('/api/analysis/confirm-analysis/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                question_id: questionId,
                                question: question,
                                chart_type: 'bar',
                                model: selectedModel,
                                edited_query: editedQuery || estimatedQuery
                            }),
                        })];
                case 2:
                    response_3 = _a.sent();
                    return [4 /*yield*/, response_3.json()];
                case 3:
                    data = _a.sent();
                    if (!response_3.ok) {
                        throw new Error(data.error || 'Failed to confirm analysis');
                    }
                    if (data.status === 'success') {
                        toast.success('Analysis confirmed successfully');
                        navigate("/app/analysis/result/".concat(questionId));
                    }
                    else {
                        throw new Error(data.error || 'Analysis confirmation failed');
                    }
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error confirming analysis:', error_1);
                    toast.error(error_1 instanceof Error ? error_1.message : 'Failed to confirm analysis');
                    return [3 /*break*/, 6];
                case 5:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleAnalysis = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response_4, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, fetch('/api/analysis/run-analysis/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                question: question,
                                chart_type: 'bar',
                                model: selectedModel || 'openai'
                            }),
                        })];
                case 2:
                    response_4 = _a.sent();
                    return [4 /*yield*/, response_4.json()];
                case 3:
                    data = _a.sent();
                    console.log('Run analysis response:', data);
                    if (!response_4.ok) {
                        throw new Error(data.error || 'Failed to run analysis');
                    }
                    // Step 2: Show estimated query page
                    if (data.status === 'cost_estimation') {
                        setQuestionId(data.question_id);
                        setEstimatedCost(data.estimated_cost);
                        setEstimatedQuery(data.query);
                        setShowEstimatedQuery(true);
                        toast.success('Analysis prepared successfully');
                    }
                    else {
                        throw new Error('Unexpected response format from server');
                    }
                    return [3 /*break*/, 6];
                case 4:
                    error_2 = _a.sent();
                    console.error('Analysis error:', error_2);
                    toast.error(error_2 instanceof Error ? error_2.message : 'An error occurred during analysis');
                    return [3 /*break*/, 6];
                case 5:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var calculateCost = function (cost) {
        if (cost === null)
            return '0';
        return (cost * 0.0442).toFixed(4);
    };
    var handleQueryFeedback = function (feedback) { return __awaiter(void 0, void 0, void 0, function () {
        var response_5, data, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!questionId || !estimatedQuery) {
                        toast.error('Missing required information');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch('/api/analysis/validate-query/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                question_id: questionId,
                                original_query: estimatedQuery,
                                adjusted_query: editedQuery || undefined,
                                validation_status: feedback === 'correct' ? 'correct' :
                                    feedback === 'adjust' ? 'adjusted' :
                                        'false'
                            })
                        })];
                case 2:
                    response_5 = _a.sent();
                    return [4 /*yield*/, response_5.json()];
                case 3:
                    data = _a.sent();
                    if (!response_5.ok) {
                        throw new Error(data.message || 'Failed to save query validation');
                    }
                    setQueryFeedback(feedback);
                    toast.success(data.message || 'Feedback saved successfully');
                    return [3 /*break*/, 5];
                case 4:
                    error_3 = _a.sent();
                    console.error('Error saving query validation:', error_3);
                    toast.error(error_3 instanceof Error ? error_3.message : 'Failed to save query validation');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var toggleExplorer = function () {
        setIsExplorerOpen(!isExplorerOpen);
    };
    var fetchQuickQuestions = function (datasetName) { return __awaiter(void 0, void 0, void 0, function () {
        var response_6, data, filteredQuestions, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/datasets/".concat(datasetName, "/questions/"), {
                            credentials: 'include',
                            headers: {
                                'Accept': 'application/json'
                            }
                        })];
                case 1:
                    response_6 = _a.sent();
                    if (!response_6.ok)
                        throw new Error('Failed to fetch questions');
                    return [4 /*yield*/, response_6.json()];
                case 2:
                    data = _a.sent();
                    filteredQuestions = data.questions
                        .filter(function (q) { return q.text.trim() !== ''; })
                        .map(function (q) { return (__assign(__assign({}, q), { text: q.text.replace(/^\d+\.\s*/, '') })); })
                        .slice(0, 10);
                    setQuickQuestions(filteredQuestions);
                    setShowQuestions(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    toast.error('Failed to load questions');
                    setShowQuestions(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleDatasetSelect = function (datasetName) {
        if (selectedDataset === datasetName) {
            setSelectedDataset(null);
            setShowQuestions(false);
            setQuickQuestions([]);
        }
        else {
            setSelectedDataset(datasetName);
            fetchQuickQuestions(datasetName);
        }
    };
    var handleQuickQuestionSelect = function (questionText) {
        setQuestion(questionText);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (React.createElement(SplitLayout, { isExplorerOpen: isExplorerOpen },
        React.createElement(Container, null,
            React.createElement(QuestionSection, null, isLoading ? (React.createElement(InteractiveLoading, { progress: 75, onComplete: function () { return setIsLoading(false); } })) : showEstimatedQuery ? (React.createElement(EstimatedQuerySection, null,
                React.createElement(QueryTitle, null, "Estimated Query"),
                React.createElement(CostInfo, null,
                    "Estimated Cost: ",
                    (estimatedCost === null || estimatedCost === void 0 ? void 0 : estimatedCost.toFixed(8)) || 0,
                    " GB",
                    React.createElement("br", null),
                    "Approximate Cost: $ ",
                    calculateCost(estimatedCost)),
                React.createElement(QueryContainer, null,
                    React.createElement(EditQueryText, null, "Edit Query"),
                    React.createElement(CopyButton, { onClick: function () {
                            navigator.clipboard.writeText(editedQuery || estimatedQuery);
                            toast.success('Query copied to clipboard!');
                        } }, navigator.clipboard ? 'Copy' : 'Copied!'),
                    React.createElement(QueryBox, { value: editedQuery || estimatedQuery, onChange: function (e) { return setEditedQuery(e.target.value); } })),
                React.createElement(QueryQuestion, null, "Is this query correct?"),
                React.createElement(FeedbackButtons, null,
                    React.createElement(FeedbackButton, { variant: "correct", onClick: function () { return handleQueryFeedback('correct'); }, disabled: queryFeedback !== null }, "Correct"),
                    React.createElement(FeedbackButton, { variant: "adjust", onClick: function () { return handleQueryFeedback('adjust'); }, disabled: queryFeedback !== null }, "Adjustment Required"),
                    React.createElement(FeedbackButton, { variant: "false", onClick: function () { return handleQueryFeedback('false'); }, disabled: queryFeedback !== null }, "Incorrect")),
                React.createElement(ConfirmationDialog, null,
                    React.createElement(QueryQuestion, null, "Do you want to proceed with the analysis?"),
                    React.createElement(DialogButtons, null,
                        React.createElement(ProceedButton, { onClick: handleProceedAnalysis }, "Yes, proceed"),
                        React.createElement(CancelButton, { onClick: function () { return setShowEstimatedQuery(false); } }, "Cancel"))))) : (React.createElement(React.Fragment, null,
                React.createElement(Label, null, "Your Question"),
                React.createElement(AutocompleteTextArea, { value: question, onChange: setQuestion, placeholder: "Type your question here...", datasets: datasets }),
                React.createElement(ButtonContainer, null,
                    React.createElement(AnalyzeButton, { onClick: handleAnalysis, disabled: !question.trim() || isLoading }, isLoading ? 'Analyzing...' : 'Run Analysis')),
                React.createElement(QuestionGeneratorSection, null,
                    React.createElement(QuestionGeneratorHeader, null,
                        React.createElement(QuestionGeneratorTitle, null, "Example Questions"),
                        React.createElement(ShowQuestionsButton, { onClick: function () { return setShowQuestions(!showQuestions); }, className: showQuestions ? 'open' : '' },
                            React.createElement(QuestionAnswerIcon, null),
                            showQuestions ? 'Hide Questions' : 'Show Questions')),
                    React.createElement(DatasetSelector, null, datasets.map(function (dataset) { return (React.createElement(DatasetChip, { key: dataset.id, isSelected: selectedDataset === dataset.name, onClick: function () { return handleDatasetSelect(dataset.name); } },
                        dataset.name,
                        React.createElement("span", { className: "dataset-count" },
                            Object.keys(dataset.data.tables).length,
                            " tables"))); })),
                    React.createElement(QuickQuestionsContainer, { className: showQuestions ? 'open' : '' },
                        React.createElement(QuestionsList, null, quickQuestions.map(function (q) { return (React.createElement(QuestionItem, { key: q.id, onClick: function () { return handleQuickQuestionSelect(q.text); } }, q.text)); }))))))),
            response && (React.createElement(ResponseSection, null,
                React.createElement(Label, null, "Analysis Result"),
                React.createElement(ResponseText, null, response)))),
        React.createElement(DataExplorerToggle, { onClick: toggleExplorer }, isExplorerOpen ? React.createElement(CloseIcon, null) : React.createElement(StorageIcon, null)),
        React.createElement(DataExplorerPanelWrapper, { isOpen: isExplorerOpen },
            React.createElement(DataExplorerHeader, null,
                React.createElement(DataExplorerTitle, null, "Available Data"),
                React.createElement(ExplorerCloseButton, { onClick: function () { return setIsExplorerOpen(false); } },
                    React.createElement(CloseIcon, null))),
            React.createElement(Box, { p: 3 }, datasets.map(function (dataset) { return (React.createElement(Accordion, { key: dataset.id, sx: {
                    mb: 2,
                    '&:before': { display: 'none' },
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)'
                } },
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                    React.createElement(DatasetHeader, null,
                        React.createElement(DatasetTitle, { variant: "subtitle1" }, dataset.name),
                        React.createElement(IconButton, { onClick: function (e) {
                                e.stopPropagation();
                                navigate("/app/".concat(dataset.name, "/questions"));
                            }, title: "Ask questions about this dataset" },
                            React.createElement(QuestionAnswerIcon, null)))),
                React.createElement(AccordionDetails, null, Object.entries(dataset.data.tables).map(function (_a) {
                    var tableName = _a[0], table = _a[1];
                    return (React.createElement(Accordion, { key: tableName, sx: {
                            mb: 1,
                            '&:before': { display: 'none' }
                        } },
                        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                            React.createElement(Typography, { variant: "body2" }, tableName)),
                        React.createElement(AccordionDetails, null,
                            React.createElement(Typography, { variant: "caption", paragraph: true }, table.description),
                            table.columns.map(function (column) { return (React.createElement(ColumnChip, { key: column.name, onClick: function (e) { return handleColumnClick(tableName, column, e, dataset.id); }, title: "Shift+Click for preview" }, column.name)); }))));
                })))); }))),
        showPreview && selectedColumn && (React.createElement(React.Fragment, null,
            React.createElement(ModalOverlay, { onClick: function () { return setShowPreview(false); } }),
            React.createElement(DataPreviewModal, null,
                React.createElement(TableHeader, null,
                    React.createElement(Typography, { variant: "h6" },
                        selectedColumn.tableName,
                        ".",
                        selectedColumn.column.name),
                    React.createElement(ModalCloseButton, { onClick: function () { return setShowPreview(false); } }, "\u00D7")),
                React.createElement(Typography, { variant: "body2", paragraph: true },
                    React.createElement("strong", null, "Type:"),
                    " ",
                    selectedColumn.column.type),
                React.createElement(Typography, { variant: "body2", paragraph: true },
                    React.createElement("strong", null, "Description:"),
                    " ",
                    selectedColumn.column.description),
                selectedColumn.column.distinct_values.length > 0 && (React.createElement(Typography, { variant: "body2", paragraph: true },
                    React.createElement("strong", null, "Distinct values:"),
                    " ",
                    selectedColumn.column.distinct_values.join(', '))),
                selectedColumn.preview && (React.createElement(PreviewTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null, selectedColumn.preview.columns.map(function (col) { return (React.createElement("th", { key: col }, col)); }))),
                    React.createElement("tbody", null, selectedColumn.preview.rows.map(function (row, i) { return (React.createElement("tr", { key: i }, row.map(function (cell, j) { return (React.createElement("td", { key: j }, cell)); }))); })))))))));
};
export default Analysis;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38, templateObject_39, templateObject_40, templateObject_41, templateObject_42, templateObject_43, templateObject_44, templateObject_45, templateObject_46, templateObject_47, templateObject_48, templateObject_49, templateObject_50, templateObject_51, templateObject_52, templateObject_53, templateObject_54, templateObject_55, templateObject_56, templateObject_57, templateObject_58, templateObject_59, templateObject_60, templateObject_61, templateObject_62, templateObject_63, templateObject_64, templateObject_65, templateObject_66, templateObject_67, templateObject_68, templateObject_69, templateObject_70, templateObject_71, templateObject_72, templateObject_73, templateObject_74, templateObject_75, templateObject_76, templateObject_77;
