var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { getCookie } from '../../utils/cookies';
// Styled Components
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var Section = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var UploadContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 24px;\n  margin-bottom: 24px;\n"], ["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 24px;\n  margin-bottom: 24px;\n"])));
var HiddenInput = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
var Button = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border: ", ";\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  display: inline-flex;\n  align-items: center;\n  gap: 8px;\n  \n  &:hover {\n    background: ", ";\n  }\n  \n  &:disabled {\n    background: #B8B8B8;\n    cursor: not-allowed;\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border: ", ";\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  display: inline-flex;\n  align-items: center;\n  gap: 8px;\n  \n  &:hover {\n    background: ", ";\n  }\n  \n  &:disabled {\n    background: #B8B8B8;\n    cursor: not-allowed;\n  }\n"])), function (props) {
    switch (props.variant) {
        case 'secondary': return '#F8F5FF';
        case 'danger': return '#FFF0F0';
        default: return '#8F4ACF';
    }
}, function (props) {
    switch (props.variant) {
        case 'secondary': return '#8F4ACF';
        case 'danger': return '#D93025';
        default: return 'white';
    }
}, function (props) { return props.variant === 'secondary' ? '1px solid #ECEAFF' : 'none'; }, function (props) {
    switch (props.variant) {
        case 'secondary': return '#ECEAFF';
        case 'danger': return '#FFE5E5';
        default: return '#7B3AAF';
    }
});
var FileCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  background: white;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin-bottom: 16px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);\n    transform: translateY(-2px);\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  background: white;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  margin-bottom: 16px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);\n    transform: translateY(-2px);\n  }\n"])));
var FileInfo = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n"])));
var FileName = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  color: #000000;\n"])));
var FileDate = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n"])));
var StatusBadge = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 6px 12px;\n  border-radius: 20px;\n  font-size: 14px;\n  font-family: 'Inter';\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n"], ["\n  padding: 6px 12px;\n  border-radius: 20px;\n  font-size: 14px;\n  font-family: 'Inter';\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n"])), function (props) { return props.status === 'active' ? '#E6F4EA' : '#FFF0F0'; }, function (props) { return props.status === 'active' ? '#1E8E3E' : '#D93025'; });
var ErrorBox = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: #FFF0F0;\n  border: 1px solid #FFD7D7;\n  border-radius: 8px;\n  padding: 16px;\n  margin-top: 16px;\n  display: flex;\n  gap: 12px;\n  align-items: flex-start;\n"], ["\n  background: #FFF0F0;\n  border: 1px solid #FFD7D7;\n  border-radius: 8px;\n  padding: 16px;\n  margin-top: 16px;\n  display: flex;\n  gap: 12px;\n  align-items: flex-start;\n"])));
var ErrorContent = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var ErrorTitle = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: #DC3545;\n  font-weight: 500;\n  font-size: 14px;\n  margin-bottom: 4px;\n"], ["\n  color: #DC3545;\n  font-weight: 500;\n  font-size: 14px;\n  margin-bottom: 4px;\n"])));
var ErrorText = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: #6B6B6B;\n  font-size: 13px;\n  line-height: 1.4;\n"], ["\n  color: #6B6B6B;\n  font-size: 13px;\n  line-height: 1.4;\n"])));
var LoadingSpinner = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  border: 2px solid #f3f3f3;\n  border-top: 2px solid #8F4ACF;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n"], ["\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  border: 2px solid #f3f3f3;\n  border-top: 2px solid #8F4ACF;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n"])));
var EmptyState = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  text-align: center;\n  padding: 40px 0;\n  color: #6B6B6B;\n"], ["\n  text-align: center;\n  padding: 40px 0;\n  color: #6B6B6B;\n"])));
// Component
var ServiceAccount = function () {
    var user = useAuth().user;
    var _a = useState(null), selectedFile = _a[0], setSelectedFile = _a[1];
    var _b = useState(false), uploading = _b[0], setUploading = _b[1];
    var _c = useState([]), serviceAccounts = _c[0], setServiceAccounts = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    // Add this to fetch fresh user data
    var fetchUserData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/user/', {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            }
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error fetching user data:', error_1);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchServiceAccounts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var userData, response, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setLoading(true);
                    return [4 /*yield*/, fetchUserData()];
                case 1:
                    userData = _b.sent();
                    if (!((_a = userData === null || userData === void 0 ? void 0 : userData.company) === null || _a === void 0 ? void 0 : _a.id)) {
                        setError('No company associated with your account');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, axios.get("/api/companies/".concat(userData.company.id, "/service-accounts/"), {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            }
                        })];
                case 2:
                    response = _b.sent();
                    setServiceAccounts(response.data);
                    setError(null);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _b.sent();
                    console.error('Error fetching service accounts:', error_2);
                    setError('Failed to fetch service accounts');
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchServiceAccounts();
    }, []); // Remove user?.company?.id dependency since we're fetching fresh data
    var handleFileSelect = function (event) {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
            setError(null);
        }
    };
    var handleUpload = function () { return __awaiter(void 0, void 0, void 0, function () {
        var userData, formData, response, fileInput, error_3;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!selectedFile) {
                        setError('Please select a file first');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, fetchUserData()];
                case 1:
                    userData = _d.sent();
                    if (!((_a = userData === null || userData === void 0 ? void 0 : userData.company) === null || _a === void 0 ? void 0 : _a.id)) {
                        setError('No company associated with your account');
                        return [2 /*return*/];
                    }
                    setUploading(true);
                    setError(null);
                    formData = new FormData();
                    formData.append('file', selectedFile);
                    _d.label = 2;
                case 2:
                    _d.trys.push([2, 7, 8, 9]);
                    console.log('Uploading file...'); // Debug log
                    return [4 /*yield*/, axios.post("/api/companies/".concat(userData.company.id, "/service-accounts/upload/"), formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 3:
                    response = _d.sent();
                    console.log('Upload response:', response.data); // Debug log
                    if (!response.data.success) return [3 /*break*/, 5];
                    return [4 /*yield*/, fetchServiceAccounts()];
                case 4:
                    _d.sent();
                    setSelectedFile(null);
                    fileInput = document.getElementById('serviceAccountFile');
                    if (fileInput)
                        fileInput.value = '';
                    return [3 /*break*/, 6];
                case 5: throw new Error(response.data.error || 'Upload failed');
                case 6: return [3 /*break*/, 9];
                case 7:
                    error_3 = _d.sent();
                    console.error('Error uploading service account:', error_3);
                    setError(((_c = (_b = error_3.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) || error_3.message || 'Failed to upload service account');
                    return [3 /*break*/, 9];
                case 8:
                    setUploading(false);
                    return [7 /*endfinally*/];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var userData, response, error_4;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!window.confirm('Are you sure you want to delete this service account?')) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, fetchUserData()];
                case 1:
                    userData = _d.sent();
                    if (!((_a = userData === null || userData === void 0 ? void 0 : userData.company) === null || _a === void 0 ? void 0 : _a.id)) {
                        setError('No company associated with your account');
                        return [2 /*return*/];
                    }
                    _d.label = 2;
                case 2:
                    _d.trys.push([2, 7, , 8]);
                    return [4 /*yield*/, axios.delete("/api/companies/".concat(userData.company.id, "/service-accounts/").concat(accountId, "/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 3:
                    response = _d.sent();
                    if (!response.data.success) return [3 /*break*/, 5];
                    return [4 /*yield*/, fetchServiceAccounts()];
                case 4:
                    _d.sent();
                    return [3 /*break*/, 6];
                case 5: throw new Error(response.data.error || 'Failed to delete service account');
                case 6: return [3 /*break*/, 8];
                case 7:
                    error_4 = _d.sent();
                    console.error('Error deleting service account:', error_4);
                    setError(((_c = (_b = error_4.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) || error_4.message || 'Failed to delete service account');
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var renderError = function () {
        if (!error)
            return null;
        var title = 'Error';
        var description = error;
        // Customize error messages based on content
        if (error.includes('already has a service account')) {
            title = 'Duplicate Service Account';
            description = 'Please delete the existing service account before uploading a new one.';
        }
        else if (error.includes('Invalid JSON')) {
            title = 'Invalid File Format';
            description = 'The selected file is not a valid service account JSON file. Please check the file and try again.';
        }
        return (React.createElement(ErrorBox, null,
            React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
                React.createElement("circle", { cx: "10", cy: "10", r: "8", stroke: "#DC3545", strokeWidth: "1.5" }),
                React.createElement("path", { d: "M10 6v5M10 13.5v.5", stroke: "#DC3545", strokeWidth: "1.5", strokeLinecap: "round" })),
            React.createElement(ErrorContent, null,
                React.createElement(ErrorTitle, null, title),
                React.createElement(ErrorText, null, description))));
    };
    var renderUploadSection = function () { return (React.createElement(UploadContainer, null,
        React.createElement("h3", null, "Upload Service Account"),
        React.createElement("p", null, "Upload your Google Cloud service account credentials (JSON file) to enable data access"),
        React.createElement(HiddenInput, { type: "file", id: "serviceAccountFile", accept: ".json", onChange: handleFileSelect }),
        React.createElement("div", { style: { display: 'flex', gap: '12px', alignItems: 'center', marginTop: '16px' } },
            React.createElement("label", { htmlFor: "serviceAccountFile" },
                React.createElement(Button, { as: "span", variant: "secondary" }, "Choose File")),
            selectedFile && (React.createElement(Button, { onClick: function () { return handleUpload(); }, disabled: uploading, type: "button" }, uploading ? 'Uploading...' : 'Upload'))),
        selectedFile && (React.createElement("p", { style: { marginTop: '8px', color: '#6B6B6B' } },
            "Selected: ",
            selectedFile.name)),
        renderError())); };
    var renderServiceAccounts = function () { return (React.createElement("div", null,
        React.createElement("h3", null, "Active Service Account"),
        loading ? (React.createElement("div", { style: { textAlign: 'center', padding: '20px' } },
            React.createElement(LoadingSpinner, null))) : serviceAccounts.length > 0 ? (serviceAccounts.map(function (account) { return (React.createElement(FileCard, { key: account.id },
            React.createElement(FileInfo, null,
                React.createElement(FileName, null, account.name),
                React.createElement(FileDate, null,
                    "Uploaded on ",
                    new Date(account.created_at).toLocaleDateString())),
            React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: '16px' } },
                React.createElement(StatusBadge, { status: account.status }, account.status.charAt(0).toUpperCase() + account.status.slice(1)),
                React.createElement(Button, { variant: "danger", onClick: function () { return handleDelete(account.id); } },
                    React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "currentColor" },
                        React.createElement("path", { d: "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" }),
                        React.createElement("path", { fillRule: "evenodd", d: "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" })),
                    "Delete")))); })) : (React.createElement(EmptyState, null,
            React.createElement("svg", { width: "48", height: "48", viewBox: "0 0 24 24", fill: "#6B6B6B", style: { marginBottom: '16px' } },
                React.createElement("path", { d: "M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" })),
            React.createElement("p", null, "No service accounts uploaded yet"))))); };
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Service Account Management"),
        React.createElement(Section, null,
            renderUploadSection(),
            renderServiceAccounts())));
};
export default ServiceAccount;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
