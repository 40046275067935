var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var RolesSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var CreateButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var Table = styled.table(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n"])));
var Th = styled.th(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"], ["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"])));
var Td = styled.td(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"], ["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"])));
var ActionButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.variant === 'edit' ? '#F8F5FF' : '#FFF1F0'; }, function (props) { return props.variant === 'edit' ? '#8F4ACF' : '#FF4D4F'; }, function (props) { return props.variant === 'edit' ? '#ECEAFF' : '#FFA39E'; }, function (props) { return props.variant === 'edit' ? '#F0E7FF' : '#FFF1F0'; });
var UrlList = styled.ul(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"], ["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"])));
var ManageRoles = function () {
    var _a = useState([
        {
            id: 1,
            name: "admin",
            displayName: "Administrator",
            accessibleUrls: ["/app/settings/*", "/app/admin/*"],
            userCount: 5
        },
        {
            id: 2,
            name: "technical_user",
            displayName: "Technical User",
            accessibleUrls: ["/app/analysis/*", "/app/datasets/*"],
            userCount: 12
        },
        {
            id: 3,
            name: "business_user",
            displayName: "Business User",
            accessibleUrls: ["/app/dashboard/*", "/app/reports/*"],
            userCount: 25
        }
    ]), roles = _a[0], setRoles = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var user = useAuth().user;
    useEffect(function () {
        window.scrollTo(0, 0);
    }, []);
    var handleCreateRole = function () {
        var newRole = {
            id: roles.length + 1,
            name: "new_role",
            displayName: "New Role",
            accessibleUrls: ["/app/new/*"],
            userCount: 0
        };
        setRoles(__spreadArray(__spreadArray([], roles, true), [newRole], false));
    };
    var handleEditRole = function (roleId) {
        console.log("Editing role ".concat(roleId));
    };
    var handleDeleteRole = function (roleId) {
        if (!window.confirm('Are you sure you want to delete this role?'))
            return;
        setRoles(roles.filter(function (role) { return role.id !== roleId; }));
    };
    if (error) {
        return (React.createElement(Container, null,
            React.createElement(Title, null, "Manage Roles"),
            React.createElement(RolesSection, null,
                React.createElement("div", { style: { color: '#FF4D4D' } }, error))));
    }
    if (loading) {
        return React.createElement(Container, null, "Loading...");
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Manage Roles"),
        React.createElement(RolesSection, null,
            React.createElement(CreateButton, { onClick: handleCreateRole }, "Create New Role"),
            React.createElement(Table, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement(Th, null, "Role Name"),
                        React.createElement(Th, null, "Accessible URLs"),
                        React.createElement(Th, null, "Users"),
                        React.createElement(Th, null, "Actions"))),
                React.createElement("tbody", null, roles.map(function (role) { return (React.createElement("tr", { key: role.id },
                    React.createElement(Td, null, role.displayName),
                    React.createElement(Td, null,
                        React.createElement(UrlList, null, role.accessibleUrls.map(function (url, index) { return (React.createElement("li", { key: index }, url)); }))),
                    React.createElement(Td, null, role.userCount),
                    React.createElement(Td, null,
                        React.createElement(ActionButton, { variant: "edit", onClick: function () { return handleEditRole(role.id); } }, "Edit"),
                        React.createElement(ActionButton, { variant: "delete", onClick: function () { return handleDeleteRole(role.id); } }, "Delete")))); }))))));
};
export default ManageRoles;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
