var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var ProfileSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var AvatarSection = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 24px;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 24px;\n  margin-bottom: 32px;\n"])));
var Avatar = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  background: ", ";\n  background-size: cover;\n  background-position: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #8F4ACF;\n  font-size: 36px;\n"], ["\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  background: ", ";\n  background-size: cover;\n  background-position: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #8F4ACF;\n  font-size: 36px;\n"])), function (props) { return props.url ? "url(".concat(props.url, ")") : '#F8F5FF'; });
var AvatarUpload = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var UploadButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  color: #8F4ACF;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:hover {\n    background: #ECEAFF;\n  }\n"], ["\n  background: #F8F5FF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  color: #8F4ACF;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:hover {\n    background: #ECEAFF;\n  }\n"])));
var Form = styled.form(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n  \n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n  \n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"])));
var FormGroup = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var Label = styled.label(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n"])));
var Input = styled.input(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  \n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var SaveButton = styled.button(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-top: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-top: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var UserProfile = function () {
    var _a;
    var user = useAuth().user;
    var navigate = useNavigate();
    var _b = useState({
        firstName: (user === null || user === void 0 ? void 0 : user.firstName) || '',
        lastName: (user === null || user === void 0 ? void 0 : user.lastName) || '',
        email: (user === null || user === void 0 ? void 0 : user.email) || '',
        phone: (user === null || user === void 0 ? void 0 : user.phone) || '',
        company: (user === null || user === void 0 ? void 0 : user.company) || '',
        department: (user === null || user === void 0 ? void 0 : user.department) || '',
        position: (user === null || user === void 0 ? void 0 : user.position) || '',
    }), formData = _b[0], setFormData = _b[1];
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setFormData(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            e.preventDefault();
            try {
                // Add your API call here to update user profile
                console.log('Updating profile:', formData);
                // After successful update:
                // await updateUserProfile(formData);
                // Show success message
            }
            catch (error) {
                console.error('Error updating profile:', error);
                // Show error message
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Container, null,
        React.createElement(Title, null, "User Profile"),
        React.createElement(ProfileSection, null,
            React.createElement(AvatarSection, null,
                React.createElement(Avatar, { url: user === null || user === void 0 ? void 0 : user.avatarUrl }, !(user === null || user === void 0 ? void 0 : user.avatarUrl) && ((_a = user === null || user === void 0 ? void 0 : user.firstName) === null || _a === void 0 ? void 0 : _a[0])),
                React.createElement(AvatarUpload, null,
                    React.createElement(UploadButton, null, "Upload New Photo"),
                    React.createElement("span", { style: { fontSize: '12px', color: '#6B6B6B' } }, "JPG, GIF or PNG. Max size 800K"))),
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "First Name"),
                    React.createElement(Input, { type: "text", name: "firstName", value: formData.firstName, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Last Name"),
                    React.createElement(Input, { type: "text", name: "lastName", value: formData.lastName, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Email"),
                    React.createElement(Input, { type: "email", name: "email", value: formData.email, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Phone"),
                    React.createElement(Input, { type: "tel", name: "phone", value: formData.phone, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Company"),
                    React.createElement(Input, { type: "text", name: "company", value: formData.company, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Department"),
                    React.createElement(Input, { type: "text", name: "department", value: formData.department, onChange: handleInputChange })),
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Position"),
                    React.createElement(Input, { type: "text", name: "position", value: formData.position, onChange: handleInputChange })),
                React.createElement(SaveButton, { type: "submit" }, "Save Changes")))));
};
export default UserProfile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
