var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
var Card = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  height: 100%;\n  transition: all 0.3s ease;\n  position: relative;\n  overflow: hidden;\n  \n  &:before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 4px;\n    height: 0;\n    background: #8F4ACF;\n    transition: height 0.3s ease;\n  }\n  \n  &:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 8px 16px rgba(143, 74, 207, 0.08);\n    \n    &:before {\n      height: 100%;\n    }\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  height: 100%;\n  transition: all 0.3s ease;\n  position: relative;\n  overflow: hidden;\n  \n  &:before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 4px;\n    height: 0;\n    background: #8F4ACF;\n    transition: height 0.3s ease;\n  }\n  \n  &:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 8px 16px rgba(143, 74, 207, 0.08);\n    \n    &:before {\n      height: 100%;\n    }\n  }\n"])));
var CardTitle = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin-bottom: 12px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin-bottom: 12px;\n"])));
var CardDescription = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 24px;\n  line-height: 1.5;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 24px;\n  line-height: 1.5;\n"])));
var Button = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  padding: 8px 16px;\n  background: #F8F5FF;\n  color: #8F4ACF;\n  border-radius: 8px;\n  text-decoration: none;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #8F4ACF;\n    color: white;\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  padding: 8px 16px;\n  background: #F8F5FF;\n  color: #8F4ACF;\n  border-radius: 8px;\n  text-decoration: none;\n  font-family: 'Inter';\n  font-size: 14px;\n  font-weight: 500;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #8F4ACF;\n    color: white;\n  }\n"])));
var SettingsCard = function (_a) {
    var title = _a.title, description = _a.description, link = _a.link, buttonText = _a.buttonText;
    return (React.createElement(Card, null,
        React.createElement(CardTitle, null, title),
        React.createElement(CardDescription, null, description),
        React.createElement(Button, { to: link }, buttonText)));
};
export default SettingsCard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
