// utils/axiosConfig.ts
import axios from 'axios';
import { getCsrfToken } from './cookies';
export var configureAxios = function () {
    // Configure defaults
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    axios.defaults.withCredentials = true;
    // Add request interceptor
    axios.interceptors.request.use(function (config) {
        var token = getCsrfToken();
        if (token) {
            config.headers['X-CSRFToken'] = token;
        }
        config.withCredentials = true;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
};
