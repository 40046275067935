var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie } from '../../utils/cookies';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  padding-bottom: 40px;\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  padding-bottom: 40px;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 30px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 30px;\n  color: #000000;\n"])));
var FolderSection = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  overflow-y: auto;\n"], ["\n  height: 100%;\n  overflow-y: auto;\n"])));
var HeaderRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 30px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 30px;\n"])));
var CreateButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  padding: 12px 20px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  padding: 12px 20px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n"])));
var FolderGrid = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 24px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 24px;\n"])));
var FolderCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 24px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 24px;\n"])));
var FolderHeader = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin-bottom: 16px;\n"])));
var FolderIcon = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 40px;\n  height: 40px;\n  background: ", ";\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 40px;\n  height: 40px;\n  background: ", ";\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), function (props) { return props.color || '#F8F5FF'; });
var FolderTitle = styled.h2(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin: 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin: 0;\n"])));
var FolderDescription = styled.p(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 24px;\n"])));
var ButtonGroup = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n"], ["\n  display: flex;\n  gap: 12px;\n"])));
var ActionButton = styled.button(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 8px 16px;\n  background: ", ";\n  border-radius: 8px;\n  border: none;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #000000;\n  cursor: pointer;\n"], ["\n  padding: 8px 16px;\n  background: ", ";\n  border-radius: 8px;\n  border: none;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #000000;\n  cursor: pointer;\n"])), function (props) { return props.variant === 'primary' ? '#F8F5FF' : '#F1F1F1'; });
var Modal = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 550px;\n  height: 569px;\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 40px;\n  z-index: 10000;\n"], ["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 550px;\n  height: 569px;\n  background: #FCFCFC;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 40px;\n  z-index: 10000;\n"])));
var ModalOverlay = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 9999;\n  pointer-events: all;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 9999;\n  pointer-events: all;\n"])));
var ModalTitle = styled.h2(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 42px;\n  line-height: 110%;\n  text-align: center;\n  color: #000000;\n  margin-bottom: 87px;\n"], ["\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 42px;\n  line-height: 110%;\n  text-align: center;\n  color: #000000;\n  margin-bottom: 87px;\n"])));
var FormGroup = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin-bottom: 40px;\n"], ["\n  margin-bottom: 40px;\n"])));
var FormLabel = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 13px;\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 110%;\n  color: #000000;\n  margin-bottom: 13px;\n"])));
var Input = styled.input(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #E8E8E8;\n  border-radius: 12px;\n  padding: 0 16px;\n  font-family: 'Inter';\n  font-size: 16px;\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #E8E8E8;\n  border-radius: 12px;\n  padding: 0 16px;\n  font-family: 'Inter';\n  font-size: 16px;\n"])));
var TextArea = styled.textarea(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  width: 422px;\n  height: 101px;\n  background: #FFFFFF;\n  border: 1px solid #E8E8E8;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Inter';\n  font-size: 16px;\n  resize: none;\n"], ["\n  width: 422px;\n  height: 101px;\n  background: #FFFFFF;\n  border: 1px solid #E8E8E8;\n  border-radius: 12px;\n  padding: 16px;\n  font-family: 'Inter';\n  font-size: 16px;\n  resize: none;\n"])));
var ModalButtonGroup = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  justify-content: flex-end;\n  margin-top: 28px;\n  \n  button:first-child {\n    margin-right: auto;\n  }\n"], ["\n  display: flex;\n  gap: 8px;\n  justify-content: flex-end;\n  margin-top: 28px;\n  \n  button:first-child {\n    margin-right: auto;\n  }\n"])));
var ModalButton = styled.button(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  height: 50px;\n  padding: 0 20px;\n  border-radius: 16px;\n  font-family: 'Inter';\n  font-size: 16px;\n  cursor: pointer;\n\n  ", "\n"], ["\n  height: 50px;\n  padding: 0 20px;\n  border-radius: 16px;\n  font-family: 'Inter';\n  font-size: 16px;\n  cursor: pointer;\n\n  ", "\n"])), function (props) {
    switch (props.variant) {
        case 'primary':
            return "\n          background: #000000;\n          color: #FFFFFF;\n          border: none;\n          min-width: 142px;\n        ";
        case 'secondary':
            return "\n          background: transparent;\n          color: #EB564F;\n          border: 1px solid #EB564F;\n          min-width: 91px;\n        ";
        default:
            return "\n          background: #F3F3F3;\n          color: #6B6B6B;\n          border: none;\n          min-width: 69px;\n        ";
    }
});
var folderIconPath = "\n  M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 \n  0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z\n";
var DeleteIcon = function () { return (React.createElement("svg", { width: "16", height: "19", viewBox: "0 0 16 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M4.17969 18.3672C3.04688 18.3672 2.3125 17.6484 2.25781 16.5312L1.71094 5.16406H0.78125C0.46875 5.16406 0.195312 4.89844 0.195312 4.57031C0.195312 4.25 0.46875 3.98438 0.78125 3.98438H4.32812V2.78906C4.32812 1.65625 5.0625 0.96875 6.26562 0.96875H9.23438C10.4375 0.96875 11.1719 1.65625 11.1719 2.78906V3.98438H14.7422C15.0625 3.98438 15.3203 4.25 15.3203 4.57031C15.3203 4.89062 15.0625 5.16406 14.7422 5.16406H13.8047L13.2656 16.5234C13.2109 17.6406 12.4609 18.3672 11.3438 18.3672H4.17969ZM5.57031 2.86719V3.98438H9.92969V2.86719C9.92969 2.42188 9.61719 2.13281 9.14844 2.13281H6.35156C5.88281 2.13281 5.57031 2.42188 5.57031 2.86719ZM4.28906 17.1875H11.2188C11.6797 17.1875 12.0156 16.8594 12.0312 16.3906L12.5469 5.16406H2.9375L3.48438 16.3906C3.50781 16.8516 3.84375 17.1875 4.28906 17.1875ZM5.44531 15.8516C5.15625 15.8516 4.96094 15.6719 4.95312 15.3906L4.71875 7.03906C4.71094 6.76562 4.90625 6.57812 5.20312 6.57812C5.48438 6.57812 5.67969 6.75781 5.6875 7.03125L5.92969 15.3906C5.9375 15.6641 5.74219 15.8516 5.44531 15.8516ZM7.75781 15.8516C7.46094 15.8516 7.25781 15.6641 7.25781 15.3906V7.03906C7.25781 6.76562 7.46094 6.57812 7.75781 6.57812C8.05469 6.57812 8.26562 6.76562 8.26562 7.03906V15.3906C8.26562 15.6641 8.05469 15.8516 7.75781 15.8516ZM10.0781 15.8516C9.78125 15.8516 9.58594 15.6641 9.59375 15.3906L9.82812 7.03906C9.83594 6.75781 10.0312 6.57812 10.3125 6.57812C10.6094 6.57812 10.8047 6.76562 10.7969 7.03906L10.5625 15.3906C10.5547 15.6719 10.3594 15.8516 10.0781 15.8516Z", fill: "#6B6B6B" }))); };
var Dashboard = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useState(false), isEditMode = _b[0], setIsEditMode = _b[1];
    var _c = useState(null), selectedFolder = _c[0], setSelectedFolder = _c[1];
    var _d = useState(''), folderName = _d[0], setFolderName = _d[1];
    var _e = useState(''), description = _e[0], setDescription = _e[1];
    var _f = useState([]), folders = _f[0], setFolders = _f[1];
    var _g = useState(true), loading = _g[0], setLoading = _g[1];
    useEffect(function () {
        fetchFolders();
    }, []);
    var fetchFolders = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    return [4 /*yield*/, fetch('/api/folders/', {
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch folders');
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (data.status === 'success') {
                        setFolders(data.folders);
                        toast.success('Successfully loaded folders', { id: 'loading-folders' });
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error fetching folders:', error_1);
                    toast.error('Failed to load folders', { id: 'loading-folders' });
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleCreateFolder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var actionType, headers, response, data, headers, response, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 10, , 11]);
                    actionType = isEditMode ? 'Updating' : 'Creating';
                    if (!(isEditMode && selectedFolder)) return [3 /*break*/, 4];
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/folders/".concat(selectedFolder.id, "/update/"), {
                            method: 'PUT',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                name: folderName,
                                description: description
                            })
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    throw new Error(data.error || 'Failed to update folder');
                case 3:
                    toast.success('Folder updated successfully', { id: 'folder-action' });
                    return [3 /*break*/, 8];
                case 4:
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch('/api/folders/create/', {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                name: folderName,
                                description: description
                            })
                        })];
                case 5:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 7];
                    return [4 /*yield*/, response.json()];
                case 6:
                    data = _a.sent();
                    throw new Error(data.error || 'Failed to create folder');
                case 7:
                    toast.success('Folder created successfully', { id: 'folder-action' });
                    _a.label = 8;
                case 8: 
                // Refresh folders list
                return [4 /*yield*/, fetchFolders()];
                case 9:
                    // Refresh folders list
                    _a.sent();
                    handleCloseModal();
                    return [3 /*break*/, 11];
                case 10:
                    error_2 = _a.sent();
                    console.error('Error saving folder:', error_2);
                    toast.error(error_2 instanceof Error ? error_2.message : 'Failed to save folder', { id: 'folder-action' });
                    return [3 /*break*/, 11];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteFolder = function (folderId) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, data, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/folders/".concat(folderId, "/delete/"), {
                            method: 'DELETE',
                            headers: headers,
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    throw new Error(data.error || 'Failed to delete folder');
                case 3:
                    toast.success('Folder deleted successfully', { id: "delete-folder-".concat(folderId) });
                    return [4 /*yield*/, fetchFolders()];
                case 4:
                    _a.sent();
                    handleCloseModal();
                    return [3 /*break*/, 6];
                case 5:
                    error_3 = _a.sent();
                    console.error('Error deleting folder:', error_3);
                    toast.error(error_3 instanceof Error ? error_3.message : 'Failed to delete folder', { id: "delete-folder-".concat(folderId) });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseModal = function () {
        setIsModalOpen(false);
        setIsEditMode(false);
        setSelectedFolder(null);
        setFolderName('');
        setDescription('');
    };
    var handleViewQuestions = function (folderId) {
        navigate("/app/dashboard/view-questions/".concat(folderId));
    };
    var handleManageQuestions = function () {
        navigate('/app/dashboard/manage-questions');
    };
    return (React.createElement(PageContainer, null,
        React.createElement(FolderSection, null,
            React.createElement(HeaderRow, null,
                React.createElement(Title, null, "Question Folders"),
                React.createElement(CreateButton, { onClick: function () { return setIsModalOpen(true); } }, "Create New Folder")),
            loading ? (React.createElement("div", null, "Loading folders...")) : (React.createElement(FolderGrid, null,
                React.createElement(FolderCard, null,
                    React.createElement(FolderHeader, null,
                        React.createElement(FolderIcon, { color: "#F0E6FF" },
                            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "#8F4ACF" },
                                React.createElement("path", { d: folderIconPath }))),
                        React.createElement(FolderTitle, null, "Favorites")),
                    React.createElement(FolderDescription, null, "Default folder for favorite questions"),
                    React.createElement(ButtonGroup, null,
                        React.createElement(ActionButton, { variant: "primary", onClick: function () { return navigate('/app/dashboard/favorites'); } }, "View Questions"))),
                folders.map(function (folder) { return (React.createElement(FolderCard, { key: folder.id },
                    React.createElement(FolderHeader, null,
                        React.createElement(FolderIcon, null,
                            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor" },
                                React.createElement("path", { d: folderIconPath }))),
                        React.createElement(FolderTitle, null, folder.name)),
                    React.createElement(FolderDescription, null, folder.description || 'No Description'),
                    React.createElement(ButtonGroup, null,
                        React.createElement(ActionButton, { onClick: function () {
                                setSelectedFolder(folder);
                                setFolderName(folder.name);
                                setDescription(folder.description);
                                setIsEditMode(true);
                                setIsModalOpen(true);
                            } }, "Edit"),
                        React.createElement(ActionButton, { onClick: function () { return navigate("/app/dashboard/manage-questions/".concat(folder.id)); } }, "Manage Questions"),
                        React.createElement(ActionButton, { variant: "primary", onClick: function () { return handleViewQuestions(folder.id); } },
                            "View Questions (",
                            folder.question_count,
                            ")")))); })))),
        isModalOpen && (React.createElement(React.Fragment, null,
            React.createElement(ModalOverlay, { onClick: handleCloseModal }),
            React.createElement(Modal, null,
                React.createElement(ModalTitle, null, isEditMode ? 'Edit Folder' : 'Create New Folder'),
                React.createElement(FormGroup, null,
                    React.createElement(FormLabel, null, "Folder Name"),
                    React.createElement(Input, { type: "text", value: folderName, onChange: function (e) { return setFolderName(e.target.value); }, placeholder: "Enter folder name" })),
                React.createElement(FormGroup, null,
                    React.createElement(FormLabel, null, "Description (Optional)"),
                    React.createElement(TextArea, { value: description, onChange: function (e) { return setDescription(e.target.value); }, placeholder: "Enter folder description" })),
                React.createElement(ModalButtonGroup, null,
                    React.createElement(ModalButton, { onClick: function () { return handleDeleteFolder((selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.id) || 0); }, title: isEditMode ? "Delete folder" : "Clear form" },
                        React.createElement(DeleteIcon, null)),
                    React.createElement(ModalButton, { variant: "secondary", onClick: handleCloseModal }, "Cancel"),
                    React.createElement(ModalButton, { variant: "primary", onClick: handleCreateFolder }, isEditMode ? 'Save Changes' : 'Create Folder')))))));
};
export default Dashboard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;
