var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
// Add these styled components at the top after imports
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 680px;\n  height: 400px;\n  background: #FAFAFA;\n  border-radius: 20px;\n  padding: 40px;\n  margin: 100px auto;\n"], ["\n  width: 680px;\n  height: 400px;\n  background: #FAFAFA;\n  border-radius: 20px;\n  padding: 40px;\n  margin: 100px auto;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 28px;\n  text-align: center;\n  margin-bottom: 20px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 28px;\n  text-align: center;\n  margin-bottom: 20px;\n"])));
var Subtitle = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  text-align: center;\n  margin-bottom: 40px;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  text-align: center;\n  margin-bottom: 40px;\n"])));
var Input = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n"])));
var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  margin-top: 20px;\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  margin-top: 20px;\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"])));
var FormContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var ResetPassword = function () {
    var _a = useParams(), uid = _a.uid, token = _a.token;
    var navigate = useNavigate();
    var _b = useState(false), isValidToken = _b[0], setIsValidToken = _b[1];
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    useEffect(function () {
        var validateToken = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, 4, 5]);
                        return [4 /*yield*/, fetch('/api/password-reset/validate/', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ uid: uid, token: token })
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        setIsValidToken(data.valid);
                        if (!data.valid) {
                            toast.error('Invalid or expired reset link');
                            navigate('/signin');
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        toast.error('Failed to validate reset link');
                        navigate('/signin');
                        return [3 /*break*/, 5];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        validateToken();
    }, [uid, token, navigate]);
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/password-reset/".concat(uid, "/").concat(token, "/"), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(values)
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (response.ok) {
                        toast.success('Password has been reset successfully');
                        navigate('/signin');
                    }
                    else {
                        throw new Error(data.error || 'Failed to reset password');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    toast.error(error_2 instanceof Error ? error_2.message : 'Failed to reset password');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (isLoading) {
        return React.createElement("div", null, "Validating reset link...");
    }
    if (!isValidToken) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Reset Your Password"),
        React.createElement(Subtitle, null, "Please enter your new password below."),
        React.createElement(Formik, { initialValues: { new_password: '', confirm_password: '' }, validationSchema: Yup.object({
                new_password: Yup.string()
                    .min(8, 'Password must be at least 8 characters')
                    .required('Password is required'),
                confirm_password: Yup.string()
                    .oneOf([Yup.ref('new_password')], 'Passwords must match')
                    .required('Please confirm your password'),
            }), onSubmit: handleSubmit }, function (_a) {
            var values = _a.values, handleChange = _a.handleChange, isValid = _a.isValid;
            return (React.createElement(Form, null,
                React.createElement(FormContainer, null,
                    React.createElement(Input, { type: "password", name: "new_password", placeholder: "New Password", value: values.new_password, onChange: handleChange }),
                    React.createElement(Input, { type: "password", name: "confirm_password", placeholder: "Confirm Password", value: values.confirm_password, onChange: handleChange }),
                    React.createElement(Button, { type: "submit", disabled: !isValid }, "Reset Password"))));
        })));
};
export default ResetPassword;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
