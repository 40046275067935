var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel, Typography, Switch, FormControlLabel, IconButton, } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { PlayArrow } from '@mui/icons-material';
import { getCookie } from '../../utils/cookies';
var ScheduleCard = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        border: "1px solid ".concat(theme.palette.divider),
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    });
});
var Schedules = function (_a) {
    var connectionId = _a.connectionId;
    var _b = useState([]), schedules = _b[0], setSchedules = _b[1];
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = useState(null), editingSchedule = _d[0], setEditingSchedule = _d[1];
    var _e = useState({
        name: '',
        frequency: 'DAILY',
        start_time: '00:00',
        dataset_id: '',
        write_disposition: 'WRITE_APPEND',
        cleaning_options: {},
        enabled: true
    }), formData = _e[0], setFormData = _e[1];
    useEffect(function () {
        fetchSchedules();
    }, [connectionId]);
    var fetchSchedules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/api/connections/".concat(connectionId, "/schedules/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    setSchedules(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    toast.error('Failed to fetch schedules');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!editingSchedule) return [3 /*break*/, 3];
                    return [4 /*yield*/, axios.put("/api/connections/".concat(connectionId, "/schedules/").concat(editingSchedule.id, "/"), formData, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true
                        })];
                case 2:
                    _a.sent();
                    toast.success('Schedule updated successfully');
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, axios.post("/api/connections/".concat(connectionId, "/schedules/"), formData, {
                        headers: {
                            'X-CSRFToken': getCookie('csrftoken'),
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true
                    })];
                case 4:
                    _a.sent();
                    toast.success('Schedule created successfully');
                    _a.label = 5;
                case 5:
                    handleCloseModal();
                    fetchSchedules();
                    return [3 /*break*/, 7];
                case 6:
                    error_2 = _a.sent();
                    toast.error(editingSchedule ? 'Failed to update schedule' : 'Failed to create schedule');
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (scheduleId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm('Are you sure you want to delete this schedule?')) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.delete("/api/connections/".concat(connectionId, "/schedules/").concat(scheduleId, "/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            withCredentials: true
                        })];
                case 2:
                    _a.sent();
                    toast.success('Schedule deleted successfully');
                    fetchSchedules();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    toast.error('Failed to delete schedule');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleEdit = function (schedule) {
        setEditingSchedule(schedule);
        setFormData({
            name: schedule.name,
            frequency: schedule.frequency,
            start_time: schedule.start_time,
            dataset_id: schedule.dataset_id,
            write_disposition: schedule.write_disposition,
            cleaning_options: schedule.cleaning_options,
            enabled: schedule.enabled
        });
        setIsModalOpen(true);
    };
    var handleCloseModal = function () {
        setIsModalOpen(false);
        setEditingSchedule(null);
        setFormData({
            name: '',
            frequency: 'DAILY',
            start_time: '00:00',
            dataset_id: '',
            write_disposition: 'WRITE_APPEND',
            cleaning_options: {},
            enabled: true
        });
    };
    var handleTriggerSchedule = function (scheduleId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.post("/api/schedules/".concat(scheduleId, "/trigger/"), {}, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        toast.success('Schedule triggered successfully');
                        fetchSchedules(); // Refresh the schedules list to update last_run time
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    toast.error('Failed to trigger schedule');
                    console.error('Error triggering schedule:', error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Box, null,
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 3 } },
            React.createElement(Typography, { variant: "h6" }, "ETL Schedules"),
            React.createElement(Button, { variant: "contained", onClick: function () { return setIsModalOpen(true); } }, "Add Schedule")),
        schedules.map(function (schedule) { return (React.createElement(ScheduleCard, { key: schedule.id },
            React.createElement(Box, null,
                React.createElement(Typography, { variant: "subtitle1" }, schedule.name),
                React.createElement(Typography, { variant: "body2", color: "textSecondary" },
                    schedule.frequency,
                    " at ",
                    schedule.start_time),
                React.createElement(Typography, { variant: "body2", color: "textSecondary" },
                    "Dataset: ",
                    schedule.dataset_id),
                React.createElement(Typography, { variant: "body2", color: "textSecondary" },
                    "Next run: ",
                    schedule.next_run ? new Date(schedule.next_run).toLocaleString() : 'Not scheduled'),
                schedule.last_run && (React.createElement(Typography, { variant: "body2", color: "textSecondary" },
                    "Last run: ",
                    new Date(schedule.last_run).toLocaleString()))),
            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: schedule.enabled, onChange: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var error_5;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, axios.put("/api/connections/".concat(connectionId, "/schedules/").concat(schedule.id, "/"), __assign(__assign({}, schedule), { enabled: !schedule.enabled }), {
                                                headers: {
                                                    'X-CSRFToken': getCookie('csrftoken'),
                                                },
                                                withCredentials: true
                                            })];
                                    case 1:
                                        _a.sent();
                                        fetchSchedules();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        error_5 = _a.sent();
                                        toast.error('Failed to update schedule status');
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }), label: schedule.enabled ? 'Enabled' : 'Disabled' }),
                React.createElement(IconButton, { onClick: function () { return handleTriggerSchedule(schedule.id); }, title: "Run Now", color: "primary", sx: { mr: 1 } },
                    React.createElement(PlayArrow, null)),
                React.createElement(Button, { startIcon: React.createElement(EditIcon, null), onClick: function () { return handleEdit(schedule); }, sx: { mr: 1 } }, "Edit"),
                React.createElement(Button, { startIcon: React.createElement(DeleteIcon, null), color: "error", onClick: function () { return handleDelete(schedule.id); } }, "Delete")))); }),
        React.createElement(Dialog, { open: isModalOpen, onClose: handleCloseModal, maxWidth: "sm", fullWidth: true },
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(DialogTitle, null, editingSchedule ? 'Edit Schedule' : 'Create Schedule'),
                React.createElement(DialogContent, null,
                    React.createElement(TextField, { fullWidth: true, label: "Name", value: formData.name, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { name: e.target.value })); }, margin: "normal", required: true }),
                    React.createElement(FormControl, { fullWidth: true, margin: "normal", required: true },
                        React.createElement(InputLabel, null, "Frequency"),
                        React.createElement(Select, { value: formData.frequency, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { frequency: e.target.value })); }, label: "Frequency" },
                            React.createElement(MenuItem, { value: "HOURLY" }, "Hourly"),
                            React.createElement(MenuItem, { value: "DAILY" }, "Daily"),
                            React.createElement(MenuItem, { value: "WEEKLY" }, "Weekly"),
                            React.createElement(MenuItem, { value: "MONTHLY" }, "Monthly"))),
                    React.createElement(TextField, { fullWidth: true, label: "Start Time", type: "time", value: formData.start_time, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { start_time: e.target.value })); }, margin: "normal", required: true, InputLabelProps: { shrink: true } }),
                    React.createElement(TextField, { fullWidth: true, label: "Dataset ID", value: formData.dataset_id, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { dataset_id: e.target.value })); }, margin: "normal", required: true }),
                    React.createElement(FormControl, { fullWidth: true, margin: "normal", required: true },
                        React.createElement(InputLabel, null, "Write Disposition"),
                        React.createElement(Select, { value: formData.write_disposition, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { write_disposition: e.target.value })); }, label: "Write Disposition" },
                            React.createElement(MenuItem, { value: "WRITE_APPEND" }, "Append"),
                            React.createElement(MenuItem, { value: "WRITE_TRUNCATE" }, "Truncate"),
                            React.createElement(MenuItem, { value: "WRITE_EMPTY" }, "Empty"))),
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: formData.enabled, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { enabled: e.target.checked })); } }), label: "Enabled", sx: { mt: 2 } })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseModal }, "Cancel"),
                    React.createElement(Button, { type: "submit", variant: "contained" }, editingSchedule ? 'Update' : 'Create'))))));
};
export default Schedules;
