var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import SignIn from './components/Auth/SignIn';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import SupportPage from './components/SupportPage';
import ContactPage from './components/ContactPage';
import MainApp from './components/MainApp/MainApp';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import styled from 'styled-components';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
var AppContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 1440px;\n  margin: 0 auto;\n  background: #FFFFFF;\n  border-radius: 20px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n"], ["\n  width: 1440px;\n  margin: 0 auto;\n  background: #FFFFFF;\n  border-radius: 20px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n"])));
var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1 0 auto;\n"], ["\n  flex: 1 0 auto;\n"])));
var App = function () {
    var _a = useState(false), isAuthenticated = _a[0], setIsAuthenticated = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(true), sidebarOpen = _c[0], setSidebarOpen = _c[1];
    var checkAuth = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, isAuthed, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, axios.get('/api/check-auth/', {
                            withCredentials: true
                        })];
                case 1:
                    response = _b.sent();
                    isAuthed = response.status === 200 && ((_a = response.data) === null || _a === void 0 ? void 0 : _a.authenticated);
                    setIsAuthenticated(isAuthed);
                    if (isAuthed) {
                        localStorage.setItem('isAuthenticated', 'true');
                    }
                    else {
                        localStorage.removeItem('isAuthenticated');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _b.sent();
                    console.error('Auth check error:', error_1);
                    setIsAuthenticated(false);
                    localStorage.removeItem('isAuthenticated');
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Check auth status on mount
    useEffect(function () {
        // Check localStorage first for immediate UI update
        var storedAuth = localStorage.getItem('isAuthenticated');
        if (storedAuth === 'true') {
            setIsAuthenticated(true);
        }
        // Then verify with server
        checkAuth();
    }, []);
    var handleAuthChange = function (newState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsAuthenticated(newState);
                    if (!newState) return [3 /*break*/, 1];
                    localStorage.setItem('isAuthenticated', 'true');
                    return [3 /*break*/, 3];
                case 1:
                    localStorage.removeItem('isAuthenticated');
                    // Double-check auth status after signout
                    return [4 /*yield*/, checkAuth()];
                case 2:
                    // Double-check auth status after signout
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (isLoading) {
        return React.createElement("div", null, "Loading...");
    }
    return (React.createElement(Router, null,
        React.createElement(AppContainer, null,
            React.createElement(ContentContainer, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "/", element: React.createElement(React.Fragment, null,
                            React.createElement(Navbar, null),
                            React.createElement(HomePage, null)) }),
                    React.createElement(Route, { path: "/about", element: React.createElement(React.Fragment, null,
                            React.createElement(Navbar, null),
                            React.createElement(AboutPage, null)) }),
                    React.createElement(Route, { path: "/support", element: React.createElement(React.Fragment, null,
                            React.createElement(Navbar, null),
                            React.createElement(SupportPage, null)) }),
                    React.createElement(Route, { path: "/contact", element: React.createElement(React.Fragment, null,
                            React.createElement(Navbar, null),
                            React.createElement(ContactPage, null)) }),
                    React.createElement(Route, { path: "/signin", element: isAuthenticated ? (React.createElement(Navigate, { to: "/app", replace: true })) : (React.createElement(React.Fragment, null,
                            React.createElement(Navbar, null),
                            React.createElement(SignIn, { setIsAuthenticated: handleAuthChange }))) }),
                    React.createElement(Route, { path: "/app/*", element: React.createElement(ProtectedRoute, { isAuthenticated: isAuthenticated },
                            React.createElement(MainApp, { setIsAuthenticated: handleAuthChange })) }),
                    React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/", replace: true }) }),
                    React.createElement(Route, { path: "/forgot-password", element: React.createElement(ForgotPassword, null) }),
                    React.createElement(Route, { path: "/reset-password/:uid/:token", element: React.createElement(ResetPassword, null) })))),
        React.createElement(ToastContainer, { position: "top-right", autoClose: 3000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true })));
};
export default App;
var templateObject_1, templateObject_2;
