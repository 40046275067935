var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getCookie } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n"], ["\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 22px;\n  line-height: 110%;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 22px;\n  line-height: 110%;\n  color: #000000;\n"])));
var TabContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n  margin-bottom: 24px;\n"], ["\n  display: flex;\n  gap: 12px;\n  margin-bottom: 24px;\n"])));
var Tab = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 8px 16px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 20px;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F8F5FF;\n    border-color: #8F4ACF;\n    color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 16px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 20px;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F8F5FF;\n    border-color: #8F4ACF;\n    color: #8F4ACF;\n  }\n"])), function (props) { return props.active ? '#F8F5FF' : '#FFFFFF'; }, function (props) { return props.active ? '#8F4ACF' : '#ECEAFF'; }, function (props) { return props.active ? '#8F4ACF' : '#000000'; });
var QuestionList = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  overflow: hidden;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  overflow: hidden;\n"])));
var QuestionItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 150px 120px;\n  gap: 24px;\n  padding: 24px;\n  border-bottom: 1px solid #ECEAFF;\n\n  &:last-child {\n    border-bottom: none;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 150px 120px;\n  gap: 24px;\n  padding: 24px;\n  border-bottom: 1px solid #ECEAFF;\n\n  &:last-child {\n    border-bottom: none;\n  }\n"])));
var QuestionText = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  line-height: 140%;\n"], ["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  line-height: 140%;\n"])));
var DateText = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #666666;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #666666;\n"])));
var FavoriteButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  &:hover {\n    color: #8F4ACF;\n  }\n"], ["\n  background: none;\n  border: none;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  &:hover {\n    color: #8F4ACF;\n  }\n"])), function (props) { return props.isFavorite ? '#8F4ACF' : '#666666'; });
var ListHeader = styled(QuestionItem)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: #FAFAFA;\n  font-weight: 600;\n"], ["\n  background: #FAFAFA;\n  font-weight: 600;\n"])));
var Pagination = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  margin-top: 20px;\n"], ["\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  margin-top: 20px;\n"])));
var PageButton = styled.button(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 8px 12px;\n  border: 1px solid ", ";\n  border-radius: 8px;\n  background: ", ";\n  color: ", ";\n  cursor: pointer;\n  \n  &:hover {\n    background: #F8F5FF;\n    border-color: #8F4ACF;\n    color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 12px;\n  border: 1px solid ", ";\n  border-radius: 8px;\n  background: ", ";\n  color: ", ";\n  cursor: pointer;\n  \n  &:hover {\n    background: #F8F5FF;\n    border-color: #8F4ACF;\n    color: #8F4ACF;\n  }\n"])), function (props) { return props.active ? '#8F4ACF' : '#ECEAFF'; }, function (props) { return props.active ? '#F8F5FF' : 'white'; }, function (props) { return props.active ? '#8F4ACF' : '#666'; });
var SortButton = styled.button(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  padding: 4px 8px;\n  background: none;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n"], ["\n  padding: 4px 8px;\n  background: none;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n"])), function (props) { return props.active ? '#8F4ACF' : '#666'; });
var FilterSection = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n  margin-bottom: 24px;\n  align-items: center;\n"], ["\n  display: flex;\n  gap: 16px;\n  margin-bottom: 24px;\n  align-items: center;\n"])));
var SearchInput = styled.input(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  width: 300px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  width: 300px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var FilterSelect = styled.select(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  background: white;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  background: white;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var QuestionHistory = function () {
    var _a = useState('recent'), activeTab = _a[0], setActiveTab = _a[1];
    var _b = useState([]), questions = _b[0], setQuestions = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(1), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = useState('desc'), sortOrder = _e[0], setSortOrder = _e[1];
    var questionsPerPage = 10;
    var navigate = useNavigate();
    var _f = useState(''), searchTerm = _f[0], setSearchTerm = _f[1];
    var _g = useState('all'), categoryFilter = _g[0], setCategoryFilter = _g[1];
    var _h = useState([]), categories = _h[0], setCategories = _h[1];
    useEffect(function () {
        var fetchQuestions = function () { return __awaiter(void 0, void 0, void 0, function () {
            var headers, response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, 4, 5]);
                        headers = {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': getCookie('csrftoken') || ''
                        };
                        return [4 /*yield*/, fetch('/api/questions/', {
                                method: 'GET',
                                headers: headers,
                                credentials: 'include'
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok)
                            throw new Error('Failed to fetch questions');
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        setQuestions(data.questions);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error fetching questions:', error_1);
                        toast.error('Failed to load questions');
                        return [3 /*break*/, 5];
                    case 4:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchQuestions();
    }, []);
    useEffect(function () {
        var uniqueCategories = Array.from(new Set(questions.map(function (q) { return q.category; }).filter(function (c) { return !!c; })));
        setCategories(uniqueCategories);
    }, [questions]);
    var toggleFavorite = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, data_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/analysis-results/".concat(id, "/favorite/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok)
                        throw new Error('Failed to update favorite status');
                    return [4 /*yield*/, response.json()];
                case 2:
                    data_1 = _a.sent();
                    setQuestions(questions.map(function (q) {
                        return q.id === id ? __assign(__assign({}, q), { is_favorite: data_1.is_favorite }) : q;
                    }));
                    toast.success(data_1.message);
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error toggling favorite:', error_2);
                    toast.error('Failed to update favorite status');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleQuestionClick = function (id) {
        navigate("/app/analysis/result/".concat(id));
    };
    var sortQuestions = function (questionsToSort) {
        return __spreadArray([], questionsToSort, true).sort(function (a, b) {
            var dateA = new Date(a.created_at).getTime();
            var dateB = new Date(b.created_at).getTime();
            return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        });
    };
    var toggleSortOrder = function () {
        setSortOrder(function (prev) { return prev === 'desc' ? 'asc' : 'desc'; });
    };
    var getFilteredQuestions = function () {
        var filtered = activeTab === 'recent' ? questions : questions.filter(function (q) { return q.is_favorite; });
        if (searchTerm) {
            filtered = filtered.filter(function (q) {
                return q.question.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }
        if (categoryFilter !== 'all') {
            filtered = filtered.filter(function (q) { return q.category === categoryFilter; });
        }
        return sortQuestions(filtered);
    };
    // Get current questions
    var indexOfLastQuestion = currentPage * questionsPerPage;
    var indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    var filteredQuestions = getFilteredQuestions();
    var currentQuestions = filteredQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);
    var totalPages = Math.ceil(filteredQuestions.length / questionsPerPage);
    var paginate = function (pageNumber) { return setCurrentPage(pageNumber); };
    if (loading)
        return React.createElement("div", null, "Loading...");
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Title, null, "Question History")),
        React.createElement(TabContainer, null,
            React.createElement(Tab, { active: activeTab === 'recent', onClick: function () { return setActiveTab('recent'); } }, "Recent Questions"),
            React.createElement(Tab, { active: activeTab === 'favorite', onClick: function () { return setActiveTab('favorite'); } }, "Favorite Questions")),
        React.createElement(FilterSection, null,
            React.createElement(SearchInput, { placeholder: "Search questions...", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); } }),
            categories.length > 0 && (React.createElement(FilterSelect, { value: categoryFilter, onChange: function (e) { return setCategoryFilter(e.target.value); } },
                React.createElement("option", { value: "all" }, "All Categories"),
                categories.map(function (category) { return (React.createElement("option", { key: category, value: category }, category)); })))),
        React.createElement(QuestionList, null,
            React.createElement(ListHeader, null,
                React.createElement(QuestionText, null, "Question"),
                React.createElement(DateText, null,
                    "Date Added",
                    React.createElement(SortButton, { active: true, onClick: toggleSortOrder }, sortOrder === 'desc' ? '↓' : '↑')),
                React.createElement("div", null, "Actions")),
            currentQuestions.map(function (question) { return (React.createElement(QuestionItem, { key: question.id },
                React.createElement(QuestionText, { onClick: function () { return handleQuestionClick(question.id); }, style: { cursor: 'pointer' } }, question.question),
                React.createElement(DateText, null, new Date(question.created_at).toLocaleDateString()),
                React.createElement(FavoriteButton, { isFavorite: question.is_favorite, onClick: function () { return toggleFavorite(question.id); } }, question.is_favorite ? '★ Favorite' : '☆ Favorite'))); })),
        totalPages > 1 && (React.createElement(Pagination, null,
            React.createElement(PageButton, { onClick: function () { return paginate(currentPage - 1); }, disabled: currentPage === 1 }, "Previous"),
            Array.from({ length: totalPages }, function (_, i) { return (React.createElement(PageButton, { key: i + 1, active: currentPage === i + 1, onClick: function () { return paginate(i + 1); } }, i + 1)); }),
            React.createElement(PageButton, { onClick: function () { return paginate(currentPage + 1); }, disabled: currentPage === totalPages }, "Next")))));
};
export default QuestionHistory;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
