var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp, FiBook, FiLogIn, FiDatabase, FiUsers, FiUserPlus, FiFileText, FiSearch, FiInfo } from 'react-icons/fi';
import FooterMain from './Layout/footer_main';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n\n  @media (max-width: 768px) {\n    padding-top: 80px;\n  }\n"], ["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n\n  @media (max-width: 768px) {\n    padding-top: 80px;\n  }\n"])));
var MainContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 15px;\n  text-align: center;\n  box-sizing: border-box;\n\n  @media (min-width: 768px) {\n    padding: 0 20px;\n  }\n"], ["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 15px;\n  text-align: center;\n  box-sizing: border-box;\n\n  @media (min-width: 768px) {\n    padding: 0 20px;\n  }\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: clamp(32px, 6vw, 58px);\n  font-weight: 700;\n  line-height: 1.2;\n  margin-bottom: 20px;\n  color: #000000;\n  padding: 0 10px;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-clip: text;\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 24px;\n    padding: 0;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: clamp(32px, 6vw, 58px);\n  font-weight: 700;\n  line-height: 1.2;\n  margin-bottom: 20px;\n  color: #000000;\n  padding: 0 10px;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-clip: text;\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 24px;\n    padding: 0;\n  }\n"])));
var Subtitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: clamp(14px, 2.5vw, 18px);\n  line-height: 1.5;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 40px;\n  padding: 0 10px;\n\n  span {\n    color: #A65CE8;\n    position: relative;\n    \n    &::after {\n      content: '';\n      position: absolute;\n      bottom: -2px;\n      left: 0;\n      width: 100%;\n      height: 2px;\n      background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n      transform: scaleX(0);\n      transition: transform 0.3s ease;\n    }\n    \n    &:hover::after {\n      transform: scaleX(1);\n    }\n  }\n\n  @media (min-width: 768px) {\n    margin: 0 auto 60px;\n    padding: 0;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: clamp(14px, 2.5vw, 18px);\n  line-height: 1.5;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 40px;\n  padding: 0 10px;\n\n  span {\n    color: #A65CE8;\n    position: relative;\n    \n    &::after {\n      content: '';\n      position: absolute;\n      bottom: -2px;\n      left: 0;\n      width: 100%;\n      height: 2px;\n      background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n      transform: scaleX(0);\n      transition: transform 0.3s ease;\n    }\n    \n    &:hover::after {\n      transform: scaleX(1);\n    }\n  }\n\n  @media (min-width: 768px) {\n    margin: 0 auto 60px;\n    padding: 0;\n  }\n"])));
var AccordionContainer = styled(motion.div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 20px;\n  margin-bottom: 40px;\n  position: relative;\n  box-sizing: border-box;\n  overflow: hidden;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 20px 20px 0 0;\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n    border-radius: 30px;\n\n    &::before {\n      border-radius: 30px 30px 0 0;\n    }\n  }\n\n  @media (min-width: 1024px) {\n    margin-bottom: 80px;\n  }\n"], ["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 20px;\n  margin-bottom: 40px;\n  position: relative;\n  box-sizing: border-box;\n  overflow: hidden;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 20px 20px 0 0;\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n    border-radius: 30px;\n\n    &::before {\n      border-radius: 30px 30px 0 0;\n    }\n  }\n\n  @media (min-width: 1024px) {\n    margin-bottom: 80px;\n  }\n"])));
var AccordionHeader = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 25px 30px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  background: ", ";\n\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 25px 30px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  background: ", ";\n\n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.isOpen ? 'linear-gradient(to right, #ffffff, #f8f9fa)' : 'white'; }, function (props) { return props.isOpen ? 'linear-gradient(to right, #ffffff, #f0f0f0)' : '#f9f9f9'; });
var AccordionTitleGroup = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"])));
var SectionIcon = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  color: white;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 1.2rem;\n"], ["\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  color: white;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 1.2rem;\n"])));
var AccordionTitle = styled.h2(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: clamp(18px, 3vw, 24px);\n  font-weight: 600;\n  color: #000000;\n  margin: 0;\n"], ["\n  font-family: 'Inter';\n  font-size: clamp(18px, 3vw, 24px);\n  font-weight: 600;\n  color: #000000;\n  margin: 0;\n"])));
var AccordionIcon = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: #7094F4;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: transform 0.3s ease;\n"], ["\n  color: #7094F4;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: transform 0.3s ease;\n"])));
var AccordionContent = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: white;\n  padding: ", ";\n  max-height: ", ";\n  overflow: hidden;\n  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);\n  opacity: ", ";\n\n  @media (min-width: 768px) {\n    padding: ", ";\n  }\n\n  @media (min-width: 1024px) {\n    padding: ", ";\n  }\n"], ["\n  background: white;\n  padding: ", ";\n  max-height: ", ";\n  overflow: hidden;\n  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);\n  opacity: ", ";\n\n  @media (min-width: 768px) {\n    padding: ", ";\n  }\n\n  @media (min-width: 1024px) {\n    padding: ", ";\n  }\n"])), function (props) { return props.isOpen ? '0 30px 30px' : '0 30px'; }, function (props) { return props.isOpen ? '3000px' : '0'; }, function (props) { return props.isOpen ? '1' : '0'; }, function (props) { return props.isOpen ? '0 40px 40px' : '0 40px'; }, function (props) { return props.isOpen ? '0 60px 60px' : '0 60px'; });
var SectionContent = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: clamp(14px, 2.5vw, 16px);\n  line-height: 1.6;\n  color: #000000;\n  text-align: left;\n"], ["\n  font-family: 'Inter';\n  font-size: clamp(14px, 2.5vw, 16px);\n  line-height: 1.6;\n  color: #000000;\n  text-align: left;\n"])));
var StepTitle = styled.h3(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: clamp(16px, 2.5vw, 20px);\n  font-weight: 600;\n  color: #7094F4;\n  margin: 2rem 0 1rem;\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n  \n  &:before {\n    content: '';\n    display: block;\n    width: 12px;\n    height: 12px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 50%;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: clamp(16px, 2.5vw, 20px);\n  font-weight: 600;\n  color: #7094F4;\n  margin: 2rem 0 1rem;\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n  \n  &:before {\n    content: '';\n    display: block;\n    width: 12px;\n    height: 12px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 50%;\n  }\n"])));
var StepDescription = styled.p(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  margin-bottom: 1.5rem;\n  font-size: clamp(14px, 2vw, 16px);\n  color: #333333;\n"], ["\n  margin-bottom: 1.5rem;\n  font-size: clamp(14px, 2vw, 16px);\n  color: #333333;\n"])));
var List = styled.ul(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-left: 2rem;\n  margin-bottom: 2rem;\n"], ["\n  margin-left: 2rem;\n  margin-bottom: 2rem;\n"])));
var ListItem = styled.li(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  margin-bottom: 0.8rem;\n  position: relative;\n  padding-left: 0.5rem;\n  font-size: clamp(14px, 2vw, 16px);\n  \n  strong {\n    color: #7094F4;\n    font-weight: 600;\n  }\n"], ["\n  margin-bottom: 0.8rem;\n  position: relative;\n  padding-left: 0.5rem;\n  font-size: clamp(14px, 2vw, 16px);\n  \n  strong {\n    color: #7094F4;\n    font-weight: 600;\n  }\n"])));
var CodeBlock = styled.pre(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  background-color: #f8f9fa;\n  padding: 1.5rem;\n  border-radius: 8px;\n  overflow-x: auto;\n  margin: 1.5rem 0;\n  font-family: 'Courier New', Courier, monospace;\n  border-left: 4px solid #7094F4;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);\n  font-size: 0.9rem;\n"], ["\n  background-color: #f8f9fa;\n  padding: 1.5rem;\n  border-radius: 8px;\n  overflow-x: auto;\n  margin: 1.5rem 0;\n  font-family: 'Courier New', Courier, monospace;\n  border-left: 4px solid #7094F4;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);\n  font-size: 0.9rem;\n"])));
var Note = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  background-color: #f8f9fa;\n  border-left: 4px solid #7094F4;\n  padding: 1.5rem;\n  margin: 2rem 0;\n  border-radius: 0 8px 8px 0;\n  position: relative;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);\n"], ["\n  background-color: #f8f9fa;\n  border-left: 4px solid #7094F4;\n  padding: 1.5rem;\n  margin: 2rem 0;\n  border-radius: 0 8px 8px 0;\n  position: relative;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);\n"])));
var NoteIcon = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  position: absolute;\n  top: -12px;\n  left: -12px;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  color: white;\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n"], ["\n  position: absolute;\n  top: -12px;\n  left: -12px;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  color: white;\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n"])));
var BackgroundDecoration = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  position: absolute;\n  width: 300px;\n  height: 300px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -100px;\n    right: -100px;\n  }\n  \n  &.bottom-left {\n    bottom: -100px;\n    left: -100px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n\n  @media (min-width: 768px) {\n    width: 450px;\n    height: 450px;\n\n    &.top-right {\n      top: -150px;\n      right: -150px;\n    }\n    \n    &.bottom-left {\n      bottom: -150px;\n      left: -150px;\n    }\n  }\n\n  @media (min-width: 1024px) {\n    width: 600px;\n    height: 600px;\n\n    &.top-right {\n      top: -200px;\n      right: -200px;\n    }\n    \n    &.bottom-left {\n      bottom: -200px;\n      left: -200px;\n    }\n  }\n"], ["\n  position: absolute;\n  width: 300px;\n  height: 300px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -100px;\n    right: -100px;\n  }\n  \n  &.bottom-left {\n    bottom: -100px;\n    left: -100px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n\n  @media (min-width: 768px) {\n    width: 450px;\n    height: 450px;\n\n    &.top-right {\n      top: -150px;\n      right: -150px;\n    }\n    \n    &.bottom-left {\n      bottom: -150px;\n      left: -150px;\n    }\n  }\n\n  @media (min-width: 1024px) {\n    width: 600px;\n    height: 600px;\n\n    &.top-right {\n      top: -200px;\n      right: -200px;\n    }\n    \n    &.bottom-left {\n      bottom: -200px;\n      left: -200px;\n    }\n  }\n"])));
var CustomFooterContainer = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  margin-top: 0;\n  width: 100%;\n  \n  & footer {\n    margin-top: 0 !important;\n    padding-bottom: 0;\n  }\n"], ["\n  margin-top: 0;\n  width: 100%;\n  \n  & footer {\n    margin-top: 0 !important;\n    padding-bottom: 0;\n  }\n"])));
var Accordion = function (_a) {
    var title = _a.title, children = _a.children, _b = _a.defaultOpen, defaultOpen = _b === void 0 ? false : _b, icon = _a.icon, step = _a.step;
    var _c = useState(defaultOpen), isOpen = _c[0], setIsOpen = _c[1];
    return (React.createElement(AccordionContainer, { initial: { opacity: 0, y: 20 }, whileInView: { opacity: 1, y: 0 }, viewport: { once: true }, transition: { delay: step * 0.1 } },
        React.createElement(AccordionHeader, { isOpen: isOpen, onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement(AccordionTitleGroup, null,
                React.createElement(SectionIcon, null, icon),
                React.createElement(AccordionTitle, null, title)),
            React.createElement(AccordionIcon, null, isOpen ? React.createElement(FiChevronUp, { size: 24 }) : React.createElement(FiChevronDown, { size: 24 }))),
        React.createElement(AccordionContent, { isOpen: isOpen }, children)));
};
var DocumentationPage = function () {
    return (React.createElement(PageContainer, null,
        React.createElement(BackgroundDecoration, { className: "top-right" }),
        React.createElement(BackgroundDecoration, { className: "bottom-left" }),
        React.createElement(MainContent, null,
            React.createElement(Title, null,
                React.createElement(FiBook, { style: { marginRight: '15px', verticalAlign: 'middle' } }),
                "Cognitio ",
                React.createElement("span", null, "Documentation")),
            React.createElement(Subtitle, null,
                "A comprehensive guide to help you ",
                React.createElement("span", null, "set up"),
                " and start ",
                React.createElement("span", null, "using our data analytics platform")),
            React.createElement(Accordion, { title: "1. Login with Admin Account", defaultOpen: true, icon: React.createElement(FiLogIn, null), step: 1 },
                React.createElement(SectionContent, null,
                    React.createElement(StepDescription, null, "To get started with Cognitio Intelligence, you need to log in with the admin account provided to you. Instead of creating a new account, you will access the system using this admin account."),
                    React.createElement(StepTitle, null, "Logging in with Admin Account"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Click \"Sign In\" on the homepage"),
                        React.createElement(ListItem, null, "Enter the admin email address provided to you"),
                        React.createElement(ListItem, null, "Enter the password provided to you"),
                        React.createElement(ListItem, null, "Click \"Sign In\"")),
                    React.createElement(StepTitle, null, "Security Settings After First Login"),
                    React.createElement(StepDescription, null, "After your first login, it is recommended to change your password for security reasons:"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Click on the profile icon in the top right corner"),
                        React.createElement(ListItem, null, "Select \"Settings\""),
                        React.createElement(ListItem, null, "Go to the \"Security\" tab"),
                        React.createElement(ListItem, null, "Click \"Change Password\""),
                        React.createElement(ListItem, null, "Create a new, strong password and save it")),
                    React.createElement(Note, null,
                        React.createElement(NoteIcon, null,
                            React.createElement(FiInfo, null)),
                        "The admin account has full permissions on the platform. You can configure all settings, manage users, and access data sources."))),
            React.createElement(Accordion, { title: "2. Creating Data Connections", icon: React.createElement(FiDatabase, null), step: 2 },
                React.createElement(SectionContent, null,
                    React.createElement(StepDescription, null, "The first step to start using the platform is to create connections to your data sources. In this step, you will connect various data sources to the platform."),
                    React.createElement(StepTitle, null, "Supported Data Sources"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null,
                            React.createElement("strong", null, "BigQuery"),
                            ": Connect to Google BigQuery datasets"),
                        React.createElement(ListItem, null,
                            React.createElement("strong", null, "PostgreSQL"),
                            ": Connect to PostgreSQL databases"),
                        React.createElement(ListItem, null,
                            React.createElement("strong", null, "MySQL"),
                            ": Connect to MySQL databases"),
                        React.createElement(ListItem, null,
                            React.createElement("strong", null, "File Upload"),
                            ": Upload CSV, Excel, or JSON files"),
                        React.createElement(ListItem, null,
                            React.createElement("strong", null, "Snowflake"),
                            ": Connect to Snowflake data warehouses")),
                    React.createElement(StepTitle, null, "Adding a New Connection"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Click on \"Data Sources\" in the left menu"),
                        React.createElement(ListItem, null, "Click \"Add New Connection\""),
                        React.createElement(ListItem, null, "Select your data source type"),
                        React.createElement(ListItem, null, "Enter the required connection details (credentials, host, etc.)"),
                        React.createElement(ListItem, null, "Test the connection"),
                        React.createElement(ListItem, null, "Save the connection")),
                    React.createElement(StepTitle, null, "BigQuery Connection Example"),
                    React.createElement(StepDescription, null, "For BigQuery connections, you'll need:"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "A Google Cloud service account JSON key file"),
                        React.createElement(ListItem, null, "Project ID"),
                        React.createElement(ListItem, null, "Dataset ID")),
                    React.createElement(CodeBlock, null, "{\n  \"type\": \"service_account\",\n  \"project_id\": \"your-project-id\",\n  \"private_key_id\": \"your-private-key-id\",\n  \"private_key\": \"-----BEGIN PRIVATE KEY-----\\n...\\n-----END PRIVATE KEY-----\\n\",\n  \"client_email\": \"your-service-account@your-project-id.iam.gserviceaccount.com\",\n  \"client_id\": \"your-client-id\",\n  \"auth_uri\": \"https://accounts.google.com/o/oauth2/auth\",\n  \"token_uri\": \"https://oauth2.googleapis.com/token\",\n  \"auth_provider_x509_cert_url\": \"https://www.googleapis.com/oauth2/v1/certs\",\n  \"client_x509_cert_url\": \"https://www.googleapis.com/robot/v1/metadata/x509/your-service-account%40your-project-id.iam.gserviceaccount.com\"\n}"),
                    React.createElement(Note, null,
                        React.createElement(NoteIcon, null,
                            React.createElement(FiInfo, null)),
                        "After creating a connection, the tables and schemas in your data source will be automatically discovered. This process may take a few minutes depending on the size of your data source."))),
            React.createElement(Accordion, { title: "3. Creating Departments and Table Access", icon: React.createElement(FiUsers, null), step: 3 },
                React.createElement(SectionContent, null,
                    React.createElement(StepDescription, null, "After creating your data connection, you can configure access for different departments in your organization. This allows you to determine which department can access which tables."),
                    React.createElement(StepTitle, null, "Creating a New Department"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Click on \"Administration\" in the left menu"),
                        React.createElement(ListItem, null, "Go to the \"Departments\" tab"),
                        React.createElement(ListItem, null, "Click \"Add New Department\""),
                        React.createElement(ListItem, null, "Enter the department name (e.g., \"Marketing\", \"Finance\", \"HR\")"),
                        React.createElement(ListItem, null, "Add a department description (optional)"),
                        React.createElement(ListItem, null, "Set the department quota (query limit)"),
                        React.createElement(ListItem, null, "Click \"Create\"")),
                    React.createElement(StepTitle, null, "Configuring Table Access for Departments"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Select the department you created"),
                        React.createElement(ListItem, null, "Click on the \"Table Access\" tab"),
                        React.createElement(ListItem, null, "Click \"Add Table Access\""),
                        React.createElement(ListItem, null, "Select the data source"),
                        React.createElement(ListItem, null, "Select the tables to grant access to"),
                        React.createElement(ListItem, null, "Save the changes")),
                    React.createElement(Note, null,
                        React.createElement(NoteIcon, null,
                            React.createElement(FiInfo, null)),
                        "Configuring table access at the department level is the most effective way to ensure data security and apply the \"need-to-know\" principle. Ensure each department only has access to the data necessary for their work."))),
            React.createElement(Accordion, { title: "4. Adding Users to Departments", icon: React.createElement(FiUserPlus, null), step: 4 },
                React.createElement(SectionContent, null,
                    React.createElement(StepDescription, null, "After creating departments and configuring table access, you can add users to departments. This allows users to access the tables assigned to their departments."),
                    React.createElement(StepTitle, null, "Creating a New User"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Click on \"Administration\" in the left menu"),
                        React.createElement(ListItem, null, "Go to the \"Users\" tab"),
                        React.createElement(ListItem, null, "Click \"Add New User\""),
                        React.createElement(ListItem, null, "Enter user information (first name, last name, email)"),
                        React.createElement(ListItem, null, "Select the user role (Standard User, Analyst, Administrator)"),
                        React.createElement(ListItem, null, "Select a department for the user"),
                        React.createElement(ListItem, null, "Set the user quota (optional)"),
                        React.createElement(ListItem, null, "Click \"Create\"")),
                    React.createElement(StepTitle, null, "Assigning Users to Departments"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "To edit an existing user, click on the user from the user list"),
                        React.createElement(ListItem, null, "Go to the \"Departments\" tab"),
                        React.createElement(ListItem, null, "Select the departments you want to assign the user to"),
                        React.createElement(ListItem, null, "Set the user role for each department"),
                        React.createElement(ListItem, null, "Save the changes")),
                    React.createElement(StepTitle, null, "Inviting Users"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "After a user is created, the system automatically sends an invitation email"),
                        React.createElement(ListItem, null, "The user can set their password by clicking the link in the email"),
                        React.createElement(ListItem, null, "If the invitation is not used within 48 hours, you will need to send a new invitation")),
                    React.createElement(Note, null,
                        React.createElement(NoteIcon, null,
                            React.createElement(FiInfo, null)),
                        "Users will only have the access permissions of the departments they are assigned to. You can assign a user to multiple departments, in which case the user will have the access permissions of all these departments."))),
            React.createElement(Accordion, { title: "5. Creating a Data Glossary", icon: React.createElement(FiFileText, null), step: 5 },
                React.createElement(SectionContent, null,
                    React.createElement(StepDescription, null, "The data glossary allows you to define the meanings and relationships of tables and columns in your dataset. This helps users better understand the data and create accurate queries."),
                    React.createElement(StepTitle, null, "Creating a Manual Data Glossary"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Go to the \"Data Glossary\" tab"),
                        React.createElement(ListItem, null, "Select a table and click \"Edit\""),
                        React.createElement(ListItem, null, "Add a table description"),
                        React.createElement(ListItem, null, "Add descriptions and metadata for each column"),
                        React.createElement(ListItem, null, "Define business rules and data quality rules"),
                        React.createElement(ListItem, null, "Save the changes")),
                    React.createElement(StepTitle, null, "Creating a Data Glossary with AI"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Go to the \"Data Glossary\" tab"),
                        React.createElement(ListItem, null, "Click \"Generate with AI\""),
                        React.createElement(ListItem, null, "The AI will automatically create descriptions by analyzing data samples"),
                        React.createElement(ListItem, null, "Review the generated descriptions and edit if necessary"),
                        React.createElement(ListItem, null, "Save the changes")),
                    React.createElement(Note, null,
                        React.createElement(NoteIcon, null,
                            React.createElement(FiInfo, null)),
                        "A comprehensive data glossary ensures users get more accurate results when creating natural language queries. The AI uses the information in your data glossary to better understand queries and translate them into more accurate SQL queries."))),
            React.createElement(Accordion, { title: "6. Data Analysis with Natural Language Queries", icon: React.createElement(FiSearch, null), step: 6 },
                React.createElement(SectionContent, null,
                    React.createElement(StepDescription, null, "After completing all setup steps, users can start analyzing data with natural language queries. This allows you to perform complex data analysis without requiring SQL knowledge."),
                    React.createElement(StepTitle, null, "Asking Questions"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Go to the \"Analysis\" section in the main panel"),
                        React.createElement(ListItem, null, "Select the dataset you want to analyze"),
                        React.createElement(ListItem, null, "Type your question in natural language (e.g., \"What were the top 10 products sold last month?\")"),
                        React.createElement(ListItem, null, "Click \"Ask\""),
                        React.createElement(ListItem, null, "The system will analyze your question and create the appropriate SQL query"),
                        React.createElement(ListItem, null, "Results will be presented visually")),
                    React.createElement(StepTitle, null, "Customizing Visualizations"),
                    React.createElement(List, null,
                        React.createElement(ListItem, null, "Use the \"Chart Type\" menu to change the result visualization"),
                        React.createElement(ListItem, null, "Customize axes, and labels"),
                        React.createElement(ListItem, null, "Use filtering and sorting options")),
                    React.createElement(Note, null,
                        React.createElement(NoteIcon, null,
                            React.createElement(FiInfo, null)),
                        "Natural language queries become more accurate over time by learning from your data glossary and previous queries. As users use the platform more, the system will become smarter.")))),
        React.createElement(CustomFooterContainer, null,
            React.createElement(FooterMain, null))));
};
export default DocumentationPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
