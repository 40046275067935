var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getCsrfToken } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n  background: #F8F9FD;\n  min-height: 100vh;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n  background: #F8F9FD;\n  min-height: 100vh;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var QuotaSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 24px;\n  padding: 40px;\n  margin-bottom: 32px;\n  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);\n"], ["\n  background: #FFFFFF;\n  border-radius: 24px;\n  padding: 40px;\n  margin-bottom: 32px;\n  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);\n"])));
var QuotaCard = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #E8EAF6;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);\n    transform: translateY(-2px);\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #E8EAF6;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);\n    transform: translateY(-2px);\n  }\n"])));
var QuotaHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"])));
var CompanyName = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  margin: 0;\n  color: #2D3748;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  margin: 0;\n  color: #2D3748;\n"])));
var QuotaBar = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 8px 0;\n  position: relative;\n  overflow: hidden;\n\n  &:after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: ", "%;\n    background: ", ";\n    transition: width 0.3s ease;\n  }\n"], ["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 8px 0;\n  position: relative;\n  overflow: hidden;\n\n  &:after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: ", "%;\n    background: ", ";\n    transition: width 0.3s ease;\n  }\n"])), function (props) { return Math.min(props.percentage, 100); }, function (props) {
    return props.percentage > 90 ? '#FF4D4F' :
        props.percentage > 70 ? '#FAAD14' :
            '#52C41A';
});
var QuotaInput = styled.input(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  width: 120px;\n  margin-right: 8px;\n"], ["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  width: 120px;\n  margin-right: 8px;\n"])));
var SaveButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 16px;\n  cursor: pointer;\n\n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 16px;\n  cursor: pointer;\n\n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var DepartmentList = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 16px;\n  border-top: 1px solid #ECEAFF;\n  padding-top: 16px;\n"], ["\n  margin-top: 16px;\n  border-top: 1px solid #ECEAFF;\n  padding-top: 16px;\n"])));
var DepartmentQuotaInput = styled(QuotaInput)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 120px;\n  padding: 10px 16px;\n  border-radius: 8px;\n  border: 1px solid #E2E8F0;\n  font-size: 14px;\n  transition: all 0.2s ease;\n\n  &:focus {\n    border-color: #8F4ACF;\n    box-shadow: 0 0 0 3px rgba(143, 74, 207, 0.1);\n    outline: none;\n  }\n"], ["\n  width: 120px;\n  padding: 10px 16px;\n  border-radius: 8px;\n  border: 1px solid #E2E8F0;\n  font-size: 14px;\n  transition: all 0.2s ease;\n\n  &:focus {\n    border-color: #8F4ACF;\n    box-shadow: 0 0 0 3px rgba(143, 74, 207, 0.1);\n    outline: none;\n  }\n"])));
var ErrorMessage = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: #FF4D4F;\n  font-size: 14px;\n  margin-top: 8px;\n"], ["\n  color: #FF4D4F;\n  font-size: 14px;\n  margin-top: 8px;\n"])));
var UserActivityRow = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 8px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 8px;\n"])));
var ActivityBreakdown = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  margin-top: 16px;\n  padding: 24px;\n  background: #F8FAFC;\n  border-radius: 12px;\n  border: 1px solid #E2E8F0;\n\n  h5 {\n    color: #4A5568;\n    font-size: 16px;\n    margin: 0 0 16px 0;\n    font-weight: 600;\n  }\n"], ["\n  margin-top: 16px;\n  padding: 24px;\n  background: #F8FAFC;\n  border-radius: 12px;\n  border: 1px solid #E2E8F0;\n\n  h5 {\n    color: #4A5568;\n    font-size: 16px;\n    margin: 0 0 16px 0;\n    font-weight: 600;\n  }\n"])));
var DailyStats = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n  margin-top: 8px;\n"], ["\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n  margin-top: 8px;\n"])));
var DayBox = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  padding: 8px 12px;\n  background: white;\n  border: 1px solid #EDF2F7;\n  border-radius: 8px;\n  font-size: 13px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    border-color: #8F4ACF;\n    transform: translateY(-2px);\n  }\n"], ["\n  padding: 8px 12px;\n  background: white;\n  border: 1px solid #EDF2F7;\n  border-radius: 8px;\n  font-size: 13px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    border-color: #8F4ACF;\n    transform: translateY(-2px);\n  }\n"])));
var DateLabel = styled.span(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  color: #6C757D;\n  font-size: 11px;\n"], ["\n  color: #6C757D;\n  font-size: 11px;\n"])));
var Count = styled.span(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-weight: 600;\n  color: #495057;\n"], ["\n  font-weight: 600;\n  color: #495057;\n"])));
var UpdateButton = styled(SaveButton)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  background: #8F4ACF;\n  padding: 10px 20px;\n  font-size: 14px;\n  font-weight: 500;\n  border-radius: 8px;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-1px);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.15);\n  }\n"], ["\n  background: #8F4ACF;\n  padding: 10px 20px;\n  font-size: 14px;\n  font-weight: 500;\n  border-radius: 8px;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #7B3AAF;\n    transform: translateY(-1px);\n    box-shadow: 0 4px 12px rgba(143, 74, 207, 0.15);\n  }\n"])));
var DepartmentHeader = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n  padding-bottom: 12px;\n  border-bottom: 1px solid #EDF2F7;\n\n  h4 {\n    color: #4A5568;\n    font-size: 18px;\n    margin: 0;\n    font-weight: 600;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n  padding-bottom: 12px;\n  border-bottom: 1px solid #EDF2F7;\n\n  h4 {\n    color: #4A5568;\n    font-size: 18px;\n    margin: 0;\n    font-weight: 600;\n  }\n"])));
var DepartmentControls = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n"])));
var ManageQuotas = function () {
    var _a = useState([]), companies = _a[0], setCompanies = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState({}), departmentQuotas = _d[0], setDepartmentQuotas = _d[1];
    var _e = useState({}), activityData = _e[0], setActivityData = _e[1];
    var fetchCompanyQuotas = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, quotasMap_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, axios.get('/api/companies/quotas/', {
                            headers: {
                                'X-CSRFToken': getCsrfToken()
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.data.success) {
                        setCompanies(response.data.companies);
                        quotasMap_1 = {};
                        response.data.companies.forEach(function (company) {
                            company.departments.forEach(function (dept) {
                                quotasMap_1["".concat(company.id, "-").concat(dept.id)] = dept.quota;
                            });
                        });
                        setDepartmentQuotas(quotasMap_1);
                        // Fetch activity counts for each company
                        response.data.companies.forEach(function (company) {
                            fetchActivityCounts(company.id);
                        });
                    }
                    return [3 /*break*/, 4];
                case 2:
                    err_1 = _a.sent();
                    setError('Failed to fetch company quotas');
                    console.error('Error fetching quotas:', err_1);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchActivityCounts = function (companyId) { return __awaiter(void 0, void 0, void 0, function () {
        var response_1, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/api/companies/".concat(companyId, "/activity-counts/"), {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCsrfToken()
                            }
                        })];
                case 1:
                    response_1 = _a.sent();
                    if (response_1.data.success) {
                        setActivityData(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[companyId] = response_1.data, _a)));
                        });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    console.error('Error fetching activity counts:', err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchCompanyQuotas();
    }, []);
    var handleCompanyQuotaUpdate = function (companyId, newQuota) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    if (newQuota < 0) {
                        setError('Quota cannot be negative');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, axios.put("/api/companies/".concat(companyId, "/quota/"), {
                            quota: newQuota
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCsrfToken()
                            }
                        })];
                case 1:
                    response = _c.sent();
                    if (!response.data.success) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchCompanyQuotas()];
                case 2:
                    _c.sent();
                    setError(null);
                    _c.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_3 = _c.sent();
                    setError(((_b = (_a = err_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Failed to update company quota');
                    console.error('Error updating quota:', err_3);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleDepartmentQuotaChange = function (companyId, deptId, value) {
        setDepartmentQuotas(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a["".concat(companyId, "-").concat(deptId)] = value === '' ? '' : Number(value), _a)));
        });
    };
    var validateAndUpdateDepartmentQuotas = function (company) { return __awaiter(void 0, void 0, void 0, function () {
        var departmentQuotasArray, response, err_4;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    departmentQuotasArray = company.departments.map(function (dept) { return ({
                        departmentId: dept.id,
                        quota: departmentQuotas["".concat(company.id, "-").concat(dept.id)] === '' ? 0 : Number(departmentQuotas["".concat(company.id, "-").concat(dept.id)])
                    }); });
                    return [4 /*yield*/, axios.put("/api/companies/".concat(company.id, "/department-quotas/"), {
                            departmentQuotas: departmentQuotasArray
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCsrfToken()
                            }
                        })];
                case 1:
                    response = _c.sent();
                    if (!response.data.success) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchCompanyQuotas()];
                case 2:
                    _c.sent();
                    setError(null);
                    _c.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_4 = _c.sent();
                    setError(((_b = (_a = err_4.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Failed to update department quotas');
                    console.error('Error updating department quotas:', err_4);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (loading)
        return React.createElement("div", null, "Loading...");
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Manage Company Quotas"),
        error && React.createElement(ErrorMessage, null, error),
        React.createElement(QuotaSection, null, companies.map(function (company) { return (React.createElement(QuotaCard, { key: company.id },
            React.createElement(QuotaHeader, null,
                React.createElement(CompanyName, null, company.name)),
            activityData[company.id] && (React.createElement("div", null,
                React.createElement("div", null,
                    "Company Usage: ",
                    activityData[company.id].total_activities,
                    " / ",
                    company.totalQuota,
                    React.createElement(QuotaBar, { percentage: (activityData[company.id].total_activities / company.totalQuota) * 100 })),
                React.createElement(ActivityBreakdown, null,
                    React.createElement("h5", null, "Company Activity Breakdown"),
                    React.createElement(DailyStats, null, Object.entries(activityData[company.id].daily_breakdown)
                        .sort(function (_a, _b) {
                        var dateA = _a[0];
                        var dateB = _b[0];
                        return dateB.localeCompare(dateA);
                    })
                        .map(function (_a) {
                        var date = _a[0], count = _a[1];
                        return (React.createElement(DayBox, { key: date },
                            React.createElement(DateLabel, null, new Date(date).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric'
                            })),
                            React.createElement(Count, null, count)));
                    }))))),
            React.createElement(DepartmentList, null, company.departments.map(function (dept) {
                var _a, _b, _c, _d;
                return (React.createElement("div", { key: dept.id },
                    React.createElement(DepartmentHeader, null,
                        React.createElement("h4", null, dept.name),
                        React.createElement(DepartmentControls, null,
                            React.createElement(DepartmentQuotaInput, { type: "number", value: departmentQuotas["".concat(company.id, "-").concat(dept.id)], onChange: function (e) { return handleDepartmentQuotaChange(company.id, dept.id, e.target.value); }, min: "0", max: company.totalQuota, onBlur: function (e) {
                                    if (e.target.value === '') {
                                        handleDepartmentQuotaChange(company.id, dept.id, '0');
                                    }
                                } }),
                            React.createElement(UpdateButton, { onClick: function () { return validateAndUpdateDepartmentQuotas(company); } }, "Update"))),
                    ((_a = activityData[company.id]) === null || _a === void 0 ? void 0 : _a.departments.find(function (d) { return d.department_id === dept.id; })) && (React.createElement("div", null,
                        React.createElement("div", null,
                            "Usage: ",
                            ((_b = activityData[company.id].departments.find(function (d) { return d.department_id === dept.id; })) === null || _b === void 0 ? void 0 : _b.total_activities) || 0,
                            " / ",
                            departmentQuotas["".concat(company.id, "-").concat(dept.id)],
                            React.createElement(QuotaBar, { percentage: ((((_c = activityData[company.id].departments.find(function (d) { return d.department_id === dept.id; })) === null || _c === void 0 ? void 0 : _c.total_activities) || 0) /
                                    (departmentQuotas["".concat(company.id, "-").concat(dept.id)] || 1)) * 100 })),
                        React.createElement(ActivityBreakdown, null,
                            React.createElement("h5", null, "Department Activity Breakdown"),
                            React.createElement(DailyStats, null, Object.entries(((_d = activityData[company.id].departments
                                .find(function (d) { return d.department_id === dept.id; })) === null || _d === void 0 ? void 0 : _d.daily_breakdown) || {})
                                .sort(function (_a, _b) {
                                var dateA = _a[0];
                                var dateB = _b[0];
                                return dateB.localeCompare(dateA);
                            })
                                .map(function (_a) {
                                var date = _a[0], count = _a[1];
                                return (React.createElement(DayBox, { key: date },
                                    React.createElement(DateLabel, null, new Date(date).toLocaleDateString('en-US', {
                                        month: 'short',
                                        day: 'numeric'
                                    })),
                                    React.createElement(Count, null, count)));
                            })))))));
            })))); }))));
};
export default ManageQuotas;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
