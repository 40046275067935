var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n"], ["\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 22px;\n  line-height: 110%;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 22px;\n  line-height: 110%;\n  color: #000000;\n"])));
var QuestionsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 24px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 20px;\n  padding: 24px;\n"])));
var QuestionItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 16px;\n  border: 1px solid ", ";\n  background: ", ";\n  border-radius: 12px;\n  margin-bottom: 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F8F5FF;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 16px;\n  border: 1px solid ", ";\n  background: ", ";\n  border-radius: 12px;\n  margin-bottom: 12px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F8F5FF;\n    border-color: #8F4ACF;\n  }\n"])), function (props) { return props.selected ? '#8F4ACF' : '#ECEAFF'; }, function (props) { return props.selected ? '#F8F5FF' : '#FFFFFF'; });
var QuestionText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  line-height: 140%;\n"], ["\n  font-family: 'Inter';\n  font-size: 15px;\n  color: #000000;\n  line-height: 140%;\n"])));
var AnalyzeButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 180px;\n  height: 52px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  margin-top: 24px;\n  transition: background-color 0.2s ease;\n\n  &:hover {\n    background: #7B3AB2;\n  }\n\n  &:disabled {\n    background: #E0E0E0;\n    cursor: not-allowed;\n  }\n"], ["\n  width: 180px;\n  height: 52px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-weight: 500;\n  font-size: 16px;\n  margin-top: 24px;\n  transition: background-color 0.2s ease;\n\n  &:hover {\n    background: #7B3AB2;\n  }\n\n  &:disabled {\n    background: #E0E0E0;\n    cursor: not-allowed;\n  }\n"])));
var ButtonContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
var ErrorMessage = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: #FFF3F3;\n  border: 1px solid #FFA5A5;\n  border-radius: 12px;\n  padding: 24px;\n  text-align: center;\n  color: #DC3545;\n  margin: 20px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n"], ["\n  background: #FFF3F3;\n  border: 1px solid #FFA5A5;\n  border-radius: 12px;\n  padding: 24px;\n  text-align: center;\n  color: #DC3545;\n  margin: 20px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n"])));
var RetryButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 8px 16px;\n  background: #DC3545;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  transition: background-color 0.2s ease;\n\n  &:hover {\n    background: #C82333;\n  }\n"], ["\n  padding: 8px 16px;\n  background: #DC3545;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 14px;\n  transition: background-color 0.2s ease;\n\n  &:hover {\n    background: #C82333;\n  }\n"])));
var Questions = function () {
    var datasetName = useParams().datasetName;
    var _a = useState([]), questions = _a[0], setQuestions = _a[1];
    var _b = useState(null), selectedQuestion = _b[0], setSelectedQuestion = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(null), datasetInfo = _e[0], setDatasetInfo = _e[1];
    var navigate = useNavigate();
    useEffect(function () {
        fetchQuestions();
    }, [datasetName]);
    var fetchQuestions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, errorData, data, filteredQuestions, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, 6, 7]);
                    setLoading(true);
                    setError(null);
                    return [4 /*yield*/, fetch("/api/datasets/".concat(datasetName, "/questions/"), {
                            credentials: 'include',
                            headers: {
                                'Accept': 'application/json'
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    errorData = _a.sent();
                    throw new Error(errorData.error || 'Failed to fetch questions');
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    data = _a.sent();
                    filteredQuestions = data.questions
                        .filter(function (q) { return q.text.trim() !== ''; })
                        .map(function (q) { return (__assign(__assign({}, q), { text: q.text.replace(/^\d+\.\s*/, '') })); });
                    setQuestions(filteredQuestions);
                    setDatasetInfo(data.dataset);
                    toast.success('Questions loaded successfully', { id: 'fetch-questions' });
                    return [3 /*break*/, 7];
                case 5:
                    error_1 = _a.sent();
                    errorMessage = error_1 instanceof Error ? error_1.message : 'An unexpected error occurred';
                    setError(errorMessage);
                    toast.error('Failed to load questions', { id: 'fetch-questions' });
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleQuestionSelect = function (id) {
        setSelectedQuestion(id);
    };
    var handleAnalyze = function () {
        var _a;
        if (selectedQuestion) {
            var selectedQuestionText = (_a = questions.find(function (q) { return q.id === selectedQuestion; })) === null || _a === void 0 ? void 0 : _a.text;
            navigate('/app/analysis', {
                state: {
                    question: selectedQuestionText,
                    datasetName: datasetName
                }
            });
        }
    };
    if (loading) {
        return (React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(Title, null,
                    React.createElement(Skeleton, { width: 300 }))),
            React.createElement(QuestionsContainer, null, __spreadArray([], Array(5), true).map(function (_, i) { return (React.createElement(QuestionItem, { key: i, selected: false },
                React.createElement(Skeleton, { height: 24 }))); }))));
    }
    if (error) {
        return (React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(Title, null, "Error Loading Questions")),
            React.createElement(ErrorMessage, null,
                error,
                React.createElement(RetryButton, { onClick: fetchQuestions }, "Try Again"))));
    }
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Title, null,
                "Questions for ", datasetInfo === null || datasetInfo === void 0 ? void 0 :
                datasetInfo.name)),
        React.createElement(QuestionsContainer, null,
            questions.map(function (question) { return (React.createElement(QuestionItem, { key: question.id, selected: selectedQuestion === question.id, onClick: function () { return handleQuestionSelect(question.id); } },
                React.createElement(QuestionText, null, question.text))); }),
            React.createElement(ButtonContainer, null,
                React.createElement(AnalyzeButton, { onClick: handleAnalyze, disabled: !selectedQuestion }, "Analyze Question")))));
};
export default Questions;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
