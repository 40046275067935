var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
export var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
export var UsersSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
export var Table = styled.table(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n"])));
export var Th = styled.th(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"], ["\n  text-align: left;\n  padding: 16px;\n  background: #F8F5FF;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 14px;\n  color: #000000;\n"])));
export var Td = styled.td(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"], ["\n  padding: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  font-family: 'Inter';\n  font-size: 14px;\n"])));
export var StatusBadge = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n"], ["\n  background: ", ";\n  color: ", ";\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n"])), function (props) { return props.status === 'active' ? '#E6F4EA' : '#FFF1F0'; }, function (props) { return props.status === 'active' ? '#137333' : '#D93025'; });
export var ActionButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-right: 8px;\n  cursor: pointer;\n  \n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.variant === 'edit' ? '#F8F5FF' : '#FFF1F0'; }, function (props) { return props.variant === 'edit' ? '#8F4ACF' : '#FF4D4F'; }, function (props) { return props.variant === 'edit' ? '#ECEAFF' : '#FFA39E'; }, function (props) { return props.variant === 'edit' ? '#F0E7FF' : '#FFF1F0'; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
