import React from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
var ProtectedRoute = function (_a) {
    var children = _a.children, isAuthenticated = _a.isAuthenticated;
    if (!isAuthenticated) {
        toast.error('Please sign in to access this page');
        return React.createElement(Navigate, { to: "/signin", replace: true });
    }
    return React.createElement(React.Fragment, null, children);
};
export default ProtectedRoute;
