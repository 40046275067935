var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 680px;\n  height: 400px;\n  background: #FAFAFA;\n  border-radius: 20px;\n  padding: 40px;\n  margin: 100px auto;\n"], ["\n  width: 680px;\n  height: 400px;\n  background: #FAFAFA;\n  border-radius: 20px;\n  padding: 40px;\n  margin: 100px auto;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 28px;\n  text-align: center;\n  margin-bottom: 20px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 28px;\n  text-align: center;\n  margin-bottom: 20px;\n"])));
var Subtitle = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  text-align: center;\n  margin-bottom: 40px;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  text-align: center;\n  margin-bottom: 40px;\n"])));
var Input = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n"])));
var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  margin-top: 20px;\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  margin-top: 20px;\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"])));
var BackToLogin = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: block;\n  text-align: center;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  text-decoration: none;\n  margin-top: 20px;\n  &:hover {\n    text-decoration: underline;\n  }\n"], ["\n  display: block;\n  text-align: center;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  text-decoration: none;\n  margin-top: 20px;\n  &:hover {\n    text-decoration: underline;\n  }\n"])));
var FormContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var ForgotPassword = function () {
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var csrfToken, response, data, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setIsSubmitting(true);
                    csrfToken = (_a = document.cookie
                        .split('; ')
                        .find(function (row) { return row.startsWith('csrftoken='); })) === null || _a === void 0 ? void 0 : _a.split('=')[1];
                    return [4 /*yield*/, fetch('/api/password-reset/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': csrfToken || '',
                            },
                            credentials: 'include',
                            body: JSON.stringify(values)
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    if (response.ok) {
                        toast.success('Password reset instructions have been sent to your email');
                    }
                    else {
                        throw new Error(data.error || 'Failed to send reset email');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _b.sent();
                    toast.error(error_1 instanceof Error ? error_1.message : 'Failed to send reset email');
                    return [3 /*break*/, 5];
                case 4:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Reset Password"),
        React.createElement(Subtitle, null, "Enter your email address and we'll send you instructions to reset your password."),
        React.createElement(Formik, { initialValues: { email: '' }, validationSchema: Yup.object({
                email: Yup.string()
                    .email('Invalid email address')
                    .required('Email is required'),
            }), onSubmit: handleSubmit }, function (_a) {
            var values = _a.values, handleChange = _a.handleChange, isValid = _a.isValid;
            return (React.createElement(Form, null,
                React.createElement(FormContainer, null,
                    React.createElement(Input, { type: "email", name: "email", placeholder: "Email", value: values.email, onChange: handleChange }),
                    React.createElement(Button, { type: "submit", disabled: !isValid || isSubmitting }, isSubmitting ? 'Sending...' : 'Send Reset Instructions'),
                    React.createElement(BackToLogin, { to: "/signin" }, "Back to Login"))));
        })));
};
export default ForgotPassword;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
