var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DatabaseIcon } from '../../assets/Icons';
import { PlayArrow as RunIcon } from '@mui/icons-material';
import { getCookie } from '../../utils/cookies';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel, Typography, FormGroup, FormControlLabel, Checkbox, Box, Alert } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Schedule as ClockIcon } from '@mui/icons-material';
import { IconButton as MuiIconButton } from '@mui/material';
import Schedules from './Schedules';
import CloseIcon from '@mui/icons-material/Close';
import { PlayCircleOutline as TestIcon } from '@mui/icons-material';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 40px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 40px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n"])));
var AddButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 12px 24px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  border: none;\n  color: white;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 12px 24px;\n  background: #8F4ACF;\n  border-radius: 12px;\n  border: none;\n  color: white;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var ConnectionCard = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: white;\n  border-radius: 12px;\n  padding: 24px;\n  margin-bottom: 20px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.05);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  background: white;\n  border-radius: 12px;\n  padding: 24px;\n  margin-bottom: 20px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.05);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var ConnectionInfo = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"])));
var ConnectionDetails = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  h3 {\n    font-family: 'Inter';\n    font-weight: 500;\n    font-size: 16px;\n    color: #000000;\n    margin-bottom: 4px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n  }\n"], ["\n  h3 {\n    font-family: 'Inter';\n    font-weight: 500;\n    font-size: 16px;\n    color: #000000;\n    margin-bottom: 4px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n  }\n"])));
var StatusBadge = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 6px 12px;\n  border-radius: 20px;\n  font-size: 14px;\n  background: ", ";\n  color: ", ";\n"], ["\n  padding: 6px 12px;\n  border-radius: 20px;\n  font-size: 14px;\n  background: ", ";\n  color: ", ";\n"])), function (props) { return props.status === 'active' ? '#E6F4EA' : '#FFF0F0'; }, function (props) { return props.status === 'active' ? '#1E8E3E' : '#D93025'; });
var Button = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 8px 16px;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  \n  ", "\n"], ["\n  padding: 8px 16px;\n  border-radius: 8px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n  \n  ", "\n"])), function (props) { return props.variant === 'outlined' ? "\n    border: 1px solid #8F4ACF;\n    background: transparent;\n    color: #8F4ACF;\n    \n    &:hover {\n      background: #F5F5F5;\n    }\n  " : "\n    border: none;\n    background: #8F4ACF;\n    color: white;\n    \n    &:hover {\n      background: #7B3AAF;\n    }\n  "; });
var Actions = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"])));
var IconButton = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  padding: 8px;\n  border: none;\n  background: transparent;\n  color: #666666;\n  cursor: pointer;\n  border-radius: 4px;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F5F5F5;\n    color: #8F4ACF;\n  }\n\n  svg {\n    width: 20px;\n    height: 20px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  padding: 8px;\n  border: none;\n  background: transparent;\n  color: #666666;\n  cursor: pointer;\n  border-radius: 4px;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: #F5F5F5;\n    color: #8F4ACF;\n  }\n\n  svg {\n    width: 20px;\n    height: 20px;\n  }\n"])));
var ConnectionActions = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  gap: 12px;\n  align-items: center;\n"], ["\n  display: flex;\n  gap: 12px;\n  align-items: center;\n"])));
var ActionButton = styled(Button)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  &.primary {\n    background: #8F4ACF;\n    color: white;\n    &:hover {\n      background: #7B3AB2;\n    }\n  }\n\n  &.secondary {\n    background: #F5F5F5;\n    color: #666;\n    &:hover {\n      background: #EBEBEB;\n    }\n  }\n\n  &.danger {\n    background: #FFF1F0;\n    color: #FF4D4F;\n    &:hover {\n      background: #FFE2E2;\n    }\n  }\n\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 16px;\n  border-radius: 8px;\n  font-size: 14px;\n  text-transform: none;\n  transition: all 0.2s;\n\n  &:disabled {\n    opacity: 0.5;\n  }\n"], ["\n  &.primary {\n    background: #8F4ACF;\n    color: white;\n    &:hover {\n      background: #7B3AB2;\n    }\n  }\n\n  &.secondary {\n    background: #F5F5F5;\n    color: #666;\n    &:hover {\n      background: #EBEBEB;\n    }\n  }\n\n  &.danger {\n    background: #FFF1F0;\n    color: #FF4D4F;\n    &:hover {\n      background: #FFE2E2;\n    }\n  }\n\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 16px;\n  border-radius: 8px;\n  font-size: 14px;\n  text-transform: none;\n  transition: all 0.2s;\n\n  &:disabled {\n    opacity: 0.5;\n  }\n"])));
var FileUpload = function (_a) {
    var onSuccess = _a.onSuccess;
    var _b = useState([]), uploadedFiles = _b[0], setUploadedFiles = _b[1];
    var handleFileChange = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var files, formData, i, response, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    files = event.target.files;
                    if (!files)
                        return [2 /*return*/];
                    formData = new FormData();
                    for (i = 0; i < files.length; i++) {
                        formData.append('files', files[i]);
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post('/api/connections/upload-files/', formData, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _c.sent();
                    if (response.data.files) {
                        setUploadedFiles(response.data.files);
                        onSuccess(response.data.files);
                    }
                    else {
                        toast.error('Invalid response format');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    console.error('Upload error:', error_1);
                    if (axios.isAxiosError(error_1)) {
                        toast.error(((_b = (_a = error_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'File upload failed');
                    }
                    else {
                        toast.error('File upload failed');
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Box, { sx: { textAlign: 'center' } },
        React.createElement(Box, { sx: {
                border: '2px dashed #ccc',
                borderRadius: 2,
                p: 3,
                mb: 2,
                cursor: 'pointer'
            } },
            React.createElement("input", { type: "file", accept: ".csv,.xlsx,.xls", onChange: handleFileChange, multiple: true, style: { display: 'none' }, id: "file-upload" }),
            React.createElement("label", { htmlFor: "file-upload" },
                React.createElement(CloudUploadIcon, { sx: { fontSize: 40, mb: 1 } }),
                React.createElement(Typography, null, "Click or drag files to this area to upload"),
                React.createElement(Typography, { variant: "caption", color: "textSecondary" }, "Support for multiple CSV and Excel files"))),
        uploadedFiles.length > 0 && (React.createElement(Box, { sx: { mt: 2 } },
            React.createElement(Typography, { variant: "subtitle1", sx: { mb: 1 } }, "Uploaded Files:"),
            uploadedFiles.map(function (file, index) { return (React.createElement(Typography, { key: index, variant: "body2", color: "textSecondary" }, file.file_name)); })))));
};
var StyledIconButton = styled(MuiIconButton)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { var _a, _b; return ((_b = (_a = props.theme.palette) === null || _a === void 0 ? void 0 : _a.primary) === null || _b === void 0 ? void 0 : _b.main) || '#8F4ACF'; });
var DatabaseConnections = function () {
    var _a = useState([]), connections = _a[0], setConnections = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = useState({
        name: '',
        connection_type: '',
        credentials: {}
    }), formData = _d[0], setFormData = _d[1];
    var _e = useState(null), editingConnection = _e[0], setEditingConnection = _e[1];
    var _f = useState(false), isETLModalOpen = _f[0], setIsETLModalOpen = _f[1];
    var _g = useState({
        target_connection_id: 0,
        dataset_id: '',
        write_disposition: 'WRITE_APPEND',
        cleaning_options: {
            remove_duplicates: false,
            fill_null_values: false,
            standardize_dates: false,
            clean_string_values: false
        }
    }), etlFormData = _g[0], setETLFormData = _g[1];
    var _h = useState(null), selectedSourceConnection = _h[0], setSelectedSourceConnection = _h[1];
    var _j = useState(null), fileInfo = _j[0], setFileInfo = _j[1];
    var _k = useState([]), uploadedFiles = _k[0], setUploadedFiles = _k[1];
    var _l = useState(false), showDestinationDialog = _l[0], setShowDestinationDialog = _l[1];
    var _m = useState({
        dataset_id: '',
        write_disposition: 'WRITE_TRUNCATE'
    }), destination = _m[0], setDestination = _m[1];
    var _o = useState([]), schedules = _o[0], setSchedules = _o[1];
    var _p = useState(false), isScheduleModalOpen = _p[0], setIsScheduleModalOpen = _p[1];
    var _q = useState({
        name: '',
        frequency: 'DAILY',
        start_time: '00:00',
        dataset_id: '',
        write_disposition: 'WRITE_APPEND',
        cleaning_options: {}
    }), scheduleFormData = _q[0], setScheduleFormData = _q[1];
    var _r = useState(false), showSchedules = _r[0], setShowSchedules = _r[1];
    var _s = useState(null), selectedConnectionId = _s[0], setSelectedConnectionId = _s[1];
    useEffect(function () {
        fetchConnections();
    }, []);
    var fetchConnections = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, axios.get('/api/connections/', {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    setConnections(response.data);
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _a.sent();
                    toast.error('Failed to fetch database connections');
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var formatCredentials = function (data) {
        var _a;
        if (data.connection_type === 'bigquery') {
            // First, normalize the private key by removing any existing markers and whitespace
            var privateKey = data.credentials.private_key || '';
            privateKey = privateKey
                .replace(/-----BEGIN PRIVATE KEY-----/g, '')
                .replace(/-----END PRIVATE KEY-----/g, '')
                .replace(/\\n/g, '')
                .replace(/\n/g, '')
                .replace(/\s/g, '');
            // Add proper PEM format
            privateKey = "-----BEGIN PRIVATE KEY-----\n".concat((_a = privateKey.match(/.{1,64}/g)) === null || _a === void 0 ? void 0 : _a.join('\n'), "\n-----END PRIVATE KEY-----\n");
            return __assign(__assign({}, data), { credentials: __assign(__assign({}, data.credentials), { type: 'service_account', auth_uri: 'https://accounts.google.com/o/oauth2/auth', token_uri: 'https://oauth2.googleapis.com/token', auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs', client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/".concat(encodeURIComponent(data.credentials.client_email || '')), private_key: privateKey }) });
        }
        return data;
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var url, method, submissionData, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    url = editingConnection
                        ? "/api/connections/".concat(editingConnection.id, "/")
                        : '/api/connections/';
                    method = editingConnection ? 'put' : 'post';
                    submissionData = __assign(__assign({}, formData), { credentials: __assign(__assign({}, formData.credentials), (editingConnection && !formData.credentials.password && {
                            password: undefined
                        })) });
                    return [4 /*yield*/, axios({
                            method: method,
                            url: url,
                            data: formatCredentials(submissionData),
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _a.sent();
                    toast.success(editingConnection ? 'Connection updated successfully' : 'Connection created successfully');
                    setIsModalOpen(false);
                    setEditingConnection(null);
                    setFormData({ name: '', connection_type: '', credentials: {} });
                    fetchConnections();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error('Error submitting connection:', error_3);
                    toast.error(editingConnection ? 'Failed to update connection' : 'Failed to create connection');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAddConnection = function () {
        setIsModalOpen(true);
    };
    var handleFormChange = function (field, value) {
        if (field.startsWith('credentials.')) {
            var credentialField_1 = field.split('.')[1];
            setFormData(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), { credentials: __assign(__assign({}, prev.credentials), (_a = {}, _a[credentialField_1] = value, _a)) }));
            });
        }
        else {
            setFormData(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[field] = value, _a)));
            });
        }
    };
    var renderCredentialFields = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var type = formData.connection_type;
        switch (type) {
            case 'postgresql':
            case 'mysql':
                return (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { fullWidth: true, label: "Host", value: formData.credentials.host || '', onChange: function (e) { return handleFormChange('credentials.host', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "Port", value: formData.credentials.port || '', onChange: function (e) { return handleFormChange('credentials.port', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "Database", value: formData.credentials.database || '', onChange: function (e) { return handleFormChange('credentials.database', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "Username", value: formData.credentials.user || '', onChange: function (e) { return handleFormChange('credentials.user', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, type: "password", label: "Password", value: formData.credentials.password || '', onChange: function (e) { return handleFormChange('credentials.password', e.target.value); }, margin: "normal", required: !editingConnection })));
            case 'bigquery':
                return (React.createElement(React.Fragment, null,
                    React.createElement("input", { type: "file", accept: ".json", onChange: handleServiceAccountUpload, style: { marginBottom: '16px' } }),
                    React.createElement(Typography, { variant: "caption", color: "textSecondary" }, "Or enter the details manually:"),
                    React.createElement(TextField, { fullWidth: true, label: "Project ID", value: formData.credentials.project_id || '', onChange: function (e) { return handleFormChange('credentials.project_id', e.target.value); }, margin: "normal", required: true, helperText: "Your Google Cloud Project ID" }),
                    React.createElement(TextField, { fullWidth: true, label: "Client Email", value: formData.credentials.client_email || '', onChange: function (e) { return handleFormChange('credentials.client_email', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "Private Key ID", value: formData.credentials.private_key_id || '', onChange: function (e) { return handleFormChange('credentials.private_key_id', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, multiline: true, rows: 4, label: "Private Key", value: formData.credentials.private_key || '', onChange: function (e) {
                            // Clean up the private key on input
                            var rawKey = e.target.value
                                .replace('-----BEGIN PRIVATE KEY-----', '')
                                .replace('-----END PRIVATE KEY-----', '')
                                .replace(/\n/g, '')
                                .trim();
                            handleFormChange('credentials.private_key', rawKey);
                        }, margin: "normal", required: true, helperText: "Paste the private key content from your service account JSON (without BEGIN/END markers)" }),
                    React.createElement(TextField, { fullWidth: true, label: "Client ID", value: formData.credentials.client_id || '', onChange: function (e) { return handleFormChange('credentials.client_id', e.target.value); }, margin: "normal", required: true }),
                    React.createElement("input", { type: "hidden", name: "credentials.auth_uri", value: "https://accounts.google.com/o/oauth2/auth" }),
                    React.createElement("input", { type: "hidden", name: "credentials.token_uri", value: "https://oauth2.googleapis.com/token" }),
                    React.createElement("input", { type: "hidden", name: "credentials.auth_provider_x509_cert_url", value: "https://www.googleapis.com/oauth2/v1/certs" }),
                    React.createElement("input", { type: "hidden", name: "credentials.type", value: "service_account" })));
            case 'snowflake':
                return (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { fullWidth: true, label: "Account", value: formData.credentials.account || '', onChange: function (e) { return handleFormChange('credentials.account', e.target.value); }, margin: "normal", required: true, helperText: "Snowflake account identifier (e.g., xyz1234.us-east-1.gcp)" }),
                    React.createElement(TextField, { fullWidth: true, label: "Username", value: formData.credentials.user || '', onChange: function (e) { return handleFormChange('credentials.user', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "Password", type: "password", value: formData.credentials.password || '', onChange: function (e) { return handleFormChange('credentials.password', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "Warehouse", value: formData.credentials.warehouse || '', onChange: function (e) { return handleFormChange('credentials.warehouse', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "Database", value: formData.credentials.database || '', onChange: function (e) { return handleFormChange('credentials.database', e.target.value); }, margin: "normal" }),
                    React.createElement(TextField, { fullWidth: true, label: "Role", value: formData.credentials.role || '', onChange: function (e) { return handleFormChange('credentials.role', e.target.value); }, margin: "normal", helperText: "Optional: Specify a role for the connection" }),
                    React.createElement(TextField, { fullWidth: true, multiline: true, rows: 4, label: "Private Key", value: formData.credentials.private_key || '', onChange: function (e) { return handleFormChange('credentials.private_key', e.target.value); }, margin: "normal", helperText: "Optional: Private key for key pair authentication" }),
                    React.createElement(TextField, { fullWidth: true, label: "Passphrase Key", type: "password", value: formData.credentials.passphrase_key || '', onChange: function (e) { return handleFormChange('credentials.passphrase_key', e.target.value); }, margin: "normal", helperText: "Optional: Passphrase for encrypted private key" })));
            case 'csv':
            case 'excel':
                return (React.createElement(React.Fragment, null,
                    React.createElement(FileUpload, { onSuccess: handleFileUpload }),
                    fileInfo && (React.createElement(Typography, { variant: "body2", sx: { mt: 1 } },
                        "Selected file: ",
                        fileInfo.file_name))));
            case 'athena':
                return (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { fullWidth: true, label: "AWS Access Key ID", value: ((_b = (_a = formData.credentials) === null || _a === void 0 ? void 0 : _a.awsConfig) === null || _b === void 0 ? void 0 : _b.aws_access_key_id) || '', onChange: function (e) { return handleFormChange('credentials.awsConfig.aws_access_key_id', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "AWS Secret Access Key", type: "password", value: ((_d = (_c = formData.credentials) === null || _c === void 0 ? void 0 : _c.awsConfig) === null || _d === void 0 ? void 0 : _d.aws_secret_access_key) || '', onChange: function (e) { return handleFormChange('credentials.awsConfig.aws_secret_access_key', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(TextField, { fullWidth: true, label: "S3 Staging Directory", value: ((_e = formData.credentials) === null || _e === void 0 ? void 0 : _e.s3StagingDir) || '', onChange: function (e) { return handleFormChange('credentials.s3StagingDir', e.target.value); }, margin: "normal", required: true, helperText: "Example: s3://your-bucket/staging/" }),
                    React.createElement(TextField, { fullWidth: true, label: "Workgroup", value: ((_f = formData.credentials) === null || _f === void 0 ? void 0 : _f.workgroup) || '', onChange: function (e) { return handleFormChange('credentials.workgroup', e.target.value); }, margin: "normal", required: true, helperText: "Athena workgroup name" }),
                    React.createElement(TextField, { fullWidth: true, label: "Region", value: ((_h = (_g = formData.credentials) === null || _g === void 0 ? void 0 : _g.awsConfig) === null || _h === void 0 ? void 0 : _h.region) || '', onChange: function (e) { return handleFormChange('credentials.awsConfig.region', e.target.value); }, margin: "normal", required: true, defaultValue: "us-east-1" })));
            case 'SYNAPSE':
                return (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "h6", sx: { mt: 2, mb: 1 } }, "Microsoft Synapse Analytics Connection"),
                    React.createElement(Alert, { severity: "info", sx: { mb: 2 } }, "Connect to Synapse SQL pools (dedicated or serverless) using the credentials below."),
                    React.createElement(TextField, { label: "Server", name: "server", value: formData.credentials.server || '', onChange: function (e) { return handleFormChange('credentials.server', e.target.value); }, fullWidth: true, required: true, margin: "normal", helperText: "Synapse workspace SQL endpoint (e.g., workspacename-ondemand.sql.azuresynapse.net)" }),
                    React.createElement(TextField, { label: "Database", name: "database", value: formData.credentials.database || '', onChange: function (e) { return handleFormChange('credentials.database', e.target.value); }, fullWidth: true, required: true, margin: "normal", helperText: "SQL database name" }),
                    React.createElement(FormControl, { fullWidth: true, margin: "normal" },
                        React.createElement(InputLabel, null, "Authentication Type"),
                        React.createElement(Select, { name: "authentication", value: formData.credentials.authentication || 'SQL', onChange: function (e) { return handleFormChange('credentials.authentication', e.target.value); }, label: "Authentication Type" },
                            React.createElement(MenuItem, { value: "SQL" }, "SQL Authentication"),
                            React.createElement(MenuItem, { value: "AAD" }, "Azure Active Directory"))),
                    React.createElement(TextField, { label: "Username", name: "user", value: formData.credentials.user || '', onChange: function (e) { return handleFormChange('credentials.user', e.target.value); }, fullWidth: true, required: true, margin: "normal" }),
                    React.createElement(TextField, { label: "Password", name: "password", type: "password", value: formData.credentials.password || '', onChange: function (e) { return handleFormChange('credentials.password', e.target.value); }, fullWidth: true, required: true, margin: "normal" }),
                    React.createElement(FormGroup, null,
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: formData.credentials.encrypt !== false, onChange: function (e) {
                                    handleFormChange('credentials.encrypt', e.target.checked);
                                }, name: "encrypt" }), label: "Encrypt Connection" }),
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: !!formData.credentials.trust_server_certificate, onChange: function (e) {
                                    handleFormChange('credentials.trust_server_certificate', e.target.checked);
                                }, name: "trust_server_certificate" }), label: "Trust Server Certificate" })),
                    React.createElement(TextField, { label: "Connection Timeout (seconds)", name: "connection_timeout", type: "number", value: formData.credentials.connection_timeout || 30, onChange: function (e) { return handleFormChange('credentials.connection_timeout', e.target.value); }, fullWidth: true, margin: "normal" })));
            default:
                return null;
        }
    };
    var handleTestConnection = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.post("/api/connections/".concat(id, "/test/"), null, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken') || '',
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    if (response.data.success) {
                        toast.success('Connection test successful');
                    }
                    else {
                        toast.error('Connection test failed');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    toast.error('Failed to test connection');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleEditConnection = function (connection) { return __awaiter(void 0, void 0, void 0, function () {
        var response, fullConnection, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/api/connections/".concat(connection.id, "/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    fullConnection = response.data;
                    // Set form data with all existing credentials
                    setFormData({
                        id: fullConnection.id,
                        name: fullConnection.name,
                        connection_type: fullConnection.connection_type,
                        credentials: __assign(__assign({}, fullConnection.credentials), (fullConnection.connection_type !== 'bigquery' && {
                            password: ''
                        }))
                    });
                    setEditingConnection(connection);
                    setIsModalOpen(true);
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _a.sent();
                    console.error('Error fetching connection details:', error_5);
                    toast.error('Failed to load connection details');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteConnection = function (id, name) { return __awaiter(void 0, void 0, void 0, function () {
        var error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm("Are you sure you want to delete connection \"".concat(name, "\"?"))) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.delete("/api/connections/".concat(id, "/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true
                        })];
                case 2:
                    _a.sent();
                    toast.success('Connection deleted successfully');
                    fetchConnections();
                    return [3 /*break*/, 4];
                case 3:
                    error_6 = _a.sent();
                    toast.error('Failed to delete connection');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseModal = function () {
        setIsModalOpen(false);
        setFormData({ name: '', connection_type: '', credentials: {} });
        setEditingConnection(null);
    };
    var handleServiceAccountUpload = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var file, reader;
        var _a;
        return __generator(this, function (_b) {
            file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
            if (!file)
                return [2 /*return*/];
            try {
                reader = new FileReader();
                reader.onload = function (e) {
                    var _a;
                    try {
                        var jsonContent_1 = JSON.parse((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
                        // Update form with service account details
                        setFormData(function (prev) { return (__assign(__assign({}, prev), { credentials: {
                                project_id: jsonContent_1.project_id || '',
                                private_key_id: jsonContent_1.private_key_id || '',
                                private_key: jsonContent_1.private_key || '',
                                client_email: jsonContent_1.client_email || '',
                                client_id: jsonContent_1.client_id || '',
                                auth_uri: jsonContent_1.auth_uri || '',
                                token_uri: jsonContent_1.token_uri || '',
                                auth_provider_x509_cert_url: jsonContent_1.auth_provider_x509_cert_url || '',
                                client_x509_cert_url: jsonContent_1.client_x509_cert_url || ''
                            } })); });
                    }
                    catch (error) {
                        console.error('Error parsing service account JSON:', error);
                        toast.error('Invalid service account file format');
                    }
                };
                reader.readAsText(file);
            }
            catch (error) {
                console.error('Error reading service account file:', error);
                toast.error('Failed to read service account file');
            }
            return [2 /*return*/];
        });
    }); };
    var handleRunETL = function (connection_id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedSourceConnection(connection_id);
            setETLFormData({
                target_connection_id: connection_id,
                dataset_id: '',
                write_disposition: 'WRITE_APPEND',
                cleaning_options: {
                    remove_duplicates: false,
                    fill_null_values: false,
                    standardize_dates: false,
                    clean_string_values: false
                }
            });
            setIsETLModalOpen(true);
            return [2 /*return*/];
        });
    }); };
    var handleFileUpload = function (files) {
        setUploadedFiles(files);
        setShowDestinationDialog(true);
    };
    var handleUploadToBigQuery = function () { return __awaiter(void 0, void 0, void 0, function () {
        var createResponse, uploadResponse, error_7;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, axios.post('/api/connections/', {
                            name: "File Upload ".concat(new Date().toISOString()),
                            connection_type: 'file_upload',
                            status: 'active',
                            credentials: {
                                files: uploadedFiles.map(function (file) { return ({
                                    file_path: file.file_path,
                                    file_name: file.file_name,
                                    file_type: file.file_type
                                }); })
                            }
                        }, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json'
                            },
                            withCredentials: true
                        })];
                case 1:
                    createResponse = _c.sent();
                    return [4 /*yield*/, axios.post("/api/connections/".concat(createResponse.data.id, "/upload-to-bigquery/"), {
                            files: uploadedFiles,
                            dataset_id: destination.dataset_id,
                            write_disposition: destination.write_disposition
                        }, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json'
                            },
                            withCredentials: true
                        })];
                case 2:
                    uploadResponse = _c.sent();
                    if (!(uploadResponse.data.status === 'completed')) return [3 /*break*/, 4];
                    toast.success('Files uploaded to BigQuery successfully');
                    setShowDestinationDialog(false);
                    fetchConnections();
                    // Clean up the temporary connection
                    return [4 /*yield*/, axios.delete("/api/connections/".concat(createResponse.data.id, "/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            withCredentials: true
                        })];
                case 3:
                    // Clean up the temporary connection
                    _c.sent();
                    _c.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_7 = _c.sent();
                    console.error('Upload error:', error_7);
                    if (axios.isAxiosError(error_7)) {
                        toast.error(((_b = (_a = error_7.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Failed to upload files to BigQuery');
                    }
                    else {
                        toast.error('Failed to upload files to BigQuery');
                    }
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var renderDestinationDialog = function () { return (React.createElement(Dialog, { open: showDestinationDialog, onClose: function () { return setShowDestinationDialog(false); }, maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, null, "Configure BigQuery Destination"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { fullWidth: true, label: "Dataset ID", value: destination.dataset_id, onChange: function (e) { return setDestination(function (prev) { return (__assign(__assign({}, prev), { dataset_id: e.target.value })); }); }, margin: "normal", required: true, helperText: "Enter the BigQuery dataset ID where tables will be created" }),
            React.createElement(FormControl, { fullWidth: true, margin: "normal" },
                React.createElement(InputLabel, null, "Write Disposition"),
                React.createElement(Select, { value: destination.write_disposition, onChange: function (e) { return setDestination(function (prev) { return (__assign(__assign({}, prev), { write_disposition: e.target.value })); }); } },
                    React.createElement(MenuItem, { value: "WRITE_TRUNCATE" }, "Replace existing data"),
                    React.createElement(MenuItem, { value: "WRITE_APPEND" }, "Append to existing data"),
                    React.createElement(MenuItem, { value: "WRITE_EMPTY" }, "Fail if table exists"))),
            React.createElement(Box, { sx: { mt: 2 } },
                React.createElement(Typography, { variant: "subtitle2", gutterBottom: true }, "Selected Files:"),
                uploadedFiles.map(function (file, index) { return (React.createElement(Typography, { key: index, variant: "body2", color: "textSecondary" }, file.file_name)); }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: function () { return setShowDestinationDialog(false); }, variant: "outlined" }, "Cancel"),
            React.createElement(Button, { onClick: handleUploadToBigQuery, variant: "contained", disabled: !destination.dataset_id }, "Upload to BigQuery")))); };
    var handleCreateSchedule = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post("/api/connections/".concat(selectedSourceConnection, "/schedules/"), scheduleFormData, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _a.sent();
                    toast.success('Schedule created successfully');
                    setIsScheduleModalOpen(false);
                    fetchSchedules();
                    return [3 /*break*/, 4];
                case 3:
                    error_8 = _a.sent();
                    toast.error('Failed to create schedule');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchSchedules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedSourceConnection) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get("/api/connections/".concat(selectedSourceConnection, "/schedules/"), {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _a.sent();
                    setSchedules(response.data);
                    return [3 /*break*/, 4];
                case 3:
                    error_9 = _a.sent();
                    toast.error('Failed to fetch schedules');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var renderScheduleModal = function () { return (React.createElement(Dialog, { open: isScheduleModalOpen, onClose: function () { return setIsScheduleModalOpen(false); }, maxWidth: "md", fullWidth: true },
        React.createElement(DialogTitle, null, "Create ETL Schedule"),
        React.createElement("form", { onSubmit: handleCreateSchedule },
            React.createElement(DialogContent, null,
                React.createElement(TextField, { fullWidth: true, label: "Schedule Name", value: scheduleFormData.name, onChange: function (e) { return setScheduleFormData(function (prev) { return (__assign(__assign({}, prev), { name: e.target.value })); }); }, margin: "normal", required: true }),
                React.createElement(FormControl, { fullWidth: true, margin: "normal" },
                    React.createElement(InputLabel, null, "Frequency"),
                    React.createElement(Select, { value: scheduleFormData.frequency, onChange: function (e) { return setScheduleFormData(function (prev) { return (__assign(__assign({}, prev), { frequency: e.target.value })); }); } },
                        React.createElement(MenuItem, { value: "HOURLY" }, "Hourly"),
                        React.createElement(MenuItem, { value: "DAILY" }, "Daily"),
                        React.createElement(MenuItem, { value: "WEEKLY" }, "Weekly"),
                        React.createElement(MenuItem, { value: "MONTHLY" }, "Monthly"))),
                React.createElement(TextField, { fullWidth: true, type: "time", label: "Start Time", value: scheduleFormData.start_time, onChange: function (e) { return setScheduleFormData(function (prev) { return (__assign(__assign({}, prev), { start_time: e.target.value })); }); }, margin: "normal", required: true })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: function () { return setIsScheduleModalOpen(false); }, variant: "outlined" }, "Cancel"),
                React.createElement(Button, { type: "submit", variant: "contained" }, "Create Schedule"))))); };
    var handleViewSchedules = function (connectionId) {
        setSelectedConnectionId(connectionId);
        setShowSchedules(true);
    };
    var renderConnections = function () {
        return connections.map(function (connection) { return (React.createElement(ConnectionCard, { key: connection.id },
            React.createElement("div", null,
                React.createElement(Typography, { variant: "h6" }, connection.name),
                React.createElement(Typography, { variant: "body2", color: "textSecondary" }, connection.connection_type)),
            React.createElement(ConnectionActions, null,
                React.createElement(ActionButton, { className: "secondary", onClick: function () { return handleTestConnection(connection.id); }, title: "Test Connection" },
                    React.createElement(TestIcon, null),
                    " Test"),
                React.createElement(ActionButton, { className: "secondary", onClick: function () { return handleViewSchedules(connection.id); }, title: "View Schedules" },
                    React.createElement(ClockIcon, null),
                    " Schedules"),
                React.createElement(ActionButton, { className: "primary", onClick: function () { return handleRunETL(connection.id); } },
                    React.createElement(RunIcon, null),
                    " Run ETL"),
                React.createElement(StyledIconButton, { onClick: function () { return handleEditConnection(connection); } },
                    React.createElement(EditIcon, null)),
                React.createElement(ActionButton, { className: "danger", onClick: function () { return handleDeleteConnection(connection.id, connection.name); } },
                    React.createElement(DeleteIcon, null),
                    " Delete")))); });
    };
    var handleSchedule = function (connectionId) {
        setSelectedSourceConnection(connectionId);
        setIsScheduleModalOpen(true);
    };
    var handleETLFormSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!etlFormData.dataset_id.trim()) {
                        toast.error('Dataset ID is required');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post("/api/connections/".concat(selectedSourceConnection, "/run_etl/"), etlFormData, {
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true
                        })];
                case 2:
                    response = _a.sent();
                    toast.success('ETL pipeline started successfully');
                    setIsETLModalOpen(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_10 = _a.sent();
                    console.error('ETL error:', error_10);
                    toast.error('Failed to start ETL pipeline');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var submitButtonText = editingConnection ? 'Update Connection' : 'Create Connection';
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Title, null, "Database"),
            React.createElement(AddButton, { onClick: handleAddConnection },
                React.createElement(DatabaseIcon, null),
                "Add Connection")),
        connections.length === 0 ? (React.createElement(Box, { sx: { textAlign: 'center', py: 4 } },
            React.createElement(Typography, { variant: "h6", color: "textSecondary" }, "No connections found"),
            React.createElement(Typography, { variant: "body2", color: "textSecondary" }, "Add a new connection to get started"))) : (renderConnections()),
        React.createElement(Dialog, { open: isModalOpen, onClose: function () {
                setIsModalOpen(false);
                setEditingConnection(null);
            }, maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, null, editingConnection ? 'Edit Connection' : 'Add New Connection'),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(DialogContent, null,
                    React.createElement(TextField, { fullWidth: true, label: "Connection Name", value: formData.name, onChange: function (e) { return handleFormChange('name', e.target.value); }, margin: "normal", required: true }),
                    React.createElement(FormControl, { fullWidth: true, margin: "normal", required: true },
                        React.createElement(InputLabel, null, "Connection Type"),
                        React.createElement(Select, { value: formData.connection_type, onChange: function (e) { return handleFormChange('connection_type', e.target.value); }, label: "Connection Type" },
                            React.createElement(MenuItem, { value: "postgresql" }, "PostgreSQL"),
                            React.createElement(MenuItem, { value: "mysql" }, "MySQL"),
                            React.createElement(MenuItem, { value: "bigquery" }, "BigQuery"),
                            React.createElement(MenuItem, { value: "snowflake" }, "Snowflake"),
                            React.createElement(MenuItem, { value: "csv" }, "CSV File"),
                            React.createElement(MenuItem, { value: "excel" }, "Excel File"),
                            React.createElement(MenuItem, { value: "athena" }, "AWS Athena"),
                            React.createElement(MenuItem, { value: "SYNAPSE" }, "Microsoft Synapse"))),
                    renderCredentialFields()),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: function () {
                            setIsModalOpen(false);
                            setEditingConnection(null);
                        }, variant: "outlined" }, "Cancel"),
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary" }, submitButtonText)))),
        React.createElement(Dialog, { open: isETLModalOpen, onClose: function () { return setIsETLModalOpen(false); }, maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, null, "Run ETL Pipeline"),
            React.createElement("form", { onSubmit: handleETLFormSubmit },
                React.createElement(DialogContent, null,
                    React.createElement(TextField, { fullWidth: true, label: "Dataset ID", value: etlFormData.dataset_id, onChange: function (e) { return setETLFormData(function (prev) { return (__assign(__assign({}, prev), { dataset_id: e.target.value })); }); }, margin: "normal", required: true, helperText: "Enter the BigQuery dataset ID" }),
                    React.createElement(FormControl, { fullWidth: true, margin: "normal" },
                        React.createElement(InputLabel, null, "Write Disposition"),
                        React.createElement(Select, { value: etlFormData.write_disposition, onChange: function (e) { return setETLFormData(function (prev) { return (__assign(__assign({}, prev), { write_disposition: e.target.value })); }); } },
                            React.createElement(MenuItem, { value: "WRITE_APPEND" }, "Append to existing data"),
                            React.createElement(MenuItem, { value: "WRITE_TRUNCATE" }, "Replace existing data"),
                            React.createElement(MenuItem, { value: "WRITE_EMPTY" }, "Fail if table exists"))),
                    React.createElement(Typography, { variant: "subtitle2", sx: { mt: 2, mb: 1 } }, "Cleaning Options"),
                    React.createElement(FormGroup, null,
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: etlFormData.cleaning_options.remove_duplicates, onChange: function (e) { return setETLFormData(function (prev) { return (__assign(__assign({}, prev), { cleaning_options: __assign(__assign({}, prev.cleaning_options), { remove_duplicates: e.target.checked }) })); }); } }), label: "Remove Duplicates" }),
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: etlFormData.cleaning_options.fill_null_values, onChange: function (e) { return setETLFormData(function (prev) { return (__assign(__assign({}, prev), { cleaning_options: __assign(__assign({}, prev.cleaning_options), { fill_null_values: e.target.checked }) })); }); } }), label: "Fill Null Values" }),
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: etlFormData.cleaning_options.standardize_dates, onChange: function (e) { return setETLFormData(function (prev) { return (__assign(__assign({}, prev), { cleaning_options: __assign(__assign({}, prev.cleaning_options), { standardize_dates: e.target.checked }) })); }); } }), label: "Standardize Date Formats" }),
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: etlFormData.cleaning_options.clean_string_values, onChange: function (e) { return setETLFormData(function (prev) { return (__assign(__assign({}, prev), { cleaning_options: __assign(__assign({}, prev.cleaning_options), { clean_string_values: e.target.checked }) })); }); } }), label: "Clean String Values (trim, normalize)" }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: function () { return setIsETLModalOpen(false); }, variant: "outlined" }, "Cancel"),
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary" }, "Start ETL Pipeline")))),
        renderDestinationDialog(),
        renderScheduleModal(),
        React.createElement(Dialog, { open: showSchedules, onClose: function () { return setShowSchedules(false); }, maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, null,
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
                    React.createElement(Typography, { variant: "h6" }, "Manage Schedules"),
                    React.createElement(IconButton, { onClick: function () { return setShowSchedules(false); } },
                        React.createElement(CloseIcon, null)))),
            React.createElement(DialogContent, null, selectedConnectionId && (React.createElement(Schedules, { connectionId: selectedConnectionId }))))));
};
export default DatabaseConnections;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
