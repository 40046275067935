var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"])));
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n"], ["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n"])));
var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 16px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 16px;\n  cursor: pointer;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var Form = function (_a) {
    var onSubmit = _a.onSubmit, children = _a.children;
    var handleSubmit = function (e) {
        e.preventDefault();
        onSubmit({});
    };
    return (React.createElement(StyledForm, { onSubmit: handleSubmit }, children));
};
export default Form;
var templateObject_1, templateObject_2, templateObject_3;
