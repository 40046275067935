var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var FooterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 0;\n  left: ", ";\n  right: 0;\n  padding: 20px 60px;\n  background: #FFFFFF;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-family: 'Inter';\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 110%;\n  color: #A1A1A1;\n  border-top: 1px solid #ECEAFF;\n  z-index: 1000;\n  height: 60px;\n  transition: left 0.3s ease;\n"], ["\n  position: fixed;\n  bottom: 0;\n  left: ", ";\n  right: 0;\n  padding: 20px 60px;\n  background: #FFFFFF;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-family: 'Inter';\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 110%;\n  color: #A1A1A1;\n  border-top: 1px solid #ECEAFF;\n  z-index: 1000;\n  height: 60px;\n  transition: left 0.3s ease;\n"])), function (props) { return props.sidebarOpen ? '284px' : '24px'; });
var LinkGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n\n  a {\n    color: #A1A1A1;\n    text-decoration: none;\n    \n    &:hover {\n      color: #8F4ACF;\n    }\n  }\n"], ["\n  display: flex;\n  gap: 20px;\n\n  a {\n    color: #A1A1A1;\n    text-decoration: none;\n    \n    &:hover {\n      color: #8F4ACF;\n    }\n  }\n"])));
var Footer = function (_a) {
    var sidebarOpen = _a.sidebarOpen;
    return (React.createElement(FooterContainer, { sidebarOpen: sidebarOpen },
        React.createElement("span", null, "\u00A9 2024 Cognitio Intelligence"),
        React.createElement(LinkGroup, null,
            React.createElement("a", { href: "/about" }, "About"),
            React.createElement("a", { href: "/support" }, "Support"),
            React.createElement("a", { href: "/contact" }, "Contact Us"))));
};
export default Footer;
var templateObject_1, templateObject_2;
